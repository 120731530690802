import React, { Component } from 'react';
import { Row, Col, Button, Divider } from 'antd';
import LoginLogo from '../../assets/Logos/vrsafety-login-logo-teal.png';
import PoweredWithLove from '../../assets/PoweredWithLove/PoweredWithLove';
import { Link } from 'react-router-dom';

class LoginMain extends Component<any, any> {
    _isMounted = false;
    constructor(props: any) {
        super(props);
        this.state = {};
        this._isMounted = false;
    }

    componentWillUnmount() {
        this._isMounted = true;
    }

    render() {
        return (
            <div className="ant-card loginCard">
                <Row>
                    <Col span={12} style={{ textAlign: 'center' }}>
                        <img
                            src={LoginLogo}
                            alt="Logo"
                            style={{ height: '200px' }}
                        />
                    </Col>
                    <Col span={12} style={{ textAlign: 'center' }}>
                        <Row>
                            <h1 style={{ color: '#fff' }} className="bebas">USER LOGIN</h1>
                            <Button className="bsd-btn-teal">
                                <Link to={`/user/login`}>User Login</Link>
                            </Button>
                        </Row>
                        <Divider />
                        <Row>
                            <h1 style={{ color: '#fff' }} className="bebas">MANAGER LOGIN</h1>
                            <Button className="bsd-btn-orange">
                                <Link to={`/management/login`}>
                                    Manager Login
                                </Link>
                            </Button>
                        </Row>
                        <Row
                            style={{ marginTop: '30px', marginBottom: '20px' }}
                        >
                            <Link
                                className="bsd-text-teal"
                                to={`/forgot-password`}
                            >
                                Forgot password
                            </Link>
                        </Row>
                    </Col>
                </Row>
                <Row
                    style={{
                        textAlign: 'center',
                        color: '#fff',
                        padding: '0px',
                        margin: '0px',
                    }}
                >
                    <PoweredWithLove />
                </Row>
            </div>
        );
    }
}

export default LoginMain;
