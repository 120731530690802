import React, { Component } from 'react';
import { Radio } from 'antd';
import { getCurrentDate } from '../../utils/tools';
interface State 
{
    value:number;
};

class ManageUserAccess extends Component<any, State> {
    state:State={
        value:0
    }
    public constructor(props:any){
        super(props);
      }

      componentDidMount(){
      }

      onChange = (e:any) => {
        if(e.target.value !== this.props.userAccessData.accessLevel)
        {
            let newAccess = this.getChangeUserAccessLevelObject(e.target.value);
            this.props.updateUserAccess(newAccess);
        }

        //console.log('radio checked', e.target.value);
        this.setState({
          value: e.target.value,
        });
      };
      getChangeUserAccessLevelObject(access:number){
        return{
            Id:this.props.userAccessData.id,
            UserId: this.props.userAccessData.userId,
            CompanyId:this.props.userAccessData.companyId,
            AccessLevel:access,
            SubmittedAt: getCurrentDate()
        }
      }

      render() {
        return (
           <div style={{margin: "30px 0"}}>
               <Radio.Group onChange={this.onChange} value={this.props.userAccessData.accessLevel}>
                    <Radio value={0}>Public</Radio>
                    <Radio value={1}>Internal</Radio>
                    <Radio value={2}>Private</Radio>
                </Radio.Group>
           </div>
        );
}
}
export default ManageUserAccess;