import * as React from 'react';
import { Row, Col, } from 'antd';
import { getGradeStyle } from "../../../utils/tools";

export interface IAppProps {
    classGrade: string,
    classTime: string,
    completed: string,
    layout: string
}

export function ClassStatistics(props: any) {
    return (
        <Row type="flex" align="middle" justify="space-between" id="ClassCourseInfo">
        <Col xs={24} sm={24} md={12}>
            <Row>
                <div className={props.layout.h3Color}>
                    <span>Class Statistics</span>
                </div>
            </Row>
        </Col>
        <Col xs={24} sm={24} md={12}>
            <Row type="flex" align="middle" justify="space-between">
            <Col className="progressStatsStyle">
            <span className={`smallText ${props.auth.layout.span}`}>Students Completed</span>
            <div style={{textAlign:"center", alignContent:"baseline"}} > <h3  style={{fontWeight:"bold"}} className={props.layout.h3Color}>{props.completed}</h3></div>
            </Col>
            <Col className="progressStatsStyle">
            <span className={`smallText ${props.auth.layout.span}`}>Average Time Spent</span>
            <div style={{textAlign:"center", alignContent:"baseline"}} > <h3 style={{fontWeight:"bold"}} className={props.layout.h3Color}>{props.classTime}</h3></div>
            </Col>
            <Col>
            <span className={`smallText ${props.auth.layout.span}`}>Class Average</span>
            <Row type="flex" align="middle" justify="end">
                <h3 style={{fontWeight:"bold"}} className={getGradeStyle(props.classGrade)}>{props.classGrade}</h3>
            </Row>
            </Col>
            </Row>
         
        </Col>
        </Row>
    );
  }