import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login, logout } from '../../actions/authActions';
import { changeManagement } from '../../actions/management-actions/management-actions';
import { isValid } from '../../utils/tools';
import { Form, Icon, Input, Button, Layout, message } from 'antd';
import { Link } from 'react-router-dom';
import {
    defaultUserCompPermission,
    userCompanyPermission,
} from '../../actions/permissionActions';
import { loginUser, loginUserPerms, userPermission } from '../../api/JanusHostApiPost';
import { Permission } from '../../Interfaces/Permission';
import { setCompanyHeaderLogin } from '../../utils/setAuthToken';
import axios from 'axios';

interface Props {
    login: (data: string) => {};
    logout: Function;
    changeManagement: Function;
    defaultUserCompPermission: Function;
    userCompanyPermission: Function;
    location: any;
}

interface State {
    email: string;
    password: string;
    errors: any;
    loading: boolean;
    layout: any;
}

class LoginForm extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            errors: [],
            loading: true,
            layout: {}
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        await this.setState({ loading: false });
    }

    async onSubmit(e: any) {
        this.setState({ loading: true });
        e.preventDefault();
        let checker = isValid({
            email: this.state.email,
            password: this.state.password,
        });
        if (checker.isValid) {
            await loginUser(
                {
                    email: this.state.email,
                    password: this.state.password,
                },
                this.UserLoginSuccess,
                this.error
            );
        }
        this.setState({ errors: checker.errors, loading: false });
    }

    error = (error: any) => {
        message.error(error.response.data);
        console.log(error);
    }

    UserLoginSuccess = async (data: any) => {
        console.log(data);
        await this.props.login(data);
        await loginUserPerms(this.successUserLoginPerms);
        //get companies
        // pop up window with little info
        // manage a company-> send data
    };

    successUserLoginPerms = async (data: any) => {
        console.log(data);
        this.setState({ layout: data.company.layoutClasses });
        localStorage.setItem('layout', JSON.stringify(data.company.layoutClasses, null, 2));
        let userCompService = {
            params: {
                User: data.userId,
                Company: data.companyId,
                Service: data.serviceId,
                Role: 'User',
            },
        };
        console.log(userCompService);
        setCompanyHeaderLogin(data.company);
        userPermission(
            userCompService,
            this.successPermissions,
            this.error
        );
    };

    successPermissions = async (data: any) => {
        console.log(data);
        await this.successUserPermissions(data, data.company)
    }

    successUserPermissions = async (data: Permission, company: any) => {
        console.log(data);
        let perms: Permission = {
            company: {
                id: company.id,
                logo: company.logoImage,
                banner: company.bannerImage,
                name: company.legalEntityName,
            },
            relation: {
                connected: data.relation.connected,
                permissions: data.relation.permissions,
                role: data.relation.role,
            },
            service: data.service,
            components: {}
        };
        perms.service.layout = this.state.layout;
        console.log(perms);
        console.log(this.state.layout);
        await axios.get(`/api/v1/vrsafety/component/getComponentAccess`)
            .then((res: any) => {
                let components: any = {
                    names: [],
                    paths: []
                };
                components.names = res.data;
                components.paths = ["/overview", "/manage", "/coursedetails/:id", "/manageAccess", "/home", "/details/:id", "/"];
                if (res.data.includes("Tour Builder")) {
                    components.paths.push("/tour/:component/:id?", "/viewer/:id");
                }
                if (res.data.includes("Grades")) {
                    components.paths.push("/manage-grades", "/teammate-grades/:id", "/grades");
                }
                if (res.data.includes("Tags"))
                    components.paths.push("/viewTags", "/updateTag/:id", "/createTag");

                if (res.data.includes("Company Profile")) {
                    components.paths.push("/company");
                }
                if (res.data.includes("Progress"))
                    components.paths.push("/progress");
                if (res.data.includes("Manage Access"))
                    components.paths.push("/manageAccess");

                perms.components = components;
            })
            .catch((err: any) => {
                console.log(err);
            })
        console.log(perms);
        await this.props.changeManagement(
            {
                menu: 'VRSafety Login',
                role: data.relation.role,
            },
            company
        );

        await this.props.userCompanyPermission(perms);
        window.location.href = '/home';
    };

    handleChange = (input: any) => (e: any) => {
        this.setState({ [input]: e.target.value } as any);
    };

    render() {
        return (
            <div
                style={{
                    textAlign: 'center',
                    minHeight: 280,
                    maxWidth: 300,
                    margin: '0 auto',
                }}
            >
                <h1 className="tealText bebas">User Login</h1>
                <Form onSubmit={this.onSubmit} className="login-form">
                    <Form.Item
                        validateStatus={this.state.errors.email ? 'error' : ''}
                        help={this.state.errors.email || ''}
                    >
                        <Input
                            prefix={
                                <Icon
                                    type="mail"
                                    style={{ color: 'rgba(0,0,0,.25)' }}
                                />
                            }
                            type="email"
                            value={this.state.email}
                            placeholder="Email"
                            onChange={this.handleChange('email')}
                        />
                    </Form.Item>
                    <Form.Item
                        validateStatus={
                            this.state.errors.password ? 'error' : ''
                        }
                        help={this.state.errors.password || ''}
                    >
                        <Input
                            prefix={
                                <Icon
                                    type="lock"
                                    style={{ color: 'rgba(0,0,0,.25)' }}
                                />
                            }
                            type="password"
                            value={this.state.password}
                            placeholder="Password"
                            onChange={this.handleChange('password')}
                        />
                    </Form.Item>
                    <Form.Item>
                        {this.state.loading ? (
                            <Button type="primary" loading>
                                Loading
                            </Button>
                        ) : (
                            <Button
                                htmlType="submit"
                                style={{
                                    padding: '0px 30px',
                                }}
                                className={`bsd-btn-teal`}
                            >
                                Sign in
                            </Button>
                            )}
                        <br />
                        <Link
                            className={`bsd-text-teal`}
                            to={`/forgot-password`}
                        >
                            Forgot password
                        </Link>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

export default connect(null, {
    login,
    logout,
    changeManagement,
    defaultUserCompPermission,
    userCompanyPermission,
})(LoginForm);
