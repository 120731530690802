import React, { Component } from 'react';
import { connect } from 'react-redux';
import { message, Col, Row, Button, Avatar, Typography, Icon, Tabs } from 'antd';
import { getProfile } from '../../api/JanusHostApiGet';
import ProfilePage from './ProfilePage';
import ProfileSettings from './ProfileSettings';

const { TabPane } = Tabs;
interface State {
    profile: any
};



class UserProfile extends Component<any, State>{
    state: State = {
        profile: {}
    }
    public constructor(props: any) {
        super(props);
    }
    componentDidMount() {
        getProfile(this.successProfile, this.getError);
    }

    successProfile = (data:any) => {
        console.log(data);
        this.setState({ profile: data.data });
    }


    getError = (data: any) => {
        message.error("There was a problem getting information. Check the console");
        console.log(data);
    }
    render() {
        return (
            <React.Fragment>
                <Row>
                    <Tabs className={ this.props.auth.layout.styleClass} defaultActiveKey="1" tabPosition={'left'}>
                        <TabPane tab="Profile" key="1">
                            <ProfilePage
                                profile={this.state.profile}
                            />
                        </TabPane>
                        <TabPane tab="Edit Profile" key="2">
                            <ProfileSettings
                                profile={this.state.profile}
                            />
                        </TabPane>
                    </Tabs>
                </Row>
            </React.Fragment>
        );
    }
}

function mapToStateToProps(state: any) {
    return {
        auth: state.auth,
        management: state.management,

    };
}
export default connect(mapToStateToProps)(UserProfile);