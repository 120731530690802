import React, { Component } from 'react';
import { connect } from 'react-redux';
import { message, Col, Row, Button, Avatar, Typography, Icon, Form, Upload, Input, Select, Switch } from 'antd';
import { getCurrentDate, isValid, objectToFormData } from '../../utils/tools';
import { editProfile, profileImage } from '../../api/JanusHostApiPost';
import { timeZonesNames } from "@vvo/tzdb";
const { Option } = Select;

class ProfileSettings extends Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            avatarImage: '',
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            jobPosition: '',
            address: '',
            city: '',
            province: '',
            postalCode: '',
            tutorialDone: false,
            submittedAt: '',
            errors: [],
            loading: true,
            imgLoading: true,
            timeZone: ''
        };
        this.onSubmit = this.onSubmit.bind(this);
    }
    componentDidMount=async()=> {
        console.log(this.props);
        await this.setState({
            email: this.props.profile.email, firstName: this.props.profile.firstName, avatarImage: this.props.profile.avatarImage,
            lastName: this.props.profile.lastName, phoneNumber: this.props.profile.phoneNumber, jobPosition: this.props.profile.userInformation.jobPosition,
            address: this.props.profile.userInformation.address, city: this.props.profile.userInformation.city, province: this.props.profile.userInformation.province,
            postalCode: this.props.profile.userInformation.postalCode, tutorialDone: this.props.profile.userInformation.tutorialDone, loading: false,
            imgLoading: false, timeZone: this.props.profile.userInformation.timeZone
        });
    }

    handleChange = (input: any) => async (e: any) => {
        if (typeof e === 'object') {
            await this.setState({ [input]: e.target.value } as any);
        }
        else {
            await this.setState({ [input]: e } as any);
        }
    }

    UpdateProfileViewModel(value: any) {

        return {
            id: (this.props.profile.id !== '') ? this.props.profile.id : this.props.userId,
            email: value.email,
            firstName: value.firstName,
            lastName: value.lastName,
            phoneNumber: value.phoneNumber,
            jobPosition: value.jobPosition,
            address: value.address,
            city: value.city,
            province: value.province,
            postalCode: value.postalCode,
            tutorialDone: value.tutorialDone,
            submittedAt: getCurrentDate(),
            timeZone: value.timeZone
        }
    }

    async onSubmit(e: any) {
        this.setState({ loading: true });
        e.preventDefault();
        let updateProfile = this.UpdateProfileViewModel(this.state);
        console.log(updateProfile);
        let checker = isValid(updateProfile);
        if (checker.isValid) {
            let formModel = objectToFormData(updateProfile);
            editProfile(formModel, this.updateSuccess, this.updateError);
        }
        this.setState({ errors: checker.errors, loading: false });
    }

    updateSuccess = async (res: any) => {
        console.log(res);
        await this.setState({
            email: res.email, firstName: res.firstName,
            lastName: res.lastName, phoneNumber: res.phoneNumber, jobPosition: res.userInformation.jobPosition,
            address: res.userInformation.address, city: res.userInformation.city, province: res.userInformation.province,
            postalCode: res.userInformation.postalCode, tutorialDone: res.userInformation.tutorialDone, timeZone: res.userInformation.timeZone,
            loading: false
        });
        message.success("Profile Updated successfully.");
    }

    updateError = (err:any) => {
        this.setState({ loading: false });
        console.log(err);
        message.error(err.response.data);
    }

    beforeUpload(file: any) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    getBase64(img: any, callback: any) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    handleImageChange = async (info: any) => {
        if (info.file.status === 'uploading') {
            this.setState({ imgLoading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            this.getBase64(info.file.originFileObj, (avatarImage: string) =>
                this.setState({
                    avatarImage,
                    imgLoading: false,
                }),

            );
        }

        let response = info.file;
        if (response.hasOwnProperty('xhr')) {

            await profileImage({
                avatarImage: response.xhr.responseText,
                submittedAt: getCurrentDate()
            }, (res:any) => { console.log(res) }, (err:any) => {console.log(err) } )
        }
    };



    getError = (data: any) => {
        message.error("There was a problem getting information. Check the console");
        console.log(data);
    }
    render() {
        var fontColor, h1Color, bgColor;
        switch (this.props.auth.layout) {
            case "VRSafety":
                fontColor = "blackText";
                h1Color = "orangeText";
                bgColor = "whiteBG";
                break;
            case "RealX":
                fontColor = "whiteText";
                h1Color = "rxlightPinkText";
                bgColor = "blackBG";
                break;
            default:
                fontColor = "blackText";
                h1Color = "orangeText";
                bgColor = "whiteBG";
                break;
        }
        const formItemLayout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        }
        return (
            <React.Fragment>
                <Row>
                    <div>
                        <Upload
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="/api/v1/file/uploadProfileImage"
                            beforeUpload={this.beforeUpload}
                            onChange={this.handleImageChange}
                        >
                            {
                                this.state.avatarImage ?
                                    <img src={this.state.avatarImage} alt="avatar" style={{ width: '100%' }} />
                                    :
                                    <div>
                                        <Icon type={this.state.imgLoading ? 'loading' : 'plus'} />
                                        <div className="ant-upload-text">Upload</div>
                                    </div>
                            }
                        </Upload>
                    </div>
                    <Row>
                        <Col xs={24} sm={24} md={18}>
                            <Form onSubmit={this.onSubmit} className="login-form" style={{}} labelAlign="left" labelCol={{ span: 2, offset: 12 }}>
                                <Form.Item {...formItemLayout} validateStatus={this.state.errors.firstName ? 'error' : ''} help={this.state.errors.firstName || ''}><br />
                                    <label className={this.props.auth.layout.secondaryColor}>First Name</label>
                                    <Input
                                        // prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type="text" value={this.state.firstName}
                                        placeholder="First Name" onChange={this.handleChange('firstName')}
                                    />
                                </Form.Item>
                                <Form.Item {...formItemLayout} validateStatus={this.state.errors.lastName ? 'error' : ''} help={this.state.errors.lastName || ''}><br />
                                    <label className={this.props.auth.layout.secondaryColor}>Last Name</label>
                                    <Input
                                        // prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type="text" value={this.state.lastName}
                                        placeholder="Last Name" onChange={this.handleChange('lastName')}
                                    />
                                </Form.Item>
                                <Form.Item {...formItemLayout} validateStatus={this.state.errors.jobPosition ? 'error' : ''} help={this.state.errors.jobPosition || ''}><br />
                                    <label className={this.props.auth.layout.secondaryColor}>Job Title</label>
                                    <Input
                                        // prefix={<Icon type="fire" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type="text" value={this.state.jobPosition}
                                        placeholder="Job Title" onChange={this.handleChange('jobPosition')}
                                    />
                                </Form.Item>
                                <Form.Item {...formItemLayout} validateStatus={this.state.errors.email ? 'error' : ''} help={this.state.errors.email || ''}><br />
                                    <label className={this.props.auth.layout.secondaryColor}>Email</label>
                                    <Input
                                        // prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type="email" value={this.state.email} disabled
                                        placeholder="Email"
                                    />
                                </Form.Item>
                                <Form.Item {...formItemLayout} validateStatus={this.state.errors.phoneNumber ? 'error' : ''} help={this.state.errors.phoneNumber || ''}><br />
                                    <label className={this.props.auth.layout.secondaryColor}>Phone Number</label>
                                    <Input
                                        // prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type="text" value={this.state.phoneNumber}
                                        placeholder="Phone Number" onChange={this.handleChange('phoneNumber')}
                                    />
                                </Form.Item>

                                <Form.Item {...formItemLayout} validateStatus={this.state.errors.address ? 'error' : ''} help={this.state.errors.address || ''}><br />
                                    <label className={this.props.auth.layout.secondaryColor}>Address</label>
                                    <Input
                                        // prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type="text" value={this.state.address}
                                        placeholder="Address" onChange={this.handleChange('address')}
                                    />
                                </Form.Item>
                                <Form.Item {...formItemLayout} validateStatus={this.state.errors.city ? 'error' : ''} help={this.state.errors.city || ''}><br />
                                    <label className={this.props.auth.layout.secondaryColor}>City</label>
                                    <Input
                                        // prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type="text" value={this.state.city}
                                        placeholder="City" onChange={this.handleChange('city')}
                                    />
                                </Form.Item>
                                <Form.Item {...formItemLayout} validateStatus={this.state.errors.province ? 'error' : ''} help={this.state.errors.province || ''}><br />
                                    <label className={this.props.auth.layout.secondaryColor}>Province</label>
                                    <Input
                                        // prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type="text" value={this.state.province}
                                        placeholder="Province" onChange={this.handleChange('province')}
                                    />
                                </Form.Item>
                                <Form.Item {...formItemLayout} validateStatus={this.state.errors.postalCode ? 'error' : ''} help={this.state.errors.postalCode || ''}><br />
                                    <label className={this.props.auth.layout.secondaryColor}>Postal Code</label>
                                    <Input
                                        // prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type="text" value={this.state.postalCode}
                                        placeholder="Postal Code" onChange={this.handleChange('postalCode')}
                                    />
                                </Form.Item>
                                <Form.Item {...formItemLayout} validateStatus={this.state.errors.timeZone ? 'error' : ''} help={this.state.errors.timeZone || ''}><br />
                                    <label className={this.props.auth.layout.secondaryColor}>Time Zone</label>
                                    <Select
                                        showSearch
                                        style={{ width: 200 }}
                                        placeholder="Time Zone"
                                        optionFilterProp="children"
                                        //onSearch={this.onSearch}
                                        onChange={this.handleChange('timeZone')}
                                        value={this.state.timeZone}
                                        filterOption={(input: any, option: any) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option value="">Select One</Option>
                                        {
                                            timeZonesNames.map((zone: any) =>

                                                <Option key={zone}
                                                    value={zone}>
                                                    {zone}
                                                </Option>)
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item {...formItemLayout} validateStatus={this.state.errors.tutorialDone ? 'error' : ''} help={this.state.errors.tutorialDone || ''}><br />
                                    <label className={this.props.auth.layout.secondaryColor}>Tutorial Check</label>
                                    <Switch onChange={this.handleChange('tutorialDone')} checked={this.state.tutorialDone} />
                                </Form.Item>
                                <Form.Item {...formItemLayout}>
                                    <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.loading}>
                                        Update Profile
                        </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col xs={24} sm={24} md={12}></Col>
                    </Row>
                </Row>
            </React.Fragment>
        );
    }
}

function mapToStateToProps(state: any) {
    return {
        auth: state.auth,
        management: state.management,

    };
}
export default connect(mapToStateToProps)(ProfileSettings);