import React, { Component} from 'react';
import Layout from './Layout';
import { Route, Switch } from "react-router-dom";
import "./App.css";
import "./realxStyles.css";
import checkManagmentPermissions from '../actions/managementPermissions';
import LandingPage from '../components/LandingPage/LandingPage';
import CompanyProfile from '../components/CompanyProfile/CompanyProfile';
import ManagePage from '../components/ManagePage/ManagePage';
import ViewAllTours from '../components/ViewAllTours/ViewAllTours';
import ManageAccess from '../components/ManageAccess/ManageAccess';
import ProgressBar from '../components/Tours/ProgressBar/ProgressBar';
import WebViewer from '../components/WebViewer/WebViewer';
import SharedViewer from '../components/SharedViewer/SharedViewer';
import UserProgress from '../components/UserProgress/UserProgress';
import CourseProfile from '../components/CourseProfile/User/CourseProfile';
import CourseManagementProfile from '../components/CourseProfile/Management/CourseProfile';
import UserGrades from '../components/Grades/UserGrades';
import ManageGrades from '../components/ManageGrades/ManageGrades';
import TeammateGrades from '../components/TeammateGrades/TeammateGrades';
import MainLanding from '../components/MainLanding/MainLanding';
import LoginMain from '../components/LoginMain/LoginMain';
import Login from '../components/Login/Login';
import LoginManagement from '../components/LoginManagement/LoginManagement';
import UrlShortener from '../components/URLShortener/UrlShortener';
import ForgotPassword from '../components/ForgotPassword/ForgotPassword';
import ManageComponentAccess from '../components/ManageComponentAccess/ManageComponentAccess';

class App extends Component<{}, {}> {  
  render() {
    return(
      <Layout>
        <Switch>
            <Route exact path='/landing' component={MainLanding} />
            <Route exact path='/home' component={checkManagmentPermissions(LandingPage)} />
            <Route exact path='/overview' component={checkManagmentPermissions(LandingPage)} />
            <Route exact path='/company' component={checkManagmentPermissions(CompanyProfile)} />
            <Route exact path='/manage' component={checkManagmentPermissions(ManagePage)} />
            <Route exact path='/progress' component={checkManagmentPermissions(UserProgress)} />
            <Route exact path='/grades' component={checkManagmentPermissions(UserGrades )} />
            <Route exact path='/details/:id' component={checkManagmentPermissions(CourseProfile)} />
            <Route exact path='/coursedetails/:id' component={checkManagmentPermissions(CourseManagementProfile)} />
            <Route exact path='/viewer/:id' component={checkManagmentPermissions(WebViewer)} />
            <Route path='/shared' component={checkManagmentPermissions(SharedViewer)} />
            <Route exact path='/viewTours' component={checkManagmentPermissions(ViewAllTours)} />
            <Route exact path='/tour/:component/:id?' component={checkManagmentPermissions(ProgressBar)} />
            <Route exact path='/manageAccess' component={checkManagmentPermissions(ManageAccess)} />
            <Route exact path='/manage-grades' component={checkManagmentPermissions(ManageGrades)} />
            <Route exact path='/teammate-grades/:id' component={checkManagmentPermissions(TeammateGrades)} />
            <Route exact path= '/' component={checkManagmentPermissions(LoginMain)}/>,
            <Route exact path='/login' component={checkManagmentPermissions(LoginMain)}/>,
            <Route exact path='/manageComponentAccess' component={checkManagmentPermissions(ManageComponentAccess)}/>,
            <Route exact path='/user/login' component={Login}/>,
            <Route exact path='/management/login' component={LoginManagement}/>,
            <Route exact path='/link' component={UrlShortener} />,
            <Route exact path='/forgot-password' component={ForgotPassword} />,
        </Switch>
      </Layout>
    )
  }
}
export default App;