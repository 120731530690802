import React, { Component,} from 'react';
import { connect } from 'react-redux';
import { message, Col, Row, Button, Progress, Carousel, Typography, Icon, Modal, Input, Switch, Spin, Table } from 'antd';
import { getTourProfileInformation, getAvailableCourses, getSharedTourInfo, getTourUsers, getCriticalHotspots } from '../../../api/APIget';
import { BrowseCourses } from '../../BrowseCourses/browseCourses';
import { getCurrentDate, getDateDMY, getGrade, getGradePoints, getGradeFromPoints, averageTime } from '../../../utils/tools';
import { CourseStats } from './CourseStats';
import { SharedStats } from './SharedStats';
import { createdSharedTourData } from '../../../api/APIpost';
import { createSharedLink, refreshSharedLink } from '../../../api/JanusHostApiPost';
import { getConnectedUsers } from '../../../api/JanusHostApiGet';
import TeamProfiles from '../../TeamProfiles/teamProfiles';

const { Paragraph } = Typography;
let searchInput: any = {};
interface filterDropDown
{
setSelectedKeys: any,
selectedKeys: any,
confirm: any,
clearFilters:any
}
interface State {
    loading: boolean,
    tour:any,
    tourProgress:any,
    hascourseProgress:boolean,
    coursesCurrentlyInProgress:any[],
    coursesCompleted:any[],
    availableCourses:any[],
    company:any,
    password:string,
    hasPassword:boolean,
    showLinkModal:boolean,
    shareLink:string,
    sharedId: string,
    teamMates: any[],
    tourUser: any[],
    userInfo: any[],
    searchText: string,
    searchedColumn: string,
    inProgress: any[],
    completed: any[],
    time: string,
    criticalHotspots: any[],
    averageGrade: string,
    failed: number,
    filteredTours: any[],
    showRefreshModal: boolean,
    sharedStats: any
};
class CourseManagementProfile extends Component<any, State> {
    state:State={
        loading:false,
        tour:{},
        tourProgress:{},
        hascourseProgress:false,
        coursesCurrentlyInProgress:[],
        coursesCompleted:[],
        availableCourses:[],
        company:{},
        password:"!23QweAsd",
        hasPassword:true,
        showLinkModal:false,
        sharedId:"",
        shareLink: "",
        teamMates: [],
        tourUser: [],
        userInfo: [],
        searchText: '',
        searchedColumn: '',
        inProgress: [],
        completed: [],
        time: '',
        criticalHotspots: [],
        averageGrade: '-',
        failed: 0,
        filteredTours: [],
        showRefreshModal: false,
        sharedStats: {}
    }
    private carousel = React.createRef<Carousel>();
    public constructor(props:any){
        super(props);
      }
      componentDidMount(){
        //get company
        //console.log(this.props);
        //getUserTourProgress({userId:this.props.auth.user.nameid,tourId:this.props.match.params.id},this.getSuccess,this.getError);
        getTourProfileInformation(this.props.match.params.id,this.getInfoSuccess,this.getError)
        //getUserProgress(this.props.auth.user.nameid,this.getProgressSuccess,this.getError);
        getAvailableCourses(this.props.auth.user.nameid,this.getAvailableSuccess,this.getError);
        getSharedTourInfo(this.props.match.params.id,this.getSharedLinkSuccess,this.getSharedLinkError);
        getCriticalHotspots(this.props.match.params.id,this.getTourSuccess,this.getError);
        
      }

    getTourSuccess = (data: any) => {
        //console.log(data.data);
        
        this.setState({ criticalHotspots: data.data });
    }

    getUserSuccess = (data: any) => {
        //console.log(data);
        this.setState({tourUser: data.data}, () => {
              getConnectedUsers(this.props.auth.company.id, this.getUsersSuccess, this.getUsersError);
        });
          //console.log(this.state.tourUser);
        /*if (this.state.tourUser.length !== 0) {
            console.log(this.state.tourUser);
            let inProgress = this.state.tourUser.filter((data: any) => { return (data.currentPano / data.totalPano) != 1.0; });
            let completed = this.state.tourUser.filter((data: any) => { return (data.currentPano / data.totalPano) == 1.0; });
            //console.log(inProgress);
            //console.log(completed);
            this.setState({ inProgress: inProgress });
            this.setState({ completed: completed });
        }*/
      }

      getSuccess=(data:any)=>
      {
        //console.log(data.data);
        var hasCourseProgress = false
        //console.log(Object.keys(data.data).length);
        //console.log(Object.keys(data.data).length > 0);
        if(Object.keys(data.data).length > 0){
          hasCourseProgress = true
        }
        this.setState({tourProgress:data.data, hascourseProgress:hasCourseProgress})
      }

      getInfoSuccess=(data:any)=>
      {
        //console.log(data.data);
        this.setState({tour:data.data});
        getTourUsers(data.data.tourId, this.getUserSuccess, this.getError);
      }
      getProgressSuccess=(data:any)=>
      {
        //console.log(data.data);
          let currentProgress = data.data.filter((data: any) => { return data.tourProgress.completedAt > getCurrentDate()});
          let currentCompleted = data.data.filter((data: any) => { return data.tourProgress.completedAt <= getCurrentDate()});
        this.setState((state)=>({coursesCurrentlyInProgress:currentProgress,coursesCompleted:currentCompleted}));
      }
      getAvailableSuccess=(data:any)=>
      {
        //console.log(data.data);
        let available=data.data.filter((tour:any)=>tour.id !== this.props.match.params.id )
        this.setState((state)=>({availableCourses:available, filteredTours: available}));
      }
      getSharedLinkSuccess=(data:any)=>
      {
          console.log(data);
        this.setState((state)=>{return{sharedId:data.data.id, sharedStats:data.data}});
      }

      getUsersSuccess=(data:any)=>
      {
        //console.log(data);
        let teamMates = data.data.connections.filter((user:any)=>user.user.firstName!=="shared");
        //console.log(teamMates);
        this.setState({teamMates:teamMates});
        //console.log(this.state.tourUser);
          let points = 0;// to store total grade points for average later;
          let length = 0;
          let users = [];
          let failed = 0;
          for (let i = 0; i < this.state.tourUser.length; i++) {
              for (let j = 0; j < teamMates.length; j++) {

                  if (this.state.tourUser[i].userId == teamMates[j].user.id) {
                      let grade = getGrade(this.state.tourUser[i], this.state.criticalHotspots);
                      if (grade !== '-') {
                          points = points + getGradePoints(grade);
                          length++;
                      }
                      if (grade === 'F') {
                          failed++;
                      }
                      let user = {
                          "id": teamMates[j].user.id,
                          "name": teamMates[j].user.firstName + " " + teamMates[j].user.lastName,
                          "completedAt": (this.state.tourUser[i].completed) ? getDateDMY(this.state.tourUser[i].completedAt):'-',
                          "startedAt": getDateDMY(this.state.tourUser[i].startedAt),
                          "timeSpent": this.state.tourUser[i].timeSpent,
                          "pass":
                              (this.state.tourUser[i].completed)
                                  ?
                                  (grade !== 'F')
                                      ? <span className="tealText">Pass</span>
                                      : <span className="redText">Fail</span>
                                  : '-',
                          "grade": grade
                      }
                      users.push(user);
                  }
              }
          }
          let averageGrade = getGradeFromPoints(Math.round(points / length));
          let inProgress = this.state.tourUser.filter((data: any) => { return (data.completedAt > getCurrentDate()) });
          let completed = this.state.tourUser.filter((data: any) => { return (data.completedAt <= getCurrentDate()) });
          this.setState({
              completed: completed,
              inProgress: inProgress,
              userInfo: users,
              averageGrade: averageGrade,
              failed: failed
          }); 

          //time
          var seconds=0;
        var minutes=0;
        var hours=0;
        for (let i = 0; i < this.state.completed.length; i++) {
            var times = [0, 0, 0]
            var max = times.length

            var a = (this.state.completed[i].timeSpent || '').split(':')

            // normalize time values
            for (var j = 0; j < max; j++) {
                a[j] = isNaN(parseInt(a[j])) ? 0 : parseInt(a[j]);
            }


            hours = hours + a[0];
            minutes = minutes + a[1];
            seconds = seconds + a[2];

            if (seconds >= 60) {
                var m = (seconds / 60) << 0
                minutes += m
                seconds -= 60 * m
            }

            if (minutes >= 60) {
                var h = (minutes / 60) << 0
                hours += h
                minutes -= 60 * h
            }

            

        }
        if (minutes == 0 && hours == 0 && seconds == 0) {
            this.setState({time: "00:00:00"})
        }
        else {
            let total = ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);
            let average = averageTime(total, this.state.completed.length);
            this.setState({ time: average });
        }
          //console.log(users);
      }

      getSharedLinkError=(data:any)=>
      {
        if(typeof data.data!=="undefined")
        {
          //console.log(data);
          alert(data.data);
        }
        
      }
      getUsersError = (data:any)=>
      {
        //console.log(data);
      }
      getError=(data:any)=>
      {
        //console.log(data);
        message.error("There was a problem getting your course");
      }
      prev =()=>
      {
        let node = this.carousel.current;
        node!.prev();
      }
      next =()=>
      {
        let node = this.carousel.current;
        node!.next();
      }
      onChange(index:number) {
        //console.log(index);
      }
      postError=(data:any)=>
      {
        console.log(data);
        message.error(data.response.data);
      }
      getSharedLink()
      {
        if(this.state.shareLink !==""){
          this.setState((state)=>{return{showLinkModal:true}})
        }
        else {
          this.setState((state) => { return { showLinkModal: true } })
          this.createlink()
        }
      }
    refreshSharedLink() {
        this.setState({ showRefreshModal: true, showLinkModal: false })
          if (this.state.sharedId !== "") {
              let obj = {
                  fullUrl: "/shared?shareContentId=" + this.state.sharedId,
                  loginProvider: "VrSafety",
                  tokenName: "AuthTokenShareContent",
              }
              console.log(this.state.sharedId);
              refreshSharedLink(obj, this.createLinkSuccess, this.postError);
          }
          else {
              let sharedTour =
              {
                  CompanyId: this.props.auth.company.id,
                  Available: true,
                  TourId: this.props.match.params.id,
                  hasPassword: this.state.hasPassword,
                  password: this.state.password,
                  submittedAt: getCurrentDate()
              }
              //console.log(sharedTour);
              
              createdSharedTourData(sharedTour, this.createSharedSuccess, this.postError);
          }
      }
      createlink()
      {
        if(this.state.sharedId!=="")
        {
          let obj = {
            fullUrl:"/shared?shareContentId="+this.state.sharedId,
            loginProvider:"VrSafety",
            tokenName: "AuthTokenShareContent"
          }
          createSharedLink(obj,this.createLinkSuccess,this.postError);
        }
        else
        {
          let sharedTour=
          {
            CompanyId:this.props.auth.company.id,
            Available:true,
            TourId:this.props.match.params.id,
            hasPassword:this.state.hasPassword,
            password:this.state.password,
            submittedAt:getCurrentDate()
          }
          //console.log(sharedTour);
          createdSharedTourData(sharedTour,this.createSharedSuccess,this.postError);
        } 
      }
      createLinkSuccess=(data:any)=>
      {
        console.log(data);
        let link = "https://"+data.data;
        this.setState((state)=>{return{shareLink:link }});
      }

      createSharedSuccess=(data:any)=>
      {
        //console.log(data);
        let obj = {
          fullUrl:"/shared?shareContentId="+data.data.id,
          loginProvider:"VrSafety",
          tokenName:"AuthTokenShareContent",
        }
        createSharedLink(obj,this.createLinkSuccess,this.postError);
      }
      handleCancel = () => this.setState({ showLinkModal:false});
      handleCancelRefresh = () => this.setState({ showRefreshModal:false});

    getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: (filterDropDown: filterDropDown) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={filterDropDown.selectedKeys[0]}
                    onChange={e => filterDropDown.setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(filterDropDown.selectedKeys, filterDropDown.confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Row>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(filterDropDown.selectedKeys, filterDropDown.confirm, dataIndex)}
                        icon="search"
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
          </Button>
                    <Button onClick={() => this.handleReset(filterDropDown.clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
          </Button>
                </Row>
            </div>
        ),
        filterIcon: (filtered: any) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: string, record: { [x: string]: { toString: () => string; }; }) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: (dataIndex: string | undefined) =>
            this.state.searchedColumn === dataIndex ? (
                dataIndex
            ) : dataIndex,
    }as any);

    handleSearch = (selectedKeys: any[], confirm: () => void, dataIndex: any) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = (clearFilters: () => void) => {
        clearFilters();
        this.setState({ searchText: '' });
    };


    onSearch = (e: any) => {
        console.log(e);
        let newList = this.state.availableCourses.filter(item => {
            const lc = item.tourName.toLowerCase();
            const filter = e.toLowerCase();
            return lc.includes(filter);
        });
        console.log(newList);
        this.setState({
            filteredTours: newList
        });
    }
    clipboard = () => {
        navigator.clipboard.writeText(this.state.shareLink);
        message.success("Copied to clipboard");
    }


    render() {
        const columns = [
            {
                title: "Name",
                dataIndex: 'name',
                key: 'name',
                sorter: (a: any, b: any) => (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0),
                sortDirections: ['descend', 'ascend'],
                ...this.getColumnSearchProps('name'),
            },
            {
                title: "Start Date",
                dataIndex: 'startedAt',
                key: 'startedAt',
                sorter: (a: any, b: any) => (a.startedAt < b.startedAt) ? 1 : ((b.startedAt < a.startedAt) ? -1 : 0),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: "Time Spent",
                dataIndex: 'timeSpent',
                key: 'timeSpent',
                sorter: (a: any, b: any) => (a.timeSpent < b.timeSpent) ? 1 : ((b.timeSpent < a.timeSpent) ? -1 : 0),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: "Complete Date",
                dataIndex: 'completedAt',
                key: 'completedAt',
                sorter: (a: any, b: any) => (a.completedAt < b.completedAt) ? 1 : ((b.completedAt < a.completedAt) ? -1 : 0),
                sortDirections: ['descend', 'ascend'],

            },
            {
                title: "Pass/Fail",
                dataIndex: 'pass',
                key: 'pass',
                filters: [
                    {
                        text: 'Pass',
                        value: 'pass',
                    },
                    {
                        text: 'Fail',
                        value: 'fail',
                    }
                ],
                onFilter: (value:any, record:any) => record.pass==value,
            },
            {
                title: "Grade",
                dataIndex: 'grade',
                key: 'grade',
            },
        ];
        return (
          <React.Fragment>
          <Row type="flex" justify="space-between">
            <Col xs={24} sm={24} md={17}  className={this.props.auth.layout.bgColor}>
            <Row style={{padding:"14px", marginBottom:"24px"}}>
              
              <Row>
                  <Col xs={24} sm={24} md={9}>
                  <Row style={{height: "80px"}}>
                    <Col xs={24} sm={24} md={12}>
                        <h3 style={{ marginBottom: "0", fontWeight: 600 }} className={this.props.auth.layout.h2Color}>{this.state.tour.tourName}</h3>
                        <h4 style={{marginBottom:"30px"}} className={this.props.auth.layout.h4Color}>{this.props.auth.company.name}</h4>
                    </Col>
                    <Col xs={24} sm={24} md={12} style={{textAlign:"right"}}>Publish <Switch style={{ marginLeft: "10px"}} checkedChildren="Enabled" unCheckedChildren="Disabled" checked={this.state.tour.status=='2'}/></Col>
                  </Row>
                  <Row>
                  <Icon type="left" className="carouselButton" onClick={() => this.prev()} />
                    <Icon type="right" style={{ right: 0 }} className="carouselButton" onClick={() => this.next()} />
                    <Carousel ref={this.carousel} afterChange={this.onChange}>
                        {
                            (typeof this.state.tour.panos === "undefined")
                                ?
                                <div>Loading....</div>
                                :
                                this.state.tour.panos.map((pano: string, index: number) =>
                                    <img key={index} className="courseProfileImg" src={pano} alt={`pano ${index}`} />
                                )
                        }
                    </Carousel>
                  </Row>
                  <Row><Paragraph className={this.props.auth.layout.pColor} style={{marginTop: "20px"}}>{this.state.tour.tourDescription}</Paragraph></Row>
                  </Col>
                  <Col xs={24} sm={24} md={15}>
                    <Row>
                        <Col span={1}></Col>
                        <Col span={23}>
                            <Row style={{marginBottom:"90px"}} type="flex" justify="space-between">
                             <Col span={6}><Button icon="edit" href={`/tour/0/${this.state.tour.id}`} style={{float:"right"}}>Edit Course</Button></Col>
                             <Col span={6}><Button icon="link" onClick={()=>this.getSharedLink()}>Shared Link</Button></Col>
                            </Row>
                            <Spin spinning={this.state.loading}>
                                <Table columns={columns} bordered rowKey={(record: any) => record.id} dataSource={this.state.userInfo} />
                            </Spin>
                        </Col>
                    </Row>
                  </Col>
              </Row>
              
              <Row style={{padding:"28px", marginBottom:""}}>
              <CourseStats
                  completed={this.state.completed.length}
                  inprogress= {this.state.inProgress.length}
                  time= {this.state.time}
                  grade= {this.state.averageGrade}
                  failed= {this.state.failed}
              />
              </Row>

              <Row style={{padding:"28px", marginBottom:""}}>
              <SharedStats
                  completed={this.state.sharedStats.timesFinished}
                  inprogress= {this.state.sharedStats.timesIncomplete}
                  visits={this.state.sharedStats.visits}
              />
              </Row>
            
            </Row>
            <Row style={{padding:"5px"}}>
                <BrowseCourses
                    bgColor={this.props.auth.layout.bgColor}
                    titleColor={this.props.auth.layout.h2Color}
                    title={"COURSE LIBRARY"}
                    available={this.state.filteredTours} 
                    company={this.props.auth.company.name} 
                    onSearch={this.onSearch}
                    layout={this.props.auth.layout}
            />
            </Row>
            </Col>
          
          <Col xs={24} sm={24} md={7} >
            <TeamProfiles 
              bgColor={"whiteBG"}
              titleColor={"orangeText"}
              textColor={"blackText"}
              available={this.state.teamMates}
              cardHeight={"1200px"}
            />
          </Col> 
          </Row>
                
          <Modal
              title={"The shareable link for this tour is: "}
              visible={this.state.showLinkModal}
              onCancel={this.handleCancel}
              footer={[
                <Button key="back" onClick={this.handleCancel}>
                  Close
                </Button>]}
          >
              <div style={{ marginBottom: 16 }}>
                <Input value={this.state.shareLink}
                  addonAfter={<Button onClick={() => this.clipboard()}>Copy <Icon type="link" /></Button>}   
                />
                <Button onClick={() => this.refreshSharedLink()}>Regenerate Link<Icon type="reload" /></Button> 
              </div>
            </Modal>  
                <Modal
                    title={"The New shareable link for this tour is: "}
                    visible={this.state.showRefreshModal}
                    onCancel={this.handleCancelRefresh}
                    footer={[
                        <Button key="back" onClick={this.handleCancelRefresh}>
                            Close
                        </Button>]}
                >
                    <div style={{ marginBottom: 16 }}>
                        <Input value={this.state.shareLink}
                            addonAfter={<Button
                                onClick={()=>this.clipboard()
                                }>Copy
                                
                                <Icon type="link" /></Button>}
                        />
                    </div>
                </Modal> 
          </React.Fragment>
        );  
      }
}
function mapToStateToProps(state: any) {
  return {
      auth: state.auth,
      management: state.management,
      
  };
}
export default connect(mapToStateToProps)(CourseManagementProfile);