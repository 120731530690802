import React from 'react';
import './style.css';

export default function PoweredWithLove() {
    return (
        <svg
            id="powered_with_love"
            data-name="Powered with Love Bit Space Development"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 444.54 35.45"
        >
            <path
                d="M315.05,236.87c.24-.51.31-.64.36-.78,1.19-3.06,3.09-4.3,6.26-4.1,3.51.22,5.79,1.79,6.44,4.85.86,4.06.05,7.74-3.38,10.44a50.47,50.47,0,0,1-4.48,3,13.74,13.74,0,0,0-5.12,5c-1.71-3.06-4.48-4.59-7.17-6.23s-5.3-3.72-5.84-7.19a14.48,14.48,0,0,1,0-4.87c.63-3.3,3.58-5.16,7.17-5C312.32,232.13,314.17,233.63,315.05,236.87Z"
                transform="translate(-34.67 -227.32)"
            />
            <path
                d="M394.67,255.5V230.44h2.41c4.12.06,8.23.1,12.34.23a8.17,8.17,0,0,1,2.55.6,6.06,6.06,0,0,1,2,10.5c-.24.21-.46.42-.77.71l.78.42a6,6,0,0,1,3.56,6,5.55,5.55,0,0,1-3.74,5.47,15.09,15.09,0,0,1-4.75,1.07C404.3,255.59,399.54,255.5,394.67,255.5ZM400.26,245c0,1.87,0,3.7,0,5.53,0,.24.37.68.57.68,2.82,0,5.65,0,8.47-.23a2.63,2.63,0,0,0,2.4-2.05,3,3,0,0,0-2.92-3.89C406,244.86,403.24,245,400.26,245Zm.06-4.26c1.89,0,3.64,0,5.39,0a11.7,11.7,0,0,0,2.71-.35,2.6,2.6,0,0,0,2-2.63,2.43,2.43,0,0,0-2.05-2.7c-2.66-.33-5.35-.36-8.08-.51Z"
                transform="translate(-34.67 -227.32)"
            />
            <path
                d="M445.32,230.43c3.86,0,7.65,0,11.43,0a19.42,19.42,0,0,1,3.8.44c6.43,1.42,10.11,6.34,9.79,13a11.79,11.79,0,0,1-11.07,11.38c-4.6.34-9.25.06-13.95.06ZM451,250.82c2.22,0,4.3.1,6.37,0,4.16-.24,6.76-2.91,7.23-7.23a7.09,7.09,0,0,0-5.39-8,59.59,59.59,0,0,0-8.21-.64Z"
                transform="translate(-34.67 -227.32)"
            />
            <path
                d="M97.15,243.81l-2.83,7.48,0,.11c-.52,1.34-.68,3.26-1.65,3.87s-2.93.26-4.45.23a1.25,1.25,0,0,1-1-.59c-2.22-6.07-4.39-12.16-6.56-18.25a1.82,1.82,0,0,1,0-.46c1.49,0,3-.06,4.43.05.34,0,.78.57.93,1,1.26,3.53,2.47,7.08,3.71,10.62.13.4.3.78.59,1.52l2.88-8c.51-1.42,1-2.84,1.52-4.28.22-.67.52-1,1.27-.93,1.2.07,2.4,0,3.75,0l4.62,13.14c.28-.68.46-1.05.59-1.43,1.28-3.57,2.59-7.13,3.82-10.72a1.23,1.23,0,0,1,1.45-1c1.19.07,2.39,0,3.72,0-.18.58-.32,1.07-.49,1.54-2,5.56-4,11.11-6,16.68-.3.83-.69,1.21-1.62,1.13-1.35-.11-3,.4-4-.24s-1.09-2.37-1.56-3.64c-1-2.61-1.91-5.24-2.87-7.86Z"
                transform="translate(-34.67 -227.32)"
            />
            <path
                d="M209.66,236.2c1.49,0,2.81.05,4.12,0a1.18,1.18,0,0,1,1.36,1c1.21,3.59,2.46,7.17,3.7,10.75.13.36.29.71.55,1.36.32-.78.53-1.26.71-1.76,1.23-3.42,2.48-6.83,3.66-10.26.28-.82.66-1.15,1.53-1.08,1.15.1,2.6-.35,3.39.2s.94,2,1.32,3.1c1.12,3.16,2.21,6.32,3.44,9.85.29-.66.47-1,.6-1.38,1.28-3.57,2.58-7.13,3.82-10.71.26-.75.56-1.12,1.42-1.06,1.19.09,2.39,0,3.75,0-.13.45-.22.81-.35,1.16-2.05,5.71-4.13,11.41-6.15,17.13a1.31,1.31,0,0,1-1.55,1c-1.39-.09-3.1.39-4.08-.27s-1.06-2.4-1.52-3.67c-.83-2.25-1.64-4.5-2.46-6.75-.13-.36-.28-.71-.54-1.35-1,2.62-1.86,5-2.75,7.3-.47,1.27-1,2.53-1.41,3.82-.22.66-.52,1-1.27.92-1.43-.08-3.22.46-4.21-.22s-1.16-2.51-1.65-3.85q-2.51-6.93-5-13.86C210,237.2,209.84,236.78,209.66,236.2Z"
                transform="translate(-34.67 -227.32)"
            />
            <path
                d="M191.36,255.43l-.17-2a8.76,8.76,0,0,1-8.36,2.13,8.58,8.58,0,0,1-5.06-3,10.87,10.87,0,0,1,.06-13.44c3-3.59,7.93-4,13.16-1.07v-8.95h5.44v26.35Zm-10.27-9.57c0,3.16,2.08,5.44,5,5.45s5-2.28,5-5.44-2.08-5.45-5-5.45S181.09,242.7,181.09,245.86Z"
                transform="translate(-34.67 -227.32)"
            />
            <path
                d="M338.3,229.05h5.34v9.21a9.06,9.06,0,0,1,9.3-1.76,8.42,8.42,0,0,1,4.58,3.51c2.49,3.78,2.09,9.64-.89,12.85a9.19,9.19,0,0,1-6.39,2.9,8.31,8.31,0,0,1-6.71-2.39l-.17,2.07H338.3Zm15.28,16.84c0-3.2-2-5.47-4.95-5.47s-5,2.33-5,5.47,2.08,5.37,4.92,5.41S353.57,249.08,353.58,245.89Z"
                transform="translate(-34.67 -227.32)"
            />
            <path
                d="M40.24,248.56v6.88H34.67V230.53c.31,0,.61-.09.91-.09,3.48,0,7-.06,10.43,0a11,11,0,0,1,7.53,2.62c2.62,2.38,3.06,5.44,2.4,8.69-.7,3.47-3.14,5.38-6.4,6.25a19.42,19.42,0,0,1-4,.52C43.81,248.63,42.1,248.56,40.24,248.56Zm.07-4.45c2.44-.17,4.84-.16,7.17-.55,1.93-.33,3-2,3-4s-.92-3.75-2.82-4.08a68,68,0,0,0-7.33-.53Z"
                transform="translate(-34.67 -227.32)"
            />
            <path
                d="M438.17,236.11a65.37,65.37,0,0,0-6.65-1.52,9.33,9.33,0,0,0-3.72.59,2.28,2.28,0,0,0-1.57,2.21,2.07,2.07,0,0,0,1.52,2.09c2,.66,4,1.15,6,1.74a26.61,26.61,0,0,1,3.05,1,6,6,0,0,1,4.06,5.06,6.82,6.82,0,0,1-3.14,6.8,13.15,13.15,0,0,1-8.14,1.89,17.46,17.46,0,0,1-9.49-2.84l1.92-4.3a14.66,14.66,0,0,0,7.86,2.56,10.54,10.54,0,0,0,3.83-.66,3.19,3.19,0,0,0,1.46-2,1.76,1.76,0,0,0-1.28-2.06c-1.56-.59-3.18-1-4.77-1.51s-3.23-.91-4.76-1.57c-5.13-2.23-4.54-8.74-1.23-11.34a11.45,11.45,0,0,1,6.08-2.25,18.35,18.35,0,0,1,9.69,1.56c.73.34.94.72.57,1.48C438.93,234.13,438.53,235.22,438.17,236.11Z"
                transform="translate(-34.67 -227.32)"
            />
            <path
                d="M277.92,255.47h-5.37v-26.4h5.34v8.73a34.27,34.27,0,0,1,4.67-1.67c5.41-1.09,9.46,2.24,9.51,7.73,0,3.72,0,7.44,0,11.16a2,2,0,0,1-.1.42h-5.33v-7.19c0-1,0-2.08,0-3.12-.06-2.75-1.33-4.28-3.68-4.45a4.29,4.29,0,0,0-4.82,4.1c-.22,3-.15,6.07-.2,9.11C277.91,254.39,277.92,254.9,277.92,255.47Z"
                transform="translate(-34.67 -227.32)"
            />
            <path
                d="M359.51,261l1.8-3.52a19.44,19.44,0,0,0,3.58.8,2.67,2.67,0,0,0,2.48-3.87q-3.58-8.37-7.19-16.73c-.18-.43-.34-.88-.56-1.45,1.8,0,3.47,0,5.13.05.31,0,.72.53.88.9,1.49,3.53,2.94,7.08,4.41,10.62.18.43.38.85.66,1.5.28-.6.48-1,.64-1.37,1.5-3.58,3-7.14,4.46-10.73a1.32,1.32,0,0,1,1.5-1c1.31.07,2.63,0,4.14,0-.17.48-.29.87-.45,1.25-2.75,6.46-5.49,12.94-8.28,19.39a14.14,14.14,0,0,1-1.68,3C368.5,263.22,362.92,263.78,359.51,261Z"
                transform="translate(-34.67 -227.32)"
            />
            <path
                d="M69.16,255.84c-6.25-.08-10.6-4.3-10.54-10.31a9.51,9.51,0,0,1,8.11-9.29,16.55,16.55,0,0,1,5.21,0c4.88.87,8,4.92,7.83,10a9.5,9.5,0,0,1-8.36,9.31C70.66,255.68,69.91,255.75,69.16,255.84Zm-5-10c0,3.15,2.1,5.42,5,5.43s5-2.26,5-5.4-2.12-5.53-5-5.49S64.2,242.73,64.21,245.88Z"
                transform="translate(-34.67 -227.32)"
            />
            <path
                d="M120.74,247.36c0,3.3,5.71,5.88,10.33,2.22l2.85,3.11a9.15,9.15,0,0,1-6.14,3c-3.72.41-7.21-.18-10-2.93s-3.41-6.26-2.3-10a9.09,9.09,0,0,1,7.3-6.52c3.78-.78,7.26-.1,10,2.82a9.92,9.92,0,0,1,2.47,7.53c0,.84-.58.79-1.16.79H120.74Zm-.2-3.26H130a4.48,4.48,0,0,0-4.58-4A4.57,4.57,0,0,0,120.54,244.1Z"
                transform="translate(-34.67 -227.32)"
            />
            <path
                d="M158.62,247.36c.31,3.72,6.25,5.63,10.34,2.2l2.87,3.12a7.9,7.9,0,0,1-4.44,2.64c-4.31,1-8.4.59-11.71-2.65-2.83-2.79-3.38-6.29-2.24-10a9.07,9.07,0,0,1,7.35-6.47c3.64-.74,7-.1,9.74,2.62a9.88,9.88,0,0,1,2.69,7.68c0,.8-.49.85-1.12.85-4,0-8.07,0-12.11,0Zm9.36-3.23a4.57,4.57,0,0,0-5.23-4,4.19,4.19,0,0,0-4.17,4Z"
                transform="translate(-34.67 -227.32)"
            />
            <path
                d="M267.46,250.84c.43,1.09.89,2.15,1.24,3.24a1,1,0,0,1-.45.88,9.17,9.17,0,0,1-8.2-.24,5.4,5.4,0,0,1-2.74-4.89c-.06-2.44,0-4.88,0-7.32v-1.57h-3v-4.21h3c0-1.33,0-2.52,0-3.7,0-.76.23-1.1,1-1.07,1.16,0,2.32,0,3.48,0,.7,0,1,.23,1,1-.05,1.19,0,2.38,0,3.68h4.71v4.21h-4.71v2.68c0,1.6,0,3.2,0,4.8,0,2.71,1.53,3.74,4.08,2.77Z"
                transform="translate(-34.67 -227.32)"
            />
            <path
                d="M139.1,236.25h5l.18,2.13c3.9-2.3,4.66-2.57,6.53-2.29v4.71a7,7,0,0,1-.78.13c-3.84.14-5.52,1.86-5.59,5.79,0,2.44,0,4.89,0,7.33v1.39H139.1Z"
                transform="translate(-34.67 -227.32)"
            />
            <path
                d="M245.89,236.26l.63-.06h4.65v19.24h-5.28Z"
                transform="translate(-34.67 -227.32)"
            />
            <path
                d="M475.81,248.91a3.28,3.28,0,0,1,3.41,3.39,3.42,3.42,0,1,1-6.84,0A3.28,3.28,0,0,1,475.81,248.91Z"
                transform="translate(-34.67 -227.32)"
            />
            <path
                d="M248.55,233.43a3.08,3.08,0,1,1-.05-6.11c2,0,3.45,1.22,3.44,3A3.15,3.15,0,0,1,248.55,233.43Z"
                transform="translate(-34.67 -227.32)"
            />
        </svg>
    );
}
