import React, { Component } from 'react';
import { Button, Avatar, Progress, Radio, Row, Col, Icon, Carousel, Card, Popover, Input } from 'antd';
import { getDateDMY, getTotalhotspotIds } from "../../utils/tools";
import tour_icon from '../../assets/Icons/VRS_asset_360globe_icon.png'
const { Search } = Input;
interface State {
    loading: boolean,
    key: string,
    tours: any[]
}
/**Progress Card on  */
const InProgressCourse = (props: any) => {
    const content = (
        <div>
            <Row>
                <Col span={14}>Started At</Col>
                <Col span={10} ><h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{getDateDMY(props.tour.tourProgress.startedAt)}</h3></Col>
            </Row>
            <Row>
                <Col span={22}>Visited Scenes</Col>
                <Col span={2} ><h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{`${props.tour.tourProgress.visitedPanoIds.length}`}</h3></Col>
            </Row>
            <Row>
                <Col span={22}>Total Scenes</Col>
                <Col span={2} ><h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{`${props.tour.tourProgress.totalPano}`}</h3></Col>
            </Row>
            <Row>
                <Col span={16}>Time Spent</Col>
                <Col span={8}><h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{props.tour.tourProgress.timeSpent}</h3></Col>
            </Row>
            <Row>
                <Col span={22}>Visited Hotspots</Col>
                <Col span={2}><h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{props.tour.tourProgress.visitedhotspots.length}</h3></Col>
            </Row>
            <Row>
                <Col span={22}>Total Hotspots</Col>
                <Col span={2}><h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{props.tour.tourProgress.totalhotspot}</h3></Col>
            </Row>
        </div>
    );
    var fontColor, bgColor, aColor, popover, buttons;
    switch (props.layout) {
        case "VRSafety":
            fontColor = "";
            bgColor = "whiteBG";
            aColor = "darkBlueText";
            popover = "managerPopOverButton";
            buttons = "teamCardButtons";
            break;
        case "RealX":
            fontColor = "greyText";
            bgColor = "blackBG";
            aColor = "whiteText tealHover";
            popover = "managerPopOverButtonRealX";
            buttons = "teamCardButtonsRealX";
            break;
        default:
            fontColor = "";
            bgColor = "whiteBG";
            popover = "managerPopOverButton";
            buttons = "teamCardButtons";
            aColor = "darkBlueText";
            break;
    }
    return (
        <Card
            className={bgColor}
            style={{ minWidth: "250px", maxWidth: "350px" }}
            size="small"
            cover={<div style={{padding: "10px"}}>
                <Row>
                    <Row type="flex" align="middle" justify="space-between">
                        <Col>{
                            (props.tour.tourInfo.tourIcon !== "" && props.tour.tourInfo.tourIcon !== null) ?
                                <Col span={8}>
                                    <a href={`/details/${props.tour.tourInfo.id}`}><Avatar shape="square" size={86} src={"https://janustourbuilder.s3.ca-central-1.amazonaws.com/" + props.tour.tourInfo.tourIcon} /></a>
                                </Col>
                                :
                                <Col span={8}>
                                    <a href={`/details/${props.tour.tourInfo.id}`}><Avatar shape="square" size={86} src={tour_icon} /></a>
                                </Col>
                        }</Col>
                        <Col span={14} style={{ marginTop: "10px", fontSize: "12px" }}>
                            <div>
                                <a href={`/details/${props.tour.tourInfo.id}`}><h3 style={{ marginBottom: "0", fontWeight: 600 }}
                                    className={aColor}>
                                    {props.tour.tourInfo.tourName}
                                </h3></a>
                                <div className={fontColor}>{props.company}</div>
                            </div>
                            <div style={{ textAlign: "center", marginTop: "5px", marginBottom: "10px" }}>
                                <span className={fontColor}>{`${Math.floor((getTotalhotspotIds(props.tour.tourProgress.visitedhotspots) / props.tour.tourProgress.totalhotspot) * 100 || 0)}% complete`}</span>
                                <Progress strokeColor={"#FA8C16"} showInfo={false} percent={((getTotalhotspotIds(props.tour.tourProgress.visitedhotspots) / props.tour.tourProgress.totalhotspot) * 100)} />
                            </div>
                            
                            <Popover placement="bottom" title="Course Statistics" content={content} trigger="click">
                                <Button
                                    className={popover} ghost >
                                    View Statistics
                                </Button>
                            </Popover>
                        </Col>
                    </Row>

                </Row>
            </div>}
            actions={[
                <span className={buttons} onClick={() => props.restart(props.tour)}><Icon type="reload" style={{ marginRight: "10px" }} />Restart</span>,
                <span className={buttons} onClick={() => { window.location.href = `/viewer/${props.tour.tourInfo.id}` }}><Icon type="caret-right" style={{ marginRight: "10px" }} />Continue</span>,
               
            ]}

        >
        </Card>
    );
}

class InProgressCourses extends Component<any, State> {
    state: State = {
        loading: false,
        key: '1',
        tours: []
    }
    public constructor(props: any) {
        super(props);
    }
    componentDidMount() {
        //console.log(this.props);
        this.setState({
            tours: this.props.inProgress,
        });
    }

    componentDidUpdate(previousProps: any) {
        if (this.props.inProgress !== previousProps.inProgress) {
            this.setState({
                tours: this.props.inProgress,
            });
        }
    }
    /*makeDivs = () => {
        var divs = [];
        for (var i = 0; i < this.state.tours.length; i++) {
            if (i < this.state.tours.length - 2) {
                var div = <Row key={i}><Col xs={24} sm={24} md={8}><InProgressCourse tour={this.state.tours[i]} company={this.props.company} /></Col>
                    <Col xs={24} sm={24} md={8}><InProgressCourse tour={this.state.tours[i + 1]} company={this.props.company} /></Col>
                    <Col xs={24} sm={24} md={8}><InProgressCourse tour={this.state.tours[i + 2]} company={this.props.company} /></Col>
                </Row>;
                i = i + 2
            }
            else if (i < this.state.tours.length - 1) {
                var div = <Row key={i}><Col xs={24} sm={24} md={8}><InProgressCourse tour={this.state.tours[i]} company={this.props.company} /></Col>
                    <Col xs={24} sm={24} md={8}><InProgressCourse tour={this.state.tours[i + 1]} company={this.props.company} /></Col>
                </Row>;
                i = i + 1
            }
            else {
                var div = <Row key={i}><Col xs={24} sm={24} md={8}><InProgressCourse tour={this.state.tours[i]} company={this.props.company} /></Col>
                </Row>
            }
            divs.push(div);
        }
        return divs
    }*/

    render() {
        return (
            <div style={{ minHeight: "200px", marginBottom: "5px", padding: "14px" }} className={this.props.layout.bgColor}>
                <Row style={{ marginBottom: "20px" }} type="flex" align="middle" justify="space-between">
                    <Col sm={12} md={4}><h2 style={{ fontWeight: "bold", fontSize: "1.5em", marginBottom: "0px" }} className={`bebas ${this.props.layout.h5Color}`}>Courses in Progress</h2></Col>
                    <Col xs={24} sm={24} md={6} style={{ textAlign: "right" }}>
                        <Search
                            placeholder="Search"
                            onSearch={this.props.onSearch}
                            style={{width:"150px", marginRight: "10px"}}
                        />
                        <Button type="primary" onClick={() => this.props.clearSearch()}>Reset</Button>
                    </Col>
                </Row>
                {
                    (this.state.tours.length > 0) ?
                        <Row type="flex"  style={{ overflowX: "auto", display: "flex", flexWrap: "nowrap" }}>
                            {
                                this.props.inProgress.map((value: any, index: number) =>
                                    <Col key={index} xs={24} sm={24} md={6} style={{ padding: "10px" }} >
                                        <InProgressCourse
                                            tour={value}
                                            company={this.props.company}
                                            layout={this.props.layout}/>
                                    </Col>
                                )
                            }
                        </Row>
                    : null
                }
            </div>
        );
    }
}
export default InProgressCourses;