import React, { Component } from 'react';
import {Radio, Row, Col, Input, Dropdown, Cascader  } from 'antd';
import AllTeamMates from './teamProfileCard';

const { Search } = Input;

interface State {
    loading: boolean,
    value:string,
    teamMembers:any[],
    allTeamMembers: any[]
}
const options = [
  { label: 'Last Name A-Z', value: 'LastName' },
  { label: 'First Name A-Z', value: 'FirstName' },
  { label: 'Job Title A-Z', value: 'JobTitle' },
  { label: 'Fail Grade', value: 'FailGrade' },
  { label: 'Inactive(1 month +)', value: 'Inactive' },
];

class TeamProfiles extends Component<any, State> {
    state:State={
      loading:false,
      value:'FirstName',
      teamMembers:[],
      allTeamMembers:[]
      
    }
    public constructor(props:any){
        super(props);
      }
      componentDidMount()
      {
        this.setState({teamMembers:this.props.available});
      }

      componentDidUpdate(prevProps:any) {
        // Typical usage (don't forget to compare props):
        if (this.props.available !== prevProps.available) {
          console.log(this.props.available);
          this.setState({
            teamMembers:this.props.available,
            allTeamMembers:this.props.available
          });
        }
      }

      onChange = (e:any) => {
       
        let sortedList=this.state.teamMembers;
        switch(e[0])
        {
          case "LastName":
            sortedList = this.state.teamMembers.sort((a:any, b:any) => ((a.user.lastName > b.user.lastName) ? 1 : ((b.user.lastName > a.user.lastName) ? -1 : 0)))
            break;
          case "FirstName":
            sortedList = this.state.teamMembers.sort((a:any, b:any) => ((a.user.firstName > b.user.firstName) ? 1 : ((b.user.firstName > a.user.firstName) ? -1 : 0)))
            break;
          case "JobTitle":
            sortedList = this.state.teamMembers.sort((a:any, b:any) => ((a.user.userInformation.jobPosition > b.user.userInformation.jobPosition) ? 1 : ((b.user.userInformation.jobPosition > a.user.userInformation.jobPosition) ? -1 : 0)))
            break;
          case "FailGrade":
            sortedList = this.state.teamMembers.sort((a:any, b:any) => ((a.user.lastName < b.user.lastName) ? 1 : ((b.user.lastName < a.user.lastName) ? -1 : 0)))
            break;
          case "Inactive":
            sortedList = this.state.teamMembers.sort((a:any, b:any) => ((a.user.lastName < b.user.lastName) ? 1 : ((b.user.lastName < a.user.lastName) ? -1 : 0)))
            break;

        }
        this.setState({
          teamMembers:sortedList,
          value: e[0],
        });
      };

      onSearch = (value: any) => {
        console.log(value);
        let newList = this.state.allTeamMembers.filter(item => {
            const lc = item.user.firstName.toLowerCase()+" "+ item.user.lastName.toLowerCase();
            const filter = value.toLowerCase();
            return lc.includes(filter);
        });
        console.log(newList);
        this.setState({
            teamMembers: newList
        });
    }

      render() {
        return (
          <Row>
          <Col span={1}></Col>
          <Col span={23}>
          <div className={this.props.bgColor} style={{minHeight:"200px", padding: "20px"}}>
            <Row type="flex" align="middle" justify="space-between" style={{marginBottom: "10px"}}>
              <Col sm={24}><h2 style={{fontWeight:"bold", marginBottom:"0px"}} className="orangeText bebas">TEAM PROFILES</h2></Col>
              
            </Row>
            <Row style={{marginBottom: "20px"}}>
                 <Col sm={24} md={14}>
                  <label>FilterBy</label>
                  <Cascader 
                        style={{ marginRight: "10px",marginLeft: "5px", textAlign: "left", width: "110px" }} 
                        size={"small"} 
                        options={options} 
                        value={[this.state.value]}
                        onChange={this.onChange} />
                </Col>
                <Col sm={24} md={10}>
                    <Search
                    placeholder="Search"
                    onSearch={this.onSearch}
                    size={"small"}
                    /> 
                </Col>
            </Row>
                {/*<AllTeamMates available={this.state.teamMembers}/>*/}
            <div>
                <Row type="flex" align="middle" style={{ height: this.props.cardHeight, paddingRight: "10px", overflowY: "auto", display: "flex", flexWrap: "nowrap", flexDirection: "column" }}>
                    {
                        this.state.teamMembers.map((value: any, index: number) =>
                            <Col key={index} span={24}><AllTeamMates teamMate={value} /></Col>
                        )
                    }
                </Row>
            </div>
          </div>
          </Col>
          </Row>
        );
      }
}
export default TeamProfiles;