import React, { Component} from 'react';
import 'aframe';
import {Entity} from 'aframe-react';
import { changePaginationPos } from '../../utils/aframetools';
interface Props {
    data: any
    id:number
    panoid:number
    viewedHotspot:(panoId:number,hotspotId:number)=>void,
    interactionsDisabled:boolean;
    started:boolean
}
interface State
{
    lineHeight:number;
    textSize: string;
    textColor: string;
    textBG: string;
}

class CaptionHotspot extends Component<Props, State> 
{
    state: State = {
        lineHeight:0.5,
        textSize: "12",
        textColor:"black",
        textBG:"transparent"
    }
    componentDidMount()
    {
        
        if (this.props.data?.message !== null)
            this.textLoaded()
    }
    componentDidUpdate(previousProps: any) {
        if (this.props.data !== previousProps.data && this.props.data?.message !==null) {

                this.textLoaded();
        }
    }
    componentWillUnmount()
    {
    }
    setLineHeight = () =>
    {
        switch (this.props.data.textSize)
        {
            case "10":
                return  0.12;
            case "12":
                 return 0.25;
            case "14":
                return 0.33;
            default:
                return 0.25;
                
        }
    }
    textLoaded=()=> {
        let lineHeight = this.setLineHeight();
        let textSize = this.props.data.textSize;
        if (textSize === null || textSize === "") {
            textSize = "12";
        }
        else {
            this.setState({ textSize: textSize })
        }
        if (this.props.data.textColor === "black") {
            this.setState({ textColor: "#000" })
        }
        else {
            this.setState({ textColor: "#fff" })
        }
        console.log(this.props.data);
        
        console.log(textSize);
        let splitMessage=this.props.data.message.split(/\r\n|\r|\n/);
        let lines=this.props.data.message.split(/\r\n|\r|\n/).length;
        let textBGHotspot: any = document.getElementById(this.props.panoid + "textBackground" + this.props.id);
        //console.log(this.state.lineHeight);
        //console.log(this.props.data.message.length);
        let height = lineHeight * (this.props.data.message.length / parseInt(textSize, 10));
        if(lines>1){
            height=lines*lineHeight;
            console.log(height);
            for(let i=0;i<lines;i++){ // after many hours of calculations and inspection, this works, leave it like this if it doesn't give any kind of bugs
                if(splitMessage[i].length<40)
                    {
                        height+= lineHeight+lineHeight
                    }
                else
                    {
                        height+= (lineHeight*(splitMessage[i].length / parseInt(textSize, 10)));
                    }
            }
        }
        textBGHotspot.setAttribute("geometry", `height: ${height + 0.9}`);
        textBGHotspot.setAttribute("geometry", `width: ${parseInt(textSize, 10) + 1}`);
        let totalWidth = this.props.data.message.length * (parseInt(textSize, 10) / 40)
        //console.log(totalWidth);
        
        //console.log(height);
        if (this.props.data.textBG === "black") {
            this.setState({ textBG: "#000" });
        }
        else if (this.props.data.textBG === "white") {
            this.setState({ textBG: "#fff" });
        }
        else {
            this.setState({ textBG: "transparent" })
        }
    }


    render(){
        return(
            <React.Fragment>
                <Entity
                    visible={true}
                    id={this.props.panoid + "textHotspot" + this.props.id} 
                    position={`${this.props.data.position.x} ${this.props.data.position.y} ${this.props.data.position.z}`}
                    rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
                >   
                <a-text 
                    
                    id={this.props.panoid + "textArea" + this.props.id} 
                    text={`value: ${this.props.data.message}; zOffset:1; width: ${parseInt(this.state.textSize,10)};`}
                    font='french-msdf.json' negate="false"
                    scale="1 1 0.1"
                    color={this.state.textColor}
                    //geometry="primitive:plane;height:0;width:0;"
                    //material={{color:(this.props.data.textColor!==null||this.props.data.textColor!=='')?this.props.data.textColor:"black"}}
                    position={`${parseInt(this.state.textSize,10)/2*-1} 0 0`}
                    align={(this.props.data.textAlign!==null||this.props.data.textAlign!=='')?this.props.data.textAlign:"left"}
                    anchor="left"
                    //rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
                    ></a-text>
                    {
                        (this.props.data.textBG !== "transparent"&&this.props.data.textBG!==null) ?
                        <Entity geometry={{ primitive: 'plane' }} material={{ color:this.state.textBG, side: "double" }}
                            id={this.props.panoid + "textBackground" + this.props.id}
                            position={"0 0 -0.1"}
                        />
                        :
                        <Entity geometry={{ primitive: 'plane' }} material={{ color: "#fff",opacity:0.0, side: "double" }}
                            id={this.props.panoid + "textBackground" + this.props.id}
                            position={"0 0 -0.1"}
                        />
                    }
                </Entity>

            </React.Fragment>
        )
    }
}
export default CaptionHotspot;