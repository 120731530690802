import React from 'react';
import { } from "antd";
import Chart from "react-apexcharts";
import { getGradeFromPoints } from '../../utils/tools';

export interface IAppProps {
    yAxis: any[],
    xAxis: any[]
}

export function GradeGraph(props: IAppProps) {
    const options = {
        chart: {
            id: 'basic-bar',
            toolbar: {
                tools: {
                    download: "<Button>Download <Icon type='reload' / ></Button>"
                }
            }
        },
        xaxis: {
            categories: props.xAxis,
            labels: {
                hideOverlappingLabels: true,
                trim: true,
            }
        },
        yaxis: {
            labels: {
                formatter: function (value: number) {
                    return getGradeFromPoints(value);
                }
            },
            min: 0,
            max: 5,
            floating: false,
            tickAmount: 5, // number of intervals to show
        }
    }
    const series = [{
        name: 'Grades',
        data: props.yAxis,
    }];
    return (
        <div className="newCourseCard">
            <h1>GRADE STATISTICS</h1>
            <Chart
                options={options}
                series={series}
                width="100%"
                height="300px"
                type="bar"
            />
        </div>
    );
}
