 import React, { Component } from 'react';
import { Row, Col, Typography, Icon, Carousel, Card, Input, Button } from 'antd';
import tour_icon from '../../assets/Icons/VRS_asset_360globe_icon.png'
const { Search } = Input;
const AvailableCourse  = (props:any) =>
{
  return(

      <Card
          className={props.layout.bgColor}
          style={{ minWidth: "250px", maxWidth: "350px" }}
          size="small"
          cover={<div>

              <Row style={{padding: "10px"}}>
                  <Row>
                      {
                          (props.tour.firstPano !== "") ?
                              <a href={`/details/${props.tour.id}`}><img src={props.tour.firstPano} style={{ width: "100%", height: "150px", margin: 0 }} className="courseImg" alt="Course"></img></a>
                              :
                              <a href={`/details/${props.tour.id}`}><img src={props.tour.tour_Icon} style={{ width: "100%", height: "150px" }} className="courseImg" alt="Course"></img></a>
                      }
                  </Row>
                  <Row>

                      <div style={{ height: "55px" }}>
                          <a href={`/details/${props.tour.id}`}>
                              <h3 style={{ marginBottom: "0", fontWeight: 600 }} className={props.layout.h2Color}>
                              {props.tour.tourName}
                              </h3>
                          </a>
                          <div className={props.layout.span}>{props.company}</div>
                      </div>
                      {/*<Row><Button size="small" style={{ marginTop: "10px", fontSize: "12px" }} type="primary" href={`/viewer/${props.tour.id}`}>Start</Button></Row>*/}
                  </Row>

              </Row>
          </div>}

      >
      </Card>

    
  );
}
interface State {
    loading: boolean,
    key: string,
    newTours: any[],
    divs: any[],
};

class AvailableCourses extends Component<any, State> {
    state:State={
      loading:false,
      key: '1',
      newTours: [],
      divs: [],
    }
    private carousel = React.createRef<Carousel>();
    public constructor(props:any){
        super(props);
      }
    componentDidMount(){
        this.setState({
            newTours: this.props.available,
        });
          
    }
    componentDidUpdate(previousProps: any) {
        if (this.props.available !== previousProps.available) {
            this.setState({
                newTours: this.props.available,
            });
        }
        //this.makeDivs();
           
    }
       

    goto = (index: number) => {
        let node = this.carousel.current;
        node!.goTo(index);
    }
    prev = () => {
        let node = this.carousel.current;
        node!.prev();
    }
    next = () => {
        let node = this.carousel.current;
        node!.next();
    }

    render() {
        var fontColor, bgColor;
        switch (this.props.layout) {
            case "VRSafety":
                fontColor = "darkBlueText bebas";
                bgColor = "whiteBG";
                break;
            case "RealX":
                fontColor = "whiteText bebas";
                bgColor = "blackBG";
                break;
            default:
                fontColor = "darkBlueText bebas";
                bgColor = "whiteBG";
                break;
        }
        return (
          <div style={{minHeight:"200px", padding:"14px"}} className={bgColor}>
            <Row type="flex" justify="space-between">
              <Col span={6}><h1 style={{fontWeight:"bold",fontSize: "1.5em", marginBottom: "20px"}} className={fontColor}>New Courses Avaliable</h1></Col>
              <Col xs={24} sm={24} md={6} style={{textAlign: "right"}}>
                    <Search
                        placeholder="Search"
                        onSearch={this.props.onSearch}
                        style={{ width: "150px", marginRight: "10px" }}
                    />
                    <Button type="primary" onClick={() => this.props.clearSearch()}>Reset</Button>
              </Col>
            </Row>
            {
                (this.state.newTours.length > 0) ?
                        <Row type="flex" justify="space-between" style={{ overflowX: "hidden", overflowY: "scroll", flexDirection: "column", height:"600px" ,display: "flex", flexWrap: "nowrap" }}>
                            <Row>
                            {
                                this.props.available.map((value: any, index: number) =>
                                    <Col key={index} xs={24} sm={24} md={6} style={{ padding: "10px" }} ><AvailableCourse tour={value} company={this.props.company} layout={ this.props.layout}/></Col>
                                )
                            }
                            </Row>
                    </Row>
                    : null
            }
          </div >
          
        );
}
}
export default AvailableCourses;