import axios from 'axios';

export function setAuthToken(token: any) {
    if (token) {
        axios.defaults.headers.Authorization = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
}

export function setCompanyHeader() {
    let compToken = localStorage.getItem("companyToken");
    if (compToken) {
        axios.defaults.headers.common['BSD-Company'] = compToken;
    } else {
        delete axios.defaults.headers.common['BSD-Company'];
    }
}

export function setCompanyHeaderLogin(company: any) {
    if (company) {
        let CompanyString = JSON.stringify(company, null, 2);
        localStorage.setItem('companyToken', btoa(CompanyString));
        axios.defaults.headers.common['BSD-Company'] = btoa(CompanyString);
    } else {
        delete axios.defaults.headers.common['BSD-Company'];
    }
}
