import * as React from 'react';
import { connect } from 'react-redux';
import { Row, Col, message } from 'antd';

export interface IAppProps {
    teamMates: number,
    courses: number,
    stats: any

}

export function CompanySummary(props: IAppProps)
{
        return (
            <Row style={{ border: "1px solid #ccc", background: "#fff", padding: "20px", height: "500px" }}>
                <Row><h1 className="blackText" style={{ textAlign: "center", marginBottom:"50px" }}>Company Summary</h1></Row>
                <Row>
                    <Col xs={24} sm={24} md={12}>Total Courses Available</Col>
                    <Col xs={24} sm={24} md={12} className="blackText" style={{ textAlign: "end" }}><strong>{props.courses}</strong></Col>
                </Row>
                <br/>
                <Row>
                    <Col xs={24} sm={24} md={12}>Total Teammates</Col>
                    <Col xs={24} sm={24} md={12} className="blackText" style={{ textAlign: "end" }}><strong>{props.teamMates}</strong></Col>
                </Row>
                <br />
                <Row>
                    <Col xs={24} sm={24} md={12}>Total in Progress</Col>
                    <Col xs={24} sm={24} md={12} className="blackText" style={{ textAlign: "end" }}><strong>{props.stats.progress}</strong></Col>
                </Row>
                <br />
                <Row>
                    <Col xs={24} sm={24} md={12}>Total Time Spent</Col>
                    <Col xs={24} sm={24} md={12} className="blackText" style={{ textAlign: "end" }}><strong>{props.stats.time}</strong></Col>
                </Row>
                <br />
                <Row>
                    <Col xs={24} sm={24} md={12}>Total Courses Failed</Col>
                    <Col xs={24} sm={24} md={12} className="blackText" style={{ textAlign: "end" }}><strong>{props.stats.failed}</strong></Col>
                </Row>
                <br />
                <Row>
                    <Col xs={24} sm={24} md={12}>Total Courses Completed</Col>
                    <Col xs={24} sm={24} md={12} className="blackText" style={{ textAlign: "end" }}><strong>{props.stats.complete}</strong></Col>
                </Row>
                <br />
                <Row>
                    <Col xs={24} sm={24} md={12}>Average Grade</Col>
                    <Col xs={24} sm={24} md={12} className="blackText" style={{ textAlign: "end" }}><strong>{props.stats.grade}</strong></Col>
                </Row>
            </Row>



        );

 }