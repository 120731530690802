import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';
import { login, logout } from '../../actions/authActions';
import { changeManagement } from '../../actions/management-actions/management-actions';
import {
    defaultUserCompPermission,
    userCompanyPermission,
} from '../../actions/permissionActions';
import { deserializeGLT, getLayoutClasses } from '../../api/JanusHostApiGet';
import { setCompanyHeader, setAuthToken } from '../../utils/setAuthToken';
import { Permission } from '../../Interfaces/Permission';
import { stringify } from 'querystring';
import axios from 'axios';
interface State {
    glt: string,
};

class MainLanding extends Component<any, State> {
    state: State = {
        glt: '',
    }
    componentDidMount() {
        let glt = this.props.location.search.substring(this.props.location.search.lastIndexOf("=")+1);
        this.setState({ glt: glt });
        deserializeGLT(glt, this.success, this.error);
    }

    success = async (data: any) => {
        console.log(this.props);
        await this.props.login(data.data.bearer);
        localStorage.setItem('companyToken', data.data.company);
        await this.successUserPermissions(data)
        //localStorage.setItem('permissions',data.data.permissions);
        //localStorage.setItem('role',data.data.role);
        //localStorage.setItem('connected',data.data.connected);
        //localStorage.setItem('companyName',data.data.companyName);
        //localStorage.setItem('serviceCode',data.data.serviceCode);
        //localStorage.setItem('serviceId',data.data.serviceId);
        //localStorage.setItem('layout',data.data.layout);
        //localStorage.setItem('logo',data.data.logo);
        setAuthToken(data.data.bearer);
        //console.log(data.data.company);
        setCompanyHeader();

        //window.location.href = 'vrsafety/home';
    }
    successUserPermissions = async (data: any) => {
        var layout= {};
        await getLayoutClasses(data.data.layout, (result: any) => { layout = result.data }, this.error);
        let perms: Permission = {
            company: {
                id: data.data.companyId,
                logo: data.data.logo,
                banner: data.data.banner,
                name: data.data.companyName,
            },
            relation: {
                connected: data.data.connected,
                permissions: data.data.permissions,
                role: data.data.role,
            },
            service: {
                id: data.data.serviceId,
                code: data.data.serviceCode,
                layout: layout,
            },
            components: {}
        };

        await this.props.changeManagement(
            {
                menu: `${data.data.role} Login`,
                role: data.data.role,
            }
        );

        await axios.get(`/api/v1/vrsafety/component/getComponentAccess`)
            .then((res: any) => {
                let components: any = {
                    names: [],
                    paths: []
                };
                components.names = res.data;
                components.paths = ["/overview", "/manage", "/coursedetails/:id", "/manageAccess", "/home", "/details/:id", "/"];
                if (res.data.includes("Tour Builder")) {
                    components.paths.push("/tour/:component/:id?", "/viewer/:id");
                }
                if (res.data.includes("Grades")) {
                    components.paths.push("/manage-grades", "/teammate-grades/:id", "/grades");
                }
                if (res.data.includes("Tags"))
                    components.paths.push("/viewTags", "/updateTag/:id", "/createTag");

                if (res.data.includes("Company Profile")) {
                    components.paths.push("/company");
                }
                if (res.data.includes("Progress"))
                    components.paths.push("/progress");
                if (res.data.includes("Manage Access"))
                    components.paths.push("/manageAccess");

                console.log(data.data.layout);
                perms.components = components;
                perms.service.layout = layout;

                localStorage.setItem('layout', JSON.stringify(layout, null, 2));

                console.log(components);
                console.log(res.data);
            })
            .catch((err: any) => {
                console.log(err);
            })
        await this.props.userCompanyPermission(perms);
        //console.log(this.props.company);
        window.location.href = '/home';
    };
    error = (data: any) => {
        console.log(data);
    }

    render() {
        return (

            <Row id="main" style={{ marginBottom: "" }}>
            </Row>
        );
    }
}
function mapToStateToProps(state: any) {
    return {
        auth: state.auth,
        management: state.management,
        
    };
}
export default connect(mapToStateToProps, {
    login,
    changeManagement,
    userCompanyPermission,
}
)(MainLanding);