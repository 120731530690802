import React, { Component, Fragment } from 'react';
import 'aframe';
import { Entity, Scene } from 'aframe-react';
import { Upload, Icon, Modal, Button, Spin, Tooltip, message, Collapse, Row, Col, Select, Form, Input, Drawer, Checkbox, List } from 'antd';
import AssignmentForm from './AssignmentForm';
import { getTourSequenceHotspot, getSharedResourcesHotspots, getHotspotResources, getTourStats, validateTour } from '../../../api/APIget';
import { deleteFile, createHotspots, updateCoordinates, moveHotspotLocation, deleteHotspot, createNewState, addDefaultState } from '../../../api/APIpost';
import Forward from '../../../assets/Icons/Forward.png';
import Hotspot from '../../../assets/Icons/Hotspot.png';
import sphere from '../../../assets/Icons/3D_sphere.png';
import Add_hotspot_icon from '../../../assets/Icons/Add_HotSpot_Icon.png';
import Audio_icon from '../../../assets/Icons/Audio_icon.png';
import Video_icon from '../../../assets/Icons/Video_icon.png';
import Video_Small from '../../../assets/Icons/Video_Small.png';
import Speaker_Icon from '../../../assets/Icons/Speaker_Icon.png';
import circle_frame from '../../../assets/Icons/circle_frame.png';
import exit_icon_white from '../../../assets/Icons/exit_icon_white.png';
import exit_icon_black from '../../../assets/Icons/exit_icon_black.png';
import Branch_icon_white from '../../../assets/Icons/Branch_icon_white.png';
import Branch_icon_black from '../../../assets/Icons/Branch_icon_black.png';
import Text_icon_white from '../../../assets/Icons/Text_Icon_White.png';
import Text_icon_black from '../../../assets/Icons/Text_Icon_Black.png';
import Pagination_icon_white from '../../../assets/Icons/Pagination_Icon_White.png';
import Pagination_icon_black from '../../../assets/Icons/Pagination_Icon_Black.png';
import Image_icon_black from '../../../assets/Icons/Image_Icon_Black.png';
import Image_icon_white from '../../../assets/Icons/Image_Icon_White.png';
import Audio_icon_white from '../../../assets/Icons/Audio_Icon_White.png';
import Audio_icon_black from '../../../assets/Icons/Audio_Icon_Black.png';
import Video360_icon_black from '../../../assets/Icons/360video_Icon_Black.png';
import Video360_icon_white from '../../../assets/Icons/360video_Icon_White.png';
import Video_icon_black from '../../../assets/Icons/Video_Icon_Black.png';
import Video_icon_white from '../../../assets/Icons/Video_Icon_White.png';
import Link_icon_white from '../../../assets/Icons/Link_Icon_White.png';
import Link_icon_black from '../../../assets/Icons/Link_Icon_Black.png';
import Camera_position_white from '../../../assets/Icons/camera_position_icon_white.png';
import Camera_position_black from '../../../assets/Icons/camera_position_icon_black.png';
import Caption_icon_black from '../../../assets/Icons/Caption_Icon_Black.png';
import Caption_icon_white from '../../../assets/Icons/Caption_Icon_White.png';
import { getCurrentDate } from '../../../utils/tools';
import { coordinates } from '../../../models/HotspotInterfaces';
import axios from 'axios';
import { AWS_URL } from '../../../app/FrontEndVariables';

const { confirm } = Modal;
const { Panel } = Collapse;
const ButtonGroup = Button.Group;
const token = localStorage.token;
const { Option } = Select;

interface State {
    positionArray: Array<any>;
    count: number;
    visible: boolean;
    selected: any;
    tourInfo: any,
    hotspotList: any,
    loading: boolean,
    uploading: boolean,
    panobackground: string,
    saving: boolean,
    fileList: any[],
    library: any[],
    click: any,
    removing: number,
    mode: 'add' | 'assign' | 'move' | 'remove'
    tourId: string,
    companyId: string,
    newVersion: boolean,
    collapseLeft: boolean,
    collapseRight: boolean,
    swapFileModal: boolean,
    isImage: boolean,
    isVideo: boolean,
    isAudio: boolean,
    fileToSwap: any,
    swapFile: any,
    isUsed: boolean,
    files: any,
    result: any,
    replace: boolean,
    swapSharedFileModal: boolean,
    panoToLink: number,
    progressionType: boolean,
    currentState: string,
    showStateModal: boolean,
    newState: string,
    baseState: string,
    tourName: string,
    statModal: boolean,
    stats: any,
    validateModal: boolean,
    validationErrors: any,
    validationWarnings: any
};


class Coordinates extends Component<any, State>
{
    state: State = {
        //complete tour info minus id
        tourInfo: {},
        //array of all the positions on the pano
        positionArray: [],
        //id when adding hotspots
        count: 0,
        //selected storage for assigning co-ordinates
        selected: {},
        //assignment modal control
        visible: false,
        //loading in control
        loading: true,
        //uploading files control
        uploading: false,
        //string for the sky/bg in aframe
        panobackground: '',
        //list of all files uploaded
        fileList: [],
        //list all hotspot files uploaded in tours's resource folder
        library: [],
        //double clicking event item capture
        click: {},
        //loading when saving
        saving: false,
        //id of co-ordinate to remove
        removing: 0,
        //mode to control which mode you are doing
        mode: 'add',
        tourId: "",
        companyId: "",
        newVersion: false,
        hotspotList: [],
        collapseLeft: false,
        collapseRight: false,
        swapFileModal: false,
        isImage: false,
        isVideo: false,
        isAudio: false,
        isUsed: false,
        fileToSwap: {},
        swapFile: {},
        files: null,
        result: null,
        replace: false,
        swapSharedFileModal: false,
        panoToLink: 0,
        progressionType: false,
        currentState: "default",
        showStateModal: false,
        newState: "",
        baseState: "",
        tourName: "",
        statModal: false,
        stats: {},
        validateModal: false,
        validationErrors: {},
        validationWarnings: {}
    };

    public constructor(props: any) {
        super(props);

        this.handleFileChange = this.handleFileChange.bind(this);
        this._handleSubmit = this._handleSubmit.bind(this);
    }
    componentDidMount() {
        this.setState({ tourId: this.props.match.params.id, companyId: this.props.auth.company.id })
        getTourSequenceHotspot(this.props.match.params.id, this.getSuccess, this.apiError);
        getHotspotResources(this.props.match.params.id, this.getHotspotSuccess, this.apiError);
        this.addCoordinates("add");
        console.log(this.state.fileList);
        getSharedResourcesHotspots(this.props.auth.company.id, this.getSharedResourcesSuccess, this.apiError);
    }


    /**
    * Gets the data and sets it
    */
    getSuccess = (data: any) => {

        if (data.data.courseProgressionType) {
            //this.addLinkHotspots();
            console.log(data);

            axios({
                method: 'post',
                url: `/api/v1/vrsafety/HotSpot/linkHotspots/${data.data.tour.id}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data; boundary=---------------------------81801171514357'
                }
            })
                .then((response) => {
                    //handle success
                    console.log(response);
                    if (!response.data) {

                        console.log(data);
                        this.setState({
                            positionArray: data.data.tour.sceneObjects[0].contentData.filter((data: any) => data.state === "default" || data.state === null),
                            tourInfo: data.data.tour,
                            tourName: data.data.tourName,
                            progressionType: data.data.courseProgressionType,
                            loading: false
                        });
                        if (data.data.tour.states === null) {
                            this.setState({ positionArray: data.data.tour.sceneObjects[0].contentData });
                        }
                        var hotspots = [];
                        for (let i = 0; i < this.state.tourInfo.sceneObjects.length; i++) {
                            for (let j = 0; j < this.state.tourInfo.sceneObjects[i].contentData.length; j++) {
                                if (this.state.tourInfo.sceneObjects[i].contentData[j].state === "default" || this.state.tourInfo.sceneObjects[i].contentData[j].state === null) {
                                    let hotspot = this.state.tourInfo.sceneObjects[i].contentData[j];
                                    hotspot.panoId = this.state.tourInfo.sceneObjects[i].id;
                                    hotspots.push(hotspot);
                                }
                            }
                        }
                        this.setState({ hotspotList: hotspots });
                        console.log(this.state.positionArray);
                        console.log(this.state.hotspotList);
                        console.log(data.data.courseProgressionType);
                    }
                    else {
                        console.log(response.data);
                        this.setState({
                            positionArray: response.data.sceneObjects[0].contentData.filter((data: any) => data.state === "default" || data.state === null),
                            progressionType: data.data.courseProgressionType,
                            tourInfo: response.data,
                            loading: false
                        });
                        if (response.data.states === null) {
                            this.setState({ positionArray: response.data.tour.sceneObjects[0].contentData });
                        }
                        var hotspots = [];
                        for (let i = 0; i < this.state.tourInfo.sceneObjects.length; i++) {
                            for (let j = 0; j < this.state.tourInfo.sceneObjects[i].contentData.length; j++) {
                                if (this.state.tourInfo.sceneObjects[i].contentData[j].state === "default" || this.state.tourInfo.sceneObjects[i].contentData[j].state === null) {
                                    let hotspot = this.state.tourInfo.sceneObjects[i].contentData[j];
                                    hotspot.panoId = this.state.tourInfo.sceneObjects[i].id;
                                    hotspots.push(hotspot);
                                }
                            }
                        }
                        this.setState({ hotspotList: hotspots });
                        console.log(this.state.positionArray);
                        console.log(this.state.hotspotList);
                        console.log(data.data.courseProgressionType);
                        message.success("Link Hotspots were added successfully");
                    }

                })
                .catch(function (response) {
                    //handle error
                    console.log(response);
                    message.error("There was a problem getting your data.");
                })



        }

        else {
            console.log(data);

            this.setState({
                positionArray: data.data.tour.sceneObjects[0].contentData.filter((data: any) => data.state === "default" || data.state === null),
                tourInfo: data.data.tour,
                tourName: data.data.tourName,
                progressionType: data.data.courseProgressionType,
                loading: false
            });
            if (data.data.tour.states === null) {
                this.setState({ positionArray: data.data.tour.sceneObjects[0].contentData });
            }
            var hotspots = [];
            for (let i = 0; i < this.state.tourInfo.sceneObjects.length; i++) {
                for (let j = 0; j < this.state.tourInfo.sceneObjects[i].contentData.length; j++) {
                    //console.log(this.state.tourInfo.sceneObjects[i].contentData[j]);
                    if (this.state.tourInfo.sceneObjects[i].contentData[j].state === "default" || this.state.tourInfo.sceneObjects[i].contentData[j].state === null) {
                        let hotspot = this.state.tourInfo.sceneObjects[i].contentData[j];
                        hotspot.panoId = this.state.tourInfo.sceneObjects[i].id;
                        hotspots.push(hotspot);
                    }
                }
            }
            this.setState({ hotspotList: hotspots });
            console.log(this.state.positionArray);
            console.log(this.state.hotspotList);
            console.log(data.data.courseProgressionType);
        }

    }

    /**
    * Gets the data and sets it
    */
    getTotalTourStats = () => {
        this.setState({ loading: true });
        getTourStats(this.state.tourInfo.id, this.getTotalTourStatsSuccess, this.getStatsError);
    }

    getTotalTourStatsSuccess = (data: any) => {
        console.log(data.data);
        this.setState({ stats: data.data, statModal: true, loading: false });
    }

    getStatsError = (data: any) => {
        console.log(data);
        this.setState({ loading: false });
        message.error("Sorry! Cannot fetch the records currently. Please try again later");
    }

    validatingTour = () => {
        this.setState({ loading: true });
        validateTour(this.state.tourInfo.id, this.validateTourSuccess, this.validateTourError);
    }

    validateTourSuccess = (data: any) => {
        console.log(data.data);
        if (data.data.errors.length === 0 && data.data.warnings.length === 0) {
            message.success("No errors or warnings found in this tour.");
            this.setState({ validateModal: true, loading: false });
        }
        else {
            this.setState({ validationErrors: data.data.errors, validationWarnings: data.data.warnings, validateModal: true, loading: false });
        }
    }

    validateTourError = (data: any) => {
        console.log(data);
        this.setState({ loading: false });
        message.error("Sorry! Cannot validate tour at this moment. Please try again later.");
    }

    /**
     * sets the panobackground after the image has loaded
     */
    loaded = (e: any) => {
        this.setState({ panobackground: '#image0' });
    }
    /**
     * checks the filetype before uploading
     */
    beforeUpload = () => async (file: any) => {
        console.log(file);
        let m = await new Promise<void>((resolve: any, reject: any) => {
            if (file.type.substring(0, file.type.indexOf('/')) === 'image' || file.type.substring(0, file.type.indexOf('/')) === 'video' || file.type.substring(0, file.type.indexOf('/')) === 'audio') {
                axios.get(`/api/v1/vrsafety/file/check-shared-resources/${file.name}/${this.props.match.params.id}`).then((result) => {
                    console.log(result);
                    if (!result.data) {
                        resolve();
                    }
                    else {
                        confirm({
                            title: "File is already uploaded",
                            content: "Would you like to upload the file anyways?",
                            okText: "Yes",
                            onOk: () => {
                                this.setState((state) => { return { newVersion: true } });
                                resolve();
                            },
                            cancelText: "No",
                            onCancel: () => {
                                reject(false);
                            }
                        });
                    }
                }).catch((error: any) => {
                    message.error("There was a problem with uploading the file. Please try again");
                    console.log(error);
                })
            }
            else {
                let filetype = file.type.substring(file.type.indexOf('/'));
                message.error(filetype + ' is not an allowed file type')
                reject(false);
            }
        });
        return m;
    }


    beforeUploadShared = () => async (file: any) => {
        console.log(file);
        let m = await new Promise<void>((resolve: any, reject: any) => {
            if (file.type.substring(0, file.type.indexOf('/')) === 'image' || file.type.substring(0, file.type.indexOf('/')) === 'video' || file.type.substring(0, file.type.indexOf('/')) === 'audio') {
                axios.get(`/api/v1/vrsafety/file/check-shared-hotspots/${file.name}`).then((result) => {
                    console.log(result);
                    if (!result.data) {
                        resolve();
                    }
                    else {
                        confirm({
                            title: "File is already uploaded",
                            content: "Would you like to upload the file anyways?",
                            okText: "Yes",
                            onOk: () => {
                                this.setState((state) => { return { newVersion: true } });
                                resolve();
                            },
                            cancelText: "No",
                            onCancel: () => {
                                reject(false);
                            }
                        });
                    }
                }).catch((error: any) => {
                    message.error("There was a problem with uploading the file. Please try again");
                    console.log(error);
                })
            }
            else {
                let filetype = file.type.substring(file.type.indexOf('/'));
                message.error(filetype + ' is not an allowed file type')
                reject(false);
            }
        });
        return m;
    }

    /**
       * Gets the hotspot files in the tour's resource folder and sets it
       */
    getHotspotSuccess = (data: any) => {
        this.setState({ library: data.data, loading: false });
        // console.log(this.state.library);
    }


    /**
     * Succes Modal 
     */
    successModal = (content: string, title?: string) => {
        Modal.success({
            title: title,
            content: content,
        });
    }
    /**
     * Error Modal
     */
    errorModal = (content: string, title?: string) => {
        Modal.error({
            title: title,
            content: content,
        });
    }
    /**
     * Gets the error data from the api call
     * @param data 
     */
    apiError = (data: any) => {
        console.log(data);
        this.errorModal("An Error occurred. Check the console for more techinical details", "Error");
    }

    getSharedResourcesSuccess = (data: any) => {
        console.log(data.data);
        this.setState((state) => { return { fileList: data.data } });
    }

    /***
     * Custom Upload request for shared resources
     */
    upload = (options: any) => {
        console.log(options);
        var bodyFormData = new FormData();
        bodyFormData.append("file", options.file);
        bodyFormData.append("tourID", this.state.tourId);
        bodyFormData.append("companyID", this.state.companyId);
        bodyFormData.append("newVersion", this.state.newVersion.toString());
        axios({
            method: 'post',
            url: '/api/v1/vrsafety/File/resource-upload-hotspot',
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data; boundary=---------------------------81801171514357'
            }
        })
            .then((response) => {
                //handle success
                console.log(response);
                let fileList = this.state.fileList
                let newFile = response.data;
                newFile.uid = fileList.length;
                console.log(newFile);
                fileList.push(newFile);
                this.setState({ fileList: fileList, uploading: false, newVersion: false });
            })
            .catch(function (response) {
                //handle error
                console.log(response);
                message.error("There was a problem uploading your file.");
            })
    }

    /***
       * Custom Upload request for local library
       */
    uploadLibrary = (options: any) => {
        console.log(options);
        var bodyFormData = new FormData();
        bodyFormData.append("file", options.file);
        bodyFormData.append("tourID", this.state.tourId);
        bodyFormData.append("companyID", this.state.companyId);
        bodyFormData.append("newVersion", this.state.newVersion.toString());
        axios({
            method: 'post',
            url: '/api/v1/vrsafety/File/largefileupload',
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data; boundary="----WebKitFormBoundarymx2fSWqWSd0OxQqq"'
            }
        })
            .then((response: any) => {
                //handle success
                console.log(response);
                let library = this.state.library
                let newFile = response.data.url.substring(response.data.url.lastIndexOf(".com/") + 5);
                console.log(newFile);
                library.push(newFile);
                this.setState({ library: library, uploading: false, newVersion: false });
                message.success("File uploaded successfully");
            })
            .catch(function (response: any) {
                //handle error
                console.log(response);
                message.error("There was a problem uploading your file.");
            })
    }



    // Experiment begins

    tryUpload = (options: any) => {
        console.log(options);
        var bodyFormData = new FormData();
        bodyFormData.append("file", options.file);
        bodyFormData.append("tourID", this.state.tourId);
        bodyFormData.append("companyID", this.state.companyId);
        bodyFormData.append("newVersion", this.state.newVersion.toString());
        axios({
            method: 'post',
            url: '/api/v1/vrsafety/File/streaming',
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then((response: any) => {
                //handle success
                console.log(response);
                /*let library = this.state.library
                let newFile = response.data.url.substring(response.data.url.lastIndexOf(".com/") + 5);
                console.log(newFile);
                library.push(newFile);
                this.setState({ library: library, uploading: false, newVersion: false });
                message.success("File uploaded successfully");*/
            })
            .catch(function (response: any) {
                //handle error
                console.log(response);
                message.error("There was a problem uploading your file.");
            })
    }

    // Experiment ends




    /**
     * Information about the Pano maker goes here
     */
    infoModal = () => {
        Modal.info({
            title: 'Hotspot Creation',
            content: (
                <div>
                    <p><Icon type="plus" /> Will change the mode to addition where hotspots can be created by double clicking</p>
                    <p><Icon type="save" /> Will save all of the hotspots that do not have data assigned to them</p>
                    <p><Icon type="delete" /> Will change the mode to deletion where clicking on a hotspot will bring up a prompt to delete the hotspot</p>
                    <p><Icon type="form" /> Will change the mode to assign where clicking on a hotspot will bring up a form to choose the type of hotspot there</p>
                </div>
            ),
            onOk() { },
        });
    }
    callback(key: any) {
        console.log(key);
    }
    /**
     * Adds the event listeners for double clicking to add hotspot co-ordinates
     */
    addCoordinates = (mode: 'add' | 'move') => {
        this.setState({ mode: mode });
        document.getElementById('scene')!.addEventListener('click', ((evt: any) => {
            //console.log(evt);
            if (evt.detail.hasOwnProperty('intersectedEl')) {
                if (evt.detail.intersectedEl.id === "sky") {
                    this.setState({ click: evt });
                }
            }

            //event to create new position on doubleclick
            document.getElementById('scene')!.addEventListener('dblclick', (event: any) => {
                event.stopPropagation();
                event.stopImmediatePropagation();
                let e = this.state.click;
                console.log(this.state.click);
                //if the sky to intersect with it will not execute the rest of the code
                if (this.state.mode === 'add' || this.state.mode === "move")
                    if (!this.isNullOrUndefined(e.detail.intersection)) {
                        let camera = e.target.parentElement;
                        let cameraRot = camera.getAttribute('rotation');
                        let position = e.detail.intersection.point;
                        if (this.state.mode === "add") {
                            let x = position.x;
                            let z = position.z;
                            let y = position.y;
                            console.log(position);
                            if (z < 0 && z >= -15) {
                                z = z + z * -0.1;
                            }
                            else if (z > 0 && z <= 15) {
                                z = z - z * 0.1;
                            }

                            if (x < 0 && x >= -15) {
                                x = x + x * -0.1;
                            }
                            else if (x > 0 && x <= 15) {
                                x = x - x * 0.1;
                            }

                            let newPosition = {
                                id: this.state.count,
                                positionArray: {
                                    positionx: x,
                                    positiony: y,
                                    positionz: z,
                                },
                                rotationArray: {
                                    rotationx: cameraRot.x,
                                    rotationy: cameraRot.y,
                                    rotationz: cameraRot.z
                                }
                            };
                            console.log(newPosition);
                            this.setState({ count: this.state.count - 1, positionArray: [newPosition, ...this.state.positionArray] });
                        }
                        else if (this.state.mode === "move" && typeof this.state.selected.positionArray === "undefined") {
                            Modal.error({ title: "Select a hotspot to move first!" });
                        }
                        else if (this.state.mode === "move" && typeof this.state.selected.positionArray !== "undefined") {
                            let updatedPosition = this.state.selected;
                            // console.log(updatedPosition);
                            let x = position.x;
                            let z = position.z;
                            let y = position.y;
                            if (z < 0 && z >= -15) {
                                z = z + z * -0.1;
                            }
                            else if (z > 0 && z <= 15) {
                                z = z - z * 0.1;
                            }

                            if (x < 0 && x >= -15) {
                                x = x + x * -0.1;
                            }
                            else if (x > 0 && x <= 15) {
                                x = x - x * 0.1;
                            }
                            updatedPosition.positionArray.positionx = x;
                            updatedPosition.positionArray.positiony = y;
                            updatedPosition.positionArray.positionz = z;

                            updatedPosition.rotationArray.rotationx = cameraRot.x;
                            updatedPosition.rotationArray.rotationy = cameraRot.y;
                            updatedPosition.rotationArray.rotationz = cameraRot.z;
                            // console.log(this.state.positionArray);
                            let positions = this.state.positionArray.filter((position) => position.id !== this.state.selected.id);
                            // console.log(positions);
                            // console.log(updatedPosition);
                            this.setState((state) => { return { positionArray: [updatedPosition, ...positions] } });
                            // console.log(this.state.positionArray);
                            confirm({
                                title: "Is this the location you would like to move the hotspot to?",
                                onOk: () => {
                                    let panoId = parseInt(this.state.panobackground.substring(this.state.panobackground.lastIndexOf('e') + 1));
                                    let movedHotspot =
                                    {
                                        Id: updatedPosition.id,
                                        PositionArray: updatedPosition.positionArray,
                                        RotationArray: updatedPosition.rotationArray,
                                        PanoId: this.state.tourInfo.sceneObjects[panoId].id,
                                        TourId: this.state.tourInfo.id
                                    };
                                    console.log(movedHotspot);
                                    moveHotspotLocation(movedHotspot, this.moveSuccess, this.apiError);
                                },
                                onCancel: () => { }
                            });
                        }
                    }
            });
        }) as EventListener);
    }
    /**
     * Removes the double click event for adding the co-ordinates
     */
    updateMode = (mode: 'add' | 'assign' | 'move' | 'remove') => {
        this.setState({ mode: mode });
    }
    /**
     * saves all the co-ordinates that have not been assigned
     */
    save = () => {
        this.setState({ saving: true });
        console.log(this.state.tourInfo);
        if (this.state.positionArray.length > 0) {
            let panoId = parseInt(this.state.panobackground.substring(this.state.panobackground.lastIndexOf('e') + 1));
            let hotspot =
            {
                "Id": this.state.tourInfo.sceneObjects[panoId].id,
                "ScopicMode": this.state.tourInfo.sceneObjects[panoId].scopicMode,
                "mediaPath": this.state.tourInfo.sceneObjects[panoId].mediaPath,
                "FileName": this.state.tourInfo.sceneObjects[panoId].fileName,
                "UID": this.state.tourInfo.sceneObjects[panoId].uid,
                "NiceName": this.state.tourInfo.sceneObjects[panoId].niceName,
                "Thumbnail": this.state.tourInfo.sceneObjects[panoId].thumbnail,
                "ContentData": this.createPositionData(),
                "type": this.state.tourInfo.sceneObjects[panoId].type,
                "TourId": this.state.tourInfo.id
            }
            updateCoordinates(hotspot, this.postCreateSuccess, this.apiError);
        }
        else {
            this.errorModal("Create Hotspots Before Attempting to Save");
        }
    }
    /**
     * Updates the arrays with the correct ids from the db
     */
    postCreateSuccess = (data: any) => {
        console.log(data.data);
        let panoId = parseInt(this.state.panobackground.substring(this.state.panobackground.lastIndexOf('e') + 1));
        let updatedTourInfo = this.state.tourInfo;
        console.log(updatedTourInfo)
        updatedTourInfo.sceneObjects[panoId].contentData = data.data;
        this.setState({ tourInfo: updatedTourInfo, positionArray: data.data, saving: false });
        this.successModal("The Co-ordinates were saved successfully!", "Success");
    }
    /**
     * 
     */
    moveSuccess = (data: any) => {
        message.success("Hotspot was moved successfully");
        this.setState({ selected: {} });
    }
    /**
     * creates the hotspot co-ordinates object
     */
    createPositionData() {
        let positions = Array<coordinates>();
        this.state.positionArray.forEach(element => {
            positions.push({
                id: element.id,
                positionArray: {
                    positionx: element.position.x,
                    positiony: element.position.y,
                    positionz: element.position.z
                },
                rotationArray: {
                    rotationx: element.aframePosition.rotationx,
                    rotationy: element.aframePosition.rotationy,
                    rotationz: element.aframePosition.rotationz
                }
            })
        });
        return positions;
    }
    /**
     * Changes the background to the sky 
     * @param id 
     */
    goto(id: number) {
        this.setState({ panobackground: '#image' + id, positionArray: this.state.tourInfo.sceneObjects[id].contentData.filter((data: any) => data.state == this.state.currentState) });
        if (this.state.currentState === "default") {
            this.setState({
                positionArray: this.state.tourInfo.sceneObjects[id].contentData.filter((data: any) => data.state == this.state.currentState || data.state === null)
            })
        }
    }
    /**
     * creates the hotspot object to save and update
     */
    assignInfo = async (information: any) => {
        console.log(this.state.positionArray);
        console.log(information.selected);
        let index = this.state.positionArray.findIndex(i => i.id === information.selected.id);
        console.log(index);
        console.log(this.state.tourInfo);
        if (index !== -1) {
            let filename = '';
            if (!this.isNullOrUndefined(information.ImagePath) && information.ImagePath !== "") {
                console.log(information.ImagePath);
                filename = information.ImagePath.substring(information.ImagePath.lastIndexOf("/") + 1);
                console.log(filename);
            }
            else if (!this.isNullOrUndefined(information.VideoPath) && information.VideoPath !== "") {
                filename = information.VideoPath.substring(information.VideoPath.lastIndexOf("/") + 1);
            }
            else if (!this.isNullOrUndefined(information.AudioFile) && information.AudioFile !== "") {
                filename = information.AudioFile.substring(information.AudioFile.lastIndexOf("/") + 1);
            }
            //gets the pano number
            console.log(this.state.panobackground);
            let panoId = parseInt(this.state.panobackground.substring(this.state.panobackground.lastIndexOf('e') + 1));
            console.log();
            console.log(this.state.tourInfo.sceneObjects);
            let hotspot = {
                Id: information.selected.id,
                AdditionalData: this.state.positionArray[index].additionalData,
                ParentUID: this.state.positionArray[index].parentUID || panoId,
                TargetUID: information.TargetUID,
                PositionArray: this.state.positionArray[index].positionArray,
                RotationArray: this.state.positionArray[index].rotationArray,
                type: information.type,
                FileName: filename,
                PageTitle: information.PageTitle,
                MessagePages: information.MessagePages,
                ImagePath: information.ImagePath,
                ImageSize: information.ImageSize,
                TextSize: information.TextSize,
                TextAlign: information.TextAlign,
                TextColor: information.TextColor,
                TextBG: information.TextBG,
                ImageTrans: information.ImageTrans,
                PreferredImageWidth: 700,
                Message: information.Message,
                VideoPath: information.VideoPath,
                AudioFile: information.AudioFile,
                AutoPlay: information.AutoPlay,
                Loop: information.Loop,
                Volume: information.Volume,
                PanoId: this.state.tourInfo.sceneObjects[panoId].id,
                deletePrevFile: false,
                TourId: this.state.tourInfo.id,
                State: this.state.currentState,
                Question: information.Question,
                QuestionImage: information.QuestionImage,
                Answers: information.AnswerData,
                IconColor: information.IconColor,
            }

            console.log(hotspot);

            if (hotspot.Id.length === 24 && (hotspot.FileName !== "" || hotspot.MessagePages.some((page: any) => page.pageImageFileName !== ""))) {
                /*confirm({
                  title:"Would you like to delete the previous file? If the file was used in any other tours it will affect those instances as well.",
                  okText:"Yes",
                  okType:"danger",
                  onOk:()=>{
                    hotspot.deletePrevFile=true;
                    createHotspots(hotspot,this.postHotspotSuccess,this.apiError);
                  },
                  cancelText:"No",
                  onCancel:()=>{
                    createHotspots(hotspot,this.postHotspotSuccess,this.apiError);
                  },
                });
                this.setState({visible:false});
                console.log(hotspot);*/

                hotspot.deletePrevFile = false;
                createHotspots(hotspot, this.postHotspotSuccess, this.apiError);

            }
            else {
                this.setState({ visible: false });
                createHotspots(hotspot, this.postHotspotSuccess, this.apiError);
            }
            this.setState({ visible: false });
        }
        else {
            this.errorModal("Select a Hotspot to assign", "Error");
        }
    }
    /**
     * If hotspot was successfully created removed from the previous mapped data array and updates
     * the saved information on the page
     */
    postHotspotSuccess = (data: any) => {
        console.log(data.data);

        let newpositionArray = this.state.positionArray;
        let index = newpositionArray.findIndex(i => i.id === this.state.selected.id);
        newpositionArray.splice(index, 1, data.data);

        let newInfo = this.state.tourInfo;
        let panoIndex = this.state.panobackground.substring(this.state.panobackground.lastIndexOf('e') + 1);

        //newInfo.sceneObjects[panoIndex].contentData=newpositionArray;
        newInfo.sceneObjects[panoIndex].contentData.push(data.data);
        console.log(newInfo);
        this.setState({ tourInfo: newInfo, positionArray: newpositionArray });
        this.successModal("Hotspot data was saved Successfully!");


        var hotspots = [];
        for (let i = 0; i < this.state.tourInfo.sceneObjects.length; i++) {
            for (let j = 0; j < this.state.tourInfo.sceneObjects[i].contentData.length; j++) {
                if (this.state.tourInfo.sceneObjects[i].contentData[j].state === "default" || this.state.tourInfo.sceneObjects[i].contentData[j].state === null) {
                    let hotspot = this.state.tourInfo.sceneObjects[i].contentData[j];
                    hotspot.panoId = this.state.tourInfo.sceneObjects[i].id;
                    hotspots.push(hotspot);
                }
            }
        }
        this.setState({ hotspotList: hotspots, selected: {} });

        //this.setState({ library: library, uploading: false, newVersion: false });
    }
    /**
     * Handles the changes when uploading files
     */


    updateLibrary = (file: string) => {
        let library = this.state.library;
        library.push(file);
        this.setState({ library: library });
    }


    handleChange = (fileList: any) => {
        console.log(fileList);
        console.log(fileList.file);
        console.log(fileList.file.status);
        let response = fileList.file;
        if (fileList.file.status === "error") {
            console.log(fileList.file.response);
            this.setState({ uploading: false });
        }
        if (fileList.file.status === 'uploading') {
            this.setState({ uploading: true });
            return;
        }
        if (response.hasOwnProperty('xhr')) {
            // Get this url from response in real world.
            let fileList = this.state.fileList
            console.log(this.state.fileList);
            let newFile = response;
            console.log(newFile);
            // newFile["url"]=response.xhr.responseText;
            fileList.push(newFile);
            this.setState({ fileList: fileList, uploading: false });
        }
        console.log(this.state.fileList);
    };
    isNullOrUndefined(value: any) {
        return value === null || value === undefined
    }
    /**
     * Handles the click event 
     *   
     */
    handleClick = (e: any) => {
        console.log('Clicked!');
        console.log(e);
        console.log(e.detail.intersectedEl);
        console.log(this.state.mode === 'add' && e.detail.intersectedEl.id !== "sky");
        if (this.state.mode === 'add' && e.detail.intersectedEl.id !== "sky") {
            let entity = e.detail.intersectedEl;
            console.log(entity);
            //DO NOT CHANGE TO === TO CORRECT WARNING 
            let selected = this.state.positionArray.find((pos) => pos.id == entity.id);
            console.log(entity.position);
            console.log(selected);

            this.setState({ selected: selected, visible: true });

            console.log(selected)

        }
        else if (this.state.mode === 'remove' && e.detail.intersectedEl.id !== "sky") {
            let entity = e.detail.intersectedEl;
            this.setState({ removing: parseInt(entity.id) });
            console.log(entity.id);
            console.log(this.state.positionArray);
            let removal = this.state.positionArray.find((coord: any) => {
                //do not change to === to correct warning
                return coord.id == entity.id
            });
            console.log(removal);
            console.log((!this.isNullOrUndefined(removal.fileName) && removal.fileName.length !== 0));
            console.log((!this.isNullOrUndefined(removal.messagePages) && removal.messagePages.some((page: any) => page.pageImageFileName.length !== 0)));
            confirm({
                title: 'Are you sure you want to remove this Hotspot?',
                okText: 'Yes',
                okType: 'danger',
                cancelText: 'No',
                onOk: () => {
                    /*if((!isNullOrUndefined(removal.fileName) && removal.fileName.length !==0) || (!isNullOrUndefined(removal.messagePages) && removal.messagePages.some((page:any)=>page.pageImageFileName.length !==0)))
                    {
                       confirm({
                        title:"Would you like to delete the file associate with this hotspot?",
                        content:"This would permenantly delete the file from your account. This will affect all instances where this file is used. This is PERMENANT",
                        okText:"Yes",
                        okType:"danger",
                        onOk:()=>{this.removeCoordinate(true);},
                        cancelText:"No",
                        onCancel:()=>{this.removeCoordinate(false);}
                      });
                    }
                    else
                    {
                      this.removeCoordinate(false);
                    }*/
                    this.removeCoordinate(false);
                },
                onCancel() {
                },
            });
        }
        else if (this.state.mode === "move" && e.detail.intersectedEl.id !== "sky") {
            let entity = e.detail.intersectedEl;
            //DO NOT CHANGE TO === TO CORRECT WARNING 
            let selected = this.state.positionArray.find((pos) => pos.id == parseInt(entity.id));
            console.log(selected);
            confirm({
                title: 'Would you like to move this Hotspot?',
                onOk: () => { this.setState({ selected: selected }); },
                onCancel() { },

            });
            //this.setState({selected: selected});
        }
    }
    /**
     * Cancels the assginment modal
     */
    onCancel = () => {
        this.setState({ visible: false });
    };

    // Swapping file begins

    swapFileModal = (file: any) => {
        console.log(file)
        if (this.fileType(file) === "image/") {
            this.setState({
                isImage: true
            });
        }
        else if (this.fileType(file) === "video/") {
            this.setState({
                isVideo: true
            });
        }
        else if (this.fileType(file) === "audio/") {
            this.setState({
                isAudio: true
            });
        }

        let fileName = file.substring(file.lastIndexOf("/") + 1, file.length);
        console.log(fileName);
        for (let i = 0; i < this.state.hotspotList.length; i++) {
            console.log(this.state.hotspotList[i]);

            if ((this.state.hotspotList[i].imagePath && file == this.state.hotspotList[i].imagePath.substring(this.state.hotspotList[i].imagePath.lastIndexOf('.com/') + 5))
                || (this.state.hotspotList[i].videoPath && file == this.state.hotspotList[i].videoPath.substring(this.state.hotspotList[i].videoPath.lastIndexOf('.com/') + 5))
                || (this.state.hotspotList[i].audioFile && file == this.state.hotspotList[i].audioFile.substring(this.state.hotspotList[i].audioFile.lastIndexOf('.com/') + 5))) {
                console.log(file);
                this.setState({
                    isUsed: true
                });
            }
        }
        this.setState((state) => { return { swapFileModal: !state.swapFileModal, fileToSwap: file } })

    }

    swapFileModalClose = () => {
        this.setState((state) => { return { swapFileModal: !state.swapFileModal } });
        this.setState({
            isImage: false,
            isVideo: false,
            isAudio: false,
            isUsed: false
        });
    }

    beforeUploadSwap = () => async (file: any) => {
        console.log(file);
        let m = await new Promise<void>((resolve: any, reject: any) => {
            if (file.type.substring(0, file.type.indexOf('/')) === 'image' || file.type.substring(0, file.type.indexOf('/')) === 'video' || file.type.substring(0, file.type.indexOf('/')) === 'audio') {
                axios.get(`/api/v1/vrsafety/file/check-shared-resources/${file.name}/${this.props.match.params.id}`).then((result) => {
                    console.log(result);
                    if (!result.data) {
                        resolve();
                    }
                    else {
                        confirm({
                            title: "File is already uploaded",
                            content: "Do you wanna continue?",
                            okText: "Yes",
                            onOk: () => {
                                confirm({
                                    title: "Replace file or create new version",
                                    content: "Do you want to replace the existing file or upload a new version of it?",
                                    okText: "Upload New Version",
                                    onOk: () => {
                                        this.setState((state) => { return { newVersion: true } });
                                        console.log(this.state.fileToSwap);

                                        resolve();
                                    },
                                    cancelText: "Replace",
                                    onCancel: () => {
                                        this.setState((state) => { return { replace: true } });

                                        var filePath = { url: this.state.fileToSwap };
                                        deleteFile(filePath, this.deletionUploadSwap, this.apiError);
                                        resolve();
                                    }
                                });
                            },
                            cancelText: "No",
                            onCancel: () => {
                                reject(false);
                            }
                        });
                    }
                }).catch((error: any) => {
                    message.error("There was a problem with uploading the file. Please try again");
                    console.log(error);
                })
            }
            else {
                let filetype = file.type.substring(file.type.indexOf('/'));
                message.error(filetype + ' is not an allowed file type')
                reject(false);
            }
        });
        return m;
    }

    uploadFileSwap = (options: any) => {
        console.log(options);
        var bodyFormData = new FormData();
        bodyFormData.append("file", options.file);
        bodyFormData.append("tourID", this.state.tourId);
        bodyFormData.append("companyID", this.state.companyId);
        bodyFormData.append("newVersion", this.state.newVersion.toString());
        bodyFormData.append("existsAlready", this.state.replace.toString());
        if (this.state.replace) {
            bodyFormData.append("existingName", this.state.fileToSwap.substring(this.state.fileToSwap.lastIndexOf("/") + 1));
        }
        else {
            bodyFormData.append("existingName", "");
        }
        axios({
            method: 'post',
            url: '/api/v1/vrsafety/File/largefileuploadSwap',
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data; boundary="----WebKitFormBoundarymx2fSWqWSd0OxQqq"'
            }
        })
            .then((response: any) => {
                //handle success
                console.log(response);
                let library = this.state.library
                let newFile = response.data.url.substring(response.data.url.lastIndexOf(".com/") + 5);
                console.log(newFile);
                library.push(newFile);
                this.setState({ library: library, uploading: false, newVersion: false });
                this.setState({ swapFile: newFile });
                message.success("File uploaded successfully");
            })
            .catch(function (response: any) {
                //handle error
                console.log(response);
                message.error("There was a problem uploading your file.");
            })
    }

    swapFile = () => {
        console.log(this.state.fileToSwap); //file to be swapped 
        console.log(this.state.swapFile);
        if (this.state.replace === false) {
            if (this.state.isUsed) {
                console.log(this.state.fileToSwap);
                confirm({
                    title: "Would you like to delete the file you are swapping or keep both files",
                    content: "Deleting this file will delete all the related data too.",
                    okType: "danger",
                    okText: "Delete",
                    onOk: () => {
                        var filePath = { url: this.state.fileToSwap };
                        console.log(filePath);
                        var type = '';
                        console.log(this.state.hotspotList);
                        if (this.state.isImage) {
                            type = "image";
                        }
                        else if (this.state.isVideo) {
                            type = "video";
                        }
                        else if (this.state.isAudio) {
                            type = "audio";
                        }
                        let tourId = this.state.tourInfo.id;
                        let fileToSwap = this.state.fileToSwap.substring(this.state.fileToSwap.lastIndexOf("/") + 1);
                        let swapFile = this.state.swapFile
                        let companyId = this.state.companyId;
                        let id = this.state.tourId;
                        var bodyFormData = new FormData();
                        bodyFormData.append("tourId", tourId);
                        bodyFormData.append("swapFile", swapFile);
                        bodyFormData.append("fileToSwap", fileToSwap);
                        bodyFormData.append("fileType", type);
                        axios({
                            method: 'post',
                            url: `/api/v1/vrsafety/HotSpot/swapHotspot`,
                            data: bodyFormData,
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'Content-Type': 'multipart/form-data; boundary=---------------------------81801171514357'
                            }
                        })
                            .then((response) => {
                                console.log(response);
                                let hotspots = [];
                                for (let i = 0; i < response.data.sceneObjects.length; i++) {
                                    for (let j = 0; j < response.data.sceneObjects[i].contentData.length; j++) {

                                        let hotspot = response.data.sceneObjects[i].contentData[j];
                                        hotspot.panoId = this.state.tourInfo.sceneObjects[i].id;
                                        hotspots.push(hotspot);
                                    }
                                }
                                console.log(hotspots);
                                this.setState({
                                    tourInfo: response.data,
                                    positionArray: response.data.sceneObjects[0].contentData,
                                    hotspotList: hotspots
                                });
                                Modal.success({
                                    content: 'Associated hotspots were swapped successfully',
                                });
                            })
                            .catch(function (response) {
                                //handle error
                                console.log(response);
                                message.error("There was a problem swapping your hotspots");
                            })



                        deleteFile(filePath, this.deleteSwap, this.apiError);
                        this.setState({
                            isImage: false,
                            isVideo: false,
                            isAudio: false,
                            isUsed: false,
                            fileToSwap: '',
                            swapFile: '',
                            swapFileModal: !this.swapFileModal
                        });
                    },
                    cancelText: "Keep Both",
                    onCancel: () => {
                        var type = '';
                        console.log(this.state.hotspotList);
                        if (this.state.isImage) {
                            type = "image";
                        }
                        else if (this.state.isVideo) {
                            type = "video";
                        }
                        else if (this.state.isAudio) {
                            type = "audio";
                        }
                        let tourId = this.state.tourInfo.id;
                        let fileToSwap = this.state.fileToSwap.substring(this.state.fileToSwap.lastIndexOf("/") + 1);
                        let swapFile = this.state.swapFile;
                        var bodyFormData = new FormData();
                        bodyFormData.append("tourId", tourId);
                        bodyFormData.append("swapFile", swapFile);
                        bodyFormData.append("fileToSwap", fileToSwap);
                        bodyFormData.append("fileType", type);
                        axios({
                            method: 'post',
                            url: `/api/v1/vrsafety/HotSpot/swapHotspot`,
                            data: bodyFormData,
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'Content-Type': 'multipart/form-data; boundary=---------------------------81801171514357'
                            }
                        })
                            .then((response) => {
                                console.log(response);
                                let hotspots = [];
                                for (let i = 0; i < response.data.sceneObjects.length; i++) {
                                    for (let j = 0; j < response.data.sceneObjects[i].contentData.length; j++) {

                                        let hotspot = response.data.sceneObjects[i].contentData[j];
                                        hotspot.panoId = this.state.tourInfo.sceneObjects[i].id;
                                        hotspots.push(hotspot);
                                    }
                                }
                                console.log(hotspots);
                                this.setState({
                                    tourInfo: response.data,
                                    positionArray: response.data.sceneObjects[0].contentData,
                                    hotspotList: hotspots
                                });
                                Modal.success({
                                    content: 'Associated hotspots were deleted successfully',
                                });
                            })
                            .catch(function (response) {
                                //handle error
                                console.log(response);
                                message.error("There was a problem swapping your hotspots");
                            })

                        this.setState({
                            isImage: false,
                            isVideo: false,
                            isAudio: false,
                            isUsed: false,
                            fileToSwap: '',
                            swapFile: '',
                            uploading: false,
                            swapFileModal: !this.swapFileModal
                        });
                    }
                })


            }
            else {
                confirm({
                    title: "Would you like to delete the file you are swapping or keep both files",
                    content: "Deleting this file will delete all the related data too.",
                    okType: "danger",
                    okText: "Yes",
                    onOk: () => {

                        var filePath = { url: this.state.fileToSwap };
                        deleteFile(filePath, this.deleteSwap, this.apiError);
                        this.setState({ uploading: false, swapFile: '' });
                        this.swapFileModalClose();
                    },
                    cancelText: "Keep Both",
                    onCancel: () => {
                        this.setState({ uploading: false, swapFile: '' });
                        this.swapFileModalClose();
                    }
                })
            }
        }
        else {
            this.setState({ replace: false, uploading: false, swapFile: '', swapFileModal: !this.swapFileModal });
            window.location.href = window.location.href
        }
    }

    swapHotspots = (swapHotspot: any, swapFile: string, type: string) => {
        console.log(swapHotspot)
        var hotspot = {
            Id: swapHotspot.id,
            AdditionalData: swapHotspot.additionalData,
            ParentUID: swapHotspot.parentUID,
            TargetUID: swapHotspot.targetUID,
            PositionArray: swapHotspot.positionArray,
            RotationArray: swapHotspot.rotationArray,
            type: swapHotspot.type,
            FileName: swapFile.substring(swapFile.lastIndexOf("/") + 1),
            PageTitle: swapHotspot.pageTitle,
            MessagePages: swapHotspot.messagePages,
            ImagePath: swapHotspot.imagePath,
            ImageSize: swapHotspot.imageSize,
            TextSize: swapHotspot.textSize,
            TextAlign: swapHotspot.textAlign,
            TextColor: swapHotspot.textColor,
            TextBG: swapHotspot.textBG,
            ImageTrans: swapHotspot.imageTrans,
            PreferredImageWidth: 700,
            Message: swapHotspot.message,
            VideoPath: swapHotspot.videoPath,
            AudioFile: swapHotspot.audioFile,
            AutoPlay: swapHotspot.autoPlay,
            Loop: swapHotspot.loop,
            Volume: swapHotspot.volume,
            updatedAt: getCurrentDate(),
            PanoId: swapHotspot.panoId,
            IconColor: swapHotspot.IconColor,
            deletePrevFile: false,
            TourId: this.state.tourInfo.id,
            State: this.state.currentState
        }
        if (type == 'image') {
            console.log("image");
            hotspot.ImagePath = AWS_URL + swapFile
        }
        else if (type == 'video') {
            console.log("video");
            hotspot.VideoPath = AWS_URL + swapFile
        }
        else if (type == 'audio') {
            console.log("audio");
            hotspot.AudioFile = AWS_URL + swapFile
        }
        console.log(hotspot);

        createHotspots(hotspot, this.swapHotspotSuccess, this.apiError);


    }


    swapHotspotSuccess = (data: any) => {
        console.log(data.data);
        let newFile = data.data.fileName;
        let newpositionArray = this.state.positionArray;
        let index = newpositionArray.findIndex(i => i.id === this.state.selected.id);
        newpositionArray.splice(index, 1, data.data);

        let newInfo = this.state.tourInfo;
        let panoIndex = this.state.panobackground.substring(this.state.panobackground.lastIndexOf('e') + 1);

        newInfo.sceneObjects[panoIndex].contentData = newpositionArray;
        console.log(newInfo);
        this.setState({ tourInfo: newInfo, positionArray: newpositionArray });
        this.successModal("Hotspot was swapped Successfully!");
        console.log(newFile);

        var hotspots = [];
        for (let i = 0; i < this.state.tourInfo.sceneObjects.length; i++) {
            for (let j = 0; j < this.state.tourInfo.sceneObjects[i].contentData.length; j++) {
                let hotspot = this.state.tourInfo.sceneObjects[i].contentData[j];
                hotspot.panoId = this.state.tourInfo.sceneObjects[i].id;
                hotspots.push(hotspot);
            }
        }
        this.setState({ hotspotList: hotspots });
        //this.setState({ positionArray: hotspots }); Not same as hotspot list
        console.log(this.state.hotspotList);
    }



    deleteSwap = (data: any) => {
        let library = this.state.library;
        this.setState({
            library: library.filter(function (file: any) {
                return file !== data.data
            }.bind(this))
        });
    }
    // Swapping file ends


    // Sending files from local to shared resources begin

    localToResource = async (file: string) => {
        console.log(file);
        let filename = file.substring(file.lastIndexOf('/') + 1);
        console.log(filename);
        let m = await new Promise<void>((resolve: any, reject: any) => {

            axios.post(`/api/v1/vrsafety/file/hotspot-resource-upload-from-local/${filename}/${this.props.match.params.id}`).then((result) => {
                console.log(result);
                let fileList = this.state.fileList
                let newFile = result.data;
                newFile.uid = fileList.length;
                console.log(newFile);
                fileList.push(newFile);
                this.setState({ fileList: fileList, uploading: false, newVersion: false });
                var filePath = { url: file };

                var type = result.data.type;
                let tourId = this.state.tourInfo.id;
                let fileToSwap = result.data.name;
                let swapFile = this.state.companyId + "/resources/" + result.data.name;
                var bodyFormData = new FormData();
                bodyFormData.append("tourId", tourId);
                bodyFormData.append("swapFile", swapFile);
                bodyFormData.append("fileToSwap", fileToSwap);
                bodyFormData.append("fileType", type);
                axios({
                    method: 'post',
                    url: `/api/v1/vrsafety/HotSpot/swapHotspot`,
                    data: bodyFormData,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data; boundary=---------------------------81801171514357'
                    }
                })
                    .then((response) => {
                        console.log(response);
                        let hotspots = [];
                        for (let i = 0; i < response.data.sceneObjects.length; i++) {
                            for (let j = 0; j < response.data.sceneObjects[i].contentData.length; j++) {

                                let hotspot = response.data.sceneObjects[i].contentData[j];
                                hotspot.panoId = this.state.tourInfo.sceneObjects[i].id;
                                hotspots.push(hotspot);
                            }
                        }

                        console.log(hotspots);
                        this.setState({
                            tourInfo: response.data,
                            positionArray: response.data.sceneObjects[0].contentData,
                            hotspotList: hotspots
                        });
                        Modal.success({
                            content: 'Associated hotspots were updated successfully',
                        });
                        deleteFile(filePath, this.deleteSwap, this.apiError);
                    })
                    .catch(function (response) {
                        //handle error
                        console.log(response);
                        message.error("There was a problem swapping your hotspots");
                    })


            }).catch((error) => {
                message.error("There was a problem with moving the file. Please try again");
                console.log(error);
            })

        })
        return m;
    }


    moveHotspots = (swapHotspot: any, type: string) => {
        console.log(swapHotspot);
        console.log(type);

        var hotspot = {
            Id: swapHotspot.id,
            AdditionalData: swapHotspot.additionalData,
            ParentUID: swapHotspot.parentUID,
            TargetUID: swapHotspot.targetUID,
            PositionArray: swapHotspot.positionArray,
            RotationArray: swapHotspot.rotationArray,
            type: swapHotspot.type,
            FileName: swapHotspot.fileName,
            PageTitle: swapHotspot.pageTitle,
            MessagePages: swapHotspot.messagePages,
            ImagePath: swapHotspot.imagePath,
            ImageSize: swapHotspot.imageSize,
            ImageTrans: swapHotspot.imageTrans,
            TextSize: swapHotspot.textSize,
            TextAlign: swapHotspot.textAlign,
            TextColor: swapHotspot.textColor,
            TextBG: swapHotspot.textBG,
            PreferredImageWidth: 700,
            Message: swapHotspot.message,
            VideoPath: swapHotspot.videoPath,
            AudioFile: swapHotspot.audioFile,
            AutoPlay: swapHotspot.autoPlay,
            Loop: swapHotspot.loop,
            Volume: swapHotspot.volume,
            PanoId: swapHotspot.panoId,
            IconColor: swapHotspot.IconColor,
            State: this.state.currentState,
            deletePrevFile: false,
            TourId: this.state.tourInfo.id
        }

        if (type == 'image/') {
            console.log("image");
            hotspot.ImagePath = AWS_URL + this.state.companyId + "/resources/" + hotspot.FileName;
        }
        else if (type == 'video/') {
            console.log("video");
            hotspot.VideoPath = AWS_URL + this.state.companyId + "/resources/" + hotspot.FileName;
        }
        else if (type == 'audio/') {
            console.log("audio");
            hotspot.AudioFile = AWS_URL + this.state.companyId + "/resources/" + hotspot.FileName;
        }

        console.log(hotspot);

        createHotspots(hotspot, this.swapHotspotSuccess, this.apiError);


    }
    // Sending files from local to shared resources ends

    // Sending files from shared to local resources begin

    ResourceToLocal = async (file: any) => {
        console.log(file);
        let m = await new Promise<void>((resolve: any, reject: any) => {

            axios.post(`/api/v1/vrsafety/file/hotspot-resource-upload-from-shared/${file.name}/${this.props.match.params.id}`).then((result) => {
                console.log(result);
                let library = this.state.library
                let newFile = result.data.url.substring(result.data.url.lastIndexOf('.com/') + 5);
                console.log(newFile);
                console.log(library);

                library.push(newFile);
                this.setState({ library: library, uploading: false, newVersion: false });
                var type = result.data.type;
                let tourId = this.state.tourInfo.id;
                let fileToSwap = result.data.name;
                let swapFile = this.state.companyId + "/" + this.state.tourId + "/resources/" + result.data.name;
                var bodyFormData = new FormData();
                bodyFormData.append("tourId", tourId);
                bodyFormData.append("swapFile", swapFile);
                bodyFormData.append("fileToSwap", fileToSwap);
                bodyFormData.append("fileType", type);
                axios({
                    method: 'post',
                    url: `/api/v1/vrsafety/HotSpot/swapHotspot`,
                    data: bodyFormData,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data; boundary=---------------------------81801171514357'
                    }
                })
                    .then((response) => {
                        console.log(response);
                        let hotspots = [];
                        for (let i = 0; i < response.data.sceneObjects.length; i++) {
                            for (let j = 0; j < response.data.sceneObjects[i].contentData.length; j++) {

                                let hotspot = response.data.sceneObjects[i].contentData[j];
                                hotspot.panoId = this.state.tourInfo.sceneObjects[i].id;
                                hotspots.push(hotspot);
                            }
                        }

                        console.log(hotspots);
                        this.setState({
                            tourInfo: response.data,
                            positionArray: response.data.sceneObjects[0].contentData,
                            hotspotList: hotspots
                        });
                        Modal.success({
                            content: 'Associated hotspots were updated successfully',
                        });
                    })
                    .catch(function (response) {
                        //handle error
                        console.log(response);
                        message.error("There was a problem swapping your hotspots");
                    })


            }).catch((error) => {
                message.error("There was a problem with moving the file. Please try again");
                console.log(error);
            })

        })
        return m;
    }


    moveHotspotsShared = (swapHotspot: any, type: string) => {
        console.log(swapHotspot);
        console.log(type);

        var hotspot = {
            Id: swapHotspot.id,
            AdditionalData: swapHotspot.additionalData,
            ParentUID: swapHotspot.parentUID,
            TargetUID: swapHotspot.targetUID,
            Position: swapHotspot.position,
            AframePosition: swapHotspot.aframePosition,
            type: swapHotspot.type,
            FileName: swapHotspot.fileName,
            PageTitle: swapHotspot.pageTitle,
            MessagePages: swapHotspot.messagePages,
            ImagePath: swapHotspot.imagePath,
            ImageSize: swapHotspot.imageSize,
            ImageTrans: swapHotspot.imageTrans,
            TextSize: swapHotspot.textSize,
            TextAlign: swapHotspot.textAlign,
            TextColor: swapHotspot.textColor,
            TextBG: swapHotspot.textBG,
            PreferredImageWidth: 700,
            Message: swapHotspot.message,
            VideoPath: swapHotspot.videoPath,
            AudioFile: swapHotspot.audioFile,
            AutoPlay: swapHotspot.autoPlay,
            Loop: swapHotspot.loop,
            Volume: swapHotspot.volume,
            PanoId: swapHotspot.panoId,
            IconColor: swapHotspot.IconColor,
            deletePrevFile: false,
            TourId: this.state.tourInfo.id,
            State: this.state.currentState
        }

        if (type == 'image/') {
            console.log("image");
            hotspot.ImagePath = AWS_URL + this.state.companyId + "/" + this.props.match.params.id + "/resources/" + hotspot.FileName;
        }
        else if (type == 'video/') {
            console.log("video");
            hotspot.VideoPath = AWS_URL + this.state.companyId + "/" + this.props.match.params.id + "/resources/" + hotspot.FileName;
        }
        else if (type == 'audio/') {
            console.log("audio");
            hotspot.AudioFile = AWS_URL + this.state.companyId + "/" + this.props.match.params.id + "/resources/" + hotspot.FileName;
        }

        console.log(hotspot);

        createHotspots(hotspot, this.swapHotspotSuccess, this.apiError);


    }

    // Sending files from shared resources to local ends



    /**
     * Gets the co-ordinates to remove from the database and the position array
     */
    removeCoordinate = (deleteFile: boolean) => {
        console.log(this.state.removing);
        let panoId = parseInt(this.state.panobackground.substring(this.state.panobackground.lastIndexOf('e') + 1));
        console.log(this.state.removing);
        if (this.state.removing !== 0) {
            console.log("created from DB");
            console.log(this.state.positionArray);
            let removal = this.state.positionArray.find((coord: any) => coord.id === this.state.removing);

            console.log(removal);
            let hotspotDeletion = {
                Id: this.state.removing,
                PositionArray: removal.positionArray,
                RotationArray: removal.rotationArray,
                deleteFile: deleteFile,
                PanoId: this.state.tourInfo.sceneObjects[panoId].id,
                ImagePath: removal.imagePath,
                VideoPath: removal.videoPath,
                AudioFile: removal.audioFile,
                MessagePages: removal.messagePages,
                type: removal.type,
                TourId: this.state.tourInfo.id
            }
            console.log(hotspotDeletion);
            deleteHotspot(hotspotDeletion, this.deleteSuccess, this.apiError);
        }
        //removes from the position array but not from the tourinfo
        this.setState({
            positionArray: this.state.positionArray.filter(function (this: Coordinates, position: coordinates) {
                if (typeof position.id === "number") {
                    return position.id !== this.state.removing;
                }
                else {
                    return position.id !== this.state.removing
                }
            }.bind(this)),
            hotspotList: this.state.hotspotList.filter(function (this: Coordinates, position: coordinates) {
                if (typeof position.id === "number") {
                    return position.id !== this.state.removing;
                }
                else {
                    return position.id !== this.state.removing
                }
            }.bind(this))
        });

        //displays success message if it was not saved to the database yet
        if (this.state.removing === 0) {
            Modal.success({
                content: 'Hotspot was successfully removed',
            });
        }
    };



    deleteAllHotspots = async (removal: any, panoId: number) => {
        let hotspotDeletion = {
            Id: removal.id,
            PositionArray: removal.positionArray,
            RotationArray: removal.rotationArray,
            deleteFile: false,
            PanoId: panoId,
            ImagePath: removal.imagePath,
            VideoPath: removal.videoPath,
            AudioFile: removal.audioFile,
            MessagePages: removal.messagePages,
            type: removal.type,
            TourId: this.state.tourInfo.id
        }
        console.log(hotspotDeletion);
        deleteHotspot(hotspotDeletion, this.deleteSuccess, this.apiError);
    };



    handleDeleteOk = (item: any) => {

        console.log(item);
        if (item) {// if it is not an array, that is if it is from upload list and not in timeline
            confirm({
                title: "This will delete this file and the hotspots associated with it?",
                content: "Deleting these files is PERMENANT and will affect the tours using the shared files",
                okType: "danger",
                okText: "Delete Anyways",
                onOk: () => {
                    //console.log("image not used in pano");
                    //var filePath = this.state.removeImage.toString();
                    var filePath = { url: item };
                    console.log(filePath.url);
                    console.log(this.state.hotspotList);

                    let tourId = this.state.tourInfo.id;
                    let file = item.substring(item.lastIndexOf("/") + 1);
                    axios({
                        method: 'delete',
                        url: `/api/v1/vrsafety/HotSpot/hotspotDeleteMultiple/${tourId}/${file}`,
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data; boundary=---------------------------81801171514357'
                        }
                    })
                        .then((response) => {
                            //handle success
                            console.log(response);
                            let hotspots = [];
                            for (let i = 0; i < response.data.sceneObjects.length; i++) {
                                for (let j = 0; j < response.data.sceneObjects[i].contentData.length; j++) {

                                    let hotspot = response.data.sceneObjects[i].contentData[j];
                                    hotspot.panoId = this.state.tourInfo.sceneObjects[i].id;
                                    hotspots.push(hotspot);
                                }
                            }
                            console.log(hotspots);
                            this.setState({
                                tourInfo: response.data,
                                positionArray: response.data.sceneObjects[0].contentData,
                                hotspotList: hotspots
                            });
                            Modal.success({
                                content: 'Associated hotspots were deleted successfully',
                            });
                            deleteFile(filePath, this.deletionUpload, this.apiError);
                        })
                        .catch(function (response) {
                            //handle error
                            console.log(response);
                            message.error("There was a problem deleting some hotspots.");
                        })

                },
                cancelText: "Don't Delete",
                onCancel: () => {

                }
            })

        }
        else {

        }
    };




    deletionUpload = (data: any) => {
        console.log(data);
        let library = this.state.library;
        this.setState({
            library: this.state.library.filter(function (file: any) {
                return file !== data.data
            }.bind(this))
        });
        Modal.success({
            content: 'File was deleted successfully',
        });
    }

    deletionUploadSwap = (data: any) => {
        console.log(data);
        let library = this.state.library;
        this.setState({
            library: this.state.library.filter(function (file: any) {
                return file !== data.data
            }.bind(this))
        });
    }




    /**
     * On Successful Removal of a hotspot removes from the tourinfo upon success from the database
     */
    deleteSuccess = (data: any) => {
        let panoId = parseInt(this.state.panobackground.substring(this.state.panobackground.lastIndexOf('e') + 1));
        let removal = this.state.tourInfo.sceneObjects[panoId].contentData.filter((coord: any) => {
            return coord.id !== this.state.removing
        });
        console.log(removal);
        let updatedFileList = this.state.tourInfo;
        updatedFileList.sceneObjects[panoId].contentData = removal;
        this.setState({ tourInfo: updatedFileList });
        Modal.success({
            content: 'Hotspot was successfully removed',
        });
    }
    /**
     * Gets FileName and sends delete request
     */
    removeFile = (file: any) => {
        let filename = file.response.substring(file.response.lastIndexOf('/') + 1);
        deleteFile(filename, this.removeFilesuccess, this.apiError)
    }
    /**
     * Delete File Success Method
     */
    removeFilesuccess = (data: any) => {
        this.successModal("File was successfully deleted.", "Success");
        let newfilelist = this.state.fileList.filter((file) => !file.response.includes(data.data));
        this.setState({ fileList: newfilelist })
    }
    /*******DOM METHODS********/
    highlightColor = (e: any) => {
        let entity = e.target;
        entity.setAttribute('material', { color: 'aqua' });
    }
    resetColor = (e: any) => {
        let entity = e.target;
        entity.setAttribute('material', { color: '#FFFFFF' })
    }
    selectColor = (e: any) => {
        let entity = e.target;
        entity.setAttribute('material', { color: 'red' });
    }
    getIcon(entity: any) {
        let iconString = ""
        switch (entity.type) {
            case 'VirtualTourNetwork.VideoData':
                if (entity.iconColor === "black")
                    iconString = '#video_icon_black';
                else
                    iconString = '#video_icon_white';
                break;
            case 'VirtualTourNetwork.SphereVideoData':
                if (entity.iconColor === "black")
                    iconString = '#video360_icon_black';
                else
                    iconString = '#video360_icon_white';
                break;
            case 'VirtualTourNetwork.AudioData':
                if (entity.iconColor === "black")
                    iconString = '#audio_icon_black';
                else
                    iconString = '#audio_icon_white';
                break;
            case 'VirtualTourNetwork.ImageMessageData':
                if (entity.iconColor === "black")
                    iconString = '#image_icon_black';
                else
                    iconString = '#image_icon_white';
                break;
            case 'VirtualTourNetwork.MessageData':
                if (entity.iconColor === "black")
                    iconString = '#text_icon_black';
                else
                    iconString = '#text_icon_white';
                break;
            case 'VirtualTourNetwork.PageMessageData':
                if (entity.iconColor === "black")
                    iconString = '#pagination_icon_black';
                else
                    iconString = '#pagination_icon_white';
                break;
            case "VirtualTourNetwork.HotspotLinkData":
                if (entity.iconColor === "black")
                    iconString = '#link_icon_black';
                else
                    iconString = '#link_icon_white';
                break;
            case "VirtualTourNetwork.CameraPositionData":
                if (entity.iconColor === "black")
                    iconString = '#camera_position_icon_black';
                else
                    iconString = '#camera_position_icon_white';
                break;
            case "VirtualTourNetwork.HotspotCompleteData":
                if (entity.iconColor === "black")
                    iconString = '#exit_black';
                else
                    iconString = '#exit_white';
                break;
            case "VirtualTourNetwork.BranchingData":
                if (entity.iconColor === "black")
                    iconString = '#Branch_Icon_White';
                else
                    iconString = '#Branch_Icon_Black';
                break;
            case "VirtualTourNetwork.CaptionData":
                if (entity.iconColor === "black")
                    iconString = '#caption_icon_black';
                else
                    iconString = '#caption_icon_white';
                break;
            default:
                iconString = "#add_hotspot_icon";
        }
        return iconString;
    }

    fileType = (item: string) => {
        var fileExtn = item.substring(item.lastIndexOf("."));
        switch (fileExtn) {
            case ".jpg":
            case ".bmp":
            case ".cgm":
            case ".g3":
            case ".gif":
            case ".jpeg":
            case ".png":
            case ".svg":
            case ".tiff":
                return "image/";
            case ".avi":
            case ".mp4":
            case ".m4v":
            case ".mpeg":
            case ".ogv":
            case ".webm":
            case ".f4v":
            case ".flv":
            case ".wmv":
            case ".wmx":
            case ".mov":
                return "video/";
            case ".mp4a":
            case ".mpga":
            case ".oga":
            case ".uva":
            case ".eol":
            case ".dra":
            case ".dts":
            case ".wav":
            case ".dtshd":
            case ".lvp":
            case ".pya":
            case ".ecelp4800":
            case ".ecelp7470":
            case ".ecelp9600":
            case ".rip":
            case ".weba":
            case ".aac":
            case ".aif":
            case ".wma":
            case ".mp3":
                return "audio/";
            default:
                return "";
        }
    }

    collapseLeftSide = () => {
        if (this.state.collapseLeft === false) {
            this.setState({ collapseLeft: true });
        }
        else {
            this.setState({ collapseLeft: false });
        }
        console.log(this.state.collapseLeft);
    }

    collapseRightSide = () => {
        if (this.state.collapseRight === false) {
            this.setState({ collapseRight: true });
        }
        else {
            this.setState({ collapseRight: false });
        }
        console.log(this.state.collapseRight);
    }


    // shared resource swap starts


    swapSharedFileModal = (pano: any) => {
        console.log(pano);
        this.setState((state) => { return { swapSharedFileModal: !state.swapSharedFileModal, fileToSwap: pano } })
        this.setState({
            replace: true
        });
        console.log(this.state.fileToSwap);

    }

    uploadSharedImageSwap = (options: any) => {
        var filePath = { url: this.state.fileToSwap.url.substring(this.state.fileToSwap.url.lastIndexOf(".com/") + 5) };

        deleteFile(filePath, this.deletionShared, this.apiError);
        console.log(this.state.fileToSwap);
        this.setState((state) => { return { uploading: true } });
        console.log(options);
        var bodyFormData = new FormData();
        bodyFormData.append("file", options.file);
        bodyFormData.append("tourID", this.props.match.params.id);
        bodyFormData.append("companyID", this.state.companyId);
        bodyFormData.append("newVersion", this.state.newVersion.toString());
        bodyFormData.append("existsAlready", this.state.replace.toString());
        if (this.state.replace) {
            bodyFormData.append("existingName", this.state.fileToSwap.name);
        }
        else {
            bodyFormData.append("existingName", "");// should not come here for shared library
        }
        axios({
            method: 'post',
            url: '/api/v1/vrsafety/File/shared-upload-swap-hotspot',
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data; boundary=---------------------------81801171514357'
            }
        })
            .then((response) => {
                //handle success
                let sharedList = this.state.fileList
                sharedList.push(response.data);
                console.log(sharedList);
                this.setState({ fileList: sharedList, uploading: false, newVersion: false });
                this.setState({ swapFile: response.data });
                console.log(this.state.swapFile);
            })
            .catch(function (response) {
                //handle error
                console.log(response);
                message.error("There was a problem uploading your Image.");
            })
    }

    deletionShared = (data: any) => {
        var fileList = this.state.fileList;
        var fileName = this.state.fileToSwap.name;
        console.log("here");
        this.setState({
            fileList: fileList.filter(function (file: any) {
                return file.name != fileName
            }.bind(this))
        });

    }

    swapSharedImage = () => {
        this.setState((state) => { return { uploading: true } });
        this.setState({ replace: false, uploading: false, swapFile: '', fileToSwap: '' });
        this.swapSharedFileModal({});

        window.location.href = window.location.href
    }


    // shared resource swap ends

    async _handleSubmit(event: any) {
        event.preventDefault();
        event.stopPropagation();
        let formData = new FormData();
        for (let i = 0; i < this.state.files.length; i++) {
            formData.append('files', this.state.files[i]);
        }

        let url = '/api/v1/vrsafety/file/streaming';
        //await axios({
        //    method: 'post',
        //    url: url,
        //    data: formData
        //}).then((response: any) => {
        //    //handle success
        //    console.log(response);
        //    message.success("File uploaded successfully");
        //})
        //.catch(function (response: any) {
        //    //handle error
        //    console.log(response);
        //    message.error("There was a problem uploading your file.");
        //});

        fetch(url, {
            method: 'POST',
            body: formData,
        }).then(r => r.json()).then(result => {
            message.success("File uploaded successfully");
            console.log("It was sentttt!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
            console.log(result);
        });
        return false;
        //let url = '/api/v1/vrsafety/FileTest/streaming';
        //const fetch = require("node-fetch");

        axios({
            method: 'post',
            url: '/api/v1/vrsafety/FileTest/streaming',
            //data: formData,
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json"
                //'Content-Type': 'multipart/form-data; boundary="----WebKitFormBoundarymx2fSWqWSd0OxQqq"'
            }
        })
            .then((response: any) => {
                //handle success
                console.log(response);
                //let library = this.state.library
                //let newFile = response.data.url.substring(response.data.url.lastIndexOf(".com/") + 5);
                //console.log(newFile);
                //library.push(newFile);
                //this.setState({ library: library, uploading: false, newVersion: false });
                message.success("File uploaded successfully");
            })
            .catch(function (response: any) {
                //handle error
                console.log(response);
                message.error("There was a problem uploading your file.");
            })

        // fetch(url, {

        //     credentials: 'include',
        //     method: 'POST',
        //     body: formData,
        //     headers: {
        //             "Authorization": `Bearer ${token}`,
        //             "Content-type": "application/json",
        //             "Accept": "application/json",
        //             "Accept-Charset": "utf-8"
        //     //    'Content-Type': 'multipart/form-data; boundary="----WebKitFormBoundarymx2fSWqWSd0OxQqq"'
        //     }
        //}).then((r: { json: () => any; }) => r.json()).then((result: any) => {

        //    console.log("It was sentttt!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
        //     let state = { ...this.state };
        //     state.result = result;
        //     this.setState(state);
        // });
        return false;
    }

    handleFileChange = (event: any) => {
        this.setState({ files: event.target.files });
    }

    selectStateOptions = () => {
        let states = [];

        if (this.state.tourInfo.states) {
            for (let i = 0; i < this.state.tourInfo.states.length; i++) {
                states.push(
                    <Option key={this.state.tourInfo.states[i]}
                        value={this.state.tourInfo.states[i]}>
                        {
                            this.state.tourInfo.states[i]
                        }
                    </Option>);
            }
        }

        return states;
    }

    handleStateChange = (e: any) => {
        console.log(e);
        let positionArray = this.state.tourInfo.sceneObjects[0].contentData.filter((data: any) => data.state === e);

        if (e === "default") {
            positionArray = this.state.tourInfo.sceneObjects[0].contentData.filter((data: any) => data.state === e || data.state === null)
        }
        this.setState({
            currentState: e,
            positionArray: positionArray,
        });
        var hotspots = [];
        for (let i = 0; i < this.state.tourInfo.sceneObjects.length; i++) {
            for (let j = 0; j < this.state.tourInfo.sceneObjects[i].contentData.length; j++) {
                if (this.state.tourInfo.sceneObjects[i].contentData[j].state === e) {
                    let hotspot = this.state.tourInfo.sceneObjects[i].contentData[j];
                    hotspot.panoId = this.state.tourInfo.sceneObjects[i].id;
                    hotspots.push(hotspot);
                }
            }
        }
        this.setState({ hotspotList: hotspots, panobackground: "#image0" });


    }

    showStateModal = () => {
        this.setState({ showStateModal: !this.state.showStateModal });
    }

    newStateName = (e: any) => {
        //console.log(e.target.value);
        this.setState({ newState: e.target.value })
    }

    handleBaseState = (e: any) => {
        console.log(e);
        this.setState({ baseState: e });
    }

    addDefaultState = () => {
        console.log("clicked");
        addDefaultState(this.state.tourInfo.id, this.addDefaultStateSuccess, this.apiError);
    }

    addDefaultStateSuccess = (data: any) => {
        console.log(data);
        this.setState({
            tourInfo: data.data
        });
        message.success("Default state added successfully");

    }

    addNewState = () => {
        console.log(this.state.newState);
        console.log(this.state.baseState);
        console.log(this.state.currentState);
        var info = {
            "tourId": this.state.tourInfo.id,
            "newState": this.state.newState,
            "baseState": this.state.baseState
        };
        this.setState({ loading: true });
        createNewState(info, this.createStateSuccess, this.apiError);
    }

    createStateSuccess = (data: any) => {
        console.log(data);
        this.setState({
            tourInfo: data.data,
            showStateModal: !this.state.showStateModal,
            loading: false
        })
        Modal.success({
            content: 'New state added successfully',
        });
    }

    render() {
        const uploadButton = (
            <div className="UploadButtonOrder">
                <Icon type={this.state.uploading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        const uploadAllButton = (
            <Button style={{ color: "#4ECDC4", borderColor: "#4ECDC4" }} className="tealText" ghost>
                <Icon type="upload" /> Click to Upload
            </Button>
        );
        return (
            <div>
                <Spin spinning={this.state.loading} >


                    <Row type="flex" justify="space-between" style={{ marginTop: "20px", padding: "8px"}} align="middle">
                        <Col xs={24} sm={24} md={3} style={{ marginTop: "5px" }}>
                            <Button icon="left" style={{ width: "100%", margin: "auto 0" }} type="primary" onClick={() => this.props.prev()}>
                                Previous
                            </Button>
                        </Col>

                        <Col xs={24} sm={24} md={3} style={{ marginTop: "5px" }}>
                            <Button style={{ width: "100%" }} type="primary" onClick={() => this.props.next()}>
                                Save and Preview <Icon type="play-circle" style={{ fontSize: "15px" }} />
                            </Button>
                        </Col>
                    </Row>
                    <Row className="darkBlueBG" style={{ marginTop: "30px" }}><h1 style={{ padding: "10px", textAlign: "center", fontSize: "1.7em" }} className="whiteText bebas">{this.state.tourName}</h1></Row>

                    {/* <Row type="flex" justify="space-between" style={{marginTop:"20px"}}>
        <Col xs={24} sm={24} md={6} >
            <h2 className={h1Color} style={{ cursor: "pointer" }} onClick={() => this.collapseLeftSide()}>
              Hotspot Content
              <Icon style={{ marginLeft: "10px" }} type={this.state.collapseLeft ? "caret-right" : "down"} /> 
            </h2>


            <Row type="flex" justify="space-between" style={{ marginTop: "20px" }}>
              {
                (this.state.collapseLeft) ?
                  <Upload
                      listType="picture"
                      showUploadList={false}
                      accept="audio/*,video/*,image/*"
                      beforeUpload={this.beforeUpload()}
                      customRequest={this.uploadLibrary}
                      multiple={true}
                  // disabled={this.state.fileList.length > 20}
                  >
                    {uploadAllButton}
                  </Upload>
                  : null}
            </Row>
        </Col>
        <Col xs={24} sm={24} md={6}></Col>
        <Col xs={24} sm={24} md={6} style={{textAlign: "right"}}>
            <h2 className={h1Color} style={{ cursor: "pointer" }} onClick={() => this.collapseRightSide()}>
              <Icon style={{ marginRight: "10px" }} type={this.state.collapseRight ? "caret-left" : "down"} />
              Shared Resources
            </h2>
        </Col>
      </Row> */}

                    <Row type="flex" justify="space-between" style={{ marginTop: "20px", padding: "8px" }}>
                        <Col xs={24} sm={24} md={5} className="clearfix">
                            <div style={{ minHeight: "600px", margin: "12px 0px", overflowX: "hidden", border: "1px solid #bbb", borderRadius: "2px" }}>
                                <Row style={{ borderBottom: "1px solid #bbb", padding: "14px" }}>
                                    <Col xs={24} sm={24} md={12}><h3 className={this.props.auth.layout.h1Color}>Upload New Content</h3></Col>
                                    <Col xs={24} sm={24} md={12} style={{ textAlign: "right" }}>
                                        <Upload
                                            listType="picture"
                                            showUploadList={false}
                                            accept="image/*,audio/*,video/*"
                                            beforeUpload={this.beforeUpload()}
                                            customRequest={this.uploadLibrary}
                                            multiple={true}
                                        // disabled={this.state.fileList.length > 20}
                                        >
                                            {uploadAllButton}
                                        </Upload>
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: "20px", padding: "14px" }}>
                                    <div className="App">
                                        {
                                            this.state.library ?
                                                <ul style={{ height: "500px", overflowY: "auto", display: "flex", flexWrap: "nowrap", flexDirection: "column" }}>
                                                    {
                                                        this.state.library.map((item: any, idx: number) => (

                                                            <li key={idx} style={{ flex: "1 1 auto" }}>
                                                                <div style={{ width: '100%', height: 'auto', background: '#FFF', border: "2px solid #bbb", borderRadius: "5px", padding: "15px" }}>
                                                                    <Row type="flex" align="middle" justify="space-between">
                                                                        <Col xs={10} sm={10} md={8}>
                                                                            <div className="showImgName">
                                                                                <p>{item.substring(item.lastIndexOf("/") + 1)}</p>
                                                                            </div>
                                                                            {
                                                                                this.fileType(item) === "image/" ?
                                                                                    <img style={{ maxWidth: "100%", maxHeight: "70px", width: "auto", height: "auto" }} className="image" alt={`360 Pano #${idx + 1}`} src={AWS_URL + item.substring(0, item.lastIndexOf("/resources")) + "/resources/thumbnails" + item.substring(item.lastIndexOf("/")) + "-Thumbnail" + item.substring(item.lastIndexOf("."))} />
                                                                                    : null
                                                                            }

                                                                            {
                                                                                this.fileType(item) === "video/" ?
                                                                                    <img style={{ maxWidth: "100%", maxHeight: "70px", width: "auto", height: "auto" }} className="image" alt={`360 Pano #${idx + 1}`} src={Video_icon} />
                                                                                    : null
                                                                            }

                                                                            {
                                                                                this.fileType(item) === "audio/" ?
                                                                                    <img style={{ maxWidth: "100%", maxHeight: "70px", width: "auto", height: "auto" }} className="image" alt={`360 Pano #${idx + 1}`} src={Audio_icon} />
                                                                                    : null
                                                                            }
                                                                        </Col>
                                                                        <Col xs={24} sm={24} md={4}></Col>
                                                                        <Col xs={12} sm={12} md={12}>
                                                                            <Row>
                                                                                <Icon style={{ margin: "0 5px", color: "#f66" }} type="delete" onClick={() => this.handleDeleteOk(item)} />
                                                                                <Icon style={{ margin: "0 5px", color: "#4ECDC4" }} type="reload" title="Swap the File" onClick={() => this.swapFileModal(item)} />
                                                                                <Icon style={{ margin: "0 5px", color: "#e6ab22" }} type="swap" title="Move to Shared Resources" onClick={() => this.localToResource(item)} />
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </li>
                                                        ))}
                                                </ul>
                                                : null
                                        }
                                    </div>
                                </Row>

                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={18}>
                            <main style={{ padding: "14px", position: "relative" }}>
                                <Row style={{ padding: "10px", border: "1px solid #ccc", borderRadius: "5px", height: "100%" }}>
                                    {
                                        this.state.tourInfo.hasOwnProperty("sceneObjects") ?
                                            <Row style={{ overflowX: "auto", display: "flex", flexWrap: "nowrap" }}>
                                                {
                                                    this.state.tourInfo.sceneObjects.map((item: any, idx: number) => (
                                                        <div className="timelineCards" key={idx}>

                                                            <div style={{ width: '100%', height: 'auto', borderRadius: "2px", padding: "8px" }} className={this.state.panobackground == ("#image" + idx) ?
                                                                "highlightBorder" : "normalBorder"} onClick={() => this.goto(idx)}>

                                                                <div>
                                                                    <Row>
                                                                        <div className="courseName"><p>{ item.niceName}</p></div>
                                                                        <img style={{ width: "100%", height: "70px" }} className="image" alt={`360 Pano #${idx + 1}`} src={item.thumbnail + "?dummy=pano" + idx} />
                                                                    </Row>
                                                                    <Row>
                                                                        <Checkbox checked={item.criticalPano} style={{ color: "#0D4D9B" }}>
                                                                            Critical Pano
                                                                        </Checkbox>
                                                                    </Row>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    ))}
                                            </Row>
                                            : null
                                    }
                                </Row>
                                <Row style={{ marginTop: "20px" }}>
                                    <Button type="primary" onClick={() => this.collapseRightSide()}>
                                        Open Shared Library
                                    </Button>
                                </Row>
                                <Row type="flex" justify="space-between" style={{ margin: "12px 0px" }}>
                                    <Col xs={24} sm={24} md={10}>
                                        <h2 className={this.props.auth.layout.h1Color} style={{ display: "inline" }} onClick={() => this.collapseLeftSide()}>
                                            Select State:
                                        </h2>
                                        <Select
                                            onChange={this.handleStateChange}
                                            value={this.state.currentState}
                                            placeholder="Select a State"
                                            style={{ width: "120px", marginLeft: "20px" }}
                                        >
                                            {this.selectStateOptions()}
                                        </Select>
                                        <Button onClick={this.showStateModal} icon="plus" type="primary" style={{ marginLeft: "20px" }}>
                                            Add a State
                                        </Button>
                                        {
                                            (this.state.tourInfo.states === null) ?
                                                <Row style={{ marginTop: "20px" }}>
                                                    <Button onClick={() => this.addDefaultState()}>Add default state</Button>
                                                </Row>
                                                :
                                                null
                                        }
                                    </Col>
                                    <Col xs={24} sm={24} md={14}>
                                        <ButtonGroup style={{ float: "right" }}>
                                            <div style={{ display: "flex", float: "right" }}>
                                                <div className={(this.props.auth.layout.h1Color === "rxlightPinkText") ? ((this.state.mode === 'add') ? "rxactiveButton" : "rxhotspotButtons") : (this.state.mode === 'add') ? "activeButton" : "hotspotButtons"}>
                                                    <Tooltip title="Double click to create new hotspot, then single click to assign it">
                                                        <h3 style={{ cursor: "pointer" }} onClick={() => this.updateMode('add')}
                                                            className={(this.props.auth.layout.h1Color === "rxlightPinkText") ? ((this.state.mode === 'add') ? "rxlightPinkText" : "whiteText") : (this.state.mode === 'add') ? "darkBlueText" : "greyText"}
                                                        ><Icon className="hotspotButtonsIcons" type="plus-circle" />Add Hotspot</h3>
                                                    </Tooltip>
                                                </div>
                                                <div className="hotspotButtons">
                                                    <Tooltip title="Save all hotspots with no data assigned">
                                                        <h3 style={{ cursor: "pointer" }} onClick={this.save} className={(this.props.auth.layout.h1Color === "rxlightPinkText") ? 'whiteText' : 'greyText'}> <Icon className="hotspotButtonsIcons" type="save" />Save</h3>
                                                    </Tooltip>
                                                </div>
                                                <div className={(this.state.mode === 'remove') ? "activeButton" : "hotspotButtons"}>
                                                    <Tooltip title="Highlight hotspot and click to delete">
                                                        <h3 style={{ cursor: "pointer" }} onClick={() => this.updateMode('remove')}
                                                            className={(this.props.auth.layout.h1Color === "rxlightPinkText") ? ((this.state.mode === 'remove') ? "rxlightPinkText" : "whiteText") : (this.state.mode === 'remove') ? "darkBlueText" : "greyText"}> <Icon className="hotspotButtonsIcons" type="delete" />Delete</h3>
                                                    </Tooltip>
                                                </div>
                                                <div className={(this.state.mode === 'move') ? "activeButton" : "hotspotButtons"}>
                                                    <Tooltip title="Highlight and click hotspot, then double-click to new chosen location">
                                                        <h3 style={{ cursor: "pointer" }} onClick={() => this.updateMode('move')}
                                                            className={(this.props.auth.layout. h1Color === "rxlightPinkText") ? ((this.state.mode === 'move') ? "rxlightPinkText" : "whiteText") : (this.state.mode === 'move') ? "darkBlueText" : "greyText"}><Icon className="hotspotButtonsIcons" type="drag" />Move</h3>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                                <div style={{ height: '600px', width: '100%' }}>
                                    <Scene id="scene" embedded>
                                        <a-assets id="asset" timeout="60000">
                                            <img id={'Branch_Icon_White'} alt='' src={Branch_icon_white} />
                                            <img id={'Branch_Icon_Black'} alt='' src={Branch_icon_black} />
                                            <img id={'exit_white'} alt='' src={exit_icon_white} />
                                            <img id={'exit_black'} alt='' src={exit_icon_black} />
                                            <img id={'text_icon_white'} alt='' src={Text_icon_white} />
                                            <img id={'text_icon_black'} alt='' src={Text_icon_black} />
                                            <img id={'pagination_icon_white'} alt='' src={Pagination_icon_white} />
                                            <img id={'pagination_icon_black'} alt='' src={Pagination_icon_black} />
                                            <img id={'link_icon_white'} alt='' src={Link_icon_white} />
                                            <img id={'link_icon_black'} alt='' src={Link_icon_black} />
                                            <img id={'video360_icon_white'} alt='' src={Video360_icon_white} />
                                            <img id={'video360_icon_black'} alt='' src={Video360_icon_black} />
                                            <img id={'video_icon_white'} alt='' src={Video_icon_white} />
                                            <img id={'video_icon_black'} alt='' src={Video_icon_black} />
                                            <img id={'image_icon_white'} alt='' src={Image_icon_white} />
                                            <img id={'image_icon_black'} alt='' src={Image_icon_black} />
                                            <img id={'audio_icon_white'} alt='' src={Audio_icon_white} />
                                            <img id={'audio_icon_black'} alt='' src={Audio_icon_black} />
                                            <img id={'camera_position_icon_black'} alt='' src={Camera_position_black} />
                                            <img id={'camera_position_icon_white'} alt='' src={Camera_position_white} />
                                            <img id={'caption_icon_black'} alt='' src={Caption_icon_black} />
                                            <img id={'caption_icon_white'} alt='' src={Caption_icon_white} />


                                            <img id={'Forward'} alt='' src={Forward} />
                                            <img id={'Complete'} alt='' src={Hotspot} />
                                            <img id={'add_hotspot_icon'} alt='' src={Add_hotspot_icon} />
                                            <img id={'ring'} alt='' src={circle_frame} />
                                            {
                                                this.state.tourInfo.hasOwnProperty("sceneObjects") ?
                                                    <React.Fragment>
                                                        {this.state.tourInfo.sceneObjects.map((asset: any, index: number) => {
                                                            if (index === 0) {
                                                                return <img key={index} id={`image` + index} alt='' crossOrigin="anonymous" src={asset.mediaPath + "?dummy=aframe" + index} onLoad={this.loaded} />
                                                            }
                                                            else {
                                                                return <img key={index} id={`image` + index} alt='' crossOrigin="anonymous" src={asset.mediaPath + "?dummy=aframe" + index} />
                                                            }
                                                        }
                                                        )}
                                                    </React.Fragment>
                                                    : null
                                            }
                                        </a-assets>
                                        {
                                            this.state.positionArray.map((entity, index) => {
                                                return <Entity key={index}
                                                    primitive="a-image"
                                                    id={entity.id}
                                                    material={{ shader: "flat" }}
                                                    // material={{alphaTest:"0.5",transparent:true}} 
                                                    position={{ x: entity.positionArray.positionx, y: entity.positionArray.positiony, z: entity.positionArray.positionz }}
                                                    rotation={{ x: entity.rotationArray.rotationx, y: entity.rotationArray.rotationy, z: entity.rotationArray.rotationz }}
                                                    className="intersectable" raycaster="objects: [data-raycastable]"
                                                    crossOrigin="anonymous"
                                                    src={this.getIcon(entity)}
                                                    cursor={{ fuse: true }}
                                                    events={{ mouseenter: this.highlightColor, mouseleave: this.resetColor, click: this.selectColor }}
                                                />
                                            })
                                        }
                                        <Entity id="sky" className={(this.state.mode === "add" || (this.state.mode === "move" && this.state.selected !== {})) ? "intersectable" : ""} primitive="a-sky" phi-length="360" phi-start="0" radius="15" opacity="0.0" scale={{ x: -1, y: 1, z: 1 }} />
                                        <Entity id="realsky" primitive="a-sky" phi-length="360" phi-start="0" radius="200" src={this.state.panobackground} scale={{ x: -1, y: 1, z: 1 }} />
                                        <Entity id="camera">
                                            <Entity primitive="a-camera" wasd-controls="false">
                                                <Entity primitive="a-cursor"
                                                    raycaster="far: 30; objects: .intersectable"
                                                    events={{ click: this.handleClick }}
                                                    animation__click={{ property: 'scale', startEvents: 'click', from: '0.1 0.1 0.1', to: '1 1 1', dur: 150 }}
                                                    animation__dbclick={{ enabled: this.state.mode === 'add', property: 'scale', startEvents: 'dblclick', from: '0.1 0.1 0.1', to: '1 1 1', dur: 150 }} />
                                            </Entity>
                                        </Entity>

                                    </Scene>
                                </div>
                                <AssignmentForm

                                    onCancel={this.onCancel}
                                    removeFile={this.removeFile}
                                    visible={this.state.visible}
                                    selected={this.state.selected}
                                    resources={this.state.fileList}
                                    library={this.state.library}
                                    currentPano={this.state.panobackground}
                                    otherPanos={this.state.tourInfo.sceneObjects}
                                    assignInfo={this.assignInfo}
                                    tourId={this.state.tourId}
                                    companyId={this.state.companyId}
                                    hotspots={this.state.hotspotList}
                                    states={this.state.tourInfo.states}
                                    updateLibrary={this.updateLibrary}
                                    icons={
                                        {
                                            Text_icon_black: Text_icon_black,
                                            Text_icon_white: Text_icon_white,
                                            Image_icon_black: Image_icon_black,
                                            Image_icon_white: Image_icon_white,
                                            Video360_icon_black: Video360_icon_black,
                                            Video360_icon_white: Video360_icon_white,
                                            Video_icon_black: Video_icon_black,
                                            Video_icon_white: Video_icon_white,
                                            Link_icon_black: Link_icon_black,
                                            Link_icon_white: Link_icon_white,
                                            Audio_icon_black: Audio_icon_black,
                                            Audio_icon_white: Audio_icon_white,
                                            Branch_icon_black: Branch_icon_black,
                                            Branch_icon_white: Branch_icon_white,
                                            Pagination_icon_black: Pagination_icon_black,
                                            Pagination_icon_white: Pagination_icon_white,
                                            exit_icon_black: exit_icon_black,
                                            exit_icon_white: exit_icon_white,
                                            Camera_position_black: Camera_position_black,
                                            Camera_position_white: Camera_position_white,
                                            Caption_icon_black: Caption_icon_black,
                                            Caption_icon_white: Caption_icon_white
                                        }
                                    }
                                />
                            </main>
                        </Col>

                        <Modal
                            title="Would you like to swap file?"
                            visible={this.state.swapFileModal}
                            onOk={() => this.swapFile()}
                            onCancel={() => this.swapFileModalClose()}>
                            <div>
                                <Row>
                                    <Col span={12}>
                                        {
                                            (this.state.isImage) ?
                                                <Upload
                                                    listType="picture-card"
                                                    showUploadList={false}
                                                    beforeUpload={this.beforeUploadSwap()}
                                                    disabled={this.state.uploading}
                                                    customRequest={this.uploadFileSwap}
                                                    accept="image/*"
                                                    className="clear-none"
                                                >
                                                    {this.state.swapFile.length > 0 && !this.state.uploading ? <img src={AWS_URL + this.state.swapFile} alt="Pano to Swap" style={{ width: '100%' }} /> : uploadButton}
                                                </Upload>
                                                : (this.state.isVideo) ?
                                                    <Upload
                                                        listType="picture-card"
                                                        showUploadList={false}
                                                        beforeUpload={this.beforeUploadSwap()}
                                                        disabled={this.state.uploading}
                                                        customRequest={this.uploadFileSwap}
                                                        accept="video/*"
                                                        className="clear-none"
                                                    >
                                                        {this.state.swapFile.length > 0 && !this.state.uploading ? <img src={Video_Small} alt="Pano to Swap" style={{ width: '100%' }} /> : uploadButton}
                                                    </Upload>
                                                    : (this.state.isAudio) ?
                                                        <Upload
                                                            listType="picture-card"
                                                            showUploadList={false}
                                                            beforeUpload={this.beforeUploadSwap()}
                                                            disabled={this.state.uploading}
                                                            customRequest={this.uploadFileSwap}
                                                            accept="audio/*"
                                                            className="clear-none"
                                                        >
                                                            {this.state.swapFile.length > 0 && !this.state.uploading ? <img src={Speaker_Icon} alt="Pano to Swap" style={{ width: '100%' }} /> : uploadButton}
                                                        </Upload>
                                                        : null
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Modal>

                        <Modal
                            title="Would you like to swap this file?"
                            visible={this.state.swapSharedFileModal}
                            onOk={() => this.swapSharedImage()}
                            onCancel={() => this.swapSharedFileModal({})}>
                            <div>
                                <Row>
                                    <Col span={12}>
                                        <Upload
                                            listType="picture-card"
                                            showUploadList={false}
                                            disabled={this.state.uploading}
                                            customRequest={this.uploadSharedImageSwap}
                                            accept="image/*"
                                            className="clear-none"
                                        >
                                            {this.state.swapFile.name && !this.state.uploading ? <img src={this.state.swapFile.url} alt="Pano to Swap" style={{ width: '100%' }} /> : uploadButton}
                                        </Upload>
                                    </Col>
                                </Row>
                            </div>
                        </Modal>

                        <Modal
                            title="Would you like to add a new Branching State?"
                            visible={this.state.showStateModal}
                            onOk={() => this.addNewState()}
                            onCancel={() => this.showStateModal()}>
                            <div>
                                <Row>
                                    <Col span={12}>
                                        <Row>
                                            <span style={{ display: "inline-block", marginBottom: "10px" }}>Enter State Name : </span>
                                            <Input placeholder="New State" onChange={this.newStateName} />
                                        </Row>
                                        <Row style={{ marginTop: "30px" }}>
                                            <span style={{ display: "inline-block", marginRight: "10px" }}>Select a Base State : </span>
                                            <Select
                                                onChange={this.handleBaseState}
                                                //value={this.state.currentState}
                                                placeholder="Select a base State"
                                                style={{ width: "200px", marginTop: "10px" }}
                                            >
                                                {this.selectStateOptions()}
                                            </Select>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Modal>
                        <Modal
                            title="Tour Stats"
                            visible={this.state.statModal}
                            onOk={() => this.setState({ statModal: false })}
                            onCancel={() => this.setState({ statModal: false })}>
                            <Row>
                                <div style={{ border: "1px solid #ccc", padding: "14px" }}>
                                    <h2 className="bebas" style={{ textAlign: "center" }}>Total Tour Stats</h2>
                                    <Row style={{ marginTop: "10px" }}>
                                        <Col span={12}><strong>Total Panos Size:</strong></Col>
                                        <Col span={12} style={{ textAlign: "right" }}>{this.state.stats.panosTotal}</Col>
                                    </Row>
                                    <Row style={{ marginTop: "10px" }}>
                                        <Col span={12}><strong>Largest Pano: </strong></Col>
                                        <Col span={12} style={{ textAlign: "right" }}>{this.state.stats.largestPano}</Col>
                                    </Row>
                                    <Row style={{ marginTop: "10px" }}>
                                        <Col span={12}><strong>Largest Pano Size: </strong></Col>
                                        <Col span={12} style={{ textAlign: "right" }}>{this.state.stats.maxPano}</Col>
                                    </Row>
                                    <Row style={{ marginTop: "10px" }}>
                                        <Col span={12}><strong>Total Resources Size:</strong></Col>
                                        <Col span={12} style={{ textAlign: "right" }}>{this.state.stats.resourceTotal}</Col>
                                    </Row>
                                    <Row style={{ marginTop: "10px" }}>
                                        <Col span={12}><strong>Largest Resource: </strong></Col>
                                        <Col span={12} style={{ textAlign: "right" }}>{this.state.stats.largestResource}</Col>
                                    </Row>
                                    <Row style={{ marginTop: "10px" }}>
                                        <Col span={12}><strong>Largest Resource Size: </strong></Col>
                                        <Col span={12} style={{ textAlign: "right" }}>{this.state.stats.maxResource}</Col>
                                    </Row>
                                </div>
                            </Row>
                        </Modal>

                        <Modal
                            title="Validation Errors"
                            visible={this.state.validateModal}
                            onOk={() => this.setState({ validateModal: false })}
                            onCancel={() => this.setState({ validateModal: false })}>
                            <Row>
                                <div style={{ border: "1px solid #ccc", padding: "14px" }}>
                                    <h2 className="bebas" style={{ textAlign: "center" }}>Errors</h2>
                                    {
                                        (this.state.validationErrors.length > 0) ?
                                            <List
                                                header={<div>Errors list</div>}
                                                footer={<div>Fix these errors before publishing</div>}
                                                bordered
                                                dataSource={this.state.validationErrors}
                                                renderItem={(item: any, ind: number) => (
                                                    <List.Item>
                                                        &#x2022; {item}
                                                    </List.Item>
                                                )}
                                            />
                                            :
                                            <p>No validation errors in this tour</p>
                                    }
                                    <hr />
                                    <h2 className="bebas" style={{ textAlign: "center" }}>Warnings</h2>
                                    {
                                        (this.state.validationWarnings.length > 0) ?
                                            <List
                                                header={<div>Warnings list</div>}
                                                bordered
                                                dataSource={this.state.validationWarnings}
                                                renderItem={(item: any, ind: number) => (
                                                    <List.Item>
                                                        &#x2022; {item}
                                                    </List.Item>
                                                )}
                                            />
                                            :
                                            <p>No warnings in this tour</p>
                                    }
                                </div>
                            </Row>
                        </Modal>

                        <Drawer
                            title=""
                            placement="right"
                            closable={true}
                            onClose={this.collapseRightSide}
                            visible={this.state.collapseRight}
                            getContainer={false}
                            width="430"
                            className={this.props.auth.layout.styleClass}
                        //key={placement}
                        >
                            <div className={this.props.auth.layout.bgColor}>
                                <Row style={{ borderBottom: "1px solid #bbb", padding: "14px" }}>
                                    <Col xs={24} sm={24} md={12}><h3 className={this.props.auth.layout.h1Color}>Shared Library</h3></Col>
                                    <Col xs={24} sm={24} md={12} style={{ textAlign: "right" }}>
                                        <Upload
                                            listType="picture"
                                            showUploadList={false}
                                            customRequest={this.upload}
                                            beforeUpload={this.beforeUploadShared()}
                                            onChange={this.handleChange}
                                            onRemove={this.removeFile}
                                            disabled={this.state.uploading}
                                            accept="audio/*,video/*,image/*"
                                            className="clear-none"
                                            multiple={true}
                                        >
                                            {uploadAllButton}
                                        </Upload>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "20px", padding: "14px" }}>
                                    <div className="App">
                                        <ul style={{ height: "400px", overflowY: "auto", display: "flex", flexWrap: "nowrap", flexDirection: "column" }}>
                                            {this.state.fileList.map((item, idx) => (
                                                <li key={idx} style={{ flex: "1 1 auto" }}>
                                                    <div style={{ width: '100%', height: 'auto', background: '#FFF', border: "2px solid #bbb", borderRadius: "5px", padding: "15px" }}>
                                                        <Row type="flex" align="middle" justify="space-between">
                                                            <Col xs={10} sm={10} md={10}>
                                                                <Row type="flex" align="middle" justify="space-between" className="uploadImageRow">
                                                                    <div className="showImgName">
                                                                        <p>{item.name}</p>
                                                                    </div>
                                                                    {
                                                                        item.type === "image/" ?
                                                                            <img style={{ maxWidth: "100%", maxHeight: "70px", width: "auto", height: "auto" }} className="image" alt={`360 Pano #${idx + 1}`} src={item.url.substring(0, item.url.lastIndexOf("/resources")) + "/resources/thumbnails" + item.url.substring(item.url.lastIndexOf("/")) + "-Thumbnail" + item.url.substring(item.url.lastIndexOf("."))} />
                                                                            : null
                                                                    }

                                                                    {
                                                                        item.type === "video/" ?
                                                                            <img style={{ maxWidth: "100%", maxHeight: "70px", width: "auto", height: "auto" }} className="image" alt={`360 Pano #${idx + 1}`} src={Video_icon} />
                                                                            : null
                                                                    }

                                                                    {
                                                                        item.type === "audio/" ?
                                                                            <img style={{ maxWidth: "100%", maxHeight: "70px", width: "auto", height: "auto" }} className="image" alt={`360 Pano #${idx + 1}`} src={Audio_icon} />
                                                                            : null
                                                                    }
                                                                </Row>
                                                            </Col>
                                                            <Col xs={12} sm={12} md={12}>
                                                                <Icon style={{ margin: "0 5px", color: "#666" }} type="retweet" title="Swap the Image" onClick={() => this.swapSharedFileModal(item)} />
                                                                <Icon style={{ margin: "0 5px", color: "#e6ab22" }} type="swap" title="Move to local library" onClick={() => this.ResourceToLocal(item)} />
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </Row>
                            </div>
                        </Drawer>
                    </Row>

                    <div className="fixedButtonDiv">
                        <Button onClick={() => this.getTotalTourStats()} className="fixedButton">Get Stats</Button>
                    </div>
                    <div className="fixedValidateButtonDiv">
                        <Button onClick={() => this.validatingTour()} className="fixedButton">Validate Tour</Button>
                    </div>
                </Spin>
            </div>
        );
    }
}
export default Coordinates;