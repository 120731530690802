import React, { Component } from 'react';
import 'aframe';
import { Entity, Scene } from 'aframe-react';
import TextHotspot from '../../HotSpotComponents/TextHotspot';
import ImageHotspot from '../../HotSpotComponents/ImageHotspot';
import AudioHotspot from '../../HotSpotComponents/AudioHotspot';
import VideoHotspot from '../../HotSpotComponents/VideoHotspot';
import Video360Hotspot from '../../HotSpotComponents/Video360Hotspot';
import LinkHotspot from '../../HotSpotComponents/LinkHotspot';
import CompleteTourHotspot from '../../HotSpotComponents/CompleteTourHotspot';
import PaginationHotspot from '../../HotSpotComponents/PaginationHotspot';
import BranchingHotspot from '../../HotSpotComponents/BranchingHotspot';
import { getTourSequence } from '../../../api/APIget';
import { getheight, getWidth } from '../../../utils/tools';
import { Modal, Spin, Checkbox, Row, Col, DatePicker, Button, Icon, message, Switch } from 'antd';
import arrow_to_left_1 from '../../../assets/Icons/arrow_to_left.png';
import arrow_to_right_1 from '../../../assets/Icons/arrow_to_right.png';
import exit_icon_white from '../../../assets/Icons/exit_icon_white.png';
import exit_icon_black from '../../../assets/Icons/exit_icon_black.png';
import Branch_icon_white from '../../../assets/Icons/Branch_icon_white.png';
import Branch_icon_black from '../../../assets/Icons/Branch_icon_black.png';
import Text_icon_white from '../../../assets/Icons/Text_Icon_White.png';
import Text_icon_black from '../../../assets/Icons/Text_Icon_Black.png';
import Pagination_icon_white from '../../../assets/Icons/Pagination_Icon_White.png';
import Pagination_icon_black from '../../../assets/Icons/Pagination_Icon_Black.png';
import Image_icon_black from '../../../assets/Icons/Image_Icon_Black.png';
import Image_icon_white from '../../../assets/Icons/Image_Icon_White.png';
import Audio_icon_white from '../../../assets/Icons/Audio_Icon_White.png';
import Audio_icon_black from '../../../assets/Icons/Audio_Icon_Black.png';
import Video360_icon_black from '../../../assets/Icons/360video_Icon_Black.png';
import Video360_icon_white from '../../../assets/Icons/360video_Icon_White.png';
import Video_icon_black from '../../../assets/Icons/Video_Icon_Black.png';
import Video_icon_white from '../../../assets/Icons/Video_Icon_White.png';
import Link_icon_white from '../../../assets/Icons/Link_Icon_White.png';
import Link_icon_black from '../../../assets/Icons/Link_Icon_Black.png';
import Forward from '../../../assets/Icons/Forward.png';
import moment from 'moment';
import CaptionHotspot from '../../HotSpotComponents/CaptionHotspot';

const CheckboxGroup = Checkbox.Group;

interface State {
    status: number;
    tourInfo: any;
    panoBackground: any;
    panoId: number,
    hotSpotArray: any;
    loading: boolean;
    panobackground: string;
    visible: boolean;
    publishBy: any,
    disableBy: any,
    publishNow: boolean,
    started: boolean,
    gaze: boolean,
    assetsLoaded: boolean,
    interactionsDisabled: boolean,
    position: any,
    rotation: any,
    selectedState: any
};

class ViewTours extends Component<any, State> {
    state: State = {
        tourInfo: {},
        status: 1,
        panoBackground: {
        },
        panoId: 0,
        hotSpotArray: [],
        loading: true,
        panobackground: '',
        visible: false,
        publishBy: null,
        disableBy: null,
        publishNow: true,
        started: false,
        assetsLoaded: true,
        gaze: false,
        interactionsDisabled: true,
        position: {},
        rotation: {},
        selectedState: "default"
    };

    public constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        getTourSequence(this.props.match.params.id, this.getSuccess, this.getError);
        console.log("props are " + this.props.match);

    }

    getSuccess = async (data: any) => {
        console.log(data);
        if (data.data.tour.sceneObjects.length > 0) {
            let hasAutoplay = this.checkAutoPlay(data.data.tour.sceneObjects[0].contentData.filter((data: any) =>
                data.state === "default" || data.state === null));

            this.setState(() => {
                return {
                    status: data.data.status,
                    publishBy: moment(data.data.publishedDate),
                    tourInfo: data.data.tour,
                    panoBackground: data.data.tour.sceneObjects[0],
                    interactionsDisabled: hasAutoplay,
                    hotSpotArray: data.data.tour.sceneObjects[0].contentData.filter((data:any)=>data.state==="default"||data.state===null),
                    loading: false
                }
            });
            let cameraPosition = data.data.tour.sceneObjects[0].contentData.filter((hotspot: any) => { return (hotspot.type === "VirtualTourNetwork.CameraPositionData"&&(hotspot.state==="default"||hotspot.state===null)) })
            console.log(cameraPosition);
            if (cameraPosition.length > 0) {
                let position = {
                    x: cameraPosition[0].position.x,
                    y: cameraPosition[0].position.y,
                    z: cameraPosition[0].position.z
                }
                let rotation = {
                    x: cameraPosition[0].aframePosition.rotationx,
                    y: cameraPosition[0].aframePosition.rotationy,
                    z: cameraPosition[0].aframePosition.rotationz
                }
                console.log(position);
                console.log(rotation);
                this.setState({
                    position: position,
                    rotation: rotation,
                });
            }
            let assetloader: any = document.querySelector('a-assets');
            let scene: any = document.querySelector('a-scene');
            assetloader?.addEventListener("loaded", this.showStartButton);
            scene?.addEventListener("enter-vr", this.selectCamera(true));
            if (data.data.tour.states === null) {
                this.setState({
                    selectedState: null
                })
            }
            else {
                this.setState({
                    selectedState: "default"
                })
            }
        }
        else {
            Modal.error({
                title: 'There is currently no data for this tour',
                content: 'There is currently no data for this tour. Press okay to edit this tour',
                onOk: () => {
                    window.location.replace(`/tour/1/${data.data.id}`)
                }
            });
        }
    }
    getError = (data: any) => {
        console.log(data);
        message.error("Resources not fully loaded. Try hard reloading the browser");
    }
    checkAutoPlay(hotspotArray: any) {
        console.log(hotspotArray);
        let hasAutoplay = false;
        let filteredArray = hotspotArray.filter((hotspot: any) => { return hotspot.autoPlay === true && (hotspot.type === "VirtualTourNetwork.VideoData" || hotspot.type === "VirtualTourNetwork.SphereVideoData") });
        console.log(filteredArray);
        if (filteredArray.length > 0)
            hasAutoplay = true;
        return hasAutoplay;
    }
    enableInteractions = () => {
        this.setState((state) => { return { interactionsDisabled: false } });
    }

    next = async (hotspot: any) => {
        console.log(hotspot);
        //console.log(this.state.tourInfo.sceneObjects);
        console.log(this.state.selectedState);
        let index = this.state.tourInfo.sceneObjects.findIndex((data: any) => data.uid === hotspot.targetUID);
        console.log(index);
        console.log(this.state.tourInfo.sceneObjects[index].contentData.filter((data: any) =>
            data.state === this.state.selectedState
        ));
        console.log(this.state.tourInfo.sceneObjects[index].contentData);
        if (typeof this.state.tourInfo.sceneObjects[index] === "undefined") {
            this.setState((state) => { return { panoId: index, panobackground: `#pano${index}` } });
            await Modal.error({
                title: 'There are currently no hotspots on this pano',
                content: 'There are currently no data for this tour. Press okay to go back and edit this tour',
                onOk: () => {
                    window.location.replace(`/tour/2/${this.props.match.params.id}`)
                }
            });
        }
        else {
            let hasAutoplay = this.checkAutoPlay(this.state.tourInfo.sceneObjects[index].contentData.filter((data: any) =>
                data.state === this.state.selectedState));
            let hotspots = this.state.tourInfo.sceneObjects[index].contentData.filter((data: any) =>
                data.state === this.state.selectedState);
            if (this.state.selectedState === "default") {
                hasAutoplay = this.checkAutoPlay(this.state.tourInfo.sceneObjects[index].contentData.filter((data: any) =>
                    data.state === this.state.selectedState || data.state === null));
                hotspots = this.state.tourInfo.sceneObjects[index].contentData.filter((data: any) =>
                    data.state === this.state.selectedState || data.state === null);
            }
            await this.setState((state) => {
                return {
                    panoId: index,
                    panobackground: `#pano${index}`,
                    interactionsDisabled: hasAutoplay,
                    hotSpotArray: hotspots
                }
            });
            //this.setState({ hotSpotArray: this.state.tourInfo.sceneObjects[hotspot.targetUID].contentData });
            /*let cameraPosition = this.state.tourInfo.data[hotspot.targetUID].mappedData.filter((hotspot: any) => { return (hotspot.type === "VirtualTourNetwork.CameraPositionData") })
              console.log(cameraPosition);
              if (cameraPosition.length > 0) {
                  let position = {
                      x: cameraPosition[0].position.x,
                      y: cameraPosition[0].position.y,
                      z: cameraPosition[0].position.z
                  }
                  let rotation = {
                      x: cameraPosition[0].aframePosition.rotationx,
                      y: cameraPosition[0].aframePosition.rotationy,
                      z: cameraPosition[0].aframePosition.rotationz
                  }
                  console.log(position);
                  console.log(rotation);
                  this.setState({
                      position: position,
                      rotation: rotation,
                  });
              }*/
        }
    }


    nextAnswer = async (pano: any, newState: string) => {
        console.log(pano);
        console.log(newState);
        let index = this.state.tourInfo.sceneObjects.findIndex((data: any) => data.uid === pano);
        console.log(this.state.tourInfo.sceneObjects[index].contentData.filter((data: any) =>
            data.state === newState
        ));
        let hasAutoplay = this.checkAutoPlay(this.state.tourInfo.sceneObjects[index].contentData.filter((data: any) =>
            data.state === newState));
        let hotspots = this.state.tourInfo.sceneObjects[index].contentData.filter((data: any) =>
            data.state === newState);
        if (newState === "default") {
            hotspots = this.state.tourInfo.sceneObjects[index].contentData.filter((data: any) =>
                data.state === newState || data.state === null);
            hasAutoplay = this.checkAutoPlay(this.state.tourInfo.sceneObjects[index].contentData.filter((data: any) =>
                data.state === newState || data.state=== null));
        }
        await this.setState((state) => {
            return {
                panoId: index,
                panobackground: `#pano${index}`,
                interactionsDisabled: hasAutoplay,
                selectedState:newState,
                hotSpotArray: hotspots
            }
        });
    }

    restartProgress = async () => {
        let hasAutoplay = this.checkAutoPlay(this.state.tourInfo.sceneObjects[0].contentData.filter((data:any)=>data.state==="default"||data.state=== null));
        await this.setState((state) => ({
            panoId: 0, panobackground: `#pano${0}`,
            interactionsDisabled: hasAutoplay,
            hotSpotArray: this.state.tourInfo.sceneObjects[0].contentData.filter((data: any) => data.state === "default" || data.state === null)
        }));
    }

    completeTour = async () => {
        this.setState({ visible: true });
    }

    loaded = (e: any) => {
        console.log(e);
        console.log("loaded");
        this.setState({ panobackground: '#pano0' });
    }
    handleClick = (e: any) => {
        console.log('Clicked!');
        console.log(e.target);
    }
    viewedHotspot = () => { }
    showStartButton = (e: any) => {
        this.setState({ assetsLoaded: true });
    }
    startTour(start: boolean, gaze: boolean) {
        console.log(start);
        this.setState((state) => { return { started: start, gaze: gaze } });
        this.selectCamera(gaze);

    }
    selectCamera = (checked: boolean) => {
        let MouseCamera: any = document.getElementById("MouseCamera");
        let GazeCamera: any = document.getElementById("GazeCamera");
        console.log("checked:" + checked);
        if (checked) {
            MouseCamera?.setAttribute('active', 'false');
            MouseCamera?.setAttribute('raycaster', { enabled: false });
            MouseCamera?.setAttribute('visible', 'false');

            GazeCamera?.setAttribute('active', 'true');
            GazeCamera?.setAttribute('raycaster', { enabled: true });
            GazeCamera?.setAttribute('visible', 'true');
        }
        else {
            MouseCamera?.setAttribute('active', 'true');
            MouseCamera?.setAttribute('raycaster', { enabled: true });
            MouseCamera?.setAttribute('visible', 'false');

            GazeCamera?.setAttribute('active', 'false');
            GazeCamera?.setAttribute('raycaster', { enabled: false });
            GazeCamera?.setAttribute('visible', 'false');

        }

    }
    assetLoaded(e: any) {
        console.log(e);
        console.log("asset Loaded");
    }

    errorLoadingResource = (id:string)=>(e: any) => {
        var el = document.getElementById(id);
        var src = el?.getAttribute("src");
        if ((src?.match(/\?t\=/g) || []).length !== 5) {
            var timestamp = new Date().getTime();
            var queryString = "?t=" + timestamp;
            src = src + queryString;
            el?.setAttribute("src", src);
        }
    }

    render() {
        return (
            <div>
                <Spin spinning={this.state.loading}>
                    <Row type="flex" justify="space-between" style={{ marginTop: "20px" }}>
                        <Col xs={24} sm={24} md={24}>
                            <div style={{ height: getheight(90), width: getWidth(), position: "absolute" }}>
                                <div className={(this.state.started) ? "hidden" : "backGroundLoader blackBG"}>
                                    {
                                        (this.state.assetsLoaded) ?
                                            <div className="centeredStartButton" >
                                                <Button style={{ margin: "10px" }} onClick={() => this.startTour(true, true)}>
                                                    Start Tour With Gaze
                                            </Button>
                                                <Button style={{ margin: "10px" }} onClick={() => this.startTour(true, false)}>
                                                    Start Tour With Mouse
                                            </Button>
                                            </div>
                                            :
                                            <Icon type="loading" className="tealText centeredStartButton" style={{ fontSize: '144px' }} />
                                    }

                                </div>
                                <Scene embedded>
                                    <a-assets id="asset" timeout="60000">
                                        <img id={'arrow_to_left'} alt='' src={arrow_to_left_1} />
                                        <img id={'arrow_to_right'} alt='' src={arrow_to_right_1} />
                                        <img id={'forward'} alt='' src={Forward} />
                                        <img id={'Branch_Icon_White'} alt='' src={Branch_icon_white} />
                                        <img id={'Branch_Icon_Black'} alt='' src={Branch_icon_black} />
                                        <img id={'exit_white'} alt='' src={exit_icon_white} />
                                        <img id={'exit_black'} alt='' src={exit_icon_black} />
                                        <img id={'text_icon_white'} alt='' src={Text_icon_white} />
                                        <img id={'text_icon_black'} alt='' src={Text_icon_black} />
                                        <img id={'pagination_icon_white'} alt='' src={Pagination_icon_white} />
                                        <img id={'pagination_icon_black'} alt='' src={Pagination_icon_black} />
                                        <img id={'link_icon_white'} alt='' src={Link_icon_white} />
                                        <img id={'link_icon_black'} alt='' src={Link_icon_black} />
                                        <img id={'video360_icon_white'} alt='' src={Video360_icon_white} />
                                        <img id={'video360_icon_black'} alt='' src={Video360_icon_black} />
                                        <img id={'video_icon_white'} alt='' src={Video_icon_white} />
                                        <img id={'video_icon_black'} alt='' src={Video_icon_black} />
                                        <img id={'image_icon_white'} alt='' src={Image_icon_white} />
                                        <img id={'image_icon_black'} alt='' src={Image_icon_black} />
                                        <img id={'audio_icon_white'} alt='' src={Audio_icon_white} />
                                        <img id={'audio_icon_black'} alt='' src={Audio_icon_black} />
                                        {
                                            this.state.tourInfo.hasOwnProperty("sceneObjects") ?
                                                <div>
                                                    {this.state.tourInfo.sceneObjects.map((asset: any, index: number) => {
                                                        if (index === 0) {
                                                            return (
                                                                <img key={index} id={`pano` + index} onError={this.errorLoadingResource(`pano` + index)} alt='' crossOrigin="anonymous" src={asset.mediaPath + "?dummy=preview" + index} onLoad={this.loaded} />
                                                            )
                                                        }
                                                        else {
                                                            return <img key={index} id={`pano` + index} onError={this.errorLoadingResource(`pano` + index)} alt='' crossOrigin="anonymous" src={asset.mediaPath} />
                                                        }

                                                    }
                                                    )}
                                                    {
                                                        this.state.tourInfo.sceneObjects.map((pano: any, ind: number) => {
                                                            if (pano.contentData.length > 0) {
                                                                return pano.contentData.map((hotspot: any, index: number) => {
                                                                    switch (hotspot.type) {
                                                                        case "VirtualTourNetwork.VideoData":
                                                                            return <video key={"assets" + hotspot.id} onError={this.errorLoadingResource(ind + `video` + hotspot.id)} id={ind + `video` + hotspot.id} crossOrigin="anonymous" src={hotspot.videoPath!} autoPlay={false} preload="auto" loop={false}></video>;
                                                                        case "VirtualTourNetwork.AudioData":
                                                                            return <audio key={"assets" + hotspot.id} onError={this.errorLoadingResource(ind + `audioSrc` + hotspot.id)} id={ind + `audioSrc` + hotspot.id} crossOrigin="anonymous" src={hotspot.audioFile!} preload="auto" ></audio>;
                                                                        case "VirtualTourNetwork.ImageMessageData":
                                                                            return <img key={"assets" + hotspot.id} onError={this.errorLoadingResource(ind + `image` + hotspot.id)} id={ind + `image` + hotspot.id} alt='' crossOrigin="anonymous" src={hotspot.imagePath!} />;
                                                                        case "VirtualTourNetwork.SphereVideoData":
                                                                            return <video key={"assets" + hotspot.id} onError={this.errorLoadingResource(ind + `video` + hotspot.id)} id={ind + `video` + hotspot.id} crossOrigin="anonymous" src={hotspot.videoPath!} autoPlay={false} preload="auto" loop={false}></video>;
                                                                        case "VirtualTourNetwork.PageMessageData":
                                                                            return <React.Fragment key={"assets" + hotspot.id}>
                                                                                {
                                                                                    hotspot.messagePages.map((page: any, pos: number) =>
                                                                                        (page.type === "VirtualTourNetwork.ImagePageData" || page.type === "VirtualTourNetwork.BothPageData") ?
                                                                                            <img key={"assets" + hotspot.id + "_" + pos} onError={this.errorLoadingResource(ind + `image` + hotspot.id + pos)} id={ind + `image` + hotspot.id + pos} alt='' crossOrigin="anonymous" src={page.pageImagePath!} />
                                                                                            :
                                                                                            null
                                                                                    )
                                                                                }

                                                                            </React.Fragment>;
                                                                        case "VirtualTourNetwork.BranchingData":
                                                                            return <React.Fragment key={"assets" + hotspot.id}>
                                                                                {
                                                                                    hotspot.answers.map((page: any, pos: number) =>
                                                                                        (page.type === "VirtualTourNetwork.ImagePageData" || page.type === "VirtualTourNetwork.BothPageData") ?
                                                                                            <img key={"assets" + hotspot.id + "_" + pos} onError={this.errorLoadingResource(ind + `image` + hotspot.id + `ans` + pos)} id={ind + `image` + hotspot.id + `ans` + pos} alt='' crossOrigin="anonymous" src={page.imageAnswer!} />
                                                                                            :
                                                                                            null
                                                                                    )
                                                                                    
                                                                                }
                                                                                {
                                                                                    (hotspot.questionImage !== null && hotspot.questionImage !== "") ?
                                                                                        <img key={"assets" + hotspot.id + "ques"} onError={this.errorLoadingResource(ind + `image` + hotspot.id + `ques`)} id={ind + `image` + hotspot.id + `ques`} alt='' crossOrigin="anonymous" src={hotspot.questionImage!} />
                                                                                        :
                                                                                        null
                                                                                }

                                                                            </React.Fragment>;
                                                                        default:
                                                                            return null;
                                                                    }
                                                                })

                                                            }
                                                            else {
                                                                return null;
                                                            }
                                                        })
                                                    }
                                                </div>
                                                :
                                                null
                                        }
                                    </a-assets>
                                    {
                                        this.state.hotSpotArray.map((asset: any, index: number) => {
                                            switch (asset.type) {
                                                case "VirtualTourNetwork.VideoData":
                                                    return <VideoHotspot key={"tour" + index} interactionsDisabled={this.state.interactionsDisabled} enableInteractions={this.enableInteractions} panoid={this.state.panoId} data={asset} viewedHotspot={this.viewedHotspot} started={this.state.started} id={asset.id} />;
                                                case "VirtualTourNetwork.ImageMessageData":
                                                    return <ImageHotspot key={"tour" + index} interactionsDisabled={this.state.interactionsDisabled} panoid={this.state.panoId} id={asset.id} started={this.state.started} viewedHotspot={this.viewedHotspot} data={asset} />;
                                                case "VirtualTourNetwork.SphereVideoData":
                                                    return <Video360Hotspot key={"tour" + index} interactionsDisabled={this.state.interactionsDisabled} enableInteractions={this.enableInteractions} panoid={this.state.panoId} data={asset} viewedHotspot={this.viewedHotspot} started={this.state.started} id={asset.id} />
                                                case "VirtualTourNetwork.MessageData":
                                                    return <TextHotspot key={"tour" + index} interactionsDisabled={this.state.interactionsDisabled} panoid={this.state.panoId} id={asset.id} viewedHotspot={this.viewedHotspot} started={this.state.started} data={asset} />;
                                                case "VirtualTourNetwork.AudioData":
                                                    return <AudioHotspot key={"tour" + index} interactionsDisabled={this.state.interactionsDisabled} panoid={this.state.panoId} id={asset.id} viewedHotspot={this.viewedHotspot} started={this.state.started} data={asset} />;
                                                case "VirtualTourNetwork.HotspotLinkData":
                                                    return <LinkHotspot key={"tour" + index} interactionsDisabled={this.state.interactionsDisabled} id={asset.id} panoid={this.state.panoId} next={() => this.next(asset)} started={this.state.started} viewedHotspot={this.viewedHotspot} data={asset} />;
                                                case "VirtualTourNetwork.HotspotCompleteData":
                                                    return <CompleteTourHotspot key={"tour" + index} interactionsDisabled={this.state.interactionsDisabled} id={asset.id} panoid={this.state.panoId} completeTour={() => this.completeTour()} started={this.state.started} viewedHotspot={this.viewedHotspot} data={asset} />;
                                                case "VirtualTourNetwork.PageMessageData":
                                                    return <PaginationHotspot key={"tour" + index} interactionsDisabled={this.state.interactionsDisabled} panoid={this.state.panoId} data={asset} started={this.state.started} viewedHotspot={this.viewedHotspot} id={asset.id} />;
                                                case "VirtualTourNetwork.BranchingData":
                                                    return <BranchingHotspot key={"tour" + index} interactionsDisabled={this.state.interactionsDisabled} panoid={this.state.panoId} data={asset} started={this.state.started} viewedHotspot={this.viewedHotspot} id={asset.id} next={ this.nextAnswer}/>;
                                                case "VirtualTourNetwork.CaptionData":
                                                    return <CaptionHotspot key={"tour" + index} interactionsDisabled={this.state.interactionsDisabled} panoid={this.state.panoId} id={asset.id} viewedHotspot={this.viewedHotspot} started={this.state.started} data={asset} />;
                                                default:
                                                    return null;
                                            }
                                        }
                                        )
                                    }
                                    <Entity id="sky" primitive="a-sky" phi-length="360" phi-start="0" radius="200" src={this.state.panobackground} scale={{ x: -1, y: 1, z: 1 }} />

                                    {
                                        (this.state.panobackground === '#pano0' && this.state.rotation.x) ?
                                            <Entity id="gazeRig" rotation={this.state.rotation.x + " " + this.state.rotation.y + " " + this.state.rotation.z}>
                                                <Entity id="GazeCamera" primitive="a-camera" wasd-controls="false">
                                                    <Entity primitive="a-cursor"
                                                        visible={true}
                                                        id="GazeCursor"
                                                        fuse={true}
                                                        fuseTimeout={"1000"}
                                                        raycaster={"far: 20; objects: .intersectable"}
                                                        material={"color:#FFF"}
                                                        animation__click={{ property: 'scale', startEvents: 'click', from: '0.1 0.1 0.1', to: '1 1 1', dur: 150 }}
                                                    ></Entity>
                                                    <Entity>

                                                    </Entity>

                                                </Entity>
                                            </Entity>
                                            :
                                            <Entity id="gazeRig">
                                                <Entity id="GazeCamera" primitive="a-camera" wasd-controls="false">
                                                    <Entity primitive="a-cursor"
                                                        visible={true}
                                                        id="GazeCursor"
                                                        fuse={true}
                                                        fuseTimeout={"1000"}
                                                        raycaster={"far: 20; objects: .intersectable"}
                                                        material={"color:#FFF"}
                                                        animation__click={{ property: 'scale', startEvents: 'click', from: '0.1 0.1 0.1', to: '1 1 1', dur: 150 }}
                                                    ></Entity>
                                                    <Entity>

                                                    </Entity>

                                                </Entity>
                                            </Entity>
                                    }
                                    {

                                        (this.state.panobackground === '#pano0' && this.state.rotation.x) ?
                                            <Entity id="mouseRig" rotation={this.state.rotation.x + " " + this.state.rotation.y + " " + this.state.rotation.z}>
                                                <Entity id="MouseCamera" primitive="a-camera" cursor={"rayOrigin: mouse"} raycaster={"far: 20; objects: .intersectable"} wasd-controls="false" />
                                            </Entity>
                                            :
                                            <Entity id="mouseRig">
                                                <Entity id="MouseCamera" primitive="a-camera" cursor={"rayOrigin: mouse"} raycaster={"far: 20; objects: .intersectable"} wasd-controls="false" />
                                            </Entity>
                                    }
                                </Scene>
                            </div>
                        </Col>
                    </Row>
                </Spin>
                <Modal
                    title="Tour has been Completed"
                    visible={this.state.visible}
                    okText="Return to Home"
                    onOk={() => { window.location.replace('/home') }}
                    cancelText="Restart the Course"
                    onCancel={() => {
                        this.setState({ visible: false });
                        console.log("Restart the Course")
                        this.restartProgress();
                    }}
                >
                    <p>What would you like to do now?</p>
                </Modal>
            </div>
        );
    }
}
export default ViewTours;