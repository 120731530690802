import * as React from 'react';
import { Row, Col } from 'antd';
import { getGradeStyle } from '../../utils/tools';

export interface IAppProps {
    coursesAvail:number;
    coursesCompleted:number;
    coursesFailed: number;
    coursesInProgress: number;
    totalTime:string;
    GPA: string;
    textColor: string
}

export function CourseOverView (props: IAppProps) {
  return (
    <div style={{height: "400px", border: "1px solid #ccc", padding: "14px"}}>
        <Row>
            <Col md={12}><span className={props.textColor}> New Courses Available</span></Col>
            <Col md={12} style={{textAlign:"end"}}><h3 className={props.textColor}>{props.coursesAvail}</h3></Col>
        </Row>
        <Row>
            <Col md={12}><span className={props.textColor}>Courses Completed</span></Col>
            <Col md={12} style={{textAlign:"end"}}><h3 className={props.textColor}>{props.coursesCompleted}</h3></Col>
        </Row>
        <Row>
            <Col md={12}><span className={props.textColor}>Courses In Progress</span></Col>
            <Col md={12} style={{textAlign:"end"}}><h3 className={props.textColor}>{props.coursesInProgress}</h3></Col>
        </Row>
        <Row>
            <Col md={12}><span className={props.textColor}>Courses Failed</span></Col>
            <Col md={12} style={{textAlign:"end"}}><h3 className={props.textColor}>{props.coursesFailed}</h3></Col>
        </Row>
        <Row>
            <Col md={12}><span className={props.textColor}>Total Time Spent</span></Col>
            <Col md={12} style={{textAlign:"end"}}><h3 className={props.textColor}>{props.totalTime}</h3></Col>
        </Row>
        <Row>
            <Col md={12}><span className={props.textColor}>My Grade Average</span></Col>
            <Col md={12} style={{textAlign:"end"}}><h3  className={getGradeStyle(props.GPA)}style={{fontWeight:"bold"}} >{props.GPA}</h3></Col>
        </Row>

    </div>
  );
}
