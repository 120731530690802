import React, { Component } from 'react';
import { connect } from 'react-redux';
import { message, Col, Row, Button, Avatar, Typography, Icon } from 'antd';
import { getCompanyToursViewer, getUserProgress, getCriticalHotspots } from '../../api/APIget';
import { getCompanyInformation} from '../../api/JanusHostApiGet';
import { getGradeStyle, getGrade, getGradePoints, getGradeFromPoints, totalTime } from '../../utils/tools';

const { Paragraph } = Typography;
interface State {
    tours: any[],
    companyInformation:any,
    teamMates: any[],
    compOwner: any,
    coursesCurrentlyInProgress: any[],
    coursesCompleted: any[],
    criticalHotspots: any[],
    ownerAvgGrade: string,
    ownerCourses: number,
    ownerTime: string
};



class CompanyProfile extends Component<any, State>{
    state: State = {
       tours:[],
       companyInformation:{},
       teamMates:[],
       compOwner:{},
       coursesCurrentlyInProgress: [],
       coursesCompleted: [],
       criticalHotspots:[],
       ownerAvgGrade:'',
       ownerCourses: 0,
       ownerTime: ''
    }
    public constructor(props: any) {
        super(props);
    }
    componentDidMount() {
        //console.log(this.props.auth.user.nameid);   
        console.log(this.props);
        getCompanyInformation(this.props.auth.company.id,this.getCompanySuccess,this.getHostError);
        getCompanyToursViewer(this.props.auth.company.id, this.getSuccess, this.getError);
        getUserProgress(this.props.auth.user.nameid, this.getProgressSuccess, this.getError);
    }
    
    getSuccess = (data: any) => {
        //console.log(data.data);
        this.setState({ tours: data.data });
    }
    
    getCompanySuccess=(data:any)=>
    {
        console.log(data.data);
        let companyInfo:any = {};
        companyInfo.city = data.data.companyInformation.city.charAt(0).toUpperCase() + data.data.companyInformation.city.substring(1).toLowerCase();
        companyInfo.province = data.data.companyInformation.province.charAt(0).toUpperCase() + data.data.companyInformation.province.substring(1).toLowerCase();
        companyInfo.address = data.data.companyInformation.address.charAt(0).toUpperCase() + data.data.companyInformation.address.substring(1).toLowerCase();
        companyInfo.postal = data.data.companyInformation.postalCode.toUpperCase();
        companyInfo.phoneNumber = data.data.companyInformation.phoneNumber;
        companyInfo.description = data.data.description;
        console.log(companyInfo);
        this.setState({ companyInformation: companyInfo });
    }

    getProgressSuccess = (data: any) => {
        //console.log(data.data);
        let currentProgress = data.data.filter((data: any) => { return (!data.tourProgress.completed) });
        let currentCompleted = data.data.filter((data: any) => { return (data.tourProgress.completed) });
        this.setState((state) => ({ coursesCurrentlyInProgress: currentProgress, coursesCompleted: currentCompleted }));
    }

    getOwnerProgressSuccess = (data: any) => {
        let currentCompleted = data.data.filter((data: any) => { return (data.tourProgress.completed) });
        var points = 0;
        //console.log(currentCompleted);
        for (var i = 0; i < currentCompleted.length; i++) {
            getCriticalHotspots(currentCompleted[i].tourInfo.id, this.getTourSuccess, this.getError);
            let grade = getGrade(currentCompleted[i].tourProgress, this.state.criticalHotspots);
            points = points + (getGradePoints(grade));
        }
        var avgPoints = Math.round(points / currentCompleted.length);
        var averageGrade = getGradeFromPoints(avgPoints);
        var ownerTime = totalTime(data.data);
        this.setState({ ownerAvgGrade: averageGrade, ownerCourses: data.data.length, ownerTime: ownerTime });
    }

    getTourSuccess = (data: any) => {
        this.setState({ criticalHotspots: data.data });
    }
    
    getError = (data: any) => 
    {
        message.error("There was a problem getting your courses");
    }
    
    getHostError =(data:any)=>
    {
        message.error("There was a problem getting information. Check the console");
        console.log(data);
    } 
    render() {
        return (
            <React.Fragment>
                <Row>
                    <Row>
                        <img src={this.props.auth.company.banner} style={{ width: "100%", height: "auto" }}/>
                    </Row>
                    <Row>
                        <Row style={{ paddingTop: "10px", marginBottom: "20px" }}>
                            <Col xs={24} sm={24} md={16} >
                                <Row type="flex" justify="space-between">
                                <Col sm={24} md={5}><Avatar size={126} shape="square" src={this.props.auth.company.logo} /></Col>
                                    <Col sm={24} md={17}><h1 className={this.props.auth.layout.secondaryColor} style={{ fontSize: "2em", paddingTop: "40px", marginLeft: "10px" }}>{this.props.auth.company.name}</h1></Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={24} md={8} style={{ textAlign: "right" }} className={this.props.auth.layout.secondaryColor}>
                                <Row>{this.state.companyInformation.address}</Row>
                                <Row>{this.state.companyInformation.city + " " + this.state.companyInformation.province} </Row>
                                <Row>{this.state.companyInformation.postal} </Row>
                                <Row>{this.state.companyInformation.phoneNumber}</Row>
                            </Col>
                        </Row>
                        <Paragraph className={this.props.auth.layout.secondaryColor}>{this.state.companyInformation.description}</Paragraph>        
                </Row>
            </Row>
            </React.Fragment>
        );
    }
}

function mapToStateToProps(state: any) {
    return {
        auth: state.auth,
        management: state.management,
        
    };
}
export default connect(mapToStateToProps)(CompanyProfile);