import { VisitedInfo } from "../models/HotspotInterfaces";
import validator from 'validator';

const months =["January","February","March","April","May","June","July","August","September","October","November","December"];
function getCurrentDate(){
    var today = new Date();
    return today.getUTCFullYear() + "-" + twoDigits(1 + today.getUTCMonth()) + "-" + twoDigits(today.getUTCDate()) + " " + twoDigits(today.getUTCHours()) + ":" + twoDigits(today.getUTCMinutes()) + ":" + twoDigits(today.getUTCSeconds());
}

function twoDigits(number:number) {
    if(0 <= number && number < 10) return "0" + number.toString();
    if(-10 < number && number < 0) return "-0" + (-1*number).toString();
    return number.toString();
}

function createMySqlDate(dataDate:string){
    var today = new Date(dataDate);
    return today.getUTCFullYear() + "-" + twoDigits(1 + today.getUTCMonth()) + "-" + twoDigits(today.getUTCDate()) + " " + twoDigits(today.getUTCHours()) + ":" + twoDigits(today.getUTCMinutes()) + ":" + twoDigits(today.getUTCSeconds());
}

function betterDateFormat(dataDate:string){
    var date = dataDate.split("T")[0].split("-");
    let newDate = `${date[0]}-${date[1]}-${date[2]}`;
    return newDate;
}
function getMonthAndYear(dataDate:string){
    var date = new Date(dataDate);
    return months[date.getMonth()] + " " + date.getFullYear(); 
}
function getDateDMY(dataDate:string){
    var today = new Date(dataDate);
    return today.getDate()+"/"+(today.getMonth()+1)+"/"+today.getUTCFullYear();
}
function getDateYMD(dataDate:string){
    var today = new Date(dataDate);
    return today.getUTCFullYear()+"-"+(today.getMonth()+1)+"-"+today.getDate();
}

export { getCurrentDate, createMySqlDate, betterDateFormat, getMonthAndYear,getDateDMY,getDateYMD };

export function getTotalhotspotIds(visitedHotspots: VisitedInfo[])
{
    let totalHotspots: number = 0;
    for (let p = 0; p < visitedHotspots.length; p++)
    {
        if (visitedHotspots[p].hotspots.length > 0)
        {
            totalHotspots += visitedHotspots[p].hotspots.length;
        }
    }
    return totalHotspots;
}

export const makeCancelable = (promise:any) => {
    let rejectFn:any;
  
    const wrappedPromise:any = new Promise((resolve, reject) => {
        rejectFn = reject;
  
        Promise.resolve(promise)
            .then(resolve)
            .catch(reject);
    });
  
    wrappedPromise.cancel = () => {
        rejectFn({ canceled: true });
    };
  
    return wrappedPromise;
};
export  function getheight(height:number=0)
{
    let main = document.getElementsByTagName("main");
    let container = document.getElementById("container");
    let style = getComputedStyle(container!);
    return (main[0].clientHeight - (parseFloat(style.paddingTop) + parseFloat(style.paddingBottom) + height));
    }
export  function getheightNoPadding()
{
    let main = document.getElementsByTagName("main");
    // let container = document.getElementById("container");
    // let style = getComputedStyle(container!);
    return (main[0].clientHeight);
    }
export function getWidth()
{
    let main = document.getElementsByTagName("main");
    let container = document.getElementById("container");
    let style = getComputedStyle(container!);
    return (main[0].clientWidth - (parseFloat(style.paddingRight) + parseFloat(style.paddingLeft)));
}
export function getWidthNoPadding()
{
    let main = document.getElementsByTagName("main");
    // let container = document.getElementById("container");
    // let style = getComputedStyle(container!);
    return (main[0].clientWidth);
}
export function getGradeStyle(grade:string){
    grade = grade.toUpperCase();
    switch(grade){
      case "A":
      case "A+":
      case "A-":
        return "darkBlueText";
      case "B":
      case "B+":
      case "B-":
      case "C":
      case "C+":
      case "C-":
        return "orangeText";
      case "D":
      case "D-":
      case "F":
        return "redText";
      default:
        return "tealText";
    }
}
export function getGrade(progress: any, criticalHotspots: VisitedInfo[]) {
    let grade;
    //getting grade
    if (progress.completedAt <= getCurrentDate()||progress.completed) {
        var a = (progress.timeSpent || '').split(':')
        if (progress.totalhotspot === getTotalhotspotIds(progress.visitedhotspots)) {
            grade = "A";
        }
        else if (progress.totalPano === progress.visitedPanoIds.length) {
            // all critical and non critical panos are visited

            if (checkCriticalProgress(criticalHotspots,progress.visitedhotspots)) {
                grade = "B"; //this means all the hotspots in critical scenes are visited, missing ones are form non critical
            }
            else {
                grade = "C";//this means that all the hotspots in critical scenes are not visited
            }
        }
        else if (progress.totalCriticalPanos === progress.visitedCriticalPanosIds.length) {
            // all critical panos are visited
            if (checkCriticalProgress(criticalHotspots, progress.visitedhotspots)) {
                grade = "C"; //this means all the hotspots in critical scenes are visited, missing ones are form non critical
            }
            else {
                grade = "D";//this means that all the hotspots in critical scenes are not visited
            }
        }
        else if (progress.totalCriticalPanos != progress.visitedCriticalPanosIds.length) {
            if (progress.visitedCriticalPanosIds.length > 0) {
                grade = "D"
            }
            else {
                grade = "F"
            }
        }
        else {
            grade = "F"
        }
    }
    else {
        grade = "-"
    }
    return grade;
}
function checkCriticalProgress(tourCriticalHotspots:VisitedInfo[],usertourProgress: VisitedInfo[])
{
    let completeCritical: boolean = true;
    for (let i = 0; i < tourCriticalHotspots.length; i++)
    {
        let userIndex: number = usertourProgress.findIndex((x)=> x.pano==tourCriticalHotspots[i].pano);
        
        if(userIndex >= 0 && tourCriticalHotspots[i].hotspots.length !== usertourProgress[userIndex].hotspots.length)
            completeCritical = false;
    }
    return completeCritical;
}

export function getGradePoints(grade: string) {
    let point=0;
    if (grade == 'A') {
        point = 5
    }
    else if (grade == 'B') {
        point = 4
    }
    else if (grade == 'C') {
        point = 3
    }
    else if (grade == 'D') {
        point = 2
    }
    else if (grade == 'F') {
        point = 1
    }
    else {
        point=0
    }
    
    return point;
}
export function getGradeFromPoints(point: number) {
    let grade;
    if (point === 5) {
        grade = 'A'
    }
    else if (point === 4) {
        grade = 'B'
    }
    else if (point === 3) {
        grade = 'C'
    }
    else if (point === 2) {
        grade = 'D' 
    }
    else if (point === 1) {
        grade = 'F'
    }
    else {
        grade = '-'
    }

    return grade;
}
export function totalTime(courses: any) {
    var seconds = 0;
    var minutes = 0;
    var hours = 0;
    for (let i = 0; i < courses.length; i++) {
        var times = [0, 0, 0]
        var max = times.length

        var a = (courses[i].tourProgress.timeSpent || '').split(':')

        // normalize time values
        for (var j = 0; j < max; j++) {
            a[j] = isNaN(parseInt(a[j])) ? 0 : parseInt(a[j]);
        }


        hours = hours + a[0];
        minutes = minutes + a[1];
        seconds = seconds + a[2];

        if (seconds >= 60) {
            var m = (seconds / 60) << 0
            minutes += m
            seconds -= 60 * m
        }

        if (minutes >= 60) {
            var h = (minutes / 60) << 0
            hours += h
            minutes -= 60 * h
        }



    }
    if (minutes == 0 && hours == 0 && seconds == 0) {
        return "00:00:00"
    }
    else {
        return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2)
    }
}
export function averageTime(time: string, length: number) {
    let timeSplit = (time || '').split(':');
    console.log(timeSplit);
    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    let a = (time || '').split(':');
    // Calculating average time
    let totalSeconds = (parseInt(a[0]) * 60 * 60) + (parseInt(a[1]) * 60) + (parseInt(a[2]));
    totalSeconds = totalSeconds / length;
    hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    minutes = Math.floor(totalSeconds / 60);
    seconds = Math.floor(totalSeconds % 60);
    return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2)

}

export function isValid(data: any) {
    let errors: any = {};
    for (var key in data) {
        if (data.hasOwnProperty(key)) {
            if (data[key] === null || data[key] === '' || data[key] === 0) {
                errors[key] = "This field is required";
            }
            if (key === 'email') {
                if (!validator.isEmail(data['email'])) {
                    errors['email'] = 'Invalid email';
                }
            }
            if (key === 'userName') {
                if (data['userName'].includes(' ')) {
                    errors['userName'] = 'UserName cannot have white spaces';
                }
            }
        }
    }
    return {
        errors,
        isValid: (Object.keys(errors).length === 0) ? true : false
    };
}

export function objectToFormData(obj: any) {
    let form = new FormData();

    for (var property in obj) {
        if (obj.hasOwnProperty(property)) {
            form.append(property, obj[property]);
        }
    }
    return form;
}