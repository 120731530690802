import * as React from 'react';
import { Row, Col } from 'antd';
import { getGradeStyle } from '../../utils/tools';

export interface IAppProps {
    stats: any,
    layout: any
}

export function AllCoursesTotal(props: IAppProps) {
    var span, fontColor;
    switch (props.layout) {
        case "VRSafety":
            span = "smallText";
            fontColor = "mediumBlueText";
            break;
        case "RealX":
            span = "greyText smallText";
            fontColor = "whiteText";
            break;
        default:
            span = "smallText";
            fontColor = "mediumBlueText";
            break;
    }
    return (
        <div>
      <Row type="flex"> 
          <Col className="progressStatsStyle">
            <span className={`smallText ${props.layout.span}`}>Total Courses Available</span>
            <div style={{ textAlign: "center", alignContent: "baseline" }} > <h3 style={{ fontWeight: "bold" }} className={props.layout.h3Color}>{props.stats.totalCourses}</h3></div>
          </Col>
          <Col className="progressStatsStyle">
            <span className={`smallText ${props.layout.span}`}>Total Teammates</span>
            <div style={{ textAlign: "center", alignContent: "baseline" }} > <h3 style={{ fontWeight: "bold" }} className={props.layout.h3Color}>{props.stats.totalUsers}</h3></div>
          </Col>
          <Col className="progressStatsStyle">
            <span className={`smallText ${props.layout.span}`}>Total In Progress</span>
            <div style={{textAlign:"center", alignContent:"baseline", fontWeight:"bold"}} > <h3 style={{fontWeight:"bold"}} className={props.layout.h3Color}>{props.stats.progress}</h3></div>
          </Col>
          <Col className="progressStatsStyle">
            <span className={`smallText ${props.layout.span}`}>Total Time Spent</span>
            <div style={{ textAlign: "center", alignContent: "baseline" }} > <h3 style={{ fontWeight: "bold" }} className={props.layout.h3Color}>{props.stats.time}</h3></div>
          </Col>
          <Col className="progressStatsStyle">
            <span className={`smallText ${props.layout.span}`}>Total Courses Completed</span>
            <div style={{textAlign:"end", alignContent:"baseline"}} > <h3 style={{fontWeight:"bold"}} className={props.layout.h3Color}>{props.stats.complete}</h3></div>
          </Col>
          <Col className="progressStatsStyle">
            <span className={`smallText ${props.layout.span}`}>Total Courses Failed</span>
            <div style={{textAlign:"end", alignContent:"baseline"}} > <h3 style={{fontWeight:"bold"}} className={props.layout.h3Color}>{props.stats.failed}</h3></div>
          </Col>
          <Col>
            <span className={`smallText ${props.layout.span}`}>Average Grade</span>
            <Row type="flex" align="middle" justify="end">
              <h3 style={{fontWeight:"bold"}} className={props.layout.h3Color}>{props.stats.grade}</h3>
            </Row>
          </Col> 
      </Row>
    </div>
  );
}
