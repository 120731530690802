import React, { Component } from 'react';
import { connect } from 'react-redux';
import { message, Row, Col, Radio, Input, Spin, Table, Button, Icon, Avatar } from 'antd';
import { getAllTourProgress, getUserProgress, getCriticalHotspots } from '../../api/APIget';
import TeamProfiles from '../TeamProfiles/teamProfiles';
import { getConnectedUsers } from '../../api/JanusHostApiGet';
import { getGrade, getDateDMY, getCurrentDate, getGradeStyle, getGradeFromPoints, getGradePoints, getTotalhotspotIds } from '../../utils/tools';
import { TeammateStats } from './TeammateStats';
import { GradeGraph } from './gradeGraph';

let searchInput: any = {};
interface filterDropDown {
    setSelectedKeys: any,
    selectedKeys: any,
    confirm: any,
    clearFilters: any,

}
interface State {
    loading: boolean,
    courses: any[],
    teamMate: any,
    teamMates: any[],
    usersProgress: any[],
    userStats: any,
    searchedColumn: string,
    searchText: string,
    courseName: any[],
    courseGrade: any[],
    criticalHotspots: any[]
};
class ManageGrades extends Component<any, State> {
    state: State = {
        loading: true,
        courses: [],
        teamMate: {},
        teamMates: [],
        usersProgress: [],
        userStats: {},
        searchedColumn: '',
        searchText: '',
        courseName: [],
        courseGrade: [],
        criticalHotspots: []
    }
    public constructor(props: any) {
        super(props);
    }
    componentDidMount() {
        console.log(this.props);
        getConnectedUsers(this.props.auth.company.id, this.getUsersSuccess, this.getError);
        getUserProgress(this.props.match.params.id, this.getUserProgressSuccess, this.getError);
    }
    getUsersSuccess = (data: any) => {
        console.log(data);
        let teamMates = data.data.connections.filter((user: any) => user.user.firstName !== "shared");
        let teamMate = data.data.connections.filter((user: any) => user.user.id === this.props.match.params.id);
        console.log(teamMate);
        this.setState({ teamMate: teamMate[0].user, teamMates: teamMates  });
        
    }
    getError = (data: any) => {
        console.log(data);
        message.error("There was a problem getting your courses");
    }

    getUserProgressSuccess = (data: any,) => {
        if (data.data.length > 0) { // To prevent getting undefined results for users that never started a course 
            console.log(data);
            let progress = data.data.filter((data: any) => { return !data.tourProgress.completed; });
            let completed = data.data.filter((data: any) => { return data.tourProgress.completed; });
            let time = this.totalTime(data.data);

            let courseStat = [];
            let grades = [];
            let points = 0;
            let length = 0;
            let failed = 0;
            let courseNames = [];
            for (var i = 0; i < data.data.length; i++) {
                // Determining grade of a course
                getCriticalHotspots(data.data[i].tourInfo.id, this.getHotspotSuccess, this.getError);
                let grade = getGrade(data.data[i].tourProgress, this.state.criticalHotspots);
                let percent = Math.floor((getTotalhotspotIds(data.data[i].tourProgress.visitedhotspots) / data.data[i].tourProgress.totalhotspot) * 100 || 0) + "%"

                // Array with all the information of a course's progress to populate the table
                let stats = {
                    "tourId": data.data[i].tourInfo.tourId,
                    "name": data.data[i].tourInfo.tourName,
                    "started": getDateDMY(data.data[i].tourProgress.startedAt),
                    "time": data.data[i].tourProgress.timeSpent,
                    "completed": (data.data[i].tourProgress.completedAt < getCurrentDate()) ? getDateDMY(data.data[i].tourProgress.completedAt) : <span className="tealText">Pending</span>,
                    "scenes": data.data[i].tourProgress.visitedPanoIds.length + "/" + data.data[i].tourProgress.totalPano,
                    "hotspots": getTotalhotspotIds(data.data[i].tourProgress.visitedhotspots) + "/" + data.data[i].tourProgress.totalhotspot,
                    "percent": percent,
                    "pass": (grade === '-') ? "-" : (grade === 'F') ? <span className="redText">Fail</span> : <span className="darkBlueText">Pass</span>,
                    "grade": <span className={getGradeStyle(grade)}>{grade}</span>
                };
                courseStat.push(stats);

                // Getting the count of courses where grade is not empty(course isn't incomplete)
                if (grade !== '-') {
                    length++;

                    // Adding courseName and grade for 
                    courseNames.push(data.data[i].tourInfo.tourName);
                    grades.push(getGradePoints(grade));
                }

                // Getting count of failed courses
                if (grade === 'F') {
                    failed++;
                }
                points = points + (getGradePoints(grade));
            }
            console.log(courseStat);
            this.setState({ usersProgress: courseStat, loading: false, courseName: courseNames, courseGrade: grades })
            var avgPoints = Math.round(points / length);
            console.log(avgPoints);
            var averageGrade = getGradeFromPoints(avgPoints);
            console.log(averageGrade);
            let user = {
                "id": this.state.teamMate.id,
                "name": this.state.teamMate.firstName + " " + this.state.teamMate.lastName,
                "completed": completed.length,
                "started": progress.length,
                "failed": failed,
                "grade": averageGrade,
                "totalTime": time
            }
            console.log(user);
            this.setState({ userStats: user })
        }
        else {
            this.setState({loading: false})
        }
    }



    getHotspotSuccess = async (data: any) => {
        await this.setState({ criticalHotspots: data.data });
    }
    totalTime = (courses: any) => {
        var seconds = 0;
        var minutes = 0;
        var hours = 0;
        for (let i = 0; i < courses.length; i++) {
            var times = [0, 0, 0]
            var max = times.length

            var a = (courses[i].tourProgress.timeSpent || '').split(':')

            // normalize time values
            for (var j = 0; j < max; j++) {
                a[j] = isNaN(parseInt(a[j])) ? 0 : parseInt(a[j]);
            }


            hours = hours + a[0];
            minutes = minutes + a[1];
            seconds = seconds + a[2];

            if (seconds >= 60) {
                var m = (seconds / 60) << 0
                minutes += m
                seconds -= 60 * m
            }

            if (minutes >= 60) {
                var h = (minutes / 60) << 0
                hours += h
                minutes -= 60 * h
            }



        }
        if (minutes == 0 && hours == 0 && seconds == 0) {
            return "00:00:00"
        }
        else {
            return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2)
        }
    }


    getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: (filterDropDown: filterDropDown) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={filterDropDown.selectedKeys[0]}
                    onChange={e => filterDropDown.setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(filterDropDown.selectedKeys, filterDropDown.confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Row>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(filterDropDown.selectedKeys, filterDropDown.confirm, dataIndex)}
                        icon="search"
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
          </Button>
                    <Button onClick={() => this.handleReset(filterDropDown.clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
          </Button>
                </Row>
            </div>
        ),
        filterIcon: (filtered: any) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: string, record: { [x: string]: { toString: () => string; }; }) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: (dataIndex: string | undefined) =>
            this.state.searchedColumn === dataIndex ? (
                dataIndex
            ) : dataIndex,
    } as any);

    handleSearch = (selectedKeys: any[], confirm: () => void, dataIndex: any) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = (clearFilters: () => void) => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    
    render() {

        const columns = [
            {
                title: "Name",
                dataIndex: 'name',
                key: 'name',
                sorter: (a: any, b: any) => (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0),
                sortDirections: ['descend', 'ascend'],
                ...this.getColumnSearchProps('name'),
            },
            {
                title: "Started At",
                dataIndex: 'started',
                key: 'started',
                sorter: (a: any, b: any) => (a.started < b.started) ? 1 : ((b.started < a.started) ? -1 : 0),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: "Time Spent",
                dataIndex: 'time',
                key: 'time',
                sorter: (a: any, b: any) => (a.time < b.time) ? 1 : ((b.time < a.time) ? -1 : 0),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: "Scenes Viewed",
                dataIndex: 'scenes',
                key: 'scenes',
            },
            {
                title: "Hot Spots Viewed",
                dataIndex: 'hotspots',
                key: 'hotspots',
            },
            {
                title: "Percentage Complete",
                dataIndex: 'percent',
                key: 'percent',
            },
            {
                title: "Completed",
                dataIndex: 'completed',
                key: 'completed',
                sorter: (a: any, b: any) => (a.completed < b.completed) ? 1 : ((b.completed < a.completed) ? -1 : 0),
                sortDirections: ['descend', 'ascend'],

            },
            {
                title: "Pass/Fail",
                dataIndex: 'pass',
                key: 'pass',
                filters: [
                    {
                        text: 'Pass',
                        value: 'pass',
                    },
                    {
                        text: 'Fail',
                        value: 'fail',
                    }
                ],
                onFilter: (value: any, record: any) => record.pass == value,
            },
            {
                title: "Grade",
                dataIndex: 'grade',
                key: 'grade',
                sorter: (a: any, b: any) => a.grade.localeCompare(b.grade),
                sortDirections: ['descend', 'ascend'],
            },
        ];


        return (
            <Row id="main" type="flex" justify="space-between" style={{ minHeight: "80vh" }}>
                <Col xs={24} sm={24} md={17} className="whiteBG" style={{ padding: "14px"}}>
                    <Row>
                        <h1 className="darkBlueText" style={{ fontSize: "1.4em" }}>{ this.state.teamMate.firstName + " " + this.state.teamMate.lastName+ "'s Progress"}</h1>
                    </Row>
                    <Row style={{ marginTop: "20px" }} type="flex" justify="space-between">
                        <Col className="darkBlueText" xs={24} sm={24} md={8} style={{ backgroundColor: "#fff", border: "1px solid #4ECDC4", padding: "5px", paddingBottom: "30px", paddingTop:"30px", textAlign: "center" }}>
                            <Row>
                                <Col span={7}><Avatar shape="circle" size={64} src={this.state.teamMate.avatarImage} /></Col>
                                <Col span={17}>
                                    <h2>{this.state.teamMate.firstName + " " + this.state.teamMate.lastName}</h2>
                                    <h5><Icon type="mail" style={{ color: "#4ECDC4", fontSize: "1.3em", marginRight: "10px"}} />{ this.state.teamMate.email}</h5>
                                    <h5><Icon type="phone" style={{ color: "#4ECDC4", fontSize: "1.3em", marginRight: "10px" }} />{ this.state.teamMate.phoneNumber}</h5>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={15}>
                            
                            <TeammateStats
                                userStats={this.state.userStats}
                            />
                        </Col>
                    </Row>

                    <Row style={{ marginTop: "50px"}}>
                        <GradeGraph
                            xAxis={this.state.courseName}
                            yAxis={this.state.courseGrade}
                        />
                    </Row>

                    <Row style={{marginTop: "20px"}}>
                        <Spin spinning={this.state.loading}>
                            <Table className="whiteBG" columns={columns} pagination={false} scroll={{ y: 300 }} rowKey={(record: any) => record.tourId} dataSource={this.state.usersProgress} />
                        </Spin> 
                    </Row>
                </Col>

                <Col xs={24} sm={24} md={7}>
                    <TeamProfiles
                        bgColor={"whiteBG"}
                        titleColor={"orangeText"}
                        textColor={"blackText"}
                        available={this.state.teamMates}
                        cardHeight={"930px"}
                    />
                </Col>
            </Row>
        );
    }
}
function mapToStateToProps(state: any) {
    return {
        auth: state.auth,
        management: state.management,
        
    };
}
export default connect(mapToStateToProps)(ManageGrades);