import React, { Component} from 'react';
import 'aframe';
import { changePaginationPos } from '../../utils/aframetools';

import {Entity} from 'aframe-react';

interface Props {
    data: any
    id:number
    panoid:number,
    viewedHotspot: (panoId: number, hotspotId: number) => void,
    interactionsDisabled:boolean;
    started:boolean,
}
interface State {
  pageNumber:number,
  read:boolean,
}

class PaginationHotspot extends Component<Props, State> 
{
    state: State = {
      pageNumber:0,
      read:false,
    }

    componentDidMount()
    {
      let iconButton = document.getElementById(this.props.panoid + "iconButton" + this.props.id);
      iconButton?.addEventListener('click',this.hover,false);
      
      let paginationEntity = document.getElementById(this.props.panoid + "paginationComponent" + this.props.id);
      paginationEntity?.setAttribute('visible','false');
      
      let backplate = document.getElementById(this.props.panoid + "backplate" + this.props.id);
      backplate?.setAttribute('visible','false');
      
      let closingBox = document.getElementById(this.props.panoid + "closingBox" + this.props.id);
      closingBox?.setAttribute('visible','false');

    }
    componentWillUnmount()
    {
      document.getElementById(this.props.panoid + "iconButton" + this.props.id)?.removeEventListener("click",this.hover,false);
      document.getElementById(this.props.panoid + "iconNextButton" + this.props.id)?.removeEventListener("click",this.next,false);
      document.getElementById(this.props.panoid + "iconPrevButton" + this.props.id)?.removeEventListener("click",this.prev,false);
    }
    hover =(evt:any)=> {
      if(!this.props.interactionsDisabled && this.props.started)
      {
        let entity= document.getElementById(this.props.panoid + "playButton" + this.props.id);
        entity?.setAttribute('visible','false');
        
        let backplate = document.getElementById(this.props.panoid + "backplate" + this.props.id);
        backplate?.setAttribute('visible','true');
        backplate?.classList.add("intersectable");

        let paginationEntity = document.getElementById(this.props.panoid + "paginationComponent" + this.props.id);
        paginationEntity?.setAttribute('visible','true');
        paginationEntity?.classList.add("intersectable");
        let closingBox:any = document.getElementById(this.props.panoid + "closingBox" + this.props.id);
        closingBox?.addEventListener("mouseleave",this.close);
        closingBox?.setAttribute('visible','true');
        closingBox?.classList.add("intersectable");
        // console.log("hovering");
        let next=document.getElementById(this.props.panoid + "iconNextButton" + this.props.id);
        next?.addEventListener("click",this.next);
        let prev=document.getElementById(this.props.panoid + "iconPrevButton" + this.props.id);
        prev?.addEventListener("click",this.prev);
      }
    }
    next = (e:any) =>
    {
      if(this.state.pageNumber === this.props.data.messagePages.length-1)
      {
        this.setState({read:true});
        this.close();
      }
      else
      {
        // console.log("next"+this.state.pageNumber);
        this.setState({pageNumber:this.state.pageNumber+1});
      }
      
    }
    close = ()=>
    {
      // console.log("close");
      this.setState({pageNumber: 0});
      let entity= document.getElementById(this.props.panoid + "playButton" + this.props.id);
      entity?.setAttribute('visible','true');

      let closingBox = document.getElementById(this.props.panoid + "closingBox" + this.props.id);
      closingBox?.setAttribute('visible','false');
      closingBox?.classList.remove("intersectable");

      let backplate = document.getElementById(this.props.panoid + "backplate" + this.props.id);
      backplate?.setAttribute('visible','false');
      backplate?.classList.remove("intersectable");

      let paginationEntity = document.getElementById(this.props.panoid + "paginationComponent" + this.props.id);
      paginationEntity?.setAttribute('visible','false');
      paginationEntity?.classList.remove("intersectable");
      
      document.getElementById(this.props.panoid + "iconNextButton" + this.props.id)?.removeEventListener("click",this.next);
      document.getElementById(this.props.panoid + "iconPrevButton" + this.props.id)?.removeEventListener("click",this.prev);
        this.props.viewedHotspot(this.props.data.parentUID,this.props.data.id);
    }
    prev = (e:any) =>
    {
      if(this.state.pageNumber !== 0)
      {
        //console.log("prev"+this.state.pageNumber);
        this.setState({pageNumber:this.state.pageNumber-1});
      }
    }
    changePaginationBackPos(position:any)
    {
      let x=position.x;
      let z=position.z;
      let y=position.y;
      if(z<0&&z>=-15)
      {
        z=z+z*-0.09;
      }
      else if(z>0&&z<=15)
      {
        z=z-z*0.09;
      }

      if(x<0&&x>=-15)
      {
        x=x+x*-0.09;
      }
      else if(x>0&&x<=15)
      {
        x=x-x*0.09;
      }
      return `${x} ${y} ${z}`;
    }
    render(){
        return(
          <React.Fragment>
            <Entity
              id={this.props.panoid + "playButton" + this.props.id}
              position={`${this.props.data.position.x} ${this.props.data.position.y} ${this.props.data.position.z}`} 
              rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
              className="intersectable" 
              raycaster="objects: [data-raycastable]"
              animation__hover={{property: 'scale', startEvents: 'mouseenter', from: '1 1 1', to: '3 3 1', dur: 2000}}
              animation__fade={{property: 'material.opacity', startEvents: 'mouseenter', from: '1', to: '0.0', dur: 1500}}
              animation__rescale={{property: 'scale', startEvents: 'mouseleave', from: '2 2 2', to: '1 1 1', dur: 500}}
              animation__refade={{property: 'material.opacity', startEvents: 'mouseleave', from: '0.25', to: '1', dur: 500}}
            >
            <Entity
              geometry={{width:2,height:2}}
              className="intersectable" 
              id={this.props.panoid + "iconButton" + this.props.id}
              raycaster="objects: [data-raycastable]"
              primitive="a-image"
              src={(this.props.data.iconColor==="black")?"#pagination_icon_black":"#pagination_icon_white"}
              events={{click:this.hover}}
            />
            </Entity>
            <Entity 
             
              id={this.props.panoid + "paginationComponent" + this.props.id}
              rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
              position={changePaginationPos(this.props.data.position)} 
              className="intersectable"
              raycaster="objects: [data-raycastable]"
            >  
            <Entity 
              primitive="a-image" 
              //geometry={{primitive: 'triangle', vertexA:'0.5 0.5 0', vertexB:'-0.5 0 0',vertexC:'0.5 -0.5 0' }} material={{color: "grey"}} 
              id={this.props.panoid + "iconPrevButton" + this.props.id}
              position={'-6.5 4.5 0'} 
              className="intersectable" raycaster="objects: [data-raycastable]"
              crossOrigin="anonymous"
              src="#arrow_to_left"
            />
            <Entity
              primitive="a-image" 
              //geometry={{primitive: 'triangle', vertexA:'-0.5 0.5 0', vertexB:'-0.5 -0.5 0',vertexC:'0.5 0 0'}} material={{color: "grey"}} 
              id={this.props.panoid + "iconNextButton" + this.props.id}
              position={'6.5 4.5 0'}
              className="intersectable" raycaster="objects: [data-raycastable]"
              crossOrigin="anonymous"
              src="#arrow_to_right"
            />
          
            <a-text  
                visible={true}
                id={this.props.panoid + "title" + this.props.id}
                text={`align:center; width: 10; value: (${this.state.pageNumber+1}/${this.props.data.messagePages.length}) \n ${this.props.data.pageTitle}`}
                font='french-msdf.json' negate="false"
                scale="1 1 1"
                xOffset="2"
                zOffset="2"
                position={'0 4.5 0'} 
              />
            {
                this.props.data.messagePages.map((page:any,pos:number)=>
                {
                    return (page.type === "VirtualTourNetwork.ImagePageData")?
                    <a-plane key={"page"+pos+this.props.id}
                      visible={this.state.pageNumber===pos}
                      geometry={"height: 5.125; width: 6.5;"}
                      id={this.props.panoid + "page" + this.props.id+pos}
                      material="transparent:true; opacity:1;"
                      src={"#"+this.props.panoid+"image"+this.props.id+pos}
                      position="0 -1 0"                        
                    />
                    :
                    (page.type === "VirtualTourNetwork.TextPageData")?
                    <a-entity key={"page"+pos+this.props.id} 
                      visible={this.state.pageNumber===pos}
                      id={this.props.panoid + "page" + this.props.id+pos}
                      text={`align:center; width: 10; value: ${page.pageContent}; font:french-msdf.json; negate:false`}
                      
                      scale="1 1 1"
                      xOffset="2"
                      zOffset="2"
                      position="0 -1 0" 
                    />
                    :
                    <Entity key={"page"+pos+this.props.id}>
                        <a-entity key={"pageText" + pos + this.props.id}
                            visible={this.state.pageNumber === pos}
                            id={this.props.panoid + "page" + this.props.id + pos}
                            text={`align:center; width: 10; value: ${page.pageContent}; font:french-msdf.json; negate:false`}

                            scale="1 1 1"
                            xOffset="2"
                            zOffset="2"
                            position="0 -3.5 0"
                        />
                        <a-plane key={"pageImage" + pos + this.props.id}
                            visible={this.state.pageNumber === pos}
                            geometry={"height: 5.125; width: 6.5;"}
                            id={this.props.panoid + "page" + this.props.id + pos}
                            material="transparent:true; opacity:1;"
                            src={"#" + this.props.panoid + "image" + this.props.id + pos}
                            position="0 1 0"
                        />
                    </Entity>
                }
                )
            }
           <Entity geometry={{primitive: 'plane',width:14,height:12}} material={{color: "black", side: "double"}} 
              id={this.props.panoid + "backplate" + this.props.id}
              crossOrigin="anonymous"
              src=""
              position="0 0 -0.1"
            />
            </Entity>
            <Entity geometry={{primitive: 'plane',width:19,height:16}} material={{color: "blue",opacity:"0.0", side: "double"}} 
              id={this.props.panoid + "closingBox" + this.props.id}
              position={this.changePaginationBackPos(this.props.data.position)}
              rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
            />
            
          </React.Fragment>
        )
    }
}
export default PaginationHotspot;