import { SET_CURRENT_USER,SET_PERMISSIONS } from '../actions/types';
import isEmpty from 'lodash/isEmpty';
import { isNullOrUndefined } from 'util';
import {updateAccessLevel} from '../api/APIpost';
import { getCurrentDate } from '../utils/tools';
import axios from 'axios';

const initialState = {
  isAuthenticated: false,
  user: { nameid: "",
    display_name: "",
    given_name: "",
    family_name: "",
    unique_name: "",
    email: "",
    avatar: "",
    type: [""],
    nbf: "",
    exp: "",
    iat: "",
  },
    permissions: ["Read"],
    company: {
        id: "",
        logo: "",
        banner: "",
        name: "",
    }
};
const PERMISSION_ARRAY = ["Create", "Read", "Update", "Delete", "Internal"];

export default (state = initialState, action: any = {}) => {
  switch(action.type) {
    case SET_CURRENT_USER:
      return {
        isAuthenticated: !isEmpty(action.user),
        user: action.user,
          permissions: state.permissions,
          
      };
      case SET_PERMISSIONS:
          console.log("Check it!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
          console.log(action);
          console.warn("Set Permissions");
          let permissions:any[]=[];
          console.error(!isNullOrUndefined(action.permissions.relation.permissions));
          if(!isNullOrUndefined(action.permissions.relation.permissions))
          {
            for(let i=0;i<action.permissions.relation.permissions.length;i++)
            {
              if(action.permissions.relation.permissions.charAt(i)==='1') 
              permissions.push(PERMISSION_ARRAY[i]);
            }
            console.log(localStorage.management);
            if(localStorage.management)
            {
              let menuPermissions = JSON.parse(localStorage.management);

              console.log(menuPermissions.menuSettings.menu.includes("Company Owner"));
              if(menuPermissions.menuSettings.menu.includes("Company Owner"))
              {
                axios.get(`/api/v1/vrsafety/UserAccess/getUserAccess/${state.user.nameid}`).then((data)=>
                {
                  if(data.data.accessLevel !== 2 )
                  {
                    let accessObject =
                    {
                      Id: data.data.id,
                      UserId: data.data.userId,
                      CompanyId: data.data.companyId,
                      AccessLevel:2,
                      SubmittedAt: getCurrentDate()
                    };
                    updateAccessLevel(accessObject,postSuccess,postError);
                  }
                }).catch((error)=>{
                  console.error(error);
                });
                axios.post(`/api/v1/vrsafety/tag/createDefaultTags/${action.permissions.company.id}`)
                    .then((data)=>{console.log(data)})
                    .catch((error)=>{console.error(error)});
                axios.post(`/api/v1/vrsafety/component/createDefaultComponents/${action.permissions.company.id}`)
                    .then((data) => { console.log(data) })
                    .catch((error) => { console.error(error) });
              }
              else if(permissions.includes("Internal"))
              {
                axios.get(`/api/v1/vrsafety/UserAccess/getUserAccess/${state.user.nameid}`).then((data)=>
                {
                  if(data.data.accessLevel === 0 )
                  {
                    let accessObject =
                    {
                      Id: data.data.id,
                      UserId: data.data.userId,
                      CompanyId: data.data.companyId,
                      AccessLevel:1,
                      SubmittedAt: getCurrentDate()
                    };
                    updateAccessLevel(accessObject,postSuccess,postError);
                  }
                }).catch((error)=>{
                  console.error(error);
                });
              }
            }
          }
          return {
            isAuthenticated: state.isAuthenticated,
            user: state.user,
            permissions: permissions,
            company: action.permissions.company,
              layout: action.permissions.service.layout,
              components: action.permissions.components
          };
    default: return state;
  }
  
}
function postSuccess(data:any){
  console.log(data);
}
function postError(data:any){
  console.log(data);
}
function getComponent(layout: string){
    axios.get(`/api/v1/vrsafety/component/getComponentAccessByLayout/${layout}`)
        .then(res => {
            console.log(res.data);
            return(res.data);
        })
        .catch(err => {
            console.log(err);
        })

}