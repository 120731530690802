import React, { Component} from 'react';
import 'aframe';
import {Entity} from 'aframe-react';

interface Props {
    data: any
    id:number
    panoid:number
    completeTour:(panoSearch:any)=>void
    viewedHotspot: (panoId: number, hotspotId: number) => void,
    interactionsDisabled:boolean,
    started:boolean,
}
interface State {}

class LinkHotspot extends Component<Props, State> 
{
    state: State = {
    
    }
    componentWillUnmount()
    {
        let link = document.getElementById(this.props.panoid + "completeHotspot" + this.props.id);
        link?.removeEventListener('click',this.props.completeTour);
    }
    completeTour=()=>
    {
        if(!this.props.interactionsDisabled && this.props.started)
        {
            this.props.viewedHotspot(this.props.data.parentUID,this.props.data.id);
            this.props.completeTour(this.props.panoid);
        }
    }
    render(){
        return(
            <Entity
              id={this.props.panoid + "completeHotspot" + this.props.id}
              position={`${this.props.data.position.x} ${this.props.data.position.y} ${this.props.data.position.z}`} 
              rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
              className="intersectable" 
              raycaster="objects: [data-raycastable]"
              //material={{color: "green"}} 
              events={{click:this.completeTour}}
                animation__hover={{property: 'scale', startEvents: 'mouseenter', from: '1 1 1', to: '3 3 1', dur: 2000}}
                animation__fade={{property: 'material.opacity', startEvents: 'mouseenter', from: '1', to: '0.0', dur: 1500}}
                animation__rescale={{property: 'scale', startEvents: 'mouseleave', from: '2 2 2', to: '1 1 1', dur: 500}}
                animation__refade={{property: 'material.opacity', startEvents: 'mouseleave', from: '0.25', to: '1', dur: 500}}
            >
            <Entity 
                geometry={{width:2,height:2}}
                primitive="a-image"
                className="intersectable"
                id={this.props.panoid + "iconButton" + this.props.id}
                raycaster="objects: [data-raycastable]"
                src={(this.props.data.iconColor==="black")?"#exit_black":"#exit_white"}
                
                />
            </Entity>
        )
    }
}
export default LinkHotspot;