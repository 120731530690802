import * as React from 'react';
import { Row, Col, Avatar, Card } from 'antd';
import { getGradeStyle } from '../../utils/tools';

export interface IAppProps {
    teamMate: any
}

export function ProfileCards(props: IAppProps) {
    return (
        <div>
            <Card
                style={{ minWidth: "400px", marginTop: "20px" }}
                size="small"
                cover={
                    <Row type="flex" justify="space-between" style={{ boxSizing: "border-box", paddingLeft: "30px" }}>
                        <Col span={7} style={{ marginTop: "20px" }}>
                            <Avatar shape="circle" size={45} src={props.teamMate.avatarImage} />
                        </Col>
                        <Col span={17} id="CourseInfo" style={{ marginTop: "20px" }}>
                            <h3 style={{ marginBottom: "0", fontWeight: 600 }}>{`${props.teamMate.firstName} ${props.teamMate.lastName}`}</h3>
                            <h4 style={{ marginBottom: "0" }} className="greyText">{`${props.teamMate.userInformation.jobPosition}`}</h4>
                        </Col>
                    </Row>
                }
            >
            </Card>
        </div>
    );
}
