export function changePaginationPos(position:any)
{
  let x=position.x;
  let z=position.z;
  let y=position.y;
  if(z<0&&z>=-15)
  {
    z=z+z*-0.25;
  }
  else if(z>0&&z<=15)
  {
    z=z-z*0.25;
  }

  if(x<0&&x>=-15)
  {
    x=x+x*-0.25;
  }
  else if(x>0&&x<=15)
  {
    x=x-x*0.25;
  }
  return `${x} ${y} ${z}`;
}