import React, { Component } from 'react';
import { Row, Col, Typography } from 'antd';
import UrlShortenerForm from './UrlShortenerForm';
import LoginLogoTeal from '../../assets/Logos/vrsafety-login-logo-teal.png';
import PoweredWithLove from '../../assets/PoweredWithLove/PoweredWithLove';
import { Link } from 'react-router-dom';
import qs from 'qs';
import axios from 'axios';

let COLOR_STYLE = 'teal';
class UrlShortener extends Component<any, any> {
    _isMounted = false;

    async componentDidMount() {
        this._isMounted = true;
        const queryString = this.props.location.search.replace('?', '');
        const params = await qs.parse(queryString, {
            strictNullHandling: true,
        });
    }

    loginwithToken(token:any)
    {

    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <div className="ant-card loginCard">
                <Row>
                    <Col span={12} style={{ textAlign: 'center' }}>
                        <button className="StrippedButton">
                            <Link to={`/`}>
                                <img
                                    src={
                                        LoginLogoTeal
                                    }
                                    alt="Logo"
                                    style={{ height: '200px' }}
                                />
                            </Link>
                        </button>
                    </Col>
                    <Col span={12}>
                        <UrlShortenerForm location={this.props.location} /> 
                    </Col>
                </Row>
                <Row
                    style={{
                        textAlign: 'center',
                        color: '#fff',
                        padding: '0px',
                        margin: '0px',
                    }}
                >
                    <PoweredWithLove />
                </Row>
            </div>
        );
    }
}

export default UrlShortener;
