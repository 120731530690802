import axios from 'axios';

export function getCompanyId(callback:Function, errorcallback:Function){
  axios.get(`/api/v1/vrsafety/user/getCompanyId`)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function getUserInformation(callback:Function, errorcallback:Function){
  axios.get(`/api/v1/vrsafety/user/getUserInformation`)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function getUserAccessLevel(userId:string,callback:Function, errorcallback:Function){
  axios.get(`/api/v1/vrsafety/UserAccess/getUserAccess/${userId}`)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function getUserTagAccess(userId:string,callback:Function, errorcallback:Function){
  axios.get(`/api/v1/vrsafety/UserAccess/getUserTagAccess/${userId}`)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function getAllTours(callback:Function, errorcallback:Function){
    axios.get('/api/v1/vrsafety/tour/all')
    .then(res => {
      //do something
      if(callback != null){
         callback(res);
      }
    })
    .catch(err => {
      // catch error
      if(errorcallback != null){
         errorcallback(err);
      }
    })
}

export function getCompanyToursViewer(id:string,callback:Function, errorcallback:Function){
  axios.get(`/api/v1/vrsafety/viewer/webcompany/${id}/tours`)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function getTourInformation(id:string,callback:Function, errorcallback:Function){
  axios.get(`/api/v1/vrsafety/tour/${id}`)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function getTourProfileInformation(id:string,callback:Function, errorcallback:Function){
  axios.get(`/api/v1/vrsafety/tour/TourInfo/${id}`)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}
export function getTourSequencePano(id:string, callback:Function, errorcallback:Function){
  axios.get(`/api/v1/vrsafety/file/tourSequence/${id}`)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function getTourSequence(id: string, callback: Function, errorcallback: Function) {
    axios.get(`/api/v1/vrsafety/tour/tourSequence/${id}`)
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}

export function getTourSequenceHotspot(id: string, callback: Function, errorcallback: Function) {
    axios.get(`/api/v1/vrsafety/tour/tourSequenceHotspot/${id}`)
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}

export function getCriticalHotspots(id: string, callback: Function, errorcallback: Function) {
    axios.get(`/api/v1/vrsafety/tour/criticalHotspots/${id}`)
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}





export function getCompanyTag(id:string, callback:Function, errorcallback:Function){
  axios.get(`/api/v1/vrsafety/tag/getCompanyTags/${id}`)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function getSingleTag(id:string, callback:Function, errorcallback:Function){
  axios.get(`/api/v1/vrsafety/tag/get/${id}`)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function getTourPermission(tourId:string, callback:Function, errorcallback:Function){
  axios.get(`/api/v1/vrsafety/viewer/sharedtouraccess/${tourId}`)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}
export function getTourPermissionById(id: string, callback: Function, errorcallback: Function) {
    axios.get(`/api/v1/vrsafety/viewer/sharedtouraccessbyid/${id}`)
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}

/*********Tour Progress Get**********/

export function getAllTourProgress(callback:Function, errorcallback:Function){
  axios.get(`/api/v1/vrsafety/tourProgress/allTourProgress`)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}



export function getTourUsers(tourId:string, callback: Function, errorcallback: Function) {
    axios.get(`/api/v1/vrsafety/tourProgress/tourUsers/${tourId}`)
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}




export function getTourProgress(id:string, callback:Function, errorcallback:Function){
  axios.get(`/api/v1/vrsafety/tourProgress/tour/${id}`)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function getUserProgress(id:string, callback:Function, errorcallback:Function){
  axios.get(`/api/v1/vrsafety/tourProgress/user/${id}`)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function getAvailableCourses(id:string, callback:Function, errorcallback:Function){
  axios.get(`/api/v1/vrsafety/tour/availableTo/${id}`)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function getUserTourProgress(tourProgress:any, callback:Function, errorcallback:Function){
  axios.get(`/api/v1/vrsafety/tourProgress/user/${tourProgress.userId}/${tourProgress.tourId}`)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}
/**************Shared Tour*************/
export function getCompanyShared(companyId:string, callback:Function, errorcallback:Function){
  axios.get(`/api/v1/vrsafety/sharedtour/${companyId}`)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function getSharedTourInfo(tour:string, callback:Function, errorcallback:Function){
  axios.get(`/api/v1/vrsafety/SharedTour/tourId/${tour}`)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function getSharedTour(information:any, callback:Function, errorcallback:Function){
  axios.get(`/api/v1/vrsafety/SharedTour/tour/${information}`)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}
/***************Pano Resources**************/
export function getSharedResources(companyId:string,callback:Function,errorcallback:Function)
{
  axios.get(`/api/v1/vrsafety/file/company-resources/${companyId}`)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

/***************Hotspot Resources**************/
export function getSharedResourcesHotspots(companyId: string, callback: Function, errorcallback: Function) {
    axios.get(`/api/v1/vrsafety/file/company-resources-hotspots/${companyId}`)
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}


export function getTourResources(tourId: string, callback: Function, errorcallback: Function) {
    axios.get(`/api/v1/vrsafety/file/getfiles/${tourId}`)
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}

export function getTourIcons(tourId: string, callback: Function, errorcallback: Function) {
    axios.get(`/api/v1/vrsafety/file/getIcons/${tourId}`)
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}


export function getHotspotResources(tourId: string, callback: Function, errorcallback: Function) {
    axios.get(`/api/v1/vrsafety/file/getHotspotFiles/${tourId}`)
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}

export function getTourStats(id: string, callback: Function, errorcallback: Function) {
    axios.get(`/api/v1/vrsafety/tour/tourSizeStats/${id}`)
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}

export function validateTour(id: string, callback: Function, errorcallback: Function) {
    axios.get(`/api/v1/vrsafety/tour/validateTour/${id}`)
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}


export function getComponentAccess(callback: Function, errorcallback: Function) {
    axios.get(`/api/v1/vrsafety/component/getComponentAccess/`)
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}

export function getComponentAccessFull(callback: Function, errorcallback: Function) {
    axios.get(`/api/v1/vrsafety/component/getComponentAccessFull/`)
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}
