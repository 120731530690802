import React, { Component} from 'react';
import 'aframe';
import {Entity} from 'aframe-react';

interface Props {
    data: any,
    id:number,
    panoid:number,
    viewedHotspot: (panoId: number, hotspotId: number) => void,
    started:boolean,
    interactionsDisabled:boolean,
    enableInteractions:()=>void,
}
interface State {
  audioArray:string[];
  interactableArray:string[];
  materialCheck:any;
}

class Video360Hotspot extends Component<Props, State> 
{
    state: State = {
     audioArray:[],
     interactableArray:[],
     materialCheck:{},
    }

    addPlayEvent=()=>
    {
      let htmlvideo: any = document.getElementById(this.props.panoid+'video'+this.props.id);
     
      //check if video is loaded and ready to play
      if(htmlvideo.readyState===4){
        //check to make sure the material is not null
        let check = setInterval(this.checkMaterial,50);
        this.setState((state)=>{return{materialCheck:check}});
      } 
      htmlvideo.removeEventListener("canplaythrough", this.addPlayEvent);
    }
    componentDidMount()
    {
      // console.log("Next Pano");
      // console.log(this.props.data.autoPlay);
      let closeButton:any = document.getElementById(this.props.panoid + "closeButton" + this.props.id);
      closeButton?.setAttribute('visible',false);
      if(this.props.started && this.props.data.autoPlay)
        this.addMaterialTextureLoaded();
    }
    componentDidUpdate(previousProps:any){
      if (this.props.started !== previousProps.started || this.props.data!==previousProps.data) 
      {
        if(this.props.data.autoPlay)
          this.addMaterialTextureLoaded();
      }
    }
    addMaterialTextureLoaded = ()=>
    {
      this.removeInteractable();
      this.props.enableInteractions();
      let htmlvideo: any = document.getElementById(this.props.panoid+'video'+this.props.id);
      //get videosphere
      let  video: any = document.getElementById(this.props.panoid + "videoSphere" + this.props.id);
      console.log(htmlvideo.readyState)
      //check if video is ready to play
      if(htmlvideo.readyState!==4){
        htmlvideo?.addEventListener("canplaythrough",this.addPlayEvent);      
      }
      //check if texture loaded
      else if(video?.components?.material?.material?.map?.image && video?.components?.material?.material?.map?.image.id===htmlvideo.id)
      {
        //console.log("texture loaded");
        this.addPlayEvent();
      }
      else
      {
       //console.log("texture not loaded");
        video?.addEventListener('materialtextureloaded',this.addPlayEvent);
      }
    }
    checkMaterial=()=>
    {
      let  video: any = document.getElementById(this.props.panoid + "videoSphere" + this.props.id);
      //check to make sure the texture is not null
      if(video?.components?.material?.material?.map?.image)
      {
          console.log("here");
        //clear loop
          clearInterval(this.state.materialCheck);
        //play 
        this.play();
      }
    }

    componentWillUnmount(){
      // let  video: any = document.getElementById(this.props.panoid + "videoPlayer" + this.props.id);
      let  stopButton: any = document.getElementById(this.props.panoid + "stopButton" + this.props.id);
      stopButton?.removeEventListener('click',this.play);
      let closeButton = document.getElementById(this.props.panoid + "closeButton" + this.props.id);
      closeButton?.removeEventListener('click',this.stop);
    }

    removeInteractable=()=>
    {
      let interactablearray:any = document.getElementsByClassName("intersectable");
      console.log("remove interactable");
      let interactableIdArray =[];
      let j =0;
      //console.log(this.props.panoid + "closeButton" + this.props.id);
      while(interactablearray.length>0 && interactablearray.length >j)
      {
        //console.log(interactablearray[j].id !== (this.props.panoid + "closeButton" + this.props.id));
        if((typeof interactablearray[j].id !== 'undefined' && interactablearray[j].id !== null && interactablearray[j].id !=="" ) && interactablearray[j].id !== (this.props.panoid + "closeButton" + this.props.id))
        {
          interactableIdArray.push(interactablearray[j].id);
          interactablearray[j].classList.remove("intersectable");
        }
        else
        {
          j++;
        }
      }
      this.setState({interactableArray:interactableIdArray});
      console.log("removed interactable");
    }

    play=()=>
    {
        console.log(this.props.interactionsDisabled);
        console.log(this.props.started);
      if(!this.props.interactionsDisabled && this.props.started)
      {
          console.log("here");
        let closeButton:any = document.getElementById(this.props.panoid + "closeButton" + this.props.id);
        closeButton?.setAttribute('position','0 5 0');
        closeButton?.setAttribute('visible',true);
      

        let videosrc:any = document.getElementById(this.props.panoid+'video'+this.props.id);
        videosrc.removeEventListener("materialvideoloadeddata",this.checkMaterial);
        

        let audioArray:any = document.getElementsByClassName("playing");
        //console.log(audioArray);
        let newAudioArray=[];
        for(let i=0;i<audioArray.length;i++)
        {
          newAudioArray.push(audioArray[i].id);
          audioArray[i].components.sound.pauseSound();
        }
        this.setState({audioArray:newAudioArray});
        let  video: any = document.getElementById(this.props.panoid + "videoSphere" + this.props.id);
        video?.setAttribute('visible','true');
        video?.components.material.material.map.image.load();
        video?.components.material.material.map.image.play();
        video?.components.material.material.map.image.addEventListener('ended',this.stop);
      }
      
    }
    stop =()=>
    {
      let  video: any = document.getElementById(this.props.panoid + "videoSphere" + this.props.id);
      video?.setAttribute('visible','false');
      let closeButton:any = document.getElementById(this.props.panoid + "closeButton" + this.props.id);
      closeButton?.setAttribute('visible',"false");
      video?.components.material.material.map.image.pause();
      
      for(let i=0;i<this.state.audioArray.length;i++)
      {
        let audio:any = document.getElementById(this.state.audioArray[i]);
        audio?.components.sound.playSound();       
      }
      for(let j=0;j<this.state.interactableArray.length;j++)
      {
        let interactable:any = document.getElementById(this.state.interactableArray[j]);
        if(interactable!==null &&typeof interactable !== "undefined")
          interactable.classList.add("intersectable");
      }
        this.props.viewedHotspot(this.props.data.parentUID,this.props.data.id);
    }

    render(){
        return(
          <React.Fragment>
          <Entity
              id={this.props.panoid + "playButton" + this.props.id}
              position={`${this.props.data.position.x} ${this.props.data.position.y} ${this.props.data.position.z}`} 
              rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
              // cursor={{fuse: true}}
              className="intersectable" 
              raycaster="objects: [data-raycastable]"

              animation__hover={{property: 'scale', startEvents: 'mouseenter', from: '1 1 1', to: '3 3 1', dur: 2000}}
              animation__fade={{property: 'material.opacity', startEvents: 'mouseenter', from: '1', to: '0.0', dur: 1500}}
              animation__rescale={{property: 'scale', startEvents: 'mouseleave', from: '2 2 2', to: '1 1 1', dur: 500}}
              animation__refade={{property: 'material.opacity', startEvents: 'mouseleave', from: '0.25', to: '1', dur: 500}}
            >
            <Entity 
              primitive='a-image' 
              geometry={{width:2,height:2}}
              src={(this.props.data.iconColor==="black")?"#video360_icon_black":"#video360_icon_white"}
              className="intersectable" 
              id={this.props.panoid + "stopButton" + this.props.id}
              raycaster="objects: [data-raycastable]"
              events={{click:this.play}}
            />
            </Entity>
            <a-videosphere
              id={this.props.panoid + "videoSphere" + this.props.id}
              visible={false}
              src={'#'+this.props.panoid+'video'+this.props.id}
              radius="9"
            />
            <Entity 
              geometry={{primitive: 'plane',width:1,height:1}} 
              text={{ align:"center", width: 4,value:"Close"}} 
              material={{color: "black"}} 
              id={this.props.panoid + "closeButton" + this.props.id}
              position={`0 0 0`} 
              rotation={"-90 0 0"}
              className="intersectable" 
              raycaster="objects: [data-raycastable]"
              crossOrigin="anonymous"
              src=""
              cursor={{fuse: true}}
              events={{click:this.stop}}/> 
          </React.Fragment>
        )
    }
}
export default Video360Hotspot;