import React, { Component } from 'react';
import { getUserAccessLevel, getUserTagAccess, getCompanyTag } from '../../api/APIget';
import { updateAccessLevel } from '../../api/APIpost';
import { getConnectedUsers } from '../../api/JanusHostApiGet';
import ManageTagAccess from './ManageTagAccess';
import ManageUserAccess from './ManageUserAccess';
import { connect } from 'react-redux';
import { Col, Modal, Row, Select } from 'antd';

const { Option } = Select;


interface State 
{
    loading: boolean,
    UserData:string,
    userAccessData:{},
    tags:any[],
    accessTags:any[],
    specialAccessTags:string[],
    users:any[],
};

class ManageAccess extends Component<any, State> {
    state:State={
        loading:true,
        UserData:"",
        userAccessData:{},
        tags:[],
        accessTags:[],
        specialAccessTags:[],
        users:[],
    }
    public constructor(props:any){
        super(props);
        
      }

      componentDidMount(){
        //console.log(this.props);
        getConnectedUsers(this.props.auth.company.id,this.getUsersSuccess,this.getUsersError);
        getCompanyTag(this.props.auth.company.id,this.getTagSuccess,this.error);
      }
      getUsersSuccess=(data:any)=>
      {
        let connections = data.data.connections.filter((user:any)=>{
            return (user.user.firstName !== "shared" && user.lastName !== "content");
        });

          let userData = connections.filter((user: any) => { return (user.user.id === this.props.userId) });
          //console.log(userData);
          if (userData.length>0) { // requested user
              
              this.setState({ users: connections, UserData: userData[0].user.id });
          }
          else { // first user
              this.setState({ users: connections, UserData: connections[0].user.id });
          }
        getUserAccessLevel(this.state.UserData,this.getAccessSuccess,this.error);
        getUserTagAccess(this.state.UserData,this.getTagAccessSuccess,this.error);
      }
      getUsersError=(data:any)=>
      {
        console.log(data);
      }

      getAccessSuccess = (data:any)=>
      {
        //console.log(data);
        this.setState({userAccessData:data.data, loading:false});
      }
      getTagSuccess = (data:any) =>
      {
        //console.log(data.data);
        let sortedTags = data.data;
        sortedTags = sortedTags.sort((a:any,b:any) => (a.accessLevelId < b.accessLevelId) ? 1 : ((b.accessLevelId < a.accessLevelId) ? -1 : 0));
        this.setState({tags:sortedTags,loading:false});
      }
      getTagAccessSuccess = (data:any) =>
      {
          let tagIds = data.data.map((data: any) => data.tagID);
          //console.log(data);
        this.setState({accessTags:data.data,specialAccessTags:tagIds})
      }
      error(error:any)
      {
        console.log(`error!`);
        console.log(error);
      }
      updateAccessLevel = (newAccessObject:any) =>
      {
        this.setState({loading:true})
        //console.log(newAccessObject);
        updateAccessLevel(newAccessObject,this.postLevelSuccess,this.error);
      }
      postLevelSuccess = (data:any) =>
      {
        this.setState({userAccessData:data.data,loading:false});
        Modal.success({
          content: "User Access Level has been updated",
        });
      }

      handleSelectChange = (input: any) => (e: any) => 
      {
        //console.log(e);
        this.setState({ [input]: e} as any);
        getUserAccessLevel(e,this.getAccessSuccess,this.error);
        getUserTagAccess(e,this.getTagAccessSuccess,this.error);
      }
      selectOptions()
      {
        let items = [];         
          for (let i = 0; i < this.state.users.length; i++) {             
              items.push(
              <Option key={this.state.users[i].user.id || 0}
                  //value={this.state.users[i].user.id || 0}
              >
                  {
                    `${this.state.users[i].user.firstName} ${this.state.users[i].user.lastName}`
                  }
              </Option>);
          }
        return items;
      }
      render() {
        return (
            <React.Fragment>
                <Row>
                  <Col span={12}>
                    <Select onChange={this.handleSelectChange("UserData")}
                      value={this.state.UserData}
                    >
                      {this.selectOptions()}
                    </Select>
                  </Col>
                </Row>
                <ManageUserAccess
                    userAccessData={this.state.userAccessData}
                    UserData={this.state.UserData}
                    company={this.props.auth.company.id}
                    loading={this.state.loading}
                    updateUserAccess={this.updateAccessLevel}
                />
                <ManageTagAccess 
                    //id={this.props.match.params.id}
                    loading={this.state.loading}
                    UserData={this.state.UserData}
                    companyId={this.props.auth.company.id}
                    userAccessData={this.state.userAccessData}
                    tags={this.state.tags}
                    accessTags={this.state.accessTags}
                    specialAccessTags={this.state.specialAccessTags}
                />
            </React.Fragment>
        );
    }
}
function mapToStateToProps(state: any) {
  return {
      auth: state.auth,
      management: state.management,
      
  };
}
export default connect(mapToStateToProps)(ManageAccess);