import React, { Component } from 'react';
import { connect } from 'react-redux';
import { message, Row, Col, Radio,Input, Spin, Cascader} from 'antd';
import AllCoursesCard from './AllCoursesCard';
import { getAllTourProgress, getTourUsers, getCriticalHotspots } from '../../api/APIget';
import { AllCoursesTotal } from './AllCoursesTotal';
import TeamProfiles from '../TeamProfiles/teamProfiles';
import { getConnectedUsers, getCompanies } from '../../api/JanusHostApiGet';
import { getGrade, getGradePoints, getGradeFromPoints, getCurrentDate } from '../../utils/tools';
import CopyATour from './CopyATour';

const { Search } = Input;
const filters = [
    { label: 'Course Title A-Z', value: 'Title' },
    { label: 'Newest To Oldest', value: 'NewToOld' },
    { label: 'Oldest To Newest', value: 'OldToNew' },
];
interface State {
    loading: boolean,
    courses:any[],
    teamMates: any[],
    value: string,
    filteredTours: any[],
    course: string,
    criticalHotspots: any[],
    stats: any[],
    companies: any[],
    layout: string,
    fontColor: string,
    h1Color: string,
    bgColor: string
};
class ManagePage extends Component<any, State> {
    state:State={
      loading:true,
      courses:[],
      teamMates: [],
      value: 'OldToNew',
      filteredTours: [],
      course: '',
      criticalHotspots:[],
      stats: [],
      companies: [],
      layout:"",
      fontColor: "",
      h1Color: "",
      bgColor: ""
    }
    public constructor(props:any){
        super(props);
    }
    componentDidMount(){
        console.log(this.props);
        getAllTourProgress(this.getSuccess,this.getError);
        getConnectedUsers(this.props.auth.company.id, this.getUsersSuccess, this.getUsersError);
    }

    getSuccess=(data:any)=>
    {
        console.log(data);
        for (let i = 0; i < data.data.length; i++) {
            this.setState({ course: data.data[i].id });
            // getting tour progresses for each tour
            getTourUsers(data.data[i].tourId, this.getUserSuccess, this.getError);
        }
        this.setState({ courses: data.data, filteredTours: data.data, loading:false });
    }

    getUserSuccess = (data: any) => {
        let stats = this.state.stats;
        let points = 0;
        let length = 0;
        var seconds = 0;
        var minutes = 0;
        var hours = 0;
        let failed = 0;
        let progress = 0;
        let complete = 0;
        let avgGrade = 0;
        let time;
        if (data.data[0].id) {
            console.log(data);
            getCriticalHotspots(this.state.course, this.getHotspotSuccess, this.getError);
            for (let i = 0; i < data.data.length; i++) {
                let grade = getGrade(data.data[i], this.state.criticalHotspots);
                if (grade !== '-') {
                    points = points + getGradePoints(grade);
                    length++;
                    complete++;
                    if (grade === 'F') {
                        failed++
                    }
                }
                else {
                    progress++;
                }
                // time
                var times = [0, 0, 0]
                var max = times.length

                var a = (data.data[i].timeSpent || '').split(':')

                // normalize time values
                for (var j = 0; j < max; j++) {
                    a[j] = isNaN(parseInt(a[j])) ? 0 : parseInt(a[j]);
                }


                hours = hours + a[0];
                minutes = minutes + a[1];
                seconds = seconds + a[2];

                if (seconds >= 60) {
                    var m = (seconds / 60) << 0
                    minutes += m
                    seconds -= 60 * m
                }

                if (minutes >= 60) {
                    var h = (minutes / 60) << 0
                    hours += h
                    minutes -= 60 * h
                }


            }
            if (length > 0) {
                avgGrade = points / length
            }
            let stat = {
                "progress": progress,
                "complete": complete,
                "failed": failed,
                "grade": avgGrade,
                "seconds": seconds,
                "minutes": minutes,
                "hours": hours
            }
            stats.push(stat);
            console.log(stat);
            this.setState({ stats: stats });
        }
    }

    getHotspotSuccess = async (data: any) => {
        //console.log(data);
        await this.setState({ criticalHotspots: data.data });
    }
    getUsersSuccess=(data:any)=>
      {
        console.log(data);
        let teamMates = data.data.connections.filter((user:any)=>user.user.firstName!=="shared");
        console.log(teamMates);
        this.setState({teamMates:teamMates});
      }
    getError=(data:any)=>
      {
        console.log(data);
        message.error("There was a problem getting your courses");
      }
    getUsersError = (data:any)=>
      {
        console.log(data);
      }
    onSearch = (value: any) => {
          console.log(value);
          let newList = this.state.courses.filter(item => {
              const lc = item.tourName.toLowerCase();
              const filter = value.toLowerCase();
              return lc.includes(filter);
          });
          console.log(newList);
          this.setState({
              filteredTours: newList
          });
      }
    onFilter = (e: any) => {
          console.log(e);
          let tours;
        this.setState({
            value: e[0]
        });
        switch (e[0]) {
            case 'NewToOld':
                tours = this.state.courses.sort((a: any, b: any) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0));
                this.setState({
                    filteredTours: tours
                });
                break;
            case 'OldToNew':
                tours = this.state.courses.sort((a: any, b: any) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0));
                this.setState({
                    filteredTours: tours
                });
                break;
            case 'Title':
                tours = this.state.courses.sort((a: any, b: any) => (a.tourName.toLowerCase() > b.tourName.toLowerCase()) ? 1 : ((b.tourName.toLowerCase() > a.tourName.toLowerCase()) ? -1 : 0));
                this.setState({
                    filteredTours: tours
                });
                break;
        }
    }
    updateCoursesList = (newTour: any) => {
        getAllTourProgress(this.getSuccess,this.getError);
    }

    getTotals = () => {
        let totalPoints = 0;
        let length = 0;
        let progress = 0;
        let complete = 0;
        let failed = 0;
        let seconds = 0;
        let minutes = 0;
        let hours = 0;
        let time;
        let grade;
        for (let i = 0; i < this.state.stats.length; i++) {
            if (this.state.stats[i].grade !== 0) {
                totalPoints = totalPoints + this.state.stats[i].grade;
                length++;
            }
            progress = progress + this.state.stats[i].progress;
            complete = complete + this.state.stats[i].complete;
            failed = failed + this.state.stats[i].failed;
            hours = hours + this.state.stats[i].hours;
            minutes = minutes + this.state.stats[i].minutes;
            seconds = seconds + this.state.stats[i].seconds;

            if (seconds >= 60) {
                var m = (seconds / 60) << 0
                minutes += m
                seconds -= 60 * m
            }

            if (minutes >= 60) {
                var h = (minutes / 60) << 0
                hours += h
                minutes -= 60 * h
            }
        }

        if (minutes == 0 && hours == 0 && seconds == 0) {
            time = "00:00:00";
        }
        else {
            time= ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2)
        }
        //console.log(totalPoints);
        //console.log(length)
        grade = getGradeFromPoints(Math.round(totalPoints / length));
        let stats = {
            "progress": progress,
            "complete": complete,
            "failed": failed,
            "time": time,
            "grade": grade,
            "totalCourses": this.state.courses.length,
            "totalUsers": this.state.teamMates.length,

        }
        return stats

    }
    render() {
        return (
          <Row type="flex" justify="space-between" style={{minHeight:"80vh"}}> 
          <Col id="main" xs={24} sm={24} md={17}>
            <Row className={this.props.auth.layout.bgColor} style={{ padding: "14px", marginBottom: "20px" }} >
            <Row style={{paddingLeft: "10px"}}  type="flex" align="middle" justify="space-between">
              <Col xs={24} sm={24} md={13}><h2 style={{fontWeight: "bold", marginBottom: "0px" }} className={`bebas ${this.props.auth.layout.h5Color}`}>COMPANY PROGRESS</h2></Col>
              <Col xs={24} sm={24} md={11}>
              <Row type="flex" align="middle" justify="space-between">
                  <Col xs={24} sm={24} md={6} style={{textAlign: "right"}}>
                  {
                    (this.props.auth.user.type = "company owner")?
                    <CopyATour
                        updateCoursesList= {this.updateCoursesList}
                    />
                    : null
                  }
                  </Col>
                  <Col xs={24} sm={24} md={8} style={{textAlign: "right"}} className={this.props.auth.layout.styleClass}>
                    <Cascader 
                        style={{ marginRight: "10px",marginLeft: "5px", textAlign: "left", width: "150px" }} 
                        options={filters} 
                        value={[this.state.value]}
                        onChange={this.onFilter} />
                  </Col>
                  <Col xs={24} sm={24} md={6} className={this.props.auth.layout.styleClass}>
                      <Search
                        placeholder="Search"
                        onSearch={this.onSearch}
                        /> 
                  </Col>
              </Row>
              </Col>
            </Row>
            <Spin tip="Loading..." spinning={this.state.loading}>
              <AllCoursesCard
                available={this.state.filteredTours}
                company={this.props.auth.company.name}
                layout={this.props.auth.layout}
              />
            </Spin>
            </Row>
            <Row className={this.props.auth.layout.bgColor} style={{ padding: "24px" }}>
            <AllCoursesTotal
                stats={this.getTotals()}
                layout= {this.props.auth.layout}
            />
            </Row>
          </Col>
          <Col xs={24} sm={24} md={7} >
             <TeamProfiles 
              bgColor={this.props.auth.layout.bgColor}
              titleColor={`bebas ${this.props.auth.layout.h5Color}`}
              textColor={this.props.auth.layout.secondaryColor}
              available={this.state.teamMates}
              cardHeight={"650px"}
            />
          </Col>
          </Row>
        );  
      }
}
function mapToStateToProps(state: any) {
  return {
      auth: state.auth,
      management: state.management,
      
  };
}
export default connect(mapToStateToProps)(ManagePage);