import { CHANGE_MENU } from '../types';
import axios from 'axios';
import { SET_CURRENT_COMPANY } from '../types';

import {
    CompanyConnection,
    MenuSettings,
    Management,
} from '../../models/Management';
import { defaultManageState, emptyManageState } from './module';

export function setManagedCompany(
    menuSettings: MenuSettings
) {
    return {
        type: CHANGE_MENU,
        menuSettings: menuSettings,
    };
}

export function defaultManaged() {
    return async (dispatch: any) => {
        localStorage.setItem(
            'managed',
            JSON.stringify(defaultManageState, null, 2)
        );
        dispatch(
            setManagedCompany(
                defaultManageState.menuSettings
            )
        );
    };
}

export function emptyManaged() {
    return async (dispatch: any) => {
        localStorage.removeItem('management');
        dispatch(
            setManagedCompany(
                emptyManageState.menuSettings
            )
        );
    };
}

export function changeManaged(
    menuSettings: MenuSettings
) {
    return async (dispatch: any) => {
        let management: Management = {
            menuSettings: menuSettings,
        };
        localStorage.setItem('management', JSON.stringify(management, null, 2));
        dispatch(setManagedCompany(menuSettings));
    };
}

function currentCompany(company: CompanyConnection, type: string) {
    return {
        type: type,
        company: company
    }
}

function setCurrentCompany(company: CompanyConnection) {
    return async (dispatch: any) => {
        localStorage.setItem(
            'company',
            JSON.stringify(company, null, 2)
        );
        dispatch(currentCompany(company, SET_CURRENT_COMPANY));
    };
}

export function changeManagement(
    menuSettings: MenuSettings
) {
    return async (dispatch: any) => {
        let manage = {
            menuSettings: menuSettings
        };
        console.log(manage);
        localStorage.setItem('management', JSON.stringify(manage, null, 2));
        let compToken = localStorage.getItem('companyToken');
        if (compToken) {
            axios.defaults.headers.common['BSD-Company'] = compToken;
        } else {
            delete axios.defaults.headers.common['BSD-Company'];
        }
        //dispatch(setCurrentCompany(managingCompany));
        dispatch(setManagedCompany(menuSettings));
    };
}
