import React, { Component } from 'react';
import { Row, Col, Modal, Spin, Table, Avatar, Popover, Button } from 'antd';
import { getTourUsers, getCriticalHotspots } from '../../api/APIget';
import { getConnectedUsers } from '../../api/JanusHostApiGet';
import { ProfileCards } from './ProfileCards';
import { getGradeStyle, getCurrentDate, getGrade, getGradeFromPoints, getGradePoints, averageTime } from '../../utils/tools';
import axios from "axios";

const token = localStorage.token;

interface State {
   tourUser: any,
   completed: any,
   inProgress: any,
   teamMates: any,
   progressUsers: any,
   completeUsers: any,
   users: any,
   showProgressModal: boolean,
   showCompleteModal: boolean,
   loading: boolean,
   criticalHotspots: any[],
   grade: string,
   failed: number,
   hasAccess: any[],
   userAccess: any[],
   showInvitedModal: boolean
};

class CourseTotals extends Component<any, State> {
    state: State = {
        tourUser: [],
        completed: [],
        inProgress: [],
        teamMates: [],
        users: [],
        progressUsers: [],
        completeUsers: [],
        showProgressModal: false,
        showCompleteModal: false,
        loading: false,
        criticalHotspots: [],
        grade: '',
        failed: 0,
        hasAccess: [],
        userAccess: [],
        showInvitedModal: false
    };

    public constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        //console.log(this.props);
        getTourUsers(this.props.course.tourId, this.getUserSuccess, this.getError);
        getConnectedUsers(this.props.course.companyId, this.getUsersSuccess, this.getError);
        getCriticalHotspots(this.props.course.id, this.getHotspotSuccess, this.getError);
    }

    getUserSuccess = (data: any) => {
        //console.log(data);
        this.setState({ tourUser: data.data });
        if (data.data[0].id) { // making sure that there is some progress. If there isn't then data.data is tourName hence length>0 will not work
            //console.log(this.state.tourUser);
            let inProgress = this.state.tourUser.filter((data: any) => { return (!data.completed) });
            let completed = this.state.tourUser.filter((data: any) => { return (data.completed) });
            let points = 0;
            let failed = 0;
            let averageGrade;
            for (let i = 0; i < completed.length; i++) {
                let grade = getGrade(completed[i], this.state.criticalHotspots);
                points = points + getGradePoints(grade);
                if (grade === 'F') {
                    failed++;
                }
            }
            //console.log(this.state.tourUser);
            
            averageGrade = getGradeFromPoints(Math.round(points / completed.length));
            this.setState({ completed: completed, inProgress: inProgress, grade: averageGrade, failed: failed });
        }
        else {
            this.setState({grade: '-'})
        }

        
    }


    getHotspotSuccess = (data: any) => {
        this.setState({ criticalHotspots: data.data });
    }

    getError = (data: any) => {
        console.log(data);
        console.log("There was a problem getting your courses");
    }


    getUsersSuccess = (data: any) => {
        let teamMates = data.data.connections.filter((user: any) => user.user.firstName !== "shared" && user.user.firstName !== "");
        this.setState({ teamMates: teamMates });
        //console.log(teamMates);
        let users = [];
        for (let i = 0; i < this.state.completed.length; i++) {
            for (let j = 0; j < this.state.teamMates.length; j++) {

                if (this.state.completed[i].userId == this.state.teamMates[j].user.id) {
                    users.push(this.state.teamMates[j].user)
                }
            }
        }
        this.setState({ completeUsers: users });
        users = [];
        for (let i = 0; i < this.state.inProgress.length; i++) {
            for (let j = 0; j < this.state.teamMates.length; j++) {

                if (this.state.inProgress[i].userId == this.state.teamMates[j].user.id) {
                    users.push(this.state.teamMates[j].user)
                }
            }
        }
        this.setState({ progressUsers: users });

        users = [];
        let publicTag = this.props.course.tags.filter((tag: any) => tag.accessLevel === "Public");
        if (publicTag.length > 0) {
            let hasAccess = []
            for (let i = 0; i < this.state.teamMates.length; i++) {
                hasAccess.push(this.state.teamMates[i].user)
            }
            this.setState({
                hasAccess: hasAccess
            })
        }
        else {
            for (let i = 0; i < this.state.teamMates.length; i++) {
                let hasAccess = this.state.hasAccess;

                axios({
                    method: 'get',
                    url: `/api/v1/vrsafety/UserAccess/getUserAccess/${this.state.teamMates[i].user.id}`,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data; boundary=---------------------------81801171514357'
                    }
                })
                .then((data) => {
                    //handle success
                    let access = this.props.course.tags.filter((tag: any) => tag.accessLevelId <= data.data.accessLevel);
                    if (access.length > 0) {
                        hasAccess.push(this.state.teamMates[i].user);
                    }
                    else {

                        axios({
                            method: 'get',
                            url: `/api/v1/vrsafety/UserAccess/getUserTagAccess/${data.data.userId}`,
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'Content-Type': 'multipart/form-data; boundary=---------------------------81801171514357'
                            }
                        })
                            .then((res) => {
                                //handle success
                                if (res.data.length > 0) {
                                    //console.log(res);
                                    let accessTag = [];
                                    for (let i = 0; i < res.data.length; i++) {
                                        accessTag.push(this.props.course.tags.filter((tag: any) => tag.id === res.data[i].tagID));
                                        //console.log(res.data[i]);
                                    }
                                    if (accessTag.length > 0) {
                                        //let hasAccess = this.state.hasAccess;
                                        hasAccess.push(this.state.teamMates[i].user);

                                    }
                                }
                            })
                            .catch(function (res) {
                                //handle error
                                console.log(res);
                            })
                    }
                        this.setState({ hasAccess: hasAccess })
                    
                })
                .catch(function (res) {
                    //handle error
                    console.log(res);
                })
            }
            //console.log(this.state.hasAccess)
        }
    }

    time = () => {
        let total = this.totalTime();
        if (total !== '00:00:00') {
            let totalAverageTime = averageTime(total, this.state.completed.length);
            return totalAverageTime;
        }
        else {
            return total;
        }
    }

    totalTime = () => {
        //console.log("here");
        //console.log(this.state);
        var seconds=0;
        var minutes=0;
        var hours=0;
        for (let i = 0; i < this.state.completed.length; i++) {
            var times = [0, 0, 0]
            var max = times.length

            var a = (this.state.completed[i].timeSpent || '').split(':')

            // normalize time values
            for (var j = 0; j < max; j++) {
                a[j] = isNaN(parseInt(a[j])) ? 0 : parseInt(a[j]);
            }


            hours = hours + a[0];
            minutes = minutes + a[1];
            seconds = seconds + a[2];

            if (seconds >= 60) {
                var m = (seconds / 60) << 0
                minutes += m
                seconds -= 60 * m
            }

            if (minutes >= 60) {
                var h = (minutes / 60) << 0
                hours += h
                minutes -= 60 * h
            }

            

        }
        if (minutes == 0 && hours == 0 && seconds == 0) {
            return "00:00:00"
        }
        else {
            return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2)
        }
    }
    

    render() {
        const style = { padding: "6px 0" };
        const content = (
            <div>
                <Row>
                    <Col span={22} className="smallText">Teammates Invited</Col>
                    <Col span={2} style={{cursor:"pointer"}} onClick={() => { this.setState({ showInvitedModal: true }) }}><h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{this.state.hasAccess.length}</h3></Col>
                </Row>
                <Row>
                    <Col span={22} className="smallText">In Progress</Col>
                    <Col span={2} style={{ cursor: "pointer" }} onClick={() => { this.setState({ showProgressModal: true })}}><h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{`${this.state.inProgress.length}`}</h3></Col>
                </Row>
                <Row>
                    <Col span={16} className="smallText">Average Duration</Col>
                    <Col span={8}><h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{this.time()}</h3></Col>
                </Row>
                <Row>
                    <Col span={22} className="smallText">Completed</Col>
                    <Col span={2} style={{ cursor: "pointer" }} onClick={() => { this.setState({ showCompleteModal: true }) }}><h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{this.state.completed.length}</h3></Col>
                </Row>
                <Row>
                    <Col span={22} className="smallText">Failed</Col>
                    <Col span={2} style={{ cursor: "pointer" }}><h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{this.state.failed}</h3></Col>
                </Row>
                <Row>
                    <Col span={22} className="smallText">Average Grade</Col>
                    <Col span={2}><h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{(this.state.grade) ? this.state.grade : '-'}</h3></Col>
                </Row>
            </div>
        );
        return (
            <Row type="flex" align="middle">
                <Popover placement="bottom" title="Course Statistics" content={content} trigger="click">
                    <Button className={this.props.layout.popover} ghost >View Statistics</Button>
                </Popover>
                {/*    <Col className="progressStatsStyle">
                    <span className="smallText">Teammates Invited</span>
                    <div onClick={() => { this.setState({ showInvitedModal: true }) }} style={{ textAlign: "center", alignContent: "baseline" }} > <h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{this.state.hasAccess.length}</h3></div>
                </Col>
                <Col className="progressStatsStyle">
                    <span className="smallText">In Progress</span>{`${this.state.inProgress.length}`}
                    <div onClick={() => { this.setState({ showProgressModal: true }); console.log(this.state.progressUsers) }} style={{ textAlign: "center", alignContent: "baseline", cursor: "pointer", fontWeight: "bold" }} > <h3 style={{ fontWeight: "bold" }} className="tealText"></h3></div>
                </Col>
                <Col className="progressStatsStyle">
                    <span className="smallText">Average Duration</span>
                    <div style={{ textAlign: "center", alignContent: "baseline" }} > <h3 style={{ fontWeight: "bold" }} className="mediumBlueText"> {this.time()}</h3></div>
                </Col>
                <Col className="progressStatsStyle">
                    <span className="smallText">Completed</span>
                    <div onClick={() => { this.setState({ showCompleteModal: true }) }} style={{ textAlign: "end", alignContent: "baseline", cursor: "pointer"}} > <h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{this.state.completed.length}</h3></div>
                </Col>
                <Col className="progressStatsStyle">
                    <span className="smallText">Failed</span>
                    <div style={{ textAlign: "end", alignContent: "baseline" }} > <h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{this.state.failed}</h3></div>
                </Col>
                <Col>
                    <span className="smallText">Average Grade</span>
                    <Row type="flex" align="middle" justify="end">
                        <h3 style={{ fontWeight: "bold" }} className={getGradeStyle((this.state.grade) ? this.state.grade : '-')}>{(this.state.grade)?this.state.grade: '-'}</h3>
                    </Row>
                </Col>
                */}
                <Modal
                    title="List of Participants"
                    visible={this.state.showCompleteModal}
                    onOk={() => { this.setState({ showCompleteModal: false }) }}
                    onCancel={() => { this.setState({ showCompleteModal: false }) }}>
                    <div>
                        <Row style={style}>
                            
                            <Spin spinning={this.state.loading}>
                                {
                                    this.state.completeUsers.map((value: any, index: number) =>
                                        <Row key={index}><ProfileCards teamMate={value} /></Row>
                                    )
                                }
                            </Spin>
                            
                        </Row>
                    </div>
                </Modal>


                <Modal
                    title="List of Participants"
                    visible={this.state.showProgressModal}
                    onOk={() => { this.setState({ showProgressModal: false }) }}
                    onCancel={() => { this.setState({ showProgressModal: false }) }}>
                    <div>
                        <Row style={style}>

                            <Spin spinning={this.state.loading}>
                                {
                                    this.state.progressUsers.map((value: any, index: number) =>
                                        <Row key={index}><ProfileCards teamMate={value} /></Row>
                                    )
                                }
                            </Spin>

                        </Row>
                    </div>
                </Modal>

                <Modal
                    title="List of Participants"
                    visible={this.state.showInvitedModal}
                    onOk={() => { this.setState({ showInvitedModal: false }) }}
                    onCancel={() => { this.setState({ showInvitedModal: false }) }}>
                    <div>
                        <Row style={{border: "1px solid #ccc"}}>
                            <h3 style={{ background: "#ddd", padding: "15px" }}>Team Profiles</h3>
                            <div style={{ height: "400px", padding: "20px", overflowY: "auto", display: "flex", flexWrap: "nowrap", flexDirection: "column"  }}>
                            <Spin spinning={this.state.loading}>
                                {
                                    this.state.hasAccess.map((value: any, index: number) =>
                                        <Row key={index}><ProfileCards teamMate={value} /></Row>
                                    )
                                }
                            </Spin>
                            </div>
                        </Row>
                    </div>
                </Modal>
            </Row>

             
        
    );

    }
}
export default CourseTotals;