import * as React from 'react';
import { Button, Avatar, Progress, Row, Col, Card, Input, Popover, Icon } from 'antd';
import { getDateDMY, getGradeStyle, getTotalhotspotIds } from "../../utils/tools";
import tour_icon from '../../assets/Icons/VRS_asset_360globe_icon.png'

const { Search } = Input;
function CompletedCourse(props: any) {
    const content = (
        <div>
            <Row>
                <Col span={15}>Started At</Col>
                <Col span={9} ><h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{getDateDMY(props.tour.tourProgress.startedAt)}</h3></Col>
            </Row>
            <Row>
                <Col span={15}>Completed At</Col>
                <Col span={9} ><h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{getDateDMY(props.tour.tourProgress.completedAt)}</h3></Col>
            </Row>
            <Row>
                <Col span={22}>Visited Scenes</Col>
                <Col span={2} ><h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{`${props.tour.tourProgress.visitedPanoIds.length}`}</h3></Col>
            </Row>
            <Row>
                <Col span={22}>Total Scenes</Col>
                <Col span={2} ><h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{`${props.tour.tourProgress.totalPano}`}</h3></Col>
            </Row>
            <Row>
                <Col span={18}>Time Spent</Col>
                <Col span={6}><h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{props.tour.tourProgress.timeSpent}</h3></Col>
            </Row>
            <Row>
                <Col span={22}>Visited Hotspots</Col>
                <Col span={2}><h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{props.tour.tourProgress.visitedhotspots.length}</h3></Col>
            </Row>
            <Row>
                <Col span={22}>Total Hotspots</Col>
                <Col span={2}><h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{props.tour.tourProgress.totalhotspot}</h3></Col>
            </Row>
        </div>
    );
    var fontColor, bgColor, aColor, popover, buttons;
    switch (props.layout) {
        case "VRSafety":
            fontColor = "";
            bgColor = "whiteBG";
            aColor = "darkBlueText";
            popover = "managerPopOverButton";
            buttons = "teamCardButtons";
            break;
        case "RealX":
            fontColor = "greyText";
            bgColor = "blackBG";
            aColor = "whiteText pinkHover";
            popover = "managerPopOverButtonRealXPink";
            buttons = "teamCardButtonsRealXPink";
            break;
        default:
            fontColor = "";
            bgColor = "whiteBG";
            popover = "managerPopOverButton";
            buttons = "teamCardButtons";
            aColor = "darkBlueText";
            break;
    }
    return (
        <Card
            className={bgColor}
            style={{ minWidth: "250px", maxWidth: "350px" }}
            size="small"
            cover={<div style={{ padding: "10px" }}>
                <Row>
                    <Row type="flex" align="middle" justify="space-between">
                        <Col>{
                            (props.tour.tourInfo.tourIcon !== "" && props.tour.tourInfo.tourIcon !== null) ?
                                <Col span={8}>
                                    <a href={`/details/${props.tour.tourInfo.id}`}><Avatar shape="square" size={86} src={"https://janustourbuilder.s3.ca-central-1.amazonaws.com/" + props.tour.tourInfo.tourIcon} /></a>
                                </Col>
                                :
                                <Col span={8}>
                                    <a href={`/details/${props.tour.tourInfo.id}`}><Avatar shape="square" size={86} src={tour_icon} /></a>
                                </Col>
                        }</Col>
                        <Col span={14} style={{ marginTop: "10px", fontSize: "12px" }}>
                            <div>
                                <a href={`/details/${props.tour.tourInfo.id}`}>
                                    <h3 style={{ marginBottom: "0", fontWeight: 600 }} className={aColor}>
                                        {props.tour.tourInfo.tourName}
                                    </h3>
                                </a>
                                <div className={fontColor}>{props.company}</div>
                            </div>
                            <div style={{ textAlign: "center", marginTop: "5px", marginBottom: "10px" }}>
                                <span className={fontColor}>{`${Math.floor((getTotalhotspotIds(props.tour.tourProgress.visitedhotspots) / props.tour.tourProgress.totalhotspot) * 100 || 0)}% complete`}</span>
                                <Progress strokeColor={"#FA8C16"} showInfo={false} percent={((getTotalhotspotIds(props.tour.tourProgress.visitedhotspots) / props.tour.tourProgress.totalhotspot) * 100)} />
                            </div>

                            <Popover placement="bottom" title="Course Statistics" content={content} trigger="click">
                                <Button className={popover} ghost >View Statistics</Button>
                            </Popover>
                        </Col>
                    </Row>

                </Row>
            </div>}
            actions={[
                <span className={buttons} onClick={() => props.restart(props.tour)}><Icon type="reload" style={{ marginRight: "10px" }} />Restart</span>,
            ]}

        >
        </Card>
    );
}
export function CourseCompleted(props: any) {
    var fontColor, bgColor;
    switch (props.layout) {
        case "VRSafety":
            fontColor = "orangeText bebas";
            bgColor = "whiteBG";
            break;
        case "RealX":
            fontColor = "whiteText bebas";
            bgColor = "blackBG";
            break;
        default:
            fontColor = "orangeText bebas";
            bgColor = "whiteBG";
            break;
    }
    return (
        <div style={{ minHeight: "200px", padding: "14px" }} className={bgColor}>
            <Row style={{ marginBottom: "20px" }} type="flex" align="middle" justify="space-between">
                <Col sm={12} md={4}><h2 style={{ fontWeight: "bold", fontSize: "1.5em", marginBottom: "0px" }} className={fontColor}>Courses Completed</h2></Col>
                <Col xs={24} sm={24} md={4}>
                    <Search
                        placeholder="Search"
                        onSearch={props.onSearch}
                    />
                </Col>
            </Row>
            {
                (props.completed.length > 0) ?
                    <Row type="flex" style={{ overflowX: "auto", display: "flex", flexWrap: "nowrap" }}>
                        {
                            props.completed.map((value: any, index: number) =>
                                <Col key={index} xs={24} sm={24} md={6} style={{ padding: "10px" }} ><CompletedCourse tour={value} company={props.company} layout={ props.layout}/></Col>
                            )
                        }
                    </Row>
                    : null
            }
        </div>
    );
}
