import * as React from 'react';
import { Row, Col, Input} from 'antd';
import tour_icon from '../../assets/Icons/VRS_asset_360globe_icon.png';

const { Search } = Input;
function CourseCard(props: any) {
    return (
        <Row className={props.layout.cardButtonsSecondary}> 
            <Row>
            {
            (props.tour.tourIcon!== "")?
            <img src={props.tour.tourIcon} className="browseImg" alt="Course"></img>
            :
            <img src={tour_icon} className="browseImg" alt="Course"></img>
            }
            </Row>
            <Row style={{ padding: "20px", height: "85px" }}>
                <a  href={`/details/${props.tour.id}`}>
                    <p style={{ margin: 0, padding: 0 }} className={`Noto ${props.layout.secondaryColor}`} >{props.tour.tourName}</p>
                </a>
                <small className={`Noto ${props.layout.pColor}`} >{props.company}</small>
            </Row>
        </Row>
    );
}
export function BrowseCourses(props: any) {
    return (
        <div className={props.bgColor}>
            <div className={props.layout.border}>
              <Row style={{padding:"10px"}} type="flex" justify="space-between">
                <Col span={6}><h1 style={{fontSize:"1.8em"}} className={`${props.titleColor} bebas `}>{props.title}</h1></Col>
                <Col span={4}>
                    <Search
                    placeholder="Search"
                    onSearch={props.onSearch}
                    />
                </Col>
              </Row>
                <Row style={{ overflowX: "auto", display: "flex", flexWrap: "nowrap" }}>
                {
                  props.available.map((value:any,index:number)=>
                    <div style={{padding:"10px"}} key={index}><CourseCard  tour={value} company={props.company} layout={props.layout}/></div>
                  )
                }
                </Row>
                </div> 
        </div>
    );
}