import React, { Component} from 'react';
import {Form,Input,Select,Button, Slider, InputNumber, Row, Col, Checkbox, Modal,Upload, Icon, message, Radio, Avatar} from 'antd';
import "../../../app/App.css";
import { deleteFile } from '../../../api/APIpost';
import Speaker_Icon from '../../../assets/Icons/Speaker_Icon.png';
import Video_Small from '../../../assets/Icons/Video_Small.png';
import axios from "axios";
import { AWS_URL } from '../../../app/FrontEndVariables';

const { confirm } = Modal;
const { Option } = Select;
const { TextArea } = Input;

const options = [
    { label: 'Small', value: 'small' },
    { label: 'Medium', value: 'medium' },
    { label: 'Large', value: 'large' },
];

const TextSizes = [
    { label: 'Small', value: '10' },
    { label: 'Medium', value: '12' },
    { label: 'Large', value: '14' },
];

const TextAlignOptions = [
  { label: 'Left', value: 'left' },
  { label: 'Right', value: 'right' },
  { label: 'Center', value: 'center' },
];

const TextColorOptions = [
    { label: 'White', value: 'white' },
    { label: 'Black', value: 'black' }
];

const TextBGOptions = [
    { label: 'White', value: 'white' },
    { label: 'Black', value: 'black' },
    { label: 'Transparent', value: 'transparent' }
];

const transOptions = [
    { label: 'None', value: 'none' },
    { label: '0.25', value: 'twentyFive' },
    { label: '0.50', value: 'fifty' },
];

const IconColorOptions = [
  { label: 'White', value: 'white' },
  { label: 'Black', value: 'black' },
];

const SPECIAL_CHAR = [';'];

const token = localStorage.token;

interface Props {
  visible:boolean;
  selected:any;
  otherPanos:any[];
  assignInfo:(object:any)=>void;
  updateLibrary:(object:any)=>void;
  removeFile:(object:any)=>void;
  onCancel:()=>void;
  currentPano:string;
  resources:any[];
  library: any[];
  tourId:string;
  companyId:string;
  hotspots:[];
  states:[],
  icons:any
}
interface MessagePages{
  pageContent:string,
  pageImagePath:string,
  pageImageFileName:string,
  type:string,
  errors:{
    errorMsg:string,
  }

}
interface AnswerData {
    textAnswer: string,
    imageAnswer: string,
    targetPano: number,
    targetState: string,
    type: string,
    errors: {
        errorMsg: string,
    }

}
type messageIndex = {
  pageContent:string,
  pageImagePath:string,
  type:string,
}
interface errors
{
  ImageMessageData?:{errorMsg:string}
  SphereVideoData?:{errorMsg:string}
  VideoData?:{errorMsg:string}
  Message?:{errorMsg:string}
  Answer?:{errorMsg:string}
  AudioData?:{errorMsg:string}
  PageTitle?:{errorMsg:string}
  Question?:{errorMsg:string}
  [key: string]: any
}

interface State {
  uploading:boolean,
  TargetUID: number, //used for exit pano
  ParentUID: number,
  type: string,
  PageTitle:string,
  Question:string,
  QuestionImage:string,
  MessagePages:MessagePages[],
  AnswerData:AnswerData[],
  ImagePath:string,
  Message:string,
  VideoPath:string,
  AudioFile:string,
  AutoPlay:boolean,
  EndOfTour:boolean,
  Loop:boolean,
  Volume:number,
  loading:boolean,
  fileList:any,
  types:any[],
  typesFirstPano:any[],
  selectedPageType:string,
  pageTypes:any[],
  answerTypes:any[],
  newVersion:boolean,
  value: string,
  transValue: string,
  ImageSize: string,
  TextSize: string,
  TextAlign: string,
  TextColor: string,
  TextBG: string,
  ImageTrans: string,
  hasErrors:boolean,
  imageLib: boolean,
  videoLib: boolean,
  audioLib: boolean,
  cameraPosition: boolean,
  IconColor:string
};

class AssignmentForm extends Component<Props,State> {
  state = {
    uploading:false,
    TargetUID: 0, //used for exit pano
    ParentUID: 0,
    type: "",
    PageTitle:"",
    Question:"",
    QuestionImage:"",
    MessagePages:[
      {
        pageContent:"",
        pageImagePath:"",
        pageImageFileName:"",
        type:"",
        errors:{
          errorMsg:""
        }
      }
    ],
    AnswerData:[
      {
        textAnswer:"",
        imageAnswer:"",
        targetPano:0,
        targetState:"",
        type:"",
        errors:{
          errorMsg:""
        }
      }
    ],
    ImagePath:"",
    Message:"",
    VideoPath:"",
    AudioFile:"",
    AutoPlay:false,
    EndOfTour:false,
    Loop:false,
    Volume:5,
    loading:false,
    fileList:[],
    types:[
        {name:"Image Hotspot",value:'VirtualTourNetwork.ImageMessageData'},
        {name:"Link to Next Hotspot",value:'VirtualTourNetwork.HotspotLinkData'},
        {name:"360 Video Hotspot",value:'VirtualTourNetwork.SphereVideoData'},
        {name:"Video Hotspot",value:'VirtualTourNetwork.VideoData'},
        {name:"Text Hotspot",value:'VirtualTourNetwork.MessageData'},
        {name:"Audio Hotspot",value:'VirtualTourNetwork.AudioData'},
        {name:"Pagination Hotspot",value:'VirtualTourNetwork.PageMessageData'},
        {name: "Complete Tour Hotspot", value: "VirtualTourNetwork.HotspotCompleteData" },
        {name:"Branching Hotspot",value:"VirtualTourNetwork.BranchingData"},
        {name:"Caption Hotspot",value:"VirtualTourNetwork.CaptionData"}
    ],
    typesFirstPano: [
        { name: "Image Hotspot", value: 'VirtualTourNetwork.ImageMessageData' },
        { name: "Link to Next Hotspot", value: 'VirtualTourNetwork.HotspotLinkData' },
        { name: "360 Video Hotspot", value: 'VirtualTourNetwork.SphereVideoData' },
        { name: "Video Hotspot", value: 'VirtualTourNetwork.VideoData' },
        { name: "Text Hotspot", value: 'VirtualTourNetwork.MessageData' },
        { name: "Audio Hotspot", value: 'VirtualTourNetwork.AudioData' },
        { name: "Pagination Hotspot", value: 'VirtualTourNetwork.PageMessageData' },
        { name: "Complete Tour Hotspot", value: "VirtualTourNetwork.HotspotCompleteData" },
        { name: "Camera Position", value: "VirtualTourNetwork.CameraPositionData" },
        { name: "Branching Hotspot", value: "VirtualTourNetwork.BranchingData" },
        { name: "Caption Hotspot", value: "VirtualTourNetwork.CaptionData" }
    ],
    selectedPageType:'',
    pageTypes:[
      {name:"Text Page", value:"VirtualTourNetwork.TextPageData"},
      {name:"Image Page", value:"VirtualTourNetwork.ImagePageData"},
      {name:"Image and Text Page", value:"VirtualTourNetwork.BothPageData"}
    ],
    answerTypes:[
      {name:"Text Page", value:"VirtualTourNetwork.TextPageData"},
      {name:"Image Page", value:"VirtualTourNetwork.ImagePageData"},
      {name:"Image and Text Page", value:"VirtualTourNetwork.BothPageData"}
    ],
    newVersion:false,
    deletePrevFile:false,
    value: "",
    transValue: "",
    ImageSize: "",
    TextSize: "12",
    TextAlign: "left",
    TextColor: "black",
    TextBG: "transparent",
    ImageTrans: "",
    hasErrors:false,
    errors:{
      ImageMessageData:{errorMsg:""},
      SphereVideoData:{errorMsg:""},
      VideoData:{errorMsg:""},
      Message:{errorMsg:""},
      Answer:{errorMsg:""},
      AudioData:{errorMsg:""},
      PageTitle:{errorMsg:""},
      Question:{errorMsg:""}
    },
    imageLib: true,
    videoLib: true,
    audioLib: true,
    cameraPosition: false,
    IconColor:'',
  };
  componentDidMount()
  {
    console.log(this.props.selected);
    this.setState({
      type:this.props.selected.type,ImagePath:this.props.selected.imagePath,Message:this.props.selected.message,
      VideoPath:this.props.selected.videoPath,AudioFile:this.props.selected.audioFile,TargetUID:this.props.selected.targetUID,
      PageTitle: this.props.selected.pageTitle, MessagePages: this.props.selected.messagePages,
      Question: this.props.selected.question, AnswerData: this.props.selected.answers,
    });
  }
  componentDidUpdate(prevProps:any) {
    // Typical usage (don't forget to compare props):
    if (this.props.selected !== prevProps.selected) {
      console.log(this.props.selected);
      this.setState({
        type:this.props.selected.type,
        ImagePath:this.props.selected.imagePath,
        ImageSize: this.props.selected.imageSize,
        TextSize: this.props.selected.textSize,
        TextAlign: this.props.selected.textAlign,
        TextColor: this.props.selected.textColor,
        TextBG: this.props.selected.textBG,
        value: this.props.selected.imageSize,
        ImageTrans: this.props.selected.imageTrans,
        transValue: this.props.selected.imageTrans,
        Message:this.props.selected.message,
        VideoPath:this.props.selected.videoPath,
        AudioFile:this.props.selected.audioFile,
        TargetUID:this.props.selected.targetUID,
        PageTitle:this.props.selected.pageTitle,
        Question:this.props.selected.question,
        QuestionImage:this.props.selected.questionImage,
        AnswerData: this.props.selected.answers,
        AutoPlay:this.props.selected.autoPlay,
        Loop:this.props.selected.loop,
        IconColor:this.props.selected.iconColor,
        Volume:5
      });
        if(this.props.selected.messagePages==null)
        {
          this.setState({MessagePages:[
            {
              pageContent:"",
              pageImagePath:"",
              pageImageFileName:"",
              type:"",
              errors:{
                errorMsg:""
              }
            }
          ]});
        }
        else
        {
          let newMessagePages = this.props.selected.messagePages;
          newMessagePages.map((obj:any)=>{obj['errors']={errorMsg:""}})
          this.setState({MessagePages:newMessagePages});
        }


        if (this.props.selected.answers == null) {
            this.setState({
                AnswerData: [
                    {
                        textAnswer: "",
                        imageAnswer: "",
                        targetPano: 0,
                        targetState: "",
                        type: "",
                        errors: {
                            errorMsg: ""
                        }
                    }
                ]
            });
        }
        else {
            let newAnswerData = this.props.selected.answers;
            newAnswerData.map((obj: any) => { obj['errors'] = { errorMsg: "" } })
            this.setState({ AnswerData: newAnswerData });
        }

      }
  }
  success=(data:any)=>
  {
    console.log("success");
  }
  error =(data:any)=>
  {
    message.error("There was a problem with changing the Image");
      this.setState({ uploading: false });
    console.log(data);
  }
  saveOrder = async () =>
  {
    console.log(this.state.type);
        if(this.state.type===null||this.state.type===''){
            message.error("Empty hotspots cannot be assigned");
        }
        else if(this.state.type==='VirtualTourNetwork.BranchingData'){
            if(this.state.AnswerData.length<2)  
                message.error("Add at least 2 answers to your branching hotspot");
            else if((this.state.AnswerData.filter((page:any)=>page.type===""||page.targetState===""||(page.textAnswer===""&&page.imageAnswer==="")).length>0))
                message.error("Answers in branching hotspot cannot be left empty or without target state");
            else
                await this.sendData();
        }
        else if(this.state.type==='VirtualTourNetwork.PageMessageData'){
            if((this.state.MessagePages.filter((page:any)=>page.type===""||(page.pageImageFileName===""&&page.pageContent==="")).length>0))
                message.error("Message pages on pagination hotspot cannot be left empty!");
            else
                await this.sendData();
        }
        else if(this.state.type==='VirtualTourNetwork.MessageData'){
            if(this.state.Message===null||this.state.Message==='')
                message.error("Message cannot be left empty for text hotspots");
            else
                await this.sendData();
        }
        else if(this.state.type==='VirtualTourNetwork.ImageMessageData'){
            if(this.state.ImagePath===null||this.state.ImagePath==='')
                message.error("Image file needs to be selected");
            else
                await this.sendData();
        }
        else if(this.state.type==='VirtualTourNetwork.VideoData'){
            if(this.state.VideoPath===null||this.state.VideoPath==='')
                message.error("Video file needs to be selected");
            else
                await this.sendData();
        }
        else if(this.state.type==='VirtualTourNetwork.AudioData'){
            if(this.state.AudioFile===null||this.state.AudioFile==='')
                message.error("Audio file needs to be selected");
            else
                await this.sendData();
        }
        else if(this.state.type==='VirtualTourNetwork.SphereVideoData'){
            if(this.state.VideoPath===null||this.state.VideoPath==='')
                message.error("Video file needs to be selected");
            else
                await this.sendData();
        }
        else if (this.state.type === 'VirtualTourNetwork.CaptionData') {
            if (this.state.Message === null || this.state.Message === '')
                message.error("Message cannot be left empty for text hotspots");
            else
                await this.sendData();
        }
        else
        {
            await this.sendData();
        }
  }

  sendData=async()=>{
    let info ={
      selected:this.props.selected,
      type: this.state.type,
      ImagePath: this.state.ImagePath,
      ImageSize: this.state.value,
      TextSize: this.state.TextSize,
      TextAlign: this.state.TextAlign,
      TextColor: this.state.TextColor,
      TextBG: this.state.TextBG,
      ImageTrans: this.state.transValue,
      Message: this.state.Message,
      VideoPath: this.state.VideoPath,
      AudioFile: this.state.AudioFile,
      AutoPlay: this.state.AutoPlay,
      Loop: this.state.Loop,
      Volume: this.state.Volume,
      PageTitle:this.state.PageTitle,
      MessagePages:this.state.MessagePages,
      Question:this.state.Question,
      QuestionImage:this.state.QuestionImage,
      AnswerData:this.state.AnswerData,
      TargetUID: this.state.TargetUID,
      deletePrevFile:this.state.deletePrevFile,
      IconColor:this.state.IconColor,
    }
    console.log(info);
    await this.props.assignInfo(info);
    this.setState({
        type: '', ImagePath: '', Message: '', ImageSize: '', value: '', transValue: '', ImageTrans: '', TextSize: '12',
        VideoPath: '', AudioFile: '', TargetUID: 0, TextAlign: 'left', IconColor: '', TextBG: "transparent",TextColor: "black",
        PageTitle: '', MessagePages: [{ pageContent: "", pageImagePath: "", pageImageFileName: "", type: "", errors: { errorMsg: "" } }],
        Question: '', QuestionImage: '', AnswerData: [{ textAnswer: "", imageAnswer: "", targetPano: 0, targetState: "", type: "", errors: { errorMsg: "" } }]
        , fileList: [], Loop: false,
        Volume: 5,
    });
  }
 
  handleChange = (input: string) => (e: any) => 
  {
      let errorMsg = "";
      SPECIAL_CHAR.some(c => {
          if (e.target.value.includes(c))
              errorMsg += `The character ${c} is not allowed. `
          return e.target.value.includes(c)
      });
      let errorObj: errors = this.state.errors;
      errorObj[input].errorMsg = errorMsg;
      this.setState({ [input]: e.target.value, errors: errorObj, hasErrors: false } as any,);
      let messagePageErrors = this.state.MessagePages.filter((page: any) => { return page.errors.errorMsg.length > 0 })
      let answerDataErrors = this.state.AnswerData.filter((page: any) => { return page.errors.errorMsg.length > 0 })
      if (errorMsg.length > 0 || messagePageErrors.length > 0 || answerDataErrors.length > 0)
      {
          this.setState({ hasErrors: true });
      }

  }
  handleNoValidationChange= (input: string) => (e: any) =>
  {
    this.setState({ [input]: e.target.value} as any);
  }
  
 /**
   * Handles the changes when uploading files
   */
  handleImageChange = (fileList:any) => 
  {
    //console.log(fileList);
    //console.log(fileList.file);
    //console.log(fileList.file.status);
    let response = fileList.file;
    if(fileList.file.status === "error"){
      //console.log(fileList.file.response);
      this.setState({uploading:false});
    }
    if (fileList.file.status === 'uploading') {
      this.setState({ uploading: true });
      return;
    }
    if(response.hasOwnProperty('xhr')){
      //console.log(this.state.ImagePath !== null);
      //console.log(this.state.ImagePath !== undefined);
      if(!(this.state.ImagePath === null || this.state.ImagePath === undefined) && response.response !== this.state.ImagePath)
      {
        let filename = this.state.ImagePath.substring(this.state.ImagePath.lastIndexOf('/')+1);
        deleteFile(filename,this.success,this.error);
      }
    // Get this url from response in real world.
    // let fileList = this.state.fileList
    //console.log(this.state.fileList);
    let newFile = response;
    //console.log(newFile);
    // newFile["url"]=response.xhr.responseText;
    // fileList.push(newFile);
    this.setState({ImagePath:response.response,uploading:false});
    }
    //console.log(this.state.fileList);
  };
  handleVideoChange = (fileList:any) => 
  {
    console.log(fileList);
    console.log(fileList.file);
    console.log(fileList.file.status);
    let response = fileList.file;
    if(fileList.file.status === "error"){
      console.log(fileList.file.response);
      this.setState({uploading:false});
    }
    if (fileList.file.status === 'uploading') {
      this.setState({ uploading: true });
      return;
    }
    if(response.hasOwnProperty('xhr')){
      console.log(this.state.VideoPath !== null);
      console.log(this.state.VideoPath !== undefined);
      if(!(this.state.VideoPath === null || this.state.VideoPath === undefined) && response.response !== this.state.VideoPath)
      {
        let filename = this.state.VideoPath.substring(this.state.VideoPath.lastIndexOf('/')+1);
        deleteFile(filename,this.success,this.error);
      }
    // Get this url from response in real world.
    // let fileList = this.state.fileList
    console.log(this.state.fileList);
    let newFile = response;
    console.log(newFile);
    // newFile["url"]=response.xhr.responseText;
    // fileList.push(newFile);
    this.setState({VideoPath:response.response,uploading:false});
    }
    console.log(this.state.fileList);
  };
  /**
   * Handles the changes when uploading files
   */
  handleAudioChange = (fileList:any) => 
  {
    console.log(fileList);
    console.log(fileList.file);
    console.log(fileList.file.status);
    let response = fileList.file;
    if(fileList.file.status === "error"){
      console.log(fileList.file.response);
      this.setState({uploading:false});
    }
    if (fileList.file.status === 'uploading') {
      this.setState({ uploading: true });
      return;
    }
    if(response.hasOwnProperty('xhr')){
      console.log(this.state.AudioFile !== null);
      console.log(this.state.AudioFile !== undefined);
      if(!(this.state.AudioFile === null || this.state.AudioFile === undefined) && response.response !== this.state.AudioFile)
      {
        let filename = this.state.AudioFile.substring(this.state.AudioFile.lastIndexOf('/')+1);
        deleteFile(filename,this.success,this.error);
      }
    // Get this url from response in real world.
    // let fileList = this.state.fileList
    console.log(this.state.fileList);
    let newFile = response;
    console.log(newFile);
    // newFile["url"]=response.xhr.responseText;
    // fileList.push(newFile);
    this.setState({AudioFile:response.response,uploading:false});
    }
    console.log(this.state.fileList);
  };
  /***
   * Custom Upload request for shared resources
   */
  upload=(input:any)=>(options:any)=>
  {
    console.log(options);
    var bodyFormData = new FormData();
    bodyFormData.append("file",options.file);
    bodyFormData.append("tourID",this.props.tourId);
    bodyFormData.append("companyID",this.props.companyId);
    bodyFormData.append("newVersion",this.state.newVersion.toString());
    axios({
      method: 'post',
      url: '/api/v1/vrsafety/File/largefileupload',
      data: bodyFormData,
      headers: { 
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data; boundary=---------------------------81801171514357' }
      })
      .then((response:any)=> {
          //handle success
          console.log()
          console.log(response);
          if(input.substring(0,input.indexOf(","))==="MessagePages")
          {
            let newMessagePages = this.state.MessagePages;
            newMessagePages[parseInt(input.substring(input.indexOf(",")+1),10)].pageImagePath=response.data.url;
            newMessagePages[parseInt(input.substring(input.indexOf(",")+1),10)].pageImageFileName=response.data.name;
            this.setState({MessagePages:newMessagePages,uploading:false,newVersion:false});
            console.log(newMessagePages);
            let file = response.data.url.substring(response.data.url.lastIndexOf(".com/") + 5);
            this.props.updateLibrary(file);
          }
          else if(input.substring(0, input.indexOf(",")) === "AnswerData")
          {
              let newAnswers = this.state.AnswerData;
              newAnswers[parseInt(input.substring(input.indexOf(",") + 1), 10)].imageAnswer = response.data.url;
              this.setState({ AnswerData: newAnswers, uploading: false, newVersion: false });
              console.log(newAnswers);
              let file = response.data.url.substring(response.data.url.lastIndexOf(".com/") + 5);
              this.props.updateLibrary(file);
          }
          else
          {
              this.setState({ [input]: response.data.url, uploading: false, newVersion: false } as any);
              console.log(response);
              let file = response.data.url.substring(response.data.url.lastIndexOf(".com/") + 5);
              this.props.updateLibrary(file);
          }
      })
      .catch( (response:any) => {
          //handle error
          console.log(response);
          this.setState({uploading:false});
          message.error("There was a problem uploading your file. Maybe the file format is not supported");
      })
  }
  handleBasicChange = (input: any) => (e: any) => 
  {
    console.log(e);
    this.setState({ [input]: e } as any);
  }
  /**
   *  check how many 
   * @param number number
   */
    validateCharacters(currentContent: string, number: number) {
        console.log(this.state.errors.PageTitle.errorMsg.length === 0)
        if (this.state.errors.PageTitle.errorMsg.length===0)
        {
            this.setState(()=>{return{hasErrors:false}});
        }
    let error ="";
    SPECIAL_CHAR.some(c=>{
      if(currentContent.includes(c))
        error+=`The character ${c} is not allowed. `
      return currentContent.includes(c)
    });

    if(error.length>0)
    {
      this.setState({hasErrors:true});
    }
    
    if (number < 480) {
      error +=""
      return {
        errorMsg: error,
      };
    }
    else if (number >= 480 && number < 500 )
    {
      error+=`There are only ${(500-number)} characters remaining. Add another text page if more room is needed. `
      return {
        errorMsg: error,
      };
    }
    this.setState({hasErrors:true});
    error+=`There are ${(500-number)} characters remaining. Add another text page. `
    return {
      errorMsg: error,
    };
  }
  handleArrayChange = (index:number) => (e: any) => 
  {
    let messageUpdate = this.state.MessagePages;
    messageUpdate[index].pageContent= e.target.value;
    messageUpdate[index].errors = this.validateCharacters(messageUpdate[index].pageContent,messageUpdate[index].pageContent.length);
    this.setState({ MessagePages:messageUpdate});
  }

  handleAnswerArrayChange = (index:number) => (e: any) => 
  {
    let answerUpdate = this.state.AnswerData;
    answerUpdate[index].textAnswer= e.target.value;
    answerUpdate[index].errors = this.validateCharacters(answerUpdate[index].textAnswer,answerUpdate[index].textAnswer.length);
    this.setState({ AnswerData:answerUpdate});
  }

  handlePaginationImageChange = (index:number)=> (fileList:any) => 
  {
    console.log(fileList);
    console.log(fileList.file);
    console.log(fileList.file.status);
    let response = fileList.file;
    if(fileList.file.status === "error"){
      console.log(fileList.file.response);
      this.setState({uploading:false});
    }
    if (fileList.file.status === 'uploading') {
      this.setState({ uploading: true });
      return;
    }
    if(response.hasOwnProperty('xhr')){ 
      let messageUpdate = this.state.MessagePages;
      console.log(messageUpdate[index].pageImagePath === null );
      console.log(messageUpdate[index].pageImagePath === undefined);
      console.log(messageUpdate[index].pageImagePath === "");
      if(!(messageUpdate[index].pageImagePath === null || messageUpdate[index].pageImagePath === undefined || messageUpdate[index].pageImagePath === "") && response.response !== messageUpdate[index].pageImagePath)
      {
        let filename = messageUpdate[index].pageImagePath.substring(messageUpdate[index].pageImagePath.lastIndexOf('/')+1);
        console.log(filename);
        deleteFile(filename,this.success,this.error);
      }
    // Get this url from response in real world.
    // let fileList = this.state.fileList
    console.log(this.state.fileList);
    let newFile = response;
    console.log(newFile);
    // newFile["url"]=response.xhr.responseText;
    // fileList.push(newFile);
    messageUpdate[index].pageImagePath = response.response;
    messageUpdate[index].pageImageFileName=response.response.substring(response.response.lastIndexOf('/')+1);
    this.setState({ MessagePages:messageUpdate, uploading:false});
    }
    console.log(this.state.fileList);
  };



  handleBranchingImageChange = (index:number)=> (fileList:any) => 
  {
    console.log(fileList);
    console.log(fileList.file);
    console.log(fileList.file.status);
    let response = fileList.file;
    if(fileList.file.status === "error"){
      console.log(fileList.file.response);
      this.setState({uploading:false});
    }
    if (fileList.file.status === 'uploading') {
      this.setState({ uploading: true });
      return;
    }
    if(response.hasOwnProperty('xhr')){ 
      let answerUpdate = this.state.AnswerData;
      if(!(answerUpdate[index].imageAnswer === null || answerUpdate[index].imageAnswer === undefined || answerUpdate[index].imageAnswer === "") && response.response !== answerUpdate[index].imageAnswer)
      {
        let filename = answerUpdate[index].imageAnswer.substring(answerUpdate[index].imageAnswer.lastIndexOf('/')+1);
        console.log(filename);
        deleteFile(filename,this.success,this.error);
      }
    // Get this url from response in real world.
    // let fileList = this.state.fileList
    console.log(this.state.fileList);
    let newFile = response;
    console.log(newFile);
    // newFile["url"]=response.xhr.responseText;
    // fileList.push(newFile);
    answerUpdate[index].imageAnswer = response.response;
    this.setState({ AnswerData:answerUpdate, uploading:false});
    }
    console.log(this.state.fileList);
    };

  handleQuestionImageChange =  (fileList:any) => 
  {
    console.log(fileList);
    console.log(fileList.file);
    console.log(fileList.file.status);
    let response = fileList.file;
    if(fileList.file.status === "error"){
      console.log(fileList.file.response);
      this.setState({uploading:false});
    }
    if (fileList.file.status === 'uploading') {
      this.setState({ uploading: true });
      return;
    }
    if(response.hasOwnProperty('xhr')){ 
    console.log(this.state.fileList);
    let newFile = response.response;
    this.setState({ QuestionImage:newFile, uploading:false});
    }
    console.log(this.state.fileList);
  };



  removePage = (index:number)=>
  {
    console.log(this.state.MessagePages);
    let messageUpdate = this.state.MessagePages;
    console.log(messageUpdate);
    let removed=messageUpdate.splice(index,1);
    console.log(removed);
    if(!(removed[0].pageImagePath === null || removed[0].pageImagePath === undefined || removed[0].pageImagePath === "")){
      let filename = removed[0].pageImagePath.substring(removed[0].pageImagePath.lastIndexOf('/')+1);
      console.log(filename);
      deleteFile(filename,this.success,this.error);
    }
    console.log(messageUpdate);
    this.setState({ MessagePages:messageUpdate});
  }


  removeAnswer = (index:number)=>
  {
    console.log(this.state.AnswerData);
    let answerUpdate = this.state.AnswerData;
    console.log(answerUpdate);
    let removed=answerUpdate.splice(index,1);
      console.log(removed);
      if (!(removed[0].imageAnswer === null || removed[0].imageAnswer === undefined || removed[0].imageAnswer === "")) {
      let filename = removed[0].imageAnswer.substring(removed[0].imageAnswer.lastIndexOf('/')+1);
      console.log(filename);
      //deleteFile(filename,this.success,this.error);
    }
    console.log(answerUpdate);
    this.setState({ AnswerData:answerUpdate});
  }


  handlePaginationImageSelectChange = (index: number) => (e:any)=>
  {
    let messageUpdate = this.state.MessagePages;
    messageUpdate[index].pageImagePath = AWS_URL+ e;
    messageUpdate[index].pageImageFileName=e.substring(e.lastIndexOf('/')+1);
    this.setState({ MessagePages:messageUpdate });
    console.log(messageUpdate);
  }

  handleBranchingImageSelectChange = (index: number) => (e:any)=>
  {
    let answerUpdate = this.state.AnswerData;
      answerUpdate[index].imageAnswer = AWS_URL+ e;
    this.setState({ AnswerData:answerUpdate });
    console.log(answerUpdate);
  }

  handleQuestionImageSelectChange = (e:any)=>
  {
      let file = AWS_URL+ e;
    this.setState({ QuestionImage:file });
  }

  handlePaginationSelectChange = (index: number) => (e: any) => 
  {
    let messageUpdate = this.state.MessagePages;
    messageUpdate[index].type = e;
    this.setState({ MessagePages:messageUpdate});
  }

  handleBranchingSelectChange = (index: number) => (e: any) => 
  {
    let answerUpdate = this.state.AnswerData;
    answerUpdate[index].type = e;
    this.setState({ AnswerData:answerUpdate});
  }

  handleBranchingStateSelectChange = (index: number) => (e: any) => 
  {
    let answerUpdate = this.state.AnswerData;
    answerUpdate[index].targetState = e;
    this.setState({ AnswerData:answerUpdate});
  }

  handleBranchingPanoSelectChange = (index: number) => (e: any) => 
  {
    let answerUpdate = this.state.AnswerData;
    answerUpdate[index].targetPano = e;
    console.log(e);
    this.setState({ AnswerData:answerUpdate});
  }


  handleSelectChange = (input: any) => (e: any) => 
  {
      console.log(input);
      console.log(e);
      switch (input) {
          case 'ImagePath':
              console.log("image");
              this.setState({
                  ImagePath: AWS_URL +e});
              break;
          case 'VideoPath':
              console.log("video");
              this.setState({ VideoPath: AWS_URL + e });
              break;
          case 'AudioFile':
              console.log("Audio");
              this.setState({ AudioFile: AWS_URL+ e });
              break;
          case 'TargetUID':
              console.log("targetuid");
              this.setState({ TargetUID: e });
              break;
          default:
              this.setState({ [input]: e } as any);
              break;
      }
  }

  handleTypeSelectChange = (input: any) => (e: any) => 
  {
      console.log(input);
      console.log(e);
      this.setState({ [input]: e } as any);
  }

  handleCheckBoxChange = (input:any)=>(e:any) =>{
      this.setState({ [input]: e.target.value } as any);
      console.log(e.target.value);
  }

  handleCheckChange =(input:any)=> (e:any)=> {
    this.setState({ [input]: e.target.checked } as any);
  }
  beforeUploadImage=async(file:any)=>
  {
    let m = await new Promise<void>((resolve:any,reject:any)=>{
      if(file.type.substring(0,file.type.indexOf('/')) === 'image'){
        axios.get(`/api/v1/vrsafety/file/check-resources/${file.name}/${this.props.tourId}`).then((result:any)=>{
          if(!result.data)
          {
            resolve();
          }
          else
          {
            confirm({
              title:"File is already uploaded",
              content:"Would you like to use the previously uploaded file?",
              okText:"Yes",
              onOk:()=>{
                  this.setState({ ImagePath: AWS_URL+result.data});
                reject(false);
              },
              cancelText:"No",
              onCancel:()=>{
                this.setState((state)=>{return{newVersion:true}});
                resolve();
              }
            });
          }
        }).catch((error)=>{
          message.error("There was a problem with uploading the file. Please try again");
          console.log(error);
          resolve();
        })
      }
      else{
        let filetype = file.type.substring(file.type.indexOf('/'));
        message.error(filetype+' is not an allowed file type')
        reject(false); 
      }
    });
    return m;
  }
  beforeUploadVideo=async(file:any)=>
  {
    let m = await new Promise<void>((resolve:any,reject:any)=>{
      if(file.type.substring(0,file.type.indexOf('/')) === 'video'){
        axios.get(`/api/v1/vrsafety/file/check-resources/${file.name}/${this.props.tourId}`).then((result)=>{
          if(!result.data)
          {
            resolve();
          }
          else
          {
            confirm({
              title:"File is already uploaded",
              content:"Would you like to use the previously uploaded file?",
              okText:"Yes",
              onOk:()=>{
                  this.setState({ VideoPath: AWS_URL+result.data});
                reject(false);
              },
              cancelText:"No",
              onCancel:()=>{
               this.setState((state)=>{return{newVersion:true}});
                resolve();
              }
            });
          }
        }).catch((error:any)=>{
          message.error("There was a problem with uploading the file. Please try again");
          console.log(error);
          resolve();
        })
      }
      else{
        let filetype = file.type.substring(file.type.indexOf('/'));
        message.error(filetype+' is not an allowed file type')
        reject(false); 
      }
    });
    return m;
  }
  beforeUploadAudio=async(file:any)=>
  {
    this.setState({uploading:true});
    let m = await new Promise<void>((resolve:any,reject:any)=>{
      if(file.type.substring(0,file.type.indexOf('/')) === 'audio'){
        axios.get(`/api/v1/vrsafety/file/check-resources/${file.name}/${this.props.tourId}`).then((result)=>{
          if(!result.data)
          {
            resolve();
          }
          else
          {
            confirm({
              title:"File is already uploaded",
              content:"Would you like to use the previously uploaded file?",
              okText:"Yes",
              onOk:()=>{
                  this.setState({ AudioFile: AWS_URL+result.data});
                reject(false);
              },
              cancelText:"No",
              onCancel:()=>{
                this.setState((state)=>{return{newVersion:true}});
                resolve();
              }
            });
          }
        }).catch((error:any)=>{
          message.error("There was a problem with uploading the file. Please try again");
          console.log(error);
          resolve();
        })
      }
      else{
        let filetype = file.type.substring(file.type.indexOf('/'));
        message.error(filetype+' is not an allowed file type')
        reject(false); 
      }
    });
    return m;
  }


// Getting file type
  fileType = (item: string) => {
        var fileExtn = item.substring(item.lastIndexOf("."));
        switch (fileExtn) {
            case ".jpg":
            case ".bmp":
            case ".cgm":
            case ".g3":
            case ".gif":
            case ".jpeg":
            case ".png":
            case ".svg":
            case ".tiff":
                return "image/";
            case ".avi":
            case ".mp4":
            case ".m4v":
            case ".mpeg":
            case ".ogv":
            case ".webm":
            case ".f4v":
            case ".flv":
            case ".wmv":
            case ".wmx":
            case ".mov":
                return "video/";
            case ".mp4a":
            case ".mpga":
            case ".oga":
            case ".uva":
            case ".eol":
            case ".dra":
            case ".dts":
            case ".wav":
            case ".dtshd":
            case ".lvp":
            case ".pya":
            case ".ecelp4800":
            case ".ecelp7470":
            case ".ecelp9600":
            case ".rip":
            case ".weba":
            case ".aac":
            case ".aif":
            case ".wma":
            case ".mp3":
                return "audio/";
            default:
                return "";
        }
    }



  addPage(){
    
    let newelem = {
      pageContent:"",
      pageImagePath:"",
      pageImageFileName:"",
      type:"",
      errors:{
        errorMsg:""
      }
    };
    this.setState({MessagePages:[...this.state.MessagePages,newelem]});

  }

  addAnswer(){
    
    let newelem = {
      textAnswer:"",
      imageAnswer:"",
      targetPano:0,
      targetState:"",
      type:"",
      errors:{
        errorMsg:""
      }
    };
    this.setState({AnswerData:[...this.state.AnswerData,newelem]});

  }


  selectOptions()
  {
      let items = [];
      let cameraPosHotspot = this.props.hotspots.filter((hotspot: any) => { return (hotspot.type === "VirtualTourNetwork.CameraPositionData") });
      if (this.props.currentPano === "#image0" && cameraPosHotspot.length===0) {
          for (let i = 0; i < this.state.typesFirstPano.length; i++) {
              items.push(
                  <Option key={this.state.typesFirstPano[i].value}
                      value={this.state.typesFirstPano[i].value || 0}>
                      {
                          this.state.typesFirstPano[i].name
                      }
                  </Option>);
          }
      }
      else {
          for (let i = 0; i < this.state.types.length; i++) {
              items.push(
                  <Option key={this.state.types[i].value}
                      value={this.state.types[i].value || 0}>
                      {
                          this.state.types[i].name
                      }
                  </Option>);
          }
      }
    return items;
  }
  paginationSelectOptions()
  {
    let items = [];         
      for (let i = 0; i < this.state.pageTypes.length; i++) {             
          items.push(
          <Option key={this.state.pageTypes[i].value || 0}
              value={this.state.pageTypes[i].value || 0}>
              {
                  this.state.pageTypes[i].name
              }
          </Option>);
      }
    return items;
  }
  answerSelectOptions()
  {
    let items = [];         
      for (let i = 0; i < this.state.answerTypes.length; i++) {             
          items.push(
          <Option key={this.state.answerTypes[i].value || 0}
              value={this.state.answerTypes[i].value || 0}>
              {
                  this.state.answerTypes[i].name
              }
          </Option>);
      }
    return items;
  }
  linkSelectOptions()
  {
    console.log(this.props.currentPano)
    let panoIndex = parseInt(this.props.currentPano.substring(this.props.currentPano.lastIndexOf('e')+1));
    let panoOptions = this.props.otherPanos.filter((pano,index) => panoIndex !== index);
    let items = [];   
      for (let i = 0; i < panoOptions.length; i++) {             
          items.push(
          <Option key={panoOptions[i].id || 0}
              value={ panoOptions[i].uid }>
              {
                  `Image ${panoOptions[i].uid + 1}. ${panoOptions[i].fileName}`
              }
          </Option>);
      }
    return items;
  }


 // Image options begin
  selectImageOptionsLibrary()
  {

      let imageOptions:any[] = [];
      for (let i = 0; i < this.props.library.length;i++) {
          let type = this.fileType(this.props.library[i]);
          if (type === 'image/') {
              imageOptions.push(this.props.library[i])
          }
      }
      console.log(imageOptions);
    let items:any[] = [];         
      for (let i = 0; i < imageOptions.length; i++) {  
          items.push(
          <Option key={"image"+i || 0}
              value={imageOptions[i]}>
              {
               imageOptions[i].substring(imageOptions[i].lastIndexOf("/") + 1)
              }
          </Option>);
      }
    return items;
    }


    selectImageOptionsShared() {

        let imageOptions: any[] = [];
        for (let i = 0; i < this.props.resources.length; i++) {
            if (this.props.resources[i].type === 'image/') {
                imageOptions.push(this.props.resources[i])
            }
        }
        console.log(imageOptions);
        let items: any[] = [];
        for (let i = 0; i < imageOptions.length; i++) {
            items.push(
                <Option key={"image" + i || 0}
                    value={imageOptions[i].url.substring(imageOptions[i].url.lastIndexOf('.com/') + 5)}>
                    {
                        imageOptions[i].name
                    }
                </Option>);
        }
        return items;
    }

    selectStateOptions() {
        let items = [];
        for (let i = 0; i < this.props.states.length; i++) {
            items.push(
                <Option key={this.props.states[i]}
                    value={this.props.states[i]}>
                    {
                        this.props.states[i]
                    }
                </Option>);
        }
        return items;
    }

    selectPanoOptions() {
        let items = [];
        for (let i = 0; i < this.props.otherPanos.length; i++) {
            items.push(
                <Option key={this.props.otherPanos[i].id}
                    value={this.props.otherPanos[i].uid}>
                    {
                        this.props.otherPanos[i].id + " : " + this.props.otherPanos[i].fileName
                    }
                </Option>);
        }
        return items;
    }

    onImageChangeChecked = (status: string) => (e: any) => {
        if (status === 'local') {
            this.setState({
                imageLib: true
            } as any);
        }
        else {
            this.setState({
                imageLib: false
            } as any);
        }
    };




    // Image options end

  // Video options begin
  selectVideoOptionsLibrary()
  {

      let imageOptions: any[] = [];
      for (let i = 0; i < this.props.library.length; i++) {
          let type = this.fileType(this.props.library[i]);
          if (type === 'video/') {
              imageOptions.push(this.props.library[i])
          }
      }
      console.log(imageOptions);
      let items: any[] = [];
      for (let i = 0; i < imageOptions.length; i++) {
          items.push(
              <Option key={"video" + i || 0}
                  value={imageOptions[i]}>
                  {
                      imageOptions[i].substring(imageOptions[i].lastIndexOf("/") + 1)
                  }
              </Option>);
      }
      return items;

    }


    selectVideoOptionsShared() {

        let imageOptions: any[] = [];
        for (let i = 0; i < this.props.resources.length; i++) {
            if (this.props.resources[i].type === 'video/') {
                imageOptions.push(this.props.resources[i])
            }
        }
        console.log(imageOptions);
        let items: any[] = [];
        for (let i = 0; i < imageOptions.length; i++) {
            items.push(
                <Option key={"video" + i || 0}
                    value={imageOptions[i].url.substring(imageOptions[i].url.lastIndexOf('.com/') + 5)}>
                    {
                        imageOptions[i].name
                    }
                </Option>);
        }
        return items;

    }

    onVideoChangeChecked = (status: string) => (e: any) => {
        if (status === 'local') {
            this.setState({
                videoLib: true
            } as any);
        }
        else {
            this.setState({
                videoLib: false
            } as any);
        }
    };

    // video options end

 // audio options begin
  selectAudioOptionsLibrary()
  {
      let imageOptions: any[] = [];
      for (let i = 0; i < this.props.library.length; i++) {
          let type = this.fileType(this.props.library[i]);
          if (type === 'audio/') {
              imageOptions.push(this.props.library[i])
          }
      }
      console.log(imageOptions);
      let items: any[] = [];
      for (let i = 0; i < imageOptions.length; i++) {
          items.push(
              <Option key={"audio" + i || 0}
                  value={imageOptions[i]}>
                  {
                      imageOptions[i].substring(imageOptions[i].lastIndexOf("/") + 1)
                  }
              </Option>);
      }
      return items;

    }



    selectAudioOptionsShared() {
        let imageOptions: any[] = [];
        for (let i = 0; i < this.props.resources.length; i++) {
            if (this.props.resources[i].type === 'audio/') {
                imageOptions.push(this.props.resources[i])
            }
        }
        console.log(imageOptions);
        let items: any[] = [];
        for (let i = 0; i < imageOptions.length; i++) {
            items.push(
                <Option key={"audio" + i || 0}
                    value={imageOptions[i].url.substring(imageOptions[i].url.lastIndexOf('.com/') + 5)}>
                    {
                        imageOptions[i].name
                    }
                </Option>);
        }
        return items;

    }


    onAudioChangeChecked = (status: string) => (e: any) => {
        if (status === 'local') {
            this.setState({
                audioLib: true
            } as any);
        }
        else {
            this.setState({
                audioLib: false
            } as any);
        }
        console.log(this.state);
    };


    // Audio options end

  formatAWSLinkforFrontEnd(link:string)
  {
    let formattedString="";
    if(link!==null && typeof link !== 'undefined')
    {
      formattedString = link.substring(link.lastIndexOf('/')+1);
    }
    return formattedString;
  }
  forms(){
    const style = {padding:"6px 0"};
    const uploadButton = (
      <div className="UploadButtonOrder">
          <Icon type={this.state.uploading ? 'loading' : 'plus'} />
          <div className="ant-upload-text">Upload</div>
      </div>
    );
    switch(this.state.type){
        case 'VirtualTourNetwork.ImageMessageData':
        return (
            <div>
            <Row style={style}>
                <Col span={12}>
                <Upload
                // action="/api/v1/vrsafety/File/largefileupload"
                listType="picture-card"
                //showUploadList={{showRemoveIcon:true, showDownloadIcon:false, showPreviewIcon:false}}
                showUploadList={false}
                // headers={{
                //   Authorization: `Bearer ${token}`
                // }}
                beforeUpload={this.beforeUploadImage}
                customRequest={this.upload("ImagePath")}
                onChange={this.handleImageChange}
                onRemove={this.props.removeFile}
                disabled={ this.state.uploading }
                accept="image/*"
                className="clear-none"
                >
                {this.state.ImagePath && !this.state.uploading ? <img src={this.state.ImagePath} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload>
                </Col>
                </Row>
                <Row style={{marginTop:"20px", marginLeft: "30px"}}>
                    <Col xs={24} sm={24} md={12}>Local Upload List <Checkbox style={{marginLeft:"40px"}} onChange={this.onImageChangeChecked("local")} checked={this.state.imageLib}/></Col>
                    <Col xs={24} sm={24} md={12}>Shared Library <Checkbox style={{marginLeft:"40px"}} onChange={this.onImageChangeChecked("shared")} checked={!this.state.imageLib}/></Col>
                </Row>
                <Row style={style}>
                <Col xs={12} sm={12} md={11}>
                        <Select
                            onChange={this.handleSelectChange("ImagePath")}
                            value={this.formatAWSLinkforFrontEnd(this.state.ImagePath)}
                            placeholder="Select a previously uploaded file"
                            disabled={!this.state.imageLib}
                        >
                            {this.selectImageOptionsLibrary()}
                        </Select>
                </Col>
                <Col xs={12} sm={12} md={2}></Col>
                <Col xs={12} sm={12} md={11}>
                        <Select
                            onChange={this.handleSelectChange("ImagePath")}
                            value={this.formatAWSLinkforFrontEnd(this.state.ImagePath)}
                            placeholder="Select a previously uploaded file"
                            disabled={this.state.imageLib}
                        >
                            {this.selectImageOptionsShared()}
                        </Select>
                </Col>
                </Row>
                <Row style={style}>
                <Col span={18}>
                <span style={{ display: "inline-block", marginRight: "10px" }}>Image Size : </span><Radio.Group options={options} onChange={this.handleCheckBoxChange("value")} value={this.state.value} style={{marginTop: "10px"}}/>
                <span style={{ display: "inline-block", marginRight: "10px" }}>Image Transparency : </span><Radio.Group options={transOptions} onChange={this.handleCheckBoxChange("transValue")} value={this.state.transValue} style={{marginTop: "10px"}}/>
                </Col>
            </Row>
            <Row style={style}>
                <Col>
                <TextArea placeholder="Message" onChange={this.handleNoValidationChange("Message")} value={this.state.Message} maxLength={100} rows={2}/>
                </Col>
            </Row>
            <Row style={style}>
                <Col span={18}>
                    <span style={{ display: "inline-block", marginRight: "10px" }}>Icon: </span>
                    <Radio.Group
                    onChange={this.handleCheckBoxChange("IconColor")} 
                    value={this.state.IconColor} 
                    style={{ marginTop: "10px" }} 
                    >
                        <Radio value="black"><Avatar size={60} src={this.props.icons.Image_icon_black} /></Radio>
                        <Radio value="white"><Avatar style={{backgroundColor: "#000"}} size={60} src={this.props.icons.Image_icon_white} /></Radio>
                    </Radio.Group>
                </Col>
            </Row>
            </div>
        )
        case 'VirtualTourNetwork.HotspotLinkData':
        return (
            <div> 
            <Row style={style}>
                <Col span={18}>
                <Select
                    //style={{ width: 120 }}
                    onChange={this.handleSelectChange("TargetUID")}
                    value={this.state.TargetUID}
                >
                    {this.linkSelectOptions()}
                </Select>
                </Col>
            </Row>
            <Row style={style}>
                <Col>
                <Input placeholder="Message" onChange={this.handleNoValidationChange("Message")} value={this.state.Message}/>
                </Col>
            </Row>
            <Row style={style}>
                <Col span={18}>
                    <span style={{ display: "inline-block", marginRight: "10px" }}>Icon: </span>
                    <Radio.Group
                    onChange={this.handleCheckBoxChange("IconColor")} 
                    value={this.state.IconColor} 
                    style={{ marginTop: "10px" }} 
                    >
                        <Radio value="black"><Avatar size={60} src={this.props.icons.Link_icon_black} /></Radio>
                        <Radio value="white"><Avatar style={{backgroundColor: "#000"}} size={60} src={this.props.icons.Link_icon_white} /></Radio>
                    </Radio.Group>
                </Col>
            </Row>
            </div>
        )
        case 'VirtualTourNetwork.HotspotCompleteData':
        return (
            <div> 
            <Row style={style}>
                <Col span={18}>
                End of Tour:
                </Col>
                <Col span={4}>
                <Checkbox onChange={this.handleCheckChange('EndOfTour')} checked={this.state.EndOfTour}></Checkbox>
                </Col>
            </Row> 
            <Row style={style}>
                <Col span={18}>
                    <span style={{ display: "inline-block", marginRight: "10px" }}>Icon: </span>
                    <Radio.Group
                    onChange={this.handleCheckBoxChange("IconColor")} 
                    value={this.state.IconColor} 
                    style={{ marginTop: "10px" }} 
                    >
                        <Radio value="black"><Avatar size={60} src={this.props.icons.exit_icon_black} /></Radio>
                        <Radio value="white"><Avatar style={{backgroundColor: "#000"}} size={60} src={this.props.icons.exit_icon_white} /></Radio>
                    </Radio.Group>
                </Col>
            </Row>         
            </div>
        )
        case 'VirtualTourNetwork.SphereVideoData':
        return (
            <div>
            <Row style={style}>
                <Col >
                <Upload
                //action="/api/v1/vrsafety/File/largefileupload"
                listType="picture-card"
                //showUploadList={{showRemoveIcon:true, showDownloadIcon:false, showPreviewIcon:false}}
                showUploadList={false}
                // headers={{
                //   Authorization: `Bearer ${token}`
                // }}
                beforeUpload={this.beforeUploadVideo}
                customRequest={this.upload("VideoPath")}
                onChange={this.handleVideoChange}
                onRemove={this.props.removeFile}
                disabled={ this.state.uploading }
                accept="video/*"
                className="clear-none"
                >
                {this.state.VideoPath && !this.state.uploading ? <img src={Video_Small} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload>
                </Col>
            </Row>
            <Row style={{marginTop:"20px", marginLeft: "30px"}}>
                <Col xs={24} sm={24} md={12}>Local Upload List <Checkbox style={{marginLeft:"40px"}} onChange={this.onVideoChangeChecked("local")} checked={this.state.videoLib}/></Col>
                <Col xs={24} sm={24} md={12}>Shared Library <Checkbox style={{marginLeft:"40px"}} onChange={this.onVideoChangeChecked("shared")} checked={!this.state.videoLib}/></Col>
            </Row>
            <Row style={style}>
                <Col xs={12} sm={12} md={11}>
                <Select
                onChange={this.handleSelectChange("VideoPath")}
                value={this.formatAWSLinkforFrontEnd(this.state.VideoPath)}
                placeholder="Select a previously uploaded file"
                disabled={!this.state.videoLib}
                >
                {this.selectVideoOptionsLibrary()}
                </Select>
                </Col>
                <Col xs={12} sm={12} md={2}></Col>
                <Col xs={12} sm={12} md={11}>
                <Select
                onChange={this.handleSelectChange("VideoPath")}
                value={this.formatAWSLinkforFrontEnd(this.state.VideoPath)}
                placeholder="Select a previously uploaded file"
                disabled={this.state.videoLib}
                >
                {this.selectVideoOptionsShared()}
                </Select>
                </Col>
            </Row>
            <Row style={style}>
                <Col span={6}>
                Autoplay:
                </Col>
                <Col span={4}>
                <Checkbox onChange={this.handleCheckChange('AutoPlay')} checked={this.state.AutoPlay}></Checkbox>
                </Col>
                <Col span={10}>
                </Col>
            </Row>
            <Row style={style}>
                <Col>
                <Input placeholder="Message" onChange={this.handleNoValidationChange("Message")} value={this.state.Message}/>
                </Col>
            </Row>   
            <Row style={style}>
                <Col span={18}>
                    <span style={{ display: "inline-block", marginRight: "10px" }}>Icon: </span>
                    <Radio.Group
                    onChange={this.handleCheckBoxChange("IconColor")} 
                    value={this.state.IconColor} 
                    style={{ marginTop: "10px" }} 
                    >
                        <Radio value="black"><Avatar size={60} src={this.props.icons.Video360_icon_black} /></Radio>
                        <Radio value="white"><Avatar style={{backgroundColor: "#000"}} size={60} src={this.props.icons.Video360_icon_white} /></Radio>
                    </Radio.Group>
                </Col>
            </Row>         
            </div>
        )
        case 'VirtualTourNetwork.VideoData':
        return (
            <div> 
            <Row style={style}>
                <Col >
                <Upload
                //action="/api/v1/vrsafety/File/largefileupload"
                listType="picture-card"
                //showUploadList={{showRemoveIcon:true, showDownloadIcon:false, showPreviewIcon:false}}
                showUploadList={false}
                // headers={{
                //   Authorization: `Bearer ${token}`
                // }}
                beforeUpload={this.beforeUploadVideo}
                customRequest={this.upload("VideoPath")}
                onChange={this.handleVideoChange}
                onRemove={this.props.removeFile}
                disabled={ this.state.uploading }
                accept="video/*"
                className="clear-none"
                >
                {this.state.VideoPath && !this.state.uploading ? <img src={Video_Small} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload>
                </Col>
                </Row>
                <Row style={{ marginTop: "20px", marginLeft: "30px" }}>
                    <Col xs={24} sm={24} md={12}>Local Upload List <Checkbox style={{ marginLeft: "40px" }} onChange={this.onVideoChangeChecked("local")} checked={this.state.videoLib} /></Col>
                    <Col xs={24} sm={24} md={12}>Shared Library <Checkbox style={{ marginLeft: "40px" }} onChange={this.onVideoChangeChecked("shared")} checked={!this.state.videoLib} /></Col>
                </Row>
                <Row style={style}>
                    <Col xs={12} sm={12} md={11}>
                        <Select
                            onChange={this.handleSelectChange("VideoPath")}
                            value={this.formatAWSLinkforFrontEnd(this.state.VideoPath)}
                            placeholder="Select a previously uploaded file"
                            disabled={!this.state.videoLib}
                        >
                            {this.selectVideoOptionsLibrary()}
                        </Select>
                    </Col>
                    <Col xs={12} sm={12} md={2}></Col>
                    <Col xs={12} sm={12} md={11}>
                        <Select
                            onChange={this.handleSelectChange("VideoPath")}
                            value={this.formatAWSLinkforFrontEnd(this.state.VideoPath)}
                            placeholder="Select a previously uploaded file"
                            disabled={this.state.videoLib}
                        >
                            {this.selectVideoOptionsShared()}
                        </Select>
                    </Col>
                </Row>
                <Row style={style}>
                <Col span={18}>
                        <span style={{ display: "inline-block", marginRight: "10px" }}>Image Size : </span><Radio.Group options={options} onChange={this.handleCheckBoxChange("value")} value={this.state.value} style={{marginTop: "10px"}}/>
                </Col>
            </Row>
            {/*<Row style={style}>
                <Col span={6}>
                Autoplay:
                </Col>
                <Col span={4}>
                <Checkbox onChange={this.handleCheckChange('AutoPlay')} checked={this.state.AutoPlay}></Checkbox>
                </Col>
                <Col span={10}>
                </Col>
            </Row>*/}
            <Row style={style}>
                <Col>
                <Input placeholder="Message" onChange={this.handleNoValidationChange("Message")} value={this.state.Message}/>
                </Col>
            </Row>
            <Row style={style}>
                <Col span={18}>
                    <span style={{ display: "inline-block", marginRight: "10px" }}>Icon: </span>
                    <Radio.Group
                    onChange={this.handleCheckBoxChange("IconColor")} 
                    value={this.state.IconColor} 
                    style={{ marginTop: "10px" }} 
                    >
                        <Radio value="black"><Avatar size={60} src={this.props.icons.Video_icon_black} /></Radio>
                        <Radio value="white"><Avatar style={{backgroundColor: "#000"}} size={60} src={this.props.icons.Video_icon_white} /></Radio>
                    </Radio.Group>
                </Col>
            </Row>  
            </div>
        )
        case 'VirtualTourNetwork.MessageData':
        return (
            <div> 
            <Row style={style}>
            <Col>
                <Form.Item
                validateStatus={(this.state.errors.Message.errorMsg!==""||'')? (this.state.errors.Message.errorMsg.indexOf("only")>-1 ? 'warning':'error'):''}
                help={this.state.errors.Message.errorMsg||""}
                >
                <Row style={style}>
                    <Col span={18}>
                        <span style={{ display: "inline-block", marginRight: "10px" }}>Text Size : </span><Radio.Group options={TextSizes} onChange={this.handleCheckBoxChange("TextSize")} value={this.state.TextSize} style={{ marginTop: "10px" }} />
                    </Col>
                </Row>
                <Row style={style}>
                    <Col span={18}>
                        <span style={{ display: "inline-block", marginRight: "10px" }}>Text Align : </span><Radio.Group options={TextAlignOptions} onChange={this.handleCheckBoxChange("TextAlign")} value={this.state.TextAlign} style={{ marginTop: "10px" }} />
                    </Col>
                </Row>
                <TextArea placeholder="Message" onChange={this.handleChange("Message")} value={this.state.Message} rows={4} />
                <Row style={style}>
                <Col span={18}>
                    <span style={{ display: "inline-block", marginRight: "10px" }}>Icon: </span>
                    <Radio.Group
                    onChange={this.handleCheckBoxChange("IconColor")} 
                    value={this.state.IconColor} 
                    style={{ marginTop: "10px" }} 
                    >
                        <Radio value="black"><Avatar size={60} src={this.props.icons.Text_icon_black} /></Radio>
                        <Radio value="white"><Avatar style={{backgroundColor: "#000"}} size={60} src={this.props.icons.Text_icon_white} /></Radio>
                    </Radio.Group>
                </Col>
            </Row>  
                </Form.Item>
            </Col>
            </Row>
            </div>
        )
        case 'VirtualTourNetwork.AudioData':
       
        return (
            <div> 
            <Row style={style}>
                <Col span={12}>
                <Upload
                // action="/api/v1/vrsafety/File/largefileupload"
                listType="picture-card"
                //showUploadList={{showRemoveIcon:true, showDownloadIcon:false, showPreviewIcon:false}}
                showUploadList={false}
                // headers={{
                //   Authorization: `Bearer ${token}`
                // }}
                beforeUpload={this.beforeUploadAudio}
                // onChange={this.handleAudioChange}
                customRequest={this.upload("AudioFile")}
                onRemove={this.props.removeFile}
                disabled={ this.state.uploading }
                accept="audio/*"
                className="clear-none"
                >
                {this.state.AudioFile && !this.state.uploading ? <img src={Speaker_Icon} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload>
                </Col>
            </Row>
            <Row style={{ marginTop: "20px", marginLeft: "30px" }}>
                <Col xs={24} sm={24} md={12}>Local Upload List <Checkbox style={{ marginLeft: "40px" }} onChange={this.onAudioChangeChecked("local")} checked={this.state.audioLib} /></Col>
                <Col xs={24} sm={24} md={12}>Shared Library <Checkbox style={{ marginLeft: "40px" }} onChange={this.onAudioChangeChecked("shared")} checked={!this.state.audioLib} /></Col>
            </Row>
            <Row style={style}>
                <Col xs={24} sm={24} md={11}>
                <Select
                    onChange={this.handleSelectChange("AudioFile")}
                    value={this.formatAWSLinkforFrontEnd(this.state.AudioFile)}
                    placeholder="Select a previously uploaded file"
                    disabled={!this.state.audioLib}
                >
                    {this.selectAudioOptionsLibrary()}
                </Select>
                </Col>
                <Col xs={24} sm={24} md={2}></Col>
                <Col xs={24} sm={24} md={11}>
                <Select
                    onChange={this.handleSelectChange("AudioFile")}
                    value={this.formatAWSLinkforFrontEnd(this.state.AudioFile)}
                    placeholder="Select a previously uploaded file"
                    disabled={this.state.audioLib}
                >
                    {this.selectAudioOptionsShared()}
                </Select>
                </Col>
            </Row>
            <Row style={style}>
                <Col span={6}>
                Autoplay:
                </Col>
                <Col span={4}>
                <Checkbox onChange={this.handleCheckChange('AutoPlay')} checked={this.state.AutoPlay}></Checkbox>
                </Col>
                <Col span={6}>
                Loop:
                </Col>
                <Col span={4}>
                <Checkbox onChange={this.handleCheckChange('Loop')}  checked={this.state.Loop}></Checkbox>
                </Col>
            </Row>
            <Row style={style}>
                <Col span={6}>
                Volume:
                </Col>
                <Col span={8}>
                <Slider
                    min={1}
                    max={10}
                    onChange={this.handleBasicChange("Volume")}
                    value={typeof this.state.Volume === 'number' ? this.state.Volume : 0}
                />
                </Col>
                <Col span={6}>
                <InputNumber
                    min={1}
                    max={10}
                    style={{ marginLeft: 16 }}
                    value={ this.state.Volume }
                    onChange={this.handleBasicChange("Volume")}
                />
                </Col>
            </Row>
            <Row style={style}>
                <Col >
                <Input placeholder="Message" onChange={this.handleNoValidationChange("Message")} value={this.state.Message}/>
                </Col>
            </Row>
            <Row style={style}>
                <Col span={18}>
                    <span style={{ display: "inline-block", marginRight: "10px" }}>Icon: </span>
                    <Radio.Group
                    onChange={this.handleCheckBoxChange("IconColor")} 
                    value={this.state.IconColor} 
                    style={{ marginTop: "10px" }} 
                    >
                        <Radio value="black"><Avatar size={60} src={this.props.icons.Audio_icon_black} /></Radio>
                        <Radio value="white"><Avatar style={{backgroundColor: "#000"}} size={60} src={this.props.icons.Audio_icon_white} /></Radio>
                    </Radio.Group>
                </Col>
            </Row>  
           
            </div>
        )
        case 'VirtualTourNetwork.PageMessageData':
        return(
            <div>
            <Row style={style}>
                <Col span={18}>
                    <span style={{ display: "inline-block", marginRight: "10px" }}>Icon: </span>
                    <Radio.Group
                    onChange={this.handleCheckBoxChange("IconColor")} 
                    value={this.state.IconColor} 
                    style={{ marginTop: "10px" }} 
                    >
                        <Radio value="black"><Avatar size={60} src={this.props.icons.Pagination_icon_black} /></Radio>
                        <Radio value="white"><Avatar style={{backgroundColor: "#000"}} size={60} src={this.props.icons.Pagination_icon_white} /></Radio>
                    </Radio.Group>
                </Col>
            </Row>  
            <Row style={style}>
                <Col span={24}>
                <Form.Item
                validateStatus={(this.state.errors.PageTitle.errorMsg!==""||'')? (this.state.errors.PageTitle.errorMsg.indexOf("only")>-1 ? 'warning':'error'):''}
                help={this.state.errors.PageTitle.errorMsg||""}
                >
                <Input value={this.state.PageTitle} onChange={this.handleChange("PageTitle")} placeholder="Page Title"/>
                </Form.Item>
                </Col>
            </Row>
            {
                this.state.MessagePages.map((page,index)=>
                <div key={index}style={{border: "1px solid #ccc", padding: "10px", marginBottom: "20px"}}>
                    <Row style={style}>
                    <Col span={22}>
                        <Select 
                        onChange={this.handlePaginationSelectChange(index)}
                        value={page.type}
                        placeholder="Select a Page Type"
                        >
                        {this.paginationSelectOptions()}
                        </Select>
                    </Col>
                    {
                    (index>0) ? 
                        <Col span={2}> 
                        <Button  type="danger" shape="circle" icon="delete" onClick={()=>this.removePage(index)}/>
                    </Col>
                    : 
                    null
                    }
                    </Row>
                    
                    {
                        (page.type === "") ? null
                        :
                        <div >
                        <React.Fragment>
                        {
                            (page.type === "VirtualTourNetwork.ImagePageData" || page.type === "VirtualTourNetwork.BothPageData") ?
                            <React.Fragment>
                                            <Row style={{ marginTop: "20px" }}>
                                                <Col span={12}>
                                                    <Upload
                                                        //action="/api/v1/vrsafety/File/largefileupload"
                                                        listType="picture-card"
                                                        //showUploadList={{showRemoveIcon:true, showDownloadIcon:false, showPreviewIcon:false}}
                                                        showUploadList={false}
                                                        // headers={{
                                                        //   Authorization: `Bearer ${token}`
                                                        // }}
                                                        beforeUpload={this.beforeUploadImage}
                                                        onChange={this.handlePaginationImageChange(index)}
                                                        customRequest={this.upload(`MessagePages,${index}`)}
                                                        onRemove={this.props.removeFile}
                                                        disabled={this.state.uploading}
                                                        accept="image/*"
                                                        className="clear-none"
                                                    >
                                                        {page.pageImagePath && !this.state.uploading ? <img src={page.pageImagePath} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                                    </Upload>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: "20px", marginLeft: "30px" }}>
                                                <Col xs={24} sm={24} md={12}>Local Upload List <Checkbox style={{ marginLeft: "40px" }} onChange={this.onImageChangeChecked("local")} checked={this.state.imageLib} /></Col>
                                                <Col xs={24} sm={24} md={12}>Shared Library <Checkbox style={{ marginLeft: "40px" }} onChange={this.onImageChangeChecked("shared")} checked={!this.state.imageLib} /></Col>
                                            </Row>
                                            <Row style={style}>
                                                <Col xs={24} sm={24} md={11}>
                                                    <Select
                                                        onChange={this.handlePaginationImageSelectChange(index)}
                                                        value={this.formatAWSLinkforFrontEnd(page.pageImagePath)}
                                                        placeholder="Select a previously uploaded file"
                                                        disabled={!this.state.imageLib}
                                                    >
                                                        {this.selectImageOptionsLibrary()}
                                                    </Select>
                                                </Col>
                                                <Col xs={24} sm={24} md={2}></Col>
                                                <Col xs={24} sm={24} md={11}>
                                                    <Select
                                                        onChange={this.handlePaginationImageSelectChange(index)}
                                                        value={this.formatAWSLinkforFrontEnd(page.pageImagePath)}
                                                        placeholder="Select a previously uploaded file"
                                                        disabled={this.state.imageLib}
                                                    >
                                                        {this.selectImageOptionsShared()}
                                                    </Select>
                                                </Col>
                                            </Row>
                                            </React.Fragment>        
                            :
                            null
                        }
                        {
                            (page.type === "VirtualTourNetwork.TextPageData" || page.type === "VirtualTourNetwork.BothPageData") ?
                                <Row>
                                    <Col>
                                        <Form.Item
                                            validateStatus={(page.errors.errorMsg !== "" || '') ? (page.errors.errorMsg.indexOf("only") > -1 ? 'warning' : 'error') : ''}
                                            help={page.errors.errorMsg || ""}
                                        >
                                            <TextArea placeholder="Message" onChange={this.handleArrayChange(index)} value={page.pageContent} rows={4} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                :
                                null
                        }
                                    </React.Fragment>
                        </div>
  
                    }
                </div>
                )
            }
                <Button type="primary" onClick={()=> this.addPage()} style={{marginTop: "20px"}}>
                Add Another Page
                </Button>
            </div>
         
        )
        case 'VirtualTourNetwork.CameraPositionData':
        return (
            <div> 
            <Row style={style}>
                <Col span={18}>
                Camera Position
                </Col>
                <Col span={4}>
                <Checkbox onChange={this.handleCheckChange('cameraPosition')} checked={this.state.cameraPosition}></Checkbox>
                </Col>
            </Row> 
            <Row style={style}>
                <Col span={18}>
                    <span style={{ display: "inline-block", marginRight: "10px" }}>Icon: </span>
                    <Radio.Group
                    onChange={this.handleCheckBoxChange("IconColor")} 
                    value={this.state.IconColor} 
                    style={{ marginTop: "10px" }} 
                    >
                        <Radio value="black"><Avatar size={60} src={this.props.icons.Camera_position_black} /></Radio>
                        <Radio value="white"><Avatar style={{backgroundColor: "#000"}} size={60} src={this.props.icons.Camera_position_white} /></Radio>
                    </Radio.Group>
                </Col>
            </Row>         
            </div>
        )
        case 'VirtualTourNetwork.BranchingData':
        return(
          <div>
            <Row style={style}>
                <Col span={18}>
                    <span style={{ display: "inline-block", marginRight: "10px" }}>Icon: </span>
                    <Radio.Group
                    onChange={this.handleCheckBoxChange("IconColor")} 
                    value={this.state.IconColor} 
                    style={{ marginTop: "10px" }} 
                    >
                      <Radio value="black"><Avatar size={60} src={this.props.icons.Branch_icon_black} /></Radio>
                      <Radio value="white"><Avatar style={{backgroundColor: "#000"}} size={60} src={this.props.icons.Branch_icon_white} /></Radio>
                    </Radio.Group>
                </Col>
            </Row>  
            <Row style={style}>
              <Col span={24}>
              <Form.Item
                validateStatus={(this.state.errors.Question.errorMsg!==""||'')? (this.state.errors.Question.errorMsg.indexOf("only")>-1 ? 'warning':'error'):''}
                help={this.state.errors.Question.errorMsg||""}
                label= "Question"
              >
                <Input value={this.state.Question} onChange={this.handleChange("Question")} placeholder="Question"/>
              </Form.Item>
              </Col>
            </Row>


            <React.Fragment>
                    <Row>
                        <span style={{ display: "inline-block" }}>Question Image : </span>
                    </Row>
                    <Row style={{ marginTop: "20px" }}>
                        <Col span={12}>
                            <Upload
                                listType="picture-card"
                                showUploadList={false}
                                beforeUpload={this.beforeUploadImage}
                                onChange={this.handleQuestionImageChange}
                                customRequest={this.upload("QuestionImage")}
                                onRemove={this.props.removeFile}
                                disabled={this.state.uploading}
                                accept="image/*"
                                className="clear-none"
                            >
                                {this.state.QuestionImage && !this.state.uploading ? <img src={this.state.QuestionImage} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "20px", marginLeft: "30px" }}>
                        <Col xs={24} sm={24} md={12}>Local Upload List <Checkbox style={{ marginLeft: "40px" }} onChange={this.onImageChangeChecked("local")} checked={this.state.imageLib} /></Col>
                        <Col xs={24} sm={24} md={12}>Shared Library <Checkbox style={{ marginLeft: "40px" }} onChange={this.onImageChangeChecked("shared")} checked={!this.state.imageLib} /></Col>
                    </Row>
                    <Row style={style}>
                        <Col xs={24} sm={24} md={11}>
                            <Select
                                onChange={this.handleQuestionImageSelectChange}
                                value={this.formatAWSLinkforFrontEnd(this.state.QuestionImage)}
                                placeholder="Select a previously uploaded file"
                                disabled={!this.state.imageLib}
                            >
                                {this.selectImageOptionsLibrary()}
                            </Select>
                        </Col>
                        <Col xs={24} sm={24} md={2}></Col>
                        <Col xs={24} sm={24} md={11}>
                            <Select
                                onChange={this.handleQuestionImageSelectChange}
                                value={this.formatAWSLinkforFrontEnd(this.state.QuestionImage)}
                                placeholder="Select a previously uploaded file"
                                disabled={this.state.imageLib}
                            >
                                {this.selectImageOptionsShared()}
                            </Select>
                        </Col>
                    </Row>
                </React.Fragment>


            {
              this.state.AnswerData.map((page,index)=>
                  <div key={index} style={{border: "1px solid #ccc", padding: "10px", marginBottom: "20px"}}>
                  <Row>
                    <span style={{ display: "inline-block", marginBottom: "10px" }}><b>Answer {index+1} : </b></span>
                  </Row>
                  <Row style={style}>
                    <span style={{ display: "inline-block", marginBottom: "10px" }}>Answer Type : </span>
                    <Col span={22}>
                      <Select 
                        onChange={this.handleBranchingSelectChange(index)}
                        value={page.type}
                        placeholder="Select an Answer Type"
                      >
                        {this.answerSelectOptions()}
                      </Select>
                    </Col>
                    {
                    (index>0) ? 
                     <Col span={2}> 
                      <Button  type="danger" shape="circle" icon="delete" onClick={()=>this.removeAnswer(index)}/>
                    </Col>
                    : 
                    null
                    }
                    </Row>
                    
                    {
                      (page.type === "") ? null
                      :
                      <React.Fragment>
                      <Row>
                      {
                        (page.type === "VirtualTourNetwork.BothPageData")?

                        <React.Fragment>
                        <Row>
                            <span style={{ display: "inline-block" }}>Answer Image : </span>
                        </Row>
                        <Row style={{ marginTop: "20px"}}>
                        <Col span={12}>
                        <Upload
                          listType="picture-card"
                          showUploadList={false}
                          beforeUpload={this.beforeUploadImage}
                          onChange={this.handleBranchingImageChange(index)}
                          customRequest={this.upload(`Answers,${index}`)}
                          onRemove={this.props.removeFile}
                          disabled={ this.state.uploading }
                          accept="image/*"
                          className="clear-none"
                        >
                          {page.imageAnswer && !this.state.uploading ? <img src={page.imageAnswer} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                        </Col>
                        </Row>
                        <Row style={{ marginTop: "20px", marginLeft: "30px" }}>
                            <Col xs={24} sm={24} md={12}>Local Upload List <Checkbox style={{ marginLeft: "40px" }} onChange={this.onImageChangeChecked("local")} checked={this.state.imageLib} /></Col>
                            <Col xs={24} sm={24} md={12}>Shared Library <Checkbox style={{ marginLeft: "40px" }} onChange={this.onImageChangeChecked("shared")} checked={!this.state.imageLib} /></Col>
                        </Row>
                        <Row style={style}>
                        <Col xs={24} sm={24} md={11}>
                          <Select
                            onChange={this.handleBranchingImageSelectChange(index)}
                            value={this.formatAWSLinkforFrontEnd(page.imageAnswer)}
                            placeholder="Select a previously uploaded file"
                            disabled={!this.state.imageLib}
                          >
                            {this.selectImageOptionsLibrary()}
                          </Select>
                        </Col>
                        <Col xs={24} sm={24} md={2}></Col>
                        <Col xs={24} sm={24} md={11}>
                          <Select
                            onChange={this.handleBranchingImageSelectChange(index)}
                            value={this.formatAWSLinkforFrontEnd(page.imageAnswer)}
                            placeholder="Select a previously uploaded file"
                            disabled={this.state.imageLib}
                          >
                            {this.selectImageOptionsShared()}
                          </Select>
                        </Col>
                        </Row>
                        <Form.Item
                              validateStatus={(page.errors.errorMsg!==""||'')? (page.errors.errorMsg.indexOf("only")>-1 ? 'warning':'error'):''}
                              help={page.errors.errorMsg||""}
                              label="Answer Text"
                            >
                            <TextArea placeholder="Answer" onChange={this.handleAnswerArrayChange(index)} value={page.textAnswer} rows={4} />
                        </Form.Item>
                        </React.Fragment>

                        :

                        (page.type === "VirtualTourNetwork.TextPageData")? 

                        <Row>
                          <Col>
                            <Form.Item
                              validateStatus={(page.errors.errorMsg!==""||'')? (page.errors.errorMsg.indexOf("only")>-1 ? 'warning':'error'):''}
                              help={page.errors.errorMsg||""}
                              label="Answer Text"
                            >
                            <TextArea placeholder="Answer" onChange={this.handleAnswerArrayChange(index)} value={page.textAnswer} rows={4} />
                          </Form.Item>
                          </Col>
                        </Row>
                        :
                        <React.Fragment>
                        <Row>
                            <span style={{ display: "inline-block" }}>Answer Image : </span>
                        </Row>
                        <Row style={{ marginTop: "20px"}}>
                        <Col span={12}>
                        <Upload
                          listType="picture-card"
                          showUploadList={false}
                          beforeUpload={this.beforeUploadImage}
                          onChange={this.handleBranchingImageChange(index)}
                          customRequest={this.upload(`Answers,${index}`)}
                          onRemove={this.props.removeFile}
                          disabled={ this.state.uploading }
                          accept="image/*"
                          className="clear-none"
                        >
                          {page.imageAnswer && !this.state.uploading ? <img src={page.imageAnswer} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                        </Col>
                        </Row>
                        <Row style={{ marginTop: "20px", marginLeft: "30px" }}>
                            <Col xs={24} sm={24} md={12}>Local Upload List <Checkbox style={{ marginLeft: "40px" }} onChange={this.onImageChangeChecked("local")} checked={this.state.imageLib} /></Col>
                            <Col xs={24} sm={24} md={12}>Shared Library <Checkbox style={{ marginLeft: "40px" }} onChange={this.onImageChangeChecked("shared")} checked={!this.state.imageLib} /></Col>
                        </Row>
                        <Row style={style}>
                        <Col xs={24} sm={24} md={11}>
                          <Select
                            onChange={this.handleBranchingImageSelectChange(index)}
                            value={this.formatAWSLinkforFrontEnd(page.imageAnswer)}
                            placeholder="Select a previously uploaded file"
                            disabled={!this.state.imageLib}
                          >
                            {this.selectImageOptionsLibrary()}
                          </Select>
                        </Col>
                        <Col xs={24} sm={24} md={2}></Col>
                        <Col xs={24} sm={24} md={11}>
                          <Select
                            onChange={this.handleBranchingImageSelectChange(index)}
                            value={this.formatAWSLinkforFrontEnd(page.imageAnswer)}
                            placeholder="Select a previously uploaded file"
                            disabled={this.state.imageLib}
                          >
                            {this.selectImageOptionsShared()}
                          </Select>
                        </Col>
                        </Row>
                        </React.Fragment>
                      }
                      </Row>
                      <Row style={style}>
                        <Row>
                            <span style={{ display: "inline-block" }}>Target State : </span>
                        </Row>
                        <Col xs={24} sm={24} md={11}>
                          <Select
                            onChange={this.handleBranchingStateSelectChange(index)}
                            value={this.formatAWSLinkforFrontEnd(page.targetState)}
                            placeholder="Select the state to direct this hotspot to"
                          >
                            {this.selectStateOptions()}
                          </Select>
                        </Col>
                      </Row>
                      <Row style={style}>
                        <Row>
                            <span style={{ display: "inline-block" }}>Target Pano : </span>
                        </Row>
                        <Col xs={24} sm={24} md={11}>
                          <Select
                            onChange={this.handleBranchingPanoSelectChange(index)}
                            value={page.targetPano}
                            placeholder="Select the pano to link this hotspot to"
                          >
                            {this.selectPanoOptions()}
                          </Select>
                        </Col>
                      </Row>
                      </React.Fragment>
  
                    }
                </div>
              )
            }
              <Button disabled={this.state.AnswerData.length===4} type="primary" onClick={()=> this.addAnswer()} style={{marginTop: "20px"}}>
                Add Another Answer
              </Button>
          </div>
         
            )
        case 'VirtualTourNetwork.CaptionData':
            return (
                <div>
                    <Row style={style}>
                        <Col>
                            <Form.Item
                                validateStatus={(this.state.errors.Message.errorMsg !== "" || '') ? (this.state.errors.Message.errorMsg.indexOf("only") > -1 ? 'warning' : 'error') : ''}
                                help={this.state.errors.Message.errorMsg || ""}
                            >
                                <Row style={style}>
                                    <Col span={18}>
                                        <span style={{ display: "inline-block", marginRight: "10px" }}>Text Size : </span><Radio.Group options={TextSizes} onChange={this.handleCheckBoxChange("TextSize")} value={this.state.TextSize} style={{ marginTop: "10px" }} />
                                    </Col>
                                </Row>
                                <Row style={style}>
                                    <Col span={18}>
                                        <span style={{ display: "inline-block", marginRight: "10px" }}>Text Align : </span><Radio.Group options={TextAlignOptions} onChange={this.handleCheckBoxChange("TextAlign")} value={this.state.TextAlign} style={{ marginTop: "10px" }} />
                                    </Col>
                                </Row>

                                <Row style={style}>
                                    <Col span={18}>
                                        <span style={{ display: "inline-block", marginRight: "10px" }}>Text Color : </span><Radio.Group options={TextColorOptions} onChange={this.handleCheckBoxChange("TextColor")} value={this.state.TextColor} style={{ marginTop: "10px" }} />
                                    </Col>
                                </Row>

                                <Row style={style}>
                                    <span style={{ display: "inline-block", marginRight: "10px" }}>Background Color : </span><Radio.Group options={TextBGOptions} onChange={this.handleCheckBoxChange("TextBG")} value={this.state.TextBG} style={{ marginTop: "10px" }} />
                                </Row>

                                <TextArea placeholder="Message" onChange={this.handleChange("Message")} value={this.state.Message} maxLength={120} rows={2} />
                                <Row style={style}>
                                    <Col span={18}>
                                        <span style={{ display: "inline-block", marginRight: "10px" }}>Icon: </span>
                                        <Radio.Group
                                            onChange={this.handleCheckBoxChange("IconColor")}
                                            value={this.state.IconColor}
                                            style={{ marginTop: "10px" }}
                                        >
                                            <Radio value="black"><Avatar size={60} src={this.props.icons.Caption_icon_black} /></Radio>
                                            <Radio value="white"><Avatar style={{ backgroundColor: "#000" }} size={60} src={this.props.icons.Caption_icon_white} /></Radio>
                                        </Radio.Group>
                                    </Col>
                                </Row>  
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            )
      default:
        return(<div>Select an option</div>)
    }
  }
  render() {
    return (
      <div>
        <Modal
            visible={this.props.visible}
            title="Assign Hotspot"
            okText="Assign"
            onCancel={this.props.onCancel}
            onOk={this.saveOrder}
            footer={[
              <Button key="back" onClick={this.props.onCancel}>
                Cancel
              </Button>,
              <Button key="submit" type="primary" disabled={this.state.hasErrors} onClick={this.saveOrder}>
                Assign
              </Button>
            ]}
        >
          <form>
            <Row style={{padding:'6px 0'}}>
              <Col span={18}>
                <Select
                  //style={{ width: 120 }}
                  onChange={this.handleTypeSelectChange("type")}
                  value={this.state.type}
                >
                  {this.selectOptions()}
                </Select>
              </Col>
            </Row>
            {
              this.forms()
            }
          </form>
        </Modal>
        <div>
          
        </div>
      </div>
    );
  }
}

export default AssignmentForm;