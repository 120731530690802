import React, { Component } from 'react';
import { connect } from 'react-redux';
import { message, Col, Row, Button } from 'antd';
import { getUserProgress, getCompanyToursViewer } from '../../api/APIget';
import { restartTourProgress } from '../../api/APIpost';
import { getCurrentDate, getGradePoints, totalTime, getGradeFromPoints } from '../../utils/tools';
import { GradeChart } from './gradeChart';
import { CourseOverView } from './courseOverView';
import { CourseList } from './courseList';

interface State {
    loading: boolean,
    inProgressCourses:any[],
    sortedCourses:any[],
    coursesCurrentlyInProgress:any[],
    coursesCompleted:any[],
    coursesFailed:any[],
    newCourses:any[],
    courseName: any[],
    courseGrade: any[],
    totalTime: string,
    GPA: string
};
class UserGrades extends Component<any, State> {
    state:State={
      loading:false,
      inProgressCourses:[],
      sortedCourses:[],
      coursesCurrentlyInProgress:[],
      coursesCompleted:[],
      coursesFailed:[],
      newCourses:[],
      courseName:[],
      courseGrade: [],
      totalTime:'',
      GPA: ''
    }
    public constructor(props:any){
        super(props);
      }
      componentDidMount(){
        //console.log(this.props.auth.user.nameid);
        getUserProgress(this.props.auth.user.nameid,this.getSuccess,this.getError);
       
      }
      getSuccess=(data:any)=>
      {
          //console.log(data.data);
          let currentProgress = data.data.filter((data: any) => { return (!data.tourProgress.completed) });
          let currentCompleted = data.data.filter((data: any) => { return (data.tourProgress.completed) });
          
          //console.log(currentCompleted);
          //console.log(currentProgress);
          let totalTimeSpent = totalTime(data.data); 
          //let sortedCourses = currentCompleted.sort((a:any,b:any) => (a.tourProgress.completedAt < b.tourProgress.completedAt) ? 1 : ((b.tourProgress.completedAt < a.tourProgress.completedAt) ? -1 : 0));
          let sortedCourses:any[]=[];
          for (var i = 0; i < data.data.length; i++) {
              sortedCourses.push({
                  name: data.data[i].tourInfo.tourName,
                  started: data.data[i].tourProgress.startedAt,
                  completed: data.data[i].tourProgress.completedAt,
                  timeSpent: data.data[i].tourProgress.timeSpent,
                  scenes: data.data[i].tourProgress.visitedPanoIds.length,
                  hotspots: data.data[i].tourProgress.visitedhotspots.length,
                  totalPanos: data.data[i].tourProgress.totalPano,
                  totalHotspots: data.data[i].tourProgress.totalhotspot,
                  grade: data.data[i].grade,
                  complete:
                      (data.data[i].tourProgress.completed)
                          ?
                          'Completed'
                          : 'Pending',
                  pass:
                      (data.data[i].tourProgress.completed)
                          ?
                          (data.data[i].grade !== 'F')
                              ? <span className="tealText">Pass</span>
                              : <span className="redText">Fail</span>
                          : '-',

              });
          }

          //console.log(coursePoints);
        this.setState((state)=>({
          inProgressCourses:data.data,
          coursesCurrentlyInProgress:currentProgress,
          coursesCompleted:currentCompleted,
          sortedCourses:sortedCourses,
          totalTime: totalTimeSpent,
        }));
          let coursePoints: any[] = [];
          let courseNames: any[] = [];
          // Course Names for graph
          currentCompleted.map((data: any, index: number) => coursePoints.push(getGradePoints(data.grade)));
          // Course Points/Grades for graph
          currentCompleted.map((data: any, index: number) => courseNames.push(data.tourInfo.tourName));
          let failed = currentCompleted.filter((data: any) => { return (data.grade === 'F') });
          //console.log(coursePoints);
          let totalGrades = 0;
          for (let i = 0; i < coursePoints.length; i++) {
              totalGrades = totalGrades + coursePoints[i];
          }
          let average = getGradeFromPoints(Math.round(totalGrades / coursePoints.length));
          this.setState({
              courseName: courseNames,
              courseGrade: coursePoints,
              GPA: average,
              coursesFailed: failed,
          });
          getCompanyToursViewer(this.props.auth.company.id,this.getToursSuccess,this.getError);
      }
      getToursSuccess=(data:any)=>
      {
        //console.log(data.data);
        let toursNotInProgress = data.data.filter((elem: any) => !this.state.inProgressCourses.find(({ tourInfo }) => elem.id === tourInfo.id));
        
        //console.log(toursNotInProgress);
        this.setState((state)=>({newCourses:toursNotInProgress}));
      }
      getError=(data:any)=>
      {
        console.log(data);
        message.error("There was a problem getting your courses");
      }
      restart=(tourProgress:any)=>{
        // let updateTourProgressObject={
        //   Id:tourProgress.id,
        //   UserId:tourProgress.userId,
        //   TourId:tourProgress.tourId,
        //   CompanyId:tourProgress.companyId,
        //   currentPano:0,
        //   totalPano:tourProgress.totalPano,
        //   submittedAt:getCurrentDate()
        // }
        restartTourProgress(tourProgress.tourId,this.restartSuccess,this.postError);
      }
      restartSuccess=(data:any)=>
      {
        window.location.assign(`/viewer/${data.data.tourId}`);
      }
      postError=(data:any)=>
      {
        console.log(data);
        message.error("There was a problem restarting the tour");
      }

    render() {
        return (
            <Row className={this.props.auth.layout.bgColor}>
            <Row type="flex" style={{padding:"10px"}}> 
              <h2 className={`bebas ${this.props.auth.layout.sortColor}`} >GRADE SUMMARY</h2>
            </Row>
            <Row type="flex" justify="space-around" style={{padding:"10px"}} >
              <Col xs={24} sm={24} md={17}>
                <CourseList  
                data={this.state.sortedCourses}
                restart={this.restart}
                />
              </Col>
              <Col xs={24} sm={24} md={6}>
                <Row >
                  <CourseOverView 
                   coursesAvail={this.state.newCourses.length}
                   coursesCompleted={this.state.coursesCompleted.length}
                   coursesFailed={this.state.coursesFailed.length}
                   coursesInProgress={this.state.coursesCurrentlyInProgress.length}
                   totalTime={this.state.totalTime}
                   GPA={this.state.GPA}
                   textColor={this.props.auth.layout.h2Color}
                  />
                </Row>
              </Col>
            </Row>
            <Row>
                <GradeChart 
                    xAxis={this.state.courseName}
                    yAxis={this.state.courseGrade}
                    h1Color={this.props.auth.layout.secondaryColor}
                />
            </Row>
        </Row>
        );  
      }
}
function mapToStateToProps(state: any) {
  return {
      auth: state.auth,
      management: state.management,
      
  };
}
export default connect(mapToStateToProps)(UserGrades);