import React, { Component} from 'react';
import 'aframe';
import {Entity} from 'aframe-react';
import { changePaginationPos } from '../../utils/aframetools';

interface Props {
    data:any
    id:number,
    panoid:number,
    viewedHotspot: (panoId: number, hotspotId: number) => void,
    interactionsDisabled:boolean,
    started:boolean,
}
interface State {
    imageSize: string,
    imageTrans: string,
    scale: number,
    textPosition: number
    opacity: number,
    img: string,
    imgElement: any,
    height:number,
    width:number
}


class ImageHotspot extends Component<Props, State> 
{
    state: State = {
        imageSize: this.props.data.imageSize,
        imageTrans: this.props.data.imageTrans,
        scale: 2.5,
        opacity: 1,
        img: '',
        textPosition: 1,
        height: 6,
        width: 6,
        imgElement: null,
    }
    componentWillMount() {
        //console.log("#" + this.props.panoid + "image" + this.props.id);
        //console.log(this.props.data);
        this.size();
        this.trans();
    }

    componentDidMount() {
        let imageHotspot:any = document.getElementById(this.props.panoid + "image" + this.props.id);
        //console.log("image mounted: " + this.props.data.fileName);
        if (imageHotspot != null) {
            //console.log(imageHotspot.complete && this.props.data.imagePath == hotspotSrc);
            //console.log("complete:" + imageHotspot.complete);
            let hotspotSrc = imageHotspot.src;
            if (hotspotSrc.lastIndexOf("?t=") > 0) {
                hotspotSrc = imageHotspot.src.substring(0, imageHotspot.src.indexOf("?t="));
            }
            if (imageHotspot.complete && this.props.data.imagePath == hotspotSrc) {
                //console.log("called method");
                this.setState({ imgElement: imageHotspot });
                //state is not updated soon enough to call method
                let currentImageHotspot: any = document.getElementById(this.props.panoid + "imageHotspot" + this.props.id);
                //console.log(this.props.panoid + "imageHotspot" + this.props.id);
                //console.log(this.state.imgElement);
                var h = imageHotspot.height;
                var w = imageHotspot.width;
                var rat = this.FindRatio(w, h);

                currentImageHotspot.setAttribute("height", 1);
                currentImageHotspot.setAttribute("width", rat);
                //console.log("Height: " + 1 + " Width: " + rat);
               
            }
            else {
                //console.log("called eventlistener");
                imageHotspot.addEventListener("load", this.onImgLoad);
                this.setState({ imgElement: imageHotspot });
            }

        }
    }
    componentDidUpdate(prevProps:Props) {
        if (prevProps.data.fileName != this.props.data.fileName)
        {
            console.log("image changed: " + this.props.data.fileName);
            let imageHotspot: any = document.getElementById(this.props.panoid + "image" + this.props.id);
            //console.log(imageHotspot.src);
            //console.log(this.props.data.imagePath);
            if (imageHotspot != null) {
                let hotspotSrc = imageHotspot.src;
                if (hotspotSrc.lastIndexOf("?t=") > 0) {
                    hotspotSrc = imageHotspot.src.substring(0, imageHotspot.src.indexOf("?t="));
                }
                //console.log(imageHotspot.complete && this.props.data.imagePath == hotspotSrc);
                //console.log("complete:" + imageHotspot.complete);
                if (imageHotspot.complete && this.props.data.imagePath == hotspotSrc) {
                    //console.log("called method");
                    this.setState({ imgElement: imageHotspot });
                    //state is not updated soon enough to call method
                    let currentImageHotspot: any = document.getElementById(this.props.panoid + "imageHotspot" + this.props.id);
                    //console.log(this.props.panoid + "imageHotspot" + this.props.id);
                    //console.log(this.state.imgElement);
                    var h = imageHotspot.height;
                    var w = imageHotspot.width;
                    var rat = this.FindRatio(w, h);

                    currentImageHotspot.setAttribute("height", 1);
                    currentImageHotspot.setAttribute("width", rat);
                    console.log("Height: " + 1 + " Width: " + rat);
                  
                }
                else {
                    //console.log("called eventlistener");
                    imageHotspot.addEventListener("load", this.onImgLoad);
                    this.setState({ imgElement: imageHotspot });
                }
               
            }
        }
    }

    onImgLoad=()=> {
        let imageHotspot: any = document.getElementById(this.props.panoid + "imageHotspot" + this.props.id);
        //console.log(this.props.panoid + "imageHotspot" + this.props.id);
        //console.log(this.state.imgElement);
        var h = this.state.imgElement.height;
        var w = this.state.imgElement.width;
        var rat = this.FindRatio(w, h);
        
        imageHotspot.setAttribute("height",1);
        imageHotspot.setAttribute("width", rat);
        /* console.log("Height: " + 1 + " Width: " + rat);*/
        let closingBox: any = document.getElementById(this.props.panoid + "closingBox" + this.props.id);
        closingBox?.setAttribute('visible', 'false');
    }
    componentWillUnmount()
    {
        let showButton = document.getElementById(this.props.panoid + "showButton" + this.props.id);
        showButton?.removeEventListener('click',this.show);
        showButton?.removeEventListener('mouseleave', this.hide);
    }
    FindRatio(a: number, b: number): number {
       
        var rat = a / b;
        rat = Math.round(rat * 10) / 10;
        return rat;
    }
    show =() =>
    {
        if(!this.props.interactionsDisabled && this.props.started)
        {
            let showButton = document.getElementById(this.props.panoid + "showButton" + this.props.id);
            showButton?.setAttribute('visible','false');
            let imageHotspot = document.getElementById(this.props.panoid + "imageHotspot" + this.props.id);
            imageHotspot?.setAttribute('visible', 'true');    
            let closingBox: any = document.getElementById(this.props.panoid + "closingBox" + this.props.id);
            closingBox?.addEventListener("mouseleave", this.hide);
            closingBox?.setAttribute('visible', 'true');
            closingBox?.classList.add("intersectable");
        }  
    }
    hide = () =>
    {
        let showButton = document.getElementById(this.props.panoid + "showButton" + this.props.id);
        showButton?.setAttribute('visible','true');
        let imageHotspot = document.getElementById(this.props.panoid + "imageHotspot" + this.props.id);
        imageHotspot?.setAttribute('visible','false'); 
        this.props.viewedHotspot(this.props.data.parentUID,this.props.data.id);
        //console.log(this.props.viewedHotspot(this.props.data.parentUID,this.props.data.id));
        //console.log(imageHotspot?.offsetHeight);
        let closingBox = document.getElementById(this.props.panoid + "closingBox" + this.props.id);
        closingBox?.setAttribute('visible', 'false');
        closingBox?.classList.remove("intersectable");
    }


    size = () => {
        if (this.state.imageSize == 'large') {
            this.setState({
                scale: 9,
                textPosition: 6,
                height: 16,
                width:16
            });
        }
        else if (this.state.imageSize == 'medium') {
            this.setState({
                scale: 5,
                textPosition: 3.2,
                height: 9,
                width:12
            });
        }
        else {
            this.setState({
                scale: 2,
                textPosition: 1.5,
                height: 4.5,
                width:6
            });
        }
    }

    trans = () => {
        if (this.state.imageTrans == 'fifty') {
            this.setState({
                opacity: 0.5
            });
        }
        else if (this.state.imageTrans == 'twentyFive') {
            this.setState({
                opacity: 0.75
            });
        }
        else {
            this.setState({
                opacity: 1
            });
        }
    }

    getSize() {

    }

    render() {
        return (
            <React.Fragment>
            <Entity
              id={this.props.panoid + "showButton" + this.props.id}
              position={`${this.props.data.position.x} ${this.props.data.position.y} ${this.props.data.position.z}`} 
              rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
              // cursor={{fuse: true}}
              className="intersectable" 
              raycaster="objects: [data-raycastable]"
              events={{click:this.show}}
              animation__hover={{property: 'scale', startEvents: 'mouseenter', from: '1 1 1', to: '3 3 1', dur: 2000}}
              animation__fade={{property: 'material.opacity', startEvents: 'mouseenter', from: '1', to: '0.0', dur: 1500}}
              animation__rescale={{property: 'scale', startEvents: 'mouseleave', from: '2 2 2', to: '1 1 1', dur: 500}}
              animation__refade={{property: 'material.opacity', startEvents: 'mouseleave', from: '0.25', to: '1', dur: 500}}
            >

            <Entity 
              primitive='a-image' 
              geometry={{width:2,height:2}}
              src={(this.props.data.iconColor==="black")?"#image_icon_black":"#image_icon_white"}
              className="intersectable" 
              id={this.props.panoid + "hideButton" + this.props.id}
              raycaster="objects: [data-raycastable]"
              events={{click:this.show}}
            />
                </Entity>
            {
                (this.props.data.message !== null && this.props.data.message !== "") ?
                <Entity 
                    visible={false}
                    id={this.props.panoid + "imageHotspot" + this.props.id}
                    position={changePaginationPos(this.props.data.position)}
                
                    rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
                >
                
                    <a-text
                        visible={true}
                        id={this.props.panoid + "title" + this.props.id}
                        text={`align:center; width: ${this.state.width-0.1}; value: ${this.props.data.message}`}
                        font='french-msdf.json' negate="false"
                        scale="1 1 1"
                        xOffset="2"
                        zOffset="2"
                        position={'0 ' + this.state.textPosition+' 0'}
                            />
                        <Entity
                            src={"#" + this.props.panoid + "image" + this.props.id}
                            scale={this.state.scale + " " + this.state.scale + " " + this.state.scale}
                            opacity={this.state.opacity}
                            primitive='a-image'
                            position="0 -1 0"
                        />
                    <Entity
                      geometry={{ primitive: 'plane', width:this.state.width ,height:this.state.height }} material={{ color: "black",opacity:"0.6", side: "double" }} 
                      id={this.props.panoid + "backplate" + this.props.id}
                      crossOrigin="anonymous"
                      src=""
                      position="0 0 -0.1"
                    />
                    </Entity>
                :
                <Entity
                    visible={false}
                    id={this.props.panoid + "imageHotspot" + this.props.id}
                    src={"#" + this.props.panoid + "image" + this.props.id}
                    scale={this.state.scale + " " + this.state.scale + " " + this.state.scale}
                    opacity={this.state.opacity}
                    primitive='a-image'
                    position={changePaginationPos(this.props.data.position)}
                    rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
                />
            }
            {
                (this.props.data.message !== null && this.props.data.message !== "") ?
                    <Entity geometry={{ primitive: 'plane', width: this.state.width, height: this.state.height }} material={{ color: "blue", opacity: "0.0", side: "double" }}
                        visible={false}
                        id={this.props.panoid + "closingBox" + this.props.id}
                        position={changePaginationPos(this.props.data.position)}
                        rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
                    />
                    :
                    <Entity geometry={{ primitive: 'plane', width: this.state.scale, height: this.state.scale }} material={{ color: "blue", opacity: "0.0", side: "double" }}
                        id={this.props.panoid + "closingBox" + this.props.id}
                        visible={false}
                        position={changePaginationPos(this.props.data.position)}
                        rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
                    />
            }
            </React.Fragment>
        )
    }
}
export default ImageHotspot;