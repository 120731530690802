import React, { Component } from 'react';
import { connect } from 'react-redux';
import { message, Col, Row, Button, Avatar, Descriptions, Icon, Statistic, Input } from 'antd';
import { authCode } from '../../api/JanusHostApiPost';
interface State {
    deadline: any,
    authCode: string
};

const { Countdown } = Statistic;

class ProfilePage extends Component<any, State>{
    state: State = {
        deadline: Date.now(),
        authCode:""
    }
    public constructor(props: any) {
        super(props);
    }
    componentDidMount() {
    }

    generateAuthCode = () => {
        authCode(this.successCode, this.getError);
    }

    successCode = (data: any) => {
        console.log(data);
        this.setState({ authCode: data, deadline: Date.now() + 3 * 60*1000})
    }

    onFinish=()=> {
        this.setState({ authCode: "" });
    }

    getError = (data: any) => {
        message.error("There was a problem getting information. Check the console");
        console.log(data);
    }

    clipboard = () => {
        navigator.clipboard.writeText(this.state.authCode);
        message.success("Copied to clipboard");
    }

    render() {
        return (
            <React.Fragment>
                <Row type="flex" justify="space-between">
                        <Row style={{textAlign:"center"}}>
                        <h1 className={this.props.auth.layout.h1Color} style={{ fontSize: "1.5em" }}>My Profile</h1>
                            {
                            (this.state.authCode !== "") ?
                                <div style={{ padding: "20px", margin: "20px", border:"3px solid #4ECDC4"}}>
                                    <Input value={this.state.authCode}
                                        addonAfter={<span style={{cursor:"pointer"}}onClick={() => this.clipboard()}>Copy <Icon type="copy" /></span>}
                                        style={{width:"180px", marginBottom:"20px"}}
                                    />
                                    <Countdown style={{ color: "#fff" }} title="Valid For:" value={this.state.deadline} onFinish={this.onFinish} />
                                </div>
                                    :
                                <Button ghost style={{ margin: "20px" }} icon="login" type="danger" onClick={this.generateAuthCode}>Generate Login Code</Button>
                            }
                            <Descriptions column={1}>
                                <Descriptions.Item >
                                    <Avatar
                                        shape="circle"
                                        src={
                                            this.props.profile.avatarImage
                                        }
                                        size={84}
                                    ></Avatar>{' '}
                                </Descriptions.Item>
                                <Descriptions.Item >
                                    <h2 className={this.props.auth.layout.h1Color}>{this.props.profile.firstName + " " + this.props.profile.lastName}</h2>
                                </Descriptions.Item>
                                <Descriptions.Item >
                                <span className={this.props.auth.layout.secondaryColor}>{this.props.profile?.userInformation?.jobPosition}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label="">
                                    <Icon className="tealText" type="mail" />{' '}
                                    <span className={this.props.auth.layout.secondaryColor}>{this.props.profile.email}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label="">
                                    <Icon className="tealText" type="phone" />{' '}
                                    <span className={this.props.auth.layout.secondaryColor}>{this.props.profile.phoneNumber}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label="">
                                    <Icon className="tealText" type="home" />{' '}
                                    <span className={this.props.auth.layout.secondaryColor}>{`${this.props.profile?.userInformation?.address}`}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label="">
                                    <Icon className="tealText" type="environment" />{' '}
                                    <span className={this.props.auth.layout.secondaryColor}>{this.props.profile?.userInformation?.city +
                                    ', ' +
                                    this.props.profile?.userInformation?.province}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label="">
                                    <Icon className="tealText" type="environment" />{' '}
                                    <span className={this.props.auth.layout.secondaryColor}>{this.props.profile?.userInformation?.postalCode}</span>
                                </Descriptions.Item>
                            </Descriptions>
                        </Row>
                </Row>
            </React.Fragment>
        );
    }
}

function mapToStateToProps(state: any) {
    return {
        auth: state.auth,
        management: state.management,

    };
}
export default connect(mapToStateToProps)(ProfilePage);