import React, { Component } from 'react';
import { Button,Avatar,Row,Col, Card, Icon, Popover} from 'antd';
import CourseTotals from './CourseTotals';
import tour_icon from '../../assets/Icons/VRS_asset_360globe_icon.png'
import { AWS_URL } from '../../app/FrontEndVariables';
const ManageCoursesCard  = (props:any) =>
{
    return(
      <Card
          className={props.layout.bgColor}
          style={{ minWidth: "320" }}
          size="small"
          cover={
              <div>
                  <Row type="flex">
                      {
                          (props.tour.tourIcon !== "" && props.tour.tourIcon !== null) ?
                              <Col>
                                  <Avatar shape="square" size={76} src={AWS_URL + props.tour.tourIcon} />
                              </Col>
                              :
                              <Col>
                                  <Avatar shape="square" size={84} src={tour_icon} />
                              </Col>
                      }
                      <Col id="CourseInfo" xs={24} sm={24} md={12} style={{ marginBottom: "0", marginLeft: "20px", fontWeight: 600 }}>
                          <Row style={{height: "50px", overflow:"hidden"}}>
                              <h3 className={props.layout.h2Color}>{props.tour.tourName}</h3>
                              
                          </Row>
                          <small className={props.layout.span}>{props.company}</small>
                          <Row style={{marginTop:"8px"}}>
                              <CourseTotals
                                  course={props.tour}
                                  layout= {props.layout}
                              />  
                          </Row>
                      </Col>
                      
                  </Row>
              </div>
          }
          actions={[
              <span className={props.layout.cardButtons} onClick={() => { window.location.href = `/coursedetails/${props.tour.id}` }}><Icon type="info-circle" style={{ marginRight: "10px" }} />Course Profile</span>,
              <span className={props.layout.cardButtons} onClick={() => { window.location.href = `/tour/0/${props.tour.id}` }}><Icon type="edit" style={{ marginRight: "10px" }} />Edit Course</span>,
          ]}
      >
      </Card>
    
  );
}
interface State {
    loading: boolean,
    key: string,
};
class AllCoursesCard extends Component<any, State> {
    state:State={
      loading:false,
      key:'1',
    }
    public constructor(props:any){
        super(props);
      }
      componentDidMount(){
          //console.log(this.props);
      }

    inProgress = () => {
        //console.log(this.props);
    }
      render() {
        return (
            <Row type="flex"  style={{ height: "575px", overflowY: "auto", display: "flex", flexWrap: "nowrap", flexDirection: "column", padding: "14px", marginTop: "20px" }}>
                <Row type="flex" justify="space-between">
                {
                  this.props.available.map((value:any,index:number)=>
                      <Col key={index} xs={24} sm={24} md={8} style={{ padding: "10px" }} ><ManageCoursesCard tour={value} company={this.props.company} layout={ this.props.layout}/></Col>
                  )
                }
                </Row>
            </Row>
        );
}
}
export default AllCoursesCard;