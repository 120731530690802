import React, { Component } from 'react';
import { Switch } from 'antd';

interface State 
{
    
};

class AccessToggle extends Component<any, State> {
    state:State={

    }
    public constructor(props:any){
        super(props);
        
      }

      componentDidMount(){
      
       
      }

      handleChange = (checked:boolean) =>
      {
        if(checked)
        {
          this.props.createTagAccess(this.props.text.id);

        }
        else
        {
          this.props.removeTagAccess(this.props.text.id);
        }
        //console.log(checked);

      }

      isChecked(data:any)
      {
        // console.log(this.props.user.accessLevel);
        if(data.accessLevel==="Public")
        { 
          // console.log("data.accessLevel===Public");
          return true
        }
        else if(this.props.user.accessLevel===2)
        {
          // console.log("this.props.user.accessLevel===Private");
          return true;
        }
        else if(this.props.user.accessLevel===data.accessLevelId)
        {
          // console.log("this.props.user.accessLevel===data.accessLevelId");
          return true;
        }
        else if(this.props.specialAccessTags.includes(data.id))
        {
          // console.log("this.props.specialAccessTags.includes(data.id)");
          return true;
        }
        else{
          return false;
        }
        
      }

      render() {
        return (
            <Switch 
                loading={this.props.loading}
                checked={this.isChecked(this.props.text)}
                onChange={this.handleChange}
                
            />
        );
}
}
export default AccessToggle;