import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addFlashMessage } from './flashMessage';
import { Redirect } from 'react-router-dom';
import { message } from 'antd';

const managerRoutes = ["/overview", "/manage", "/coursedetails/:id", "/manageAccess", "/viewTags", "/updateTag/:id", "/createTag", "/tour/:component/:id?", "/company", "/manage-grades", "/teammate-grades/:id"];
const userRoutes = ["/home", "/progress", "/grades", "/details/:id", "/viewer/:id", "/company"];
const loginRoutes = ["/", "/management/login", "/user/login", "/login"]
export default function checkManagmentPermissions(ComposedComponent)
{
    
    class RedirectUsers extends Component {
    componentDidMount() 
    {
        console.log(this.props);
        if (!this.props.isAuthenticated && !loginRoutes.includes(this.props.match.path)) {
            window.location.href='/';
        }
    }
    componentDidUpdate(nextProps) {
        if (!nextProps.isAuthenticated && !loginRoutes.includes(this.props.match.path)) {
            return (<Redirect to='/' />);
        }
    }

    successAccess = (data) => {
        console.log(data);
    }

    error = (data) => {
        console.log(data)
    }
    message()
    {
        message.error("There was a problem getting your credentials. Contact your company Owner to grant you permissions");
    }

        render() {  
        if ((typeof this.props.management.menuSettings === "undefined" || !this.props.isAuthenticated) && loginRoutes.includes(this.props.match.path)) {
            return (<ComposedComponent {...this.props} />);
        }
        else if (typeof this.props.management.menuSettings=== "undefined" || !this.props.isAuthenticated)
        {
            return ( <Redirect to='/'/> );
        }
        else if (this.props.isAuthenticated && (
            (this.props.management.menuSettings.role === "User" && this.props.management.menuSettings.menu === "VRSafety ShareContent Login" && this.props.match.path === "/shared")
            || (this.props.management.menuSettings.role === "User" && this.props.management.menuSettings.menu !== "VRSafety ShareContent Login" && userRoutes.includes(this.props.match.path) && this.props.components.paths.includes(this.props.match.path) && !loginRoutes.includes(this.props.match.path))
            || (this.props.management.menuSettings.role !== "User" && managerRoutes.includes(this.props.match.path) && this.props.components.paths.includes(this.props.match.path) && !loginRoutes.includes(this.props.match.path)))
            || (this.props.match.path === "/manageComponentAccess" && this.props.user.email ==="janus@bsdev.ca"))
        {
            
            return ( <ComposedComponent {...this.props} /> );
        }
        else if(this.props.management.menuSettings.role === "User" && this.props.management.menuSettings.menu === "VRSafety ShareContent Login" && this.props.match.path !== "/shared" )
        {
            console.log("local storage cleared");
            localStorage.clear();
            return ( <Redirect to='/'/> );
        }
        else if (this.props.management.menuSettings.role !== "User"  && (userRoutes.includes(this.props.match.path) || this.props.match.path === "/shared" || loginRoutes.includes(this.props.match.path)))
        {
            return ( <Redirect to='/manage'/> );
        }
        else if (this.props.management.menuSettings.role === "User" && this.props.management.menuSettings.menu !== "VRSafety ShareContent Login" && (managerRoutes.includes(this.props.match.path) || this.props.match.path === "/shared" || loginRoutes.includes(this.props.match.path) ))
        {
            return ( <Redirect to='/home'/> );
        }
            
        else{
            return ( <Redirect to='/'/> );
        }
    }
}

  RedirectUsers.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    user:PropTypes.object.isRequired,
    addFlashMessage: PropTypes.func.isRequired,
    management: PropTypes.object.isRequired,
  }

  RedirectUsers.contextTypes = {
    router: PropTypes.object.isRequired
  }

function mapStateToProps(state) {
        return {
            isAuthenticated: state.auth.isAuthenticated,
            user: state.auth.user,
            permissions: state.auth.permissions,
            management: state.management,
            layout: state.auth.layout,
            components: state.auth.components
        };
  }

  return connect(mapStateToProps, { addFlashMessage })(RedirectUsers);
}