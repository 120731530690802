import React, { Component } from 'react';
import { connect } from 'react-redux';
import { message, Col, Row, Button, Progress, Carousel, Typography, Icon, Layout } from 'antd';
import { getUserTourProgress, getTourProfileInformation, getUserProgress, getAvailableCourses, getCriticalHotspots, getTourUsers } from '../../../api/APIget';
import { BrowseCourses } from '../../BrowseCourses/browseCourses';
import { ClassStatistics } from './ClassStatistics';
import { InProgressCourseNoAvatar } from './UserProgressStats';
import { getCurrentDate, getGrade, getGradePoints, getGradeFromPoints, averageTime, getTotalhotspotIds } from '../../../utils/tools';
import { restartTourProgress } from '../../../api/APIpost';

const { Paragraph } = Typography;
const { Content } = Layout;

interface State {
    loading: boolean,
    tour: any,
    tourProgress: any,
    hascourseProgress: boolean,
    coursesCurrentlyInProgress: any[],
    coursesCompleted: any[],
    availableCourses: any[],
    grade: string,
    criticalHotspots: any[],
    classAverage: string,
    classTime: string,
    totalCompleted: number,
    filteredTours: any[]
};
class CourseProfile extends Component<any, State> {
    state: State = {
        loading: false,
        tour: {},
        tourProgress: {},
        hascourseProgress: false,
        coursesCurrentlyInProgress: [],
        coursesCompleted: [],
        availableCourses: [],
        grade: '',
        criticalHotspots: [],
        classAverage: '',
        classTime: '',
        totalCompleted: 0,
        filteredTours:[]
    }
    private carousel = React.createRef<Carousel>();
    public constructor(props: any) {
        super(props);
    }
    componentDidMount() {
        //console.log(this.props.auth.user.nameid);

        getTourProfileInformation(this.props.match.params.id, this.getInfoSuccess, this.getError);
        getUserProgress(this.props.auth.user.nameid, this.getProgressSuccess, this.getError);
        getAvailableCourses(this.props.auth.user.nameid, this.getAvailableSuccess, this.getError);
        getCriticalHotspots(this.props.match.params.id, this.getHotspotSuccess, this.getError);
    }

    getSuccess = (data: any) => {

        //console.log(data.data);
        var hasCourseProgress = false
        //console.log(Object.keys(data.data).length);
        //console.log(Object.keys(data.data).length > 0);
        if (Object.keys(data.data).length > 0) {
            hasCourseProgress = true
        }
        this.setState({ tourProgress: data.data, hascourseProgress: hasCourseProgress })
        let grade = getGrade(this.state.tourProgress, this.state.criticalHotspots);
        //console.log(grade);
        this.setState({ grade: grade });

    }

    getInfoSuccess = (data: any) => {
        //console.log(data.data);
        this.setState({ tour: data.data });
        // getting info of all users for this course 
        getTourUsers(data.data.tourId, this.getUserSuccess, this.getError);
        getUserTourProgress({ userId: this.props.auth.user.nameid, tourId: data.data.tourId }, this.getSuccess, this.getError);
    }

    getUserSuccess = (data: any) => {
        //console.log(data.data);
        let points = 0;
        let length = 0;
        let averageGrade = '-';
        let averageTotalTime = '00:00:00';
        let completed = [];
        if (typeof data.data !== "string") {
            for (let i = 0; i < data.data.length; i++) {
                let grade = getGrade(data.data[i], this.state.criticalHotspots);
                if (grade !== '-') {
                    points = points + getGradePoints(grade);
                    length++;
                }
            }
            let completed = data.data.filter((data: any) => { return (data.completed) });
            //console.log(completed);
            let totalTime = this.totalTime(completed);
            let averageTotalTime
            if (totalTime === '00:00:00') {
                averageTotalTime = totalTime
            }
            else {
                averageTotalTime = averageTime(totalTime, completed.length);
            }
            let averageGrade = getGradeFromPoints(Math.round(points / length));
        }

        this.setState({
            classAverage: averageGrade,
            classTime: averageTotalTime,
            totalCompleted: completed.length
        })

    }


    totalTime = (courses: any) => {
        var seconds = 0;
        var minutes = 0;
        var hours = 0;
        for (let i = 0; i < courses.length; i++) {
            var times = [0, 0, 0]
            var max = times.length

            var a = (courses[i].timeSpent || '').split(':')

            // normalize time values
            for (var j = 0; j < max; j++) {
                a[j] = isNaN(parseInt(a[j])) ? 0 : parseInt(a[j]);
            }


            hours = hours + a[0];
            minutes = minutes + a[1];
            seconds = seconds + a[2];

            if (seconds >= 60) {
                var m = (seconds / 60) << 0
                minutes += m
                seconds -= 60 * m
            }

            if (minutes >= 60) {
                var h = (minutes / 60) << 0
                hours += h
                minutes -= 60 * h
            }



        }
        return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2)

    }

    getHotspotSuccess = (data: any) => {
        //console.log(data.data);
        this.setState({ criticalHotspots: data.data });
    }
    getProgressSuccess = (data: any) => {
        console.log(data.data);
        let currentProgress = data.data.filter((data: any) => { return (!data.tourProgress.completed) });
        let currentCompleted = data.data.filter((data: any) => { return (data.tourProgress.completed) });
        this.setState((state) => ({ coursesCurrentlyInProgress: currentProgress, coursesCompleted: currentCompleted }));
    }
    getAvailableSuccess = (data: any) => {
        //console.log(data.data);
        let available = data.data.filter((tour: any) => tour.id !== this.props.match.params.id)
        this.setState((state) => ({ availableCourses: available, filteredTours: available }));
    }

    getError = (data: any) => {
        console.log(data);
        message.error("There was a problem getting your course");
    }
    prev = () => {
        let node = this.carousel.current;
        node!.prev();
    }
    next = () => {
        let node = this.carousel.current;
        node!.next();
    }
    onChange(index: number) {
        //console.log(index);
    }
    restart = (tourProgress: any) => {
        // let updateTourProgressObject={
        //   Id:tourProgress.id,
        //   UserId:tourProgress.userId,
        //   TourId:tourProgress.tourId,
        //   CompanyId:tourProgress.companyId,
        //   currentPano:0,
        //   totalPano:tourProgress.totalPano,
        //   submittedAt:getCurrentDate()
        // }
        restartTourProgress(tourProgress.tourId, this.restartSuccess, this.postError);
    }
    restartSuccess = (data: any) => {
        //console.log(data);
        window.location.assign(`/viewer/${this.state.tour.id}`);
    }
    postError = (data: any) => {
        console.log(data);
        message.error(data.response.data);
    }
    onSearch = (e: any) => {
        console.log(e);
        let newList = this.state.availableCourses.filter(item => {
            const lc = item.tourName.toLowerCase();
            const filter = e.toLowerCase();
            return lc.includes(filter);
        });
        console.log(newList);
        this.setState({
            filteredTours: newList
        });
    }
    render() {
        return (
            <React.Fragment>
                    <Row>
                    <main style={{ padding: "14px" }} className={this.props.auth.layout.bgColor}>
                            <Row type="flex" align="top" justify="space-between" style={{ margin: "12px 0" }} >
                                <Col span={11}>
                                    <Icon type="left" className="carouselButton" onClick={() => this.prev()} />
                                    <Icon type="right" style={{ right: 0 }} className="carouselButton" onClick={() => this.next()} />
                                    <Carousel ref={this.carousel} afterChange={this.onChange}>
                                        {
                                            (typeof this.state.tour.panos === "undefined")
                                                ?
                                                <div>Loading....</div>
                                                :
                                                this.state.tour.panos.map((pano: string, index: number) =>
                                                    <img key={index} className="courseProfileImg" src={pano} alt={`pano ${index}`} />
                                                )
                                        }
                                    </Carousel>

                                </Col>
                                <Col span={12} style={{ marginTop: "-7px" }}>
                                    <Row style={{height: "365px"}}>
                                        <h3 style={{ marginBottom: "0", fontWeight: 600 }} className={this.props.auth.layout.h2Color}>{this.state.tour.tourName}</h3>
                                        <h4 style={{ marginBottom: "20px" }} className={this.props.auth.layout.pColor}>Company Name</h4>
                                        <Paragraph className={this.props.auth.layout.span}>{this.state.tour.tourDescription}</Paragraph>
                                    </Row>
                                    <Row style={{ margin: "12px 0" }}>
                                    <Col span={6}>
                                        {
                                            (this.state.hascourseProgress && !this.state.tourProgress.completed)
                                                ?
                                                <Button icon="reload" onClick={() => this.restart(this.state.tourProgress)}>Restart</Button>
                                                :
                                                null
                                        }
                                    </Col>
                                    <Col span={12}>
                                        {
                                            (this.state.hascourseProgress)

                                                ?
                                                <Row>
                                                    <div style={{ textAlign: "center" }} className="mediumBlueText">
                                                        <span>{`${Math.floor((getTotalhotspotIds(this.state.tourProgress.visitedhotspots) / this.state.tourProgress.totalhotspot) * 100)}% complete`}</span>
                                                    </div>
                                                    <div style={{ textAlign: "center" }} >
                                                        <Progress style={{ width: "80%" }} strokeColor={((getTotalhotspotIds(this.state.tourProgress.visitedhotspots) / this.state.tourProgress.totalhotspot) === 1) ? "#3B77BA" : "#FA8C16"} showInfo={false} percent={((getTotalhotspotIds(this.state.tourProgress.visitedhotspots) / this.state.tourProgress.totalhotspot) * 100)} />
                                                    </div>
                                                </Row>
                                                :
                                                <Row></Row>
                                        }
                                    </Col>
                                    <Col span={6} style={{ textAlign: "end" }}>
                                        {

                                            (this.state.hascourseProgress)

                                                ?
                                                (this.state.tourProgress.completed)

                                                    ?
                                                    <Button icon="reload" type="primary" onClick={() => this.restart(this.state.tourProgress)}>Restart</Button>
                                                    :
                                                    <Button icon="caret-right" type="primary" href={`/viewer/${this.props.match.params.id}`}>Continue</Button>
                                                :
                                                <Button type="primary" href={`/viewer/${this.props.match.params.id}`}>Start</Button>
                                        }
                                    </Col>
                                    </Row>
                                </Col>
                                
                            </Row>
                            <Row>
                            <Col xs={24} sm={24} md={11}></Col>
                            <Col xs={24} sm={24} md={13}>
                            <Row>
                                {
                                    (this.state.hascourseProgress)
                                        ?
                                        <InProgressCourseNoAvatar tour={this.state.tourProgress} grade={this.state.grade} layout={this.props.auth.layout}/>
                                        :
                                        null
                                }
                            </Row>
                            <Row>
                                <ClassStatistics
                                    classGrade={this.state.classAverage}
                                    classTime={this.state.classTime}
                                    completed={this.state.totalCompleted}
                                    layout={this.props.auth.layout}
                                />
                            </Row>
                            </Col>
                            </Row>
                            <Row >
                                <BrowseCourses
                                    bgColor={this.props.auth.layout.bgColor}
                                    titleColor={this.props.auth.layout.h2Color}
                                    title={"COURSE LIBRARY"}
                                    available={this.state.filteredTours} 
                                    company={this.props.auth.company.name} 
                                    onSearch={ this.onSearch }
                                    layout={this.props.auth.layout}
                                />
                                    
                            </Row>
                        </main>
                        
                    </Row>
                    
            </React.Fragment>
        );
    }
}
function mapToStateToProps(state: any) {
    return {
        auth: state.auth,
        management: state.management,
        
    };
}
export default connect(mapToStateToProps)(CourseProfile);