import React from 'react';
import { Button, Icon} from "antd";
import Chart from "react-apexcharts";
import { getGradeFromPoints, getCurrentDate } from '../../utils/tools';


export interface IAppProps {
    yAxis: any[],
    xAxis: any[],
    totalCourses: number
}

export function GradeGraph(props: IAppProps) {
    const options = {
        chart: {
            id: 'basic-bar',
            toolbar: {
                tools: {
                    download: "<Button>Download <Icon type='reload' / ></Button>"
                }
            }
        },
        xaxis: {
            categories: props.xAxis,
            labels: {
                hideOverlappingLabels: true,
                trim: true,
            }
        },
        yaxis: {
            labels: {
                formatter: function (value: number) {
                    return getGradeFromPoints(value);
                }
            }
        }
    }
    const series = [{
        name: 'Grades',
        data: props.yAxis,
    }];
    return (
        (props.yAxis.length===props.totalCourses && props.xAxis.length!=0)?
        <div>
            <h1 style={{borderBottom: "1px solid #ccc", padding: "20px"}}>GRADE STATISTICS</h1>
            <Chart
                options={options}
                series={series}
                width="100%"
                type="bar"
                height="350px"
            />
        </div>
        :null
    );
}
