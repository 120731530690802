import React, { Component } from 'react';
import { isValid } from '../../utils/tools';
import axios from 'axios';
import { NavLink, Link } from 'react-router-dom';
import { Card, Row, Col, Form, Icon, Input, Button, message } from 'antd';
import LoginLogoTeal from '../../assets/Logos/vrsafety-login-logo-teal.png';
import PoweredWithLove from '../../assets/PoweredWithLove/PoweredWithLove';
import { forgotPassword } from '../../api/JanusHostApiPost';

class ForgotPassword extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            errors: [],
            loading: true,
            email: '',
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        this.setState({ loading: false });
    }

    async onSubmit(e: any) {
        this.setState({ loading: true });
        e.preventDefault();
        let checker = isValid(this.state);
        if (checker.isValid) {
            let formData = new FormData();
            formData.append("email", this.state.email);

            //let formData = objectToFormData({ email: this.state.email });
            await forgotPassword(formData, this.successSubmit, this.errorSubmit)
        }
        this.setState({ errors: checker.errors, loading: false });
    }

    successSubmit = (res: any) => {
        this.setState({ loading: false });
        message.success("Password Reset Email Sent");
    }
    errorSubmit = (err: any) => {
        this.setState({ loading: false });
        message.error(err.response.data);
    }

    handleChange = (input: any) => (e: any) => {
        this.setState({ [input]: e.target.value } as any);
        let checker = isValid(this.state);
        this.setState({ errors: checker.errors, loading: false });
    };

    render() {
        return (
            <div className="ant-card loginCard">
                <Row>
                    <Col span={12} style={{ textAlign: 'center' }}>
                        <img
                            src={LoginLogoTeal}
                            alt="Logo"
                            style={{ height: '200px' }}
                        />
                    </Col>
                    <Col span={12} style={{ textAlign: 'center' }}>
                        <h1 className="tealText">Forgot password</h1>
                        <Form onSubmit={this.onSubmit} className="login-form">
                            <Form.Item
                                validateStatus={
                                    this.state.errors.email ? 'error' : ''
                                }
                                help={this.state.errors.email || ''}
                            >
                                <Input
                                    prefix={
                                        <Icon
                                            type="mail"
                                            style={{ color: 'rgba(0,0,0,.25)' }}
                                        />
                                    }
                                    type="email"
                                    value={this.state.email}
                                    placeholder="Email"
                                    onChange={this.handleChange('email')}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Row>
                                    <Col span={12}>
                                        <Button className="bsd-btn-blue">
                                            <Link to={`/`}>Back to log in</Link>
                                        </Button>
                                    </Col>
                                    <Col span={12}>
                                        {this.state.loading ? (
                                            <Button type="primary" loading>
                                                Loading
                                            </Button>
                                        ) : (
                                                <Button
                                                    htmlType="submit"
                                                    className="bsd-btn-blue"
                                                >
                                                    Submit!
                                                </Button>
                                            )}
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Row
                    style={{
                        textAlign: 'center',
                        color: '#fff',
                        padding: '0px',
                        margin: '0px',
                        marginTop: '50px',
                    }}
                >
                    <PoweredWithLove />
                </Row>
            </div>
        );
    }
}

export default ForgotPassword;
