import React, { Component} from 'react';
import 'aframe';
import {Entity} from 'aframe-react';

interface Props {
    data: any
    id:number
    panoid:number
    next:(panoSearch:any)=>void
    viewedHotspot: (panoId: number, hotspotId: number) => void,
    interactionsDisabled:boolean;
    started:boolean,
}
interface State {}

class LinkHotspot extends Component<Props, State> 
{
    state: State = {
    
    }
    componentWillUnmount()
    {
        let link = document.getElementById(this.props.panoid + "linkHotspot" + this.props.id);
        link?.removeEventListener('click',this.props.next);
    }
    completeTour=()=>
    {
        //console.log("go to next page");
        //console.log(this.props.interactionsDisabled);
        if(!this.props.interactionsDisabled && this.props.started)
        {
            //console.log(this.props.data.id);
            this.props.viewedHotspot(this.props.data.parentUID,this.props.data.id);
            this.props.next(this.props.data); 
        }
        
    }
    render(){
        return(
            <Entity 
                id={this.props.panoid + "linkHotspot" + this.props.id}
                geometry={{width:2,height:2}}
                raycaster="objects: [data-raycastable]"
                primitive="a-image"
                src={(this.props.data.iconColor==="black")?"#link_icon_black":"#link_icon_white"}
                className="intersectable"
                events={{click:this.completeTour}} 
                animation__hover={{property:'material.opacity',startEvents:'mouseenter',from:'0.8',to:'0',dur:1500}}
                animation__hoverleave={{property:'material.opacity',startEvents:'mouseleave',from:'0',to:'0.8',dur:1500}}
                position={`${this.props.data.position.x} ${this.props.data.position.y} ${this.props.data.position.z}`} 
                rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
                
            />
        )
    }
}
export default LinkHotspot;