import axios from 'axios';
const JANUS_ADDRESS = "https://janus.bsdev.ca";

export function createSharedLink(payload:any, callback:Function, errorcallback:Function){
    axios.post(JANUS_ADDRESS +'/api/v1/url-shortener/create-short-url/',payload)
    .then(res => {
      //do something
      if(callback != null){
         callback(res);
      }
    })
    .catch(err => {
      // catch error
      if(errorcallback != null){
         errorcallback(err);
      }
    })
}

export function refreshSharedLink(payload: any, callback: Function, errorcallback: Function) {
    axios.post(JANUS_ADDRESS + '/api/v1/url-shortener/refresh-short-url/', payload)
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}

export function loginUser(Model:any, callback: Function, errorCallback: Function) {

    axios
        .put(JANUS_ADDRESS +`/api/v1/auth/login`, {
            email: Model.email,
            password: Model.password,
        })
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errorCallback != null) {
                await errorCallback(err);
            }
        });
}

export function loginUserPerms(callback: Function, data?: string) {
    if (data == undefined && data == null)
        data = "VRSafety";
    axios
        .post(JANUS_ADDRESS +`/api/v1/auth/login-user-perms`,
            { service: data })
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data);
            }
        })
        .catch(async (err) => {
            // catch error
        });
}

export function loginUserPermsAll(callback: Function, data?: string) {
    if (data == undefined && data == null)
        data = "VRSafety";
    axios
        .post(JANUS_ADDRESS +`/api/v1/auth/login-user-perms-all`,
            { service: data })
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data);
            }
        })
        .catch(async (err) => {
            // catch error
        });
}

export function userPermission(config: object,
    callback: Function,
    errCallback: Function) {
    console.log("here");
    axios
        .get(JANUS_ADDRESS +`/api/v1/access-level/user/permissions`, config)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errCallback != null) {
                await errCallback(err);
            }
        });
}

export function forgotPassword(config: object,
    callback: Function,
    errCallback: Function) {
    console.log("here");
    axios
        .post(JANUS_ADDRESS + '/api/v1/account/forgot-password', config)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errCallback != null) {
                await errCallback(err);
            }
        });
}

export function editProfile(formModel: object,
    callback: Function,
    errCallback: Function) {
    console.log("here");
    axios
        .patch(JANUS_ADDRESS + '/api/v1/account/anyProfile', formModel)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errCallback != null) {
                await errCallback(err);
            }
        });
}

export function profileImage(formModel: object,
    callback: Function,
    errCallback: Function) {
    console.log("here");
    axios
        .post(JANUS_ADDRESS + '/api/v1/file/upload/profile/image', formModel)
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errCallback != null) {
                await errCallback(err);
            }
        });
}

export function authCode(
    callback: Function,
    errCallback: Function) {
    axios
        .get(JANUS_ADDRESS + '/api/v1/auth/authcode')
        .then(async (res) => {
            //do something
            if (callback != null) {
                await callback(res.data);
            }
        })
        .catch(async (err) => {
            // catch error
            if (errCallback != null) {
                await errCallback(err);
            }
        });
}
