import React, { Component} from 'react';
import 'aframe';
import { Entity } from 'aframe-react';

interface Props {
    data: any,
    panoid:number,
    id:number,
    viewedHotspot: (panoId: number, hotspotId: number) => void,
    started:boolean,
    interactionsDisabled:boolean;

}
interface State {}

class AudioHotspot extends Component<Props, State> 
{
    state: State = {
    
    }
    componentDidMount =()=>
    { 
        let  audio: any = document.getElementById(this.props.panoid + "audio" + this.props.id);
        if(this.props.started && this.props.data.autoPlay)
        {
           this.autoPlay()
        }
        audio?.addEventListener('sound-ended',this.stopPlaying)
    }
    componentDidUpdate(previousProps:any){
        if (this.props.started !== previousProps.started && this.props.data.autoPlay) 
        {
            this.autoPlay()
        }
      }
    autoPlay=()=>
    {
        let  audio: any = document.getElementById(this.props.panoid + "audio" + this.props.id);
        audio?.classList.add("playing");
    }
    play=()=>
    {
        if(!this.props.interactionsDisabled && this.props.started)
        {
            let  audio: any = document.getElementById(this.props.panoid + "audio" + this.props.id);
            let playButton:any = document.getElementById(this.props.panoid + "playButton" + this.props.id);
            playButton.setAttribute('visible',false);
            playButton.setAttribute("position",`${this.props.data.position.x} -20 ${this.props.data.position.z}`)
            let closeButton:any = document.getElementById(this.props.panoid + "closeButton" + this.props.id);
            closeButton.setAttribute("position",`${this.props.data.position.x} ${this.props.data.position.y} ${this.props.data.position.z}`);
            closeButton.setAttribute('visible',true);
            audio?.components.sound.playSound();
            audio?.classList.add("playing");
        }
    }
    stopPlaying = ()=>
    {
        let  audio: any = document.getElementById(this.props.panoid + "audio" + this.props.id);
        audio?.classList.remove("playing");
        audio?.components.sound.stopSound();
        this.stop();
    }
    stop =()=>
    {
        this.props.viewedHotspot(this.props.data.parentUID, this.props.data.id);
        let closeButton:any = document.getElementById(this.props.panoid + "closeButton" + this.props.id);
        closeButton.setAttribute('visible',false)
        closeButton.setAttribute("position",`${this.props.data.position.x} -20 ${this.props.data.position.z}`);
        let playButton:any = document.getElementById(this.props.panoid + "playButton" + this.props.id);
        playButton.setAttribute("position",`${this.props.data.position.x} ${this.props.data.position.y} ${this.props.data.position.z}`)
        playButton.setAttribute('visible',true);
    }
    render(){
        return(
            <React.Fragment>
                {
                    (this.props.data.autoPlay) ? 
                        <React.Fragment>
                        <Entity
                            visible={true}
                            id={this.props.panoid + "closeButton" + this.props.id}
                            className="intersectable" raycaster="objects: [data-raycastable]"
                            position={`${this.props.data.position.x} ${this.props.data.position.y} ${this.props.data.position.z}`} 
                            rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
                            animation__hover={{property: 'scale', startEvents: 'mouseenter', from: '1 1 1', to: '3 3 1', dur: 2000}}
                            animation__fade={{property: 'material.opacity', startEvents: 'mouseenter', from: '1', to: '0.0', dur: 1500}}
                            animation__rescale={{property: 'scale', startEvents: 'mouseleave', from: '2 2 2', to: '1 1 1', dur: 500}}
                            animation__refade={{property: 'material.opacity', startEvents: 'mouseleave', from: '0.25', to: '1', dur: 500}}
                        >
                            <Entity 
                                geometry={{width:2,height:2}}
                                primitive="a-image"
                                src={(this.props.data.iconColor==="black")?"#audio_icon_black":"#audio_icon_white"}
                                id={this.props.panoid + "AudioPlayingBacking" + this.props.id}
                                className="intersectable" raycaster="objects: [data-raycastable]"
                                cursor={{fuse: true}}
                                events={{click:this.stopPlaying}}
                            />
                        </Entity>
                        <Entity
                            visible={false}
                            className="intersectable" raycaster="objects: [data-raycastable]"
                            id={this.props.panoid + "playButton" + this.props.id}
                            position={`${this.props.data.position.x} ${this.props.data.position.y} ${this.props.data.position.z}`} 
                            rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
                        >
                            <Entity 
                                primitive="a-image"
                                geometry={{primitive: 'plane', width:2, height:2}}
                                id={this.props.panoid + "AudioHotspotBacking" + this.props.id}
                                className="intersectable" raycaster="objects: [data-raycastable]"
                                crossOrigin="anonymous"
                                cursor={{fuse: true}}
                                events={{click:this.play}}
                                src={(this.props.data.iconColor==="black")?"#audio_icon_black":"#audio_icon_white"}
                            />
                        </Entity>
                        </React.Fragment>
                    :
                    <React.Fragment>
                        <Entity
                         className="intersectable" raycaster="objects: [data-raycastable]"
                         id={this.props.panoid + "playButton" + this.props.id}
                         position={`${this.props.data.position.x} ${this.props.data.position.y} ${this.props.data.position.z}`} 
                         rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
                         animation__hover={{property: 'scale', startEvents: 'mouseenter', from: '1 1 1', to: '3 3 1', dur: 2000}}
                         animation__fade={{property: 'material.opacity', startEvents: 'mouseenter', from: '1', to: '0.0', dur: 1500}}
                         animation__rescale={{property: 'scale', startEvents: 'mouseleave', from: '2 2 2', to: '1 1 1', dur: 500}}
                         animation__refade={{property: 'material.opacity', startEvents: 'mouseleave', from: '0.25', to: '1', dur: 500}}
                        >
                            <Entity 
                                primitive="a-image"
                                id={this.props.panoid + "audioBacking" + this.props.id}
                                geometry={{primitive: 'plane', width:2, height:2}} 
                                crossOrigin="anonymous"
                                src={(this.props.data.iconColor==="black")?"#audio_icon_black":"#audio_icon_white"}
                                className="intersectable" raycaster="objects: [data-raycastable]"
                                cursor={{fuse: true}}
                                events={{click:this.play}}
                            />
                        </Entity>
                        <Entity
                            visible={false}
                            id={this.props.panoid + "closeButton" + this.props.id}
                            className="intersectable" raycaster="objects: [data-raycastable]"
                            position={`${this.props.data.position.x} -20 ${this.props.data.position.z}`} 
                            rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
                        >
                            <Entity 
                                primitive="a-image"
                                id={this.props.panoid + "audioPlayingBacking" + this.props.id}
                                geometry={{primitive: 'plane', width:2, height:2}} 
                                className="intersectable" raycaster="objects: [data-raycastable]"
                                crossOrigin="anonymous"
                                src={(this.props.data.iconColor==="black")?"#audio_icon_black":"#audio_icon_white"}
                                cursor={{fuse: true}}
                                events={{click:this.stopPlaying}}
                            />
                        </Entity>
                    </React.Fragment>
                }
                  <a-sound 
                    id={this.props.panoid + "audio" + this.props.id}
                    autoplay={this.props.started && this.props.data.autoPlay}
                    loop={this.props.data.loop}
                    src={'#'+this.props.panoid+'audioSrc'+this.props.id}
                    volume={this.props.data.volume}
                    maxDistance={100}
                    position={`${this.props.data.position.x} ${this.props.data.position.y} ${this.props.data.position.z}`} 
                />
            </React.Fragment>
        )
    }
}
export default AudioHotspot;