import * as React from 'react';
import { Row, Col, Radio, Button, Cascader, Table } from 'antd';
import { getGradeStyle, getDateYMD } from '../../utils/tools';

export interface IAppProps {
    data:any;
    restart:Function
}
export function CourseList (props: IAppProps) {
    const columns = [
        {
            title: "Name",
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: "Start Date",
            dataIndex: 'started',
            key: 'started'
        },
        {
            title: "Time Spent",
            dataIndex: 'timeSpent',
            key: 'timeSpent'
        },
        {
            title: "Complete",
            dataIndex: 'complete',
            key: 'complete',
            filters: [
                {
                    text: 'Completed',
                    value: 'Completed',
                },
                {
                    text: 'Pending',
                    value: 'Pending',
                }
            ],
            onFilter: (value: any, record: any) => record.pass == value,
        },
        {
            title: "Pass/Fail",
            dataIndex: 'pass',
            key: 'pass',
            filters: [
                {
                    text: 'Pass',
                    value: 'pass',
                },
                {
                    text: 'Fail',
                    value: 'fail',
                }
            ],
            onFilter: (value: any, record: any) => record.pass == value,
        },
        {
            title: "Grade",
            dataIndex: 'grade',
            key: 'grade',
        },
    ];
  
  return (

    <div>
          <Table columns={columns} pagination={false} scroll={{ y: 350, x:"auto" }} bordered rowKey={(record: any) => record.id} dataSource={props.data} />
    </div>
  );
}
