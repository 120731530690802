import React, { Component} from 'react';
import 'aframe';
import {Entity} from 'aframe-react';
import { changePaginationPos } from '../../utils/aframetools';

interface Props {
    data: any
    id:number
    panoid:number
    viewedHotspot: (panoId: number, hotspotId: number) => void,
    enableInteractions:()=>void
    started:boolean;
    interactionsDisabled:boolean;

}
interface State 
{
  imageSize: string,
  scale: number,
  audioArray:string[];
  interactableArray:string[];
  materialCheck:any;
}

class VideoHotspot extends Component<Props, State> 
{
    state: State = {
      audioArray:[],
      interactableArray:[],
      materialCheck:{},
      imageSize: this.props.data.imageSize,
      scale: 5
    }
    componentDidMount()
    {
      this.size();
      //console.log(this.props.data.autoPlay);
      if(this.props.started && this.props.panoid === 0 ){
        let htmlvideo: any = document.getElementById(this.props.panoid+'video'+this.props.id);
        //if(typeof htmlvideo!== 'undefined' && htmlvideo !== null)
         // htmlvideo?.addEventListener("canplaythrough",this.addPlayEvent);
        
      }
        if (this.props.data.autoPlay) {
            console.log("here");
            this.props.enableInteractions();
        }
      /*else if(this.props.started && this.props.data.autoPlay && this.props.panoid > 0)
      {
        //get videosphere
        let  video: any = document.getElementById(this.props.panoid + "videoPlayer" + this.props.id);
        //check if texture loaded
        video?.addEventListener('materialtextureloaded',this.addPlayEvent);
      }*/
    }
    componentDidUpdate(previousProps:any){
      /*if (this.props.started !== previousProps.started) 
      {
        if(this.props.data.autoPlay)
          this.addPlayEvent();
      }*/
    }
    /*addPlayEvent=()=>
    {
      let htmlvideo: any = document.getElementById(this.props.panoid+'video'+this.props.id);
      if(typeof htmlvideo!== 'undefined' && htmlvideo !== null)
        htmlvideo.removeEventListener("canplaythrough", this.addPlayEvent);
      //check if video is loaded and ready to play
      if(htmlvideo.readyState===4){
        //check to make sure the material is not null
        let check = setInterval(this.checkMaterial,500);
        this.setState((state)=>{return{materialCheck:check}});
      }
    }*/
    checkMaterial=()=>
    {
      let  video: any = document.getElementById(this.props.panoid + "videoPlayer" + this.props.id);
      //check to make sure the texture is not null
      if(video?.components?.material?.material?.map?.image)
      {
          let videoelement: any = document.getElementById(this.props.panoid + "video" + this.props.id);
          var w = videoelement.videoWidth;
          var h = videoelement.videoHeight;
          var rat = this.FindRatio(w, h);
          video.setAttribute("height",1);
          video.setAttribute("width",rat);

          let closingBox: any = document.getElementById(this.props.panoid + "closingBox" + this.props.id);
          closingBox.setAttribute("geometry", `height: ${1}`);
          closingBox.setAttribute("geometry", `width: ${rat}`);
          closingBox?.setAttribute('visible', 'false');
        //clear loop
        clearInterval(this.state.materialCheck);
          if (this.props.data.autoPlay) {
              //play
              this.play();
          }
      }
    }
    componentWillUnmount(){
      let  video: any = document.getElementById(this.props.panoid + "videoPlayer" + this.props.id);
      video?.components?.material?.material?.map.image.removeEventListener('canplay',((event:any)=>{}));
      video?.components?.material?.material?.map.image.removeEventListener('ended',((event:any)=>{}));
      let  stopButton: any = document.getElementById(this.props.panoid + "stopButton" + this.props.id);
      stopButton?.removeEventListener('click',this.play);
        //stopButton?.removeEventListener('mouseleave', this.stop);
    }
    FindRatio(a: number, b: number): number {
        
        var rat = a / b;
        rat = Math.round(rat * 10) / 10;
        return rat;
    }
    play=()=>
    {
      if(!this.props.interactionsDisabled && this.props.started)
      {
        // let interactablearray:any = document.getElementsByClassName("intersectable");
        // console.log(interactablearray);
        // let interactableIdArray =[];
        // let j=0;
        // while(interactablearray.length>0 && interactablearray.length >j)
        // {
        //   if((typeof interactablearray[j].id !== 'undefined' && interactablearray[j].id !== null && interactablearray[j].id !=="" ) && interactablearray[j].id !== (this.props.panoid + "stopButton" + this.props.id))
        //   {
        //     interactableIdArray.push(interactablearray[j].id);
        //     interactablearray[j].classList.remove("intersectable");
        //   }
        //   else
        //   {
        //     j++;
        //   }
        // }
        // console.log(interactableIdArray);
        let audioArray:any = document.getElementsByClassName("playing");
        let newAudioArray=[];
        for(let i=0;i<audioArray.length;i++)
        {
          newAudioArray.push(audioArray[i].id);
          audioArray[i].components.sound.pauseSound();
        }
        this.setState({audioArray:newAudioArray,});//interactableArray:interactableIdArray
        let playbutton = document.getElementById(this.props.panoid + "playButton" + this.props.id);
        playbutton?.setAttribute('visible','false');
        let  video: any = document.getElementById(this.props.panoid + "videoPlayer" + this.props.id);

        video?.components.material.material.map.image.load();
        //console.log("load");
        video?.components.material.material.map.image.addEventListener('canplay',((event:any)=>{
          let  video: any = document.getElementById(this.props.panoid + "videoPlayer" + this.props.id);
          video?.setAttribute('visible',true);
          //console.log("play");
          video?.components.material.material.map.image.play();
        }));
        
        video?.components.material.material.map.image.addEventListener('ended',((event:any)=>{
          //console.log("ended");
          let  video: any = document.getElementById(this.props.panoid + "videoPlayer" + this.props.id);
          video?.setAttribute('visible','false');
          let playbutton = document.getElementById(this.props.panoid + "playButton" + this.props.id);
        playbutton?.setAttribute('visible', 'true');

        }));



          let closingBox: any = document.getElementById(this.props.panoid + "closingBox" + this.props.id);
          closingBox?.addEventListener("mouseleave", this.stop);
          closingBox?.setAttribute('visible', 'true');
          closingBox?.classList.add("intersectable");
      }
    }
    stop =()=>
    {
      let  video: any = document.getElementById(this.props.panoid + "videoPlayer" + this.props.id);
      video?.setAttribute('visible','false');
      let playbutton = document.getElementById(this.props.panoid + "playButton" + this.props.id);
      playbutton?.setAttribute('visible','true');
        video?.components.material.material.map.image.pause();

        let closingBox = document.getElementById(this.props.panoid + "closingBox" + this.props.id);
        closingBox?.setAttribute('visible', 'false');
        closingBox?.classList.remove("intersectable");

      for(let i=0;i<this.state.audioArray.length;i++)
      {
        let audio:any = document.getElementById(this.state.audioArray[i]);
        audio?.components.sound.playSound(); 
      }
      for(let j=0;j<this.state.interactableArray.length;j++)
      {
        let interactable:any = document.getElementById(this.state.interactableArray[j]);
        interactable.classList.add("intersectable");
      }
        this.props.viewedHotspot(this.props.data.parentUID,this.props.data.id);
    }

    size = () => {
        if (this.state.imageSize == 'large') {
            this.setState({
                scale: 15
            });
        }
        else if (this.state.imageSize == 'medium') {
            this.setState({
                scale: 10
            });
        }
        else {
            this.setState({
                scale: 5
            });
        }
    }
    render(){
        return(
          <React.Fragment>
                <Entity
                    id={this.props.panoid + "playButton" + this.props.id}
                    position={`${this.props.data.position.x} ${this.props.data.position.y} ${this.props.data.position.z}`} 
                    rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
                    // cursor={{fuse: true}}
                    className="intersectable" 
                    raycaster="objects: [data-raycastable]"
                    animation__hover={{property: 'scale', startEvents: 'mouseenter', from: '1 1 1', to: '3 3 1', dur: 2000}}
                    animation__fade={{property: 'material.opacity', startEvents: 'mouseenter', from: '1', to: '0.0', dur: 1500}}
                    animation__rescale={{property: 'scale', startEvents: 'mouseleave', from: '2 2 2', to: '1 1 1', dur: 500}}
                    animation__refade={{property: 'material.opacity', startEvents: 'mouseleave', from: '0.25', to: '1', dur: 500}}
                >

                <Entity 
                    primitive='a-image' 
                    geometry={{width:2,height:2}}
                    src={(this.props.data.iconColor==="black")?"#video_icon_black":"#video_icon_white"}
                    className="intersectable" 
                    id={this.props.panoid + "stopButton" + this.props.id}
                    raycaster="objects: [data-raycastable]"
                    events={{click:this.play}}
                />
                </Entity>
                <a-video visible={false}
                    id={this.props.panoid + "videoPlayer" + this.props.id}
                    src={'#'+this.props.panoid+'video'+this.props.id}
                    scale={this.state.scale + " " + this.state.scale + " " + this.state.scale}
                    position={changePaginationPos(this.props.data.position)} 
                    rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
                /> 
                <Entity geometry={{ primitive: 'plane' }} material={{ color: "blue", opacity: "0.0", side: "double" }}
                    id={this.props.panoid + "closingBox" + this.props.id}
                    position={changePaginationPos(this.props.data.position)}
                    rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
                    visible={false}
                    scale={(this.state.scale + 1) + " " + (this.state.scale + 1) + " " + (this.state.scale + 1)}
                />
          </React.Fragment>
          
        )
    }
}
export default VideoHotspot;