import React, { Component } from 'react';
import { message, Table } from 'antd';
import AccessToggle from './AccessToggle';
import { getCurrentDate } from '../../utils/tools'; 
import { createAccessTag, removeAccessTag } from '../../api/APIpost';

interface State {
    loading: boolean,
    UserData:any,
    userAccessData:{},
    tags:[],
    accessTags:any[]
    specialAccessTags:string[],
};
class ManageTagAccess extends Component<any, any> {
    
    public constructor(props:any){
        super(props);
        this.state={
          loading:true,
          UserData:{},
          userAccessData:{},
          accessTags:[],
          tags:[],
          specialAccessTags:[],
        }
      }
      componentDidMount()
      {
       
      }
      componentWillReceiveProps(nextProps:any) {
        //console.log(nextProps.loading);
        if(!nextProps.loading)
          this.setState({
            loading:nextProps.loading,
            UserData:nextProps.UserData,
            userAccessData:nextProps.userAccessData,
            tags:nextProps.tags,
            accessTags:nextProps.accessTags,
            specialAccessTags:nextProps.specialAccessTags,
          });
      }
      error(error:any)
      {
        console.log(`error!`);
        console.log(error);
      }
      createTagAccess = (tagId:string)=>
      {
        let tag = this.createTagAccessObject(tagId);
        // console.log(tag);
        createAccessTag(tag,this.postTagSuccess,this.error)
      }
      removeTagAccess = (tagId:string)=>
      {
        let tag = this.createTagAccessObject(tagId);
        // console.log(tag);
        removeAccessTag(tag,this.removeTagSuccess,this.error)
      }

      createTagAccessObject = (tagId:string) =>
      {
        // console.log(tagId);
        let accessTagId = this.props.accessTags.filter((access:any) => access.tagID === tagId);
        // console.log(accessTagId)
        return {
          Id:accessTagId[0]?.id,
          UserID: this.props.UserData,
          CompanyId:this.props.companyId,
          TagID: tagId,
          SubmittedAt: getCurrentDate()
        }
      }
      postTagSuccess = (data:any) =>
      {
        let updatedAccess=this.state.accessTags;
        updatedAccess.push(data.data);
        let tagIds = updatedAccess.map( (data:any) => data.tagID);
        this.setState({accessTags:updatedAccess,specialAccessTags:tagIds});
        message.success('Access to the tag was granted');
      }

      removeTagSuccess = (data:any) =>
      {
        //console.log(data.data);
        let updatedAccess=this.state.accessTags;
        let index = updatedAccess.findIndex((elem:any)=> elem.Id === data.data.id);
        updatedAccess.splice(index,1);
        let tagIds = updatedAccess.map( (data:any) => data.tagID);
        this.setState({accessTags:updatedAccess,specialAccessTags:tagIds});
        message.success('Access to the tag was revoked');
      }

      getAccessClass(tag:any)
      {
        if(tag.accessLevel==="Public"){
          return "accessPublic";
        } 
        else if(tag.accessLevel==="Internal" && this.props.userAccessData?.accessLevel >= 1)
        {
          return "accessPublic"
        }
        else if(tag.accessLevel==="Private" && this.props.userAccessData?.accessLevel === 2)
        {
          return "accessPublic"
        }
        else if(this.state.specialAccessTags.includes(tag.id)){
          return "accessTags";
        }
        else if(tag.accessLevel==="Private"){
         return "accessNone"; 
        }
        else{
          return "";
        }
        
      }

      render() {
        const columns = [
            {
                title: 'Tag',
                dataIndex: 'name',
                key: 'name',
                render: (text:any) => <p>{text}</p>,
            },
            {
                title: 'Access Level',
                dataIndex: 'accessLevel',
                key: 'accessLevel',
            },
            {
              title: 'Access',
              key: 'action',
              render: (text:any, record:any) => 
              <div>
                {
                  (text.accessLevel === "Public")? null:
                   <AccessToggle 
                    user={this.props.userAccessData}
                    text={text}
                    loading={this.props.loading}
                    specialAccessTags={this.state.specialAccessTags}
                    createTagAccess={this.createTagAccess}
                    removeTagAccess={this.removeTagAccess}
                  />
                }
              </div>              
            },
          ];
          // console.log(this.state);
          // console.log(this.props.tags);
        return (
          <div>
            
           <Table 
              loading={this.state.loading} 
              rowKey={record => record.id} 
              rowClassName={record=>this.getAccessClass(record)}
              columns={columns} 
              pagination={{pageSize:100}} 
              dataSource={this.state.tags} 
            />
          </div>
        );
}
}
export default ManageTagAccess;