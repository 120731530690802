import { Management } from '../../models/Management';

export const defaultManageState: Management = {
    menuSettings: {
        menu: 'Default',
        role: 'User',
    },
};

export const emptyManageState: Management = {
    menuSettings: {
        menu: '',
        role: '',
    },
};
