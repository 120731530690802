import * as React from 'react';
import { Row, Col, } from 'antd';
import { getDateYMD, getGradeStyle, getTotalhotspotIds } from "../../../utils/tools";

export function InProgressCourseNoAvatar(props: any) {
    return (
        <Row type="flex" align="middle" id="UserCourseInfo">
            <Col xs={24} sm={24} md={3}>
                <Row>
                    <div className={props.auth.layout.h5Color}>
                        <span>My Progress</span>
                    </div>
                </Row>
            </Col>
            <Col xs={24} sm={24} md={21}>
                <Row type="flex" align="middle">
                    <Col className="progressStatsStyle">
                        <span className={`smallText ${props.auth.layout.span}`}>Started On</span>
                        <div style={{ textAlign: "center", alignContent: "baseline" }} > <h3 style={{ fontWeight: "bold" }} className={props.auth.layout.h3Color}>{getDateYMD(props.tour.startedAt)}</h3></div>
                    </Col>
                    <Col className="progressStatsStyle">
                        <span className={`smallText ${props.auth.layout.span}`}>Completed On</span>
                        <div style={{ textAlign: "center", alignContent: "baseline", fontWeight: "bold" }} >
                            <h3 style={{ fontWeight: "bold" }} className={props.auth.layout.h6Color}>
                                {
                                    (props.tour.startedAt === props.tour.completedAt) ? "Pending" : getDateYMD(props.tour.startedAt)
                                }
                            </h3>
                        </div>
                    </Col>
                    <Col className="progressStatsStyle">
                        <span className={`smallText ${props.auth.layout.span}`}>Time Spent</span>
                        <div style={{ textAlign: "center", alignContent: "baseline" }} > <h3 style={{ fontWeight: "bold" }} className={props.auth.layout.h3Color}>{props.tour.timeSpent}</h3></div>
                    </Col>
                    <Col className="progressStatsStyle">
                        <span className={`smallText ${props.auth.layout.span}`}>Scenes Viewed</span>
                        <div style={{ textAlign: "end", alignContent: "baseline" }} > <h3 style={{ fontWeight: "bold" }} className={props.auth.layout.h3Color}>{`${props.tour.visitedPanoIds.length}/${props.tour.totalPano}`}</h3></div>
                    </Col>
                    <Col className="progressStatsStyle">
                        <span className={`smallText ${props.auth.layout.span}`}>Hotspots Visited</span>
                        <div style={{ textAlign: "end", alignContent: "baseline" }} > <h3 style={{ fontWeight: "bold" }} className={props.auth.layout.h3Color}>{`${(getTotalhotspotIds(props.tour.visitedhotspots))}/${props.tour.totalhotspot}`}</h3></div>
                    </Col>
                    <Col>
                        <span className={`smallText ${props.auth.layout.span}`}>My Current Grade</span>
                        <Row type="flex" align="middle" justify="end">
                            <h3 style={{ fontWeight: "bold" }} className={getGradeStyle(props.grade)}>{props.grade}</h3>
                        </Row>
                    </Col>
                </Row>
            </Col>   
        </Row>
    );
}