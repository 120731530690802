import React, { Component } from 'react';
import { Upload, Icon, Modal, Carousel, Button, Spin, message, Checkbox, Row, Col, Collapse, Select, Card, Drawer, List, Typography } from 'antd';
import "./PanoSequence.css";
import { getCurrentDate } from '../../../utils/tools';
import { getTourSequencePano, validateTour } from '../../../api/APIget';
import { getTourResources, getSharedResources, getTourStats } from '../../../api/APIget';
import { deleteFile } from '../../../api/APIpost';
import { createPanoSequence, deletePano, updatePanoImage } from '../../../api/APIpost';
import { Prompt } from 'react-router';
import axios from "axios";
import { AWS_URL } from '../../../app/FrontEndVariables';

const token = localStorage.token;

const { Panel } = Collapse;
const { confirm } = Modal;
const { Option } = Select;

interface State {
    isBlocking: boolean,
    showModal: boolean,
    showDeleteModal: boolean,
    showSharedDeleteModal: boolean,
    swapImageModal: boolean,
    swapSharedImageModal: boolean,
    swapTimelineImageModal: boolean,
    loading: boolean,
    saveLoading: boolean,
    removeImage: any,
    swapImage: any,
    panoToSwap: any,
    fileList: any[],
    uploadList: any[],
    sharedList: any[],
    tourId: string,
    companyId: string,
    uploading: boolean,
    newVersion: boolean,
    versionNum: number,
    deleteShared: boolean,
    isInTimeline: boolean,
    borderHighlight: number,
    collapseLeft: boolean,
    collapseRight: boolean,
    timelineSwapImage: string,
    local: boolean,
    replace: boolean,
    progressionType: boolean,
    tourName: string,
    errors: any,
    errorModal:boolean,
    statModal: boolean,
    stats: any,
    validateModal: boolean,
    validationErrors: any,
    validationWarnings: any,
    swapPanoThumb:string
};
class PanoSequence extends Component<any, State> {
    state: State = {
        isBlocking: true,
        showModal: false,
        showDeleteModal: false,
        showSharedDeleteModal: false,
        swapImageModal: false,
        swapSharedImageModal: false,
        swapTimelineImageModal: false,
        loading: true,
        saveLoading: false,
        uploading: false,
        removeImage: {},
        swapImage: {},
        panoToSwap: {},
        fileList: [],
        uploadList: [],
        sharedList: [],
        tourId: "",
        companyId: "",
        newVersion: false,
        versionNum: 0,
        deleteShared: false,
        isInTimeline: false,
        borderHighlight: 0,
        collapseLeft: false,
        collapseRight: false,
        timelineSwapImage: "",
        local: true,
        replace: false,
        progressionType: false,
        tourName: "",
        errors: {},
        errorModal:false,
        statModal:false,
        stats: {},
        validateModal: false,
        validationErrors: {},
        validationWarnings: {},
        swapPanoThumb:""
    };
    private carousel = React.createRef<Carousel>();
    public draggedItem: any = {};
    public constructor(props: any) {
        super(props);

    }
    componentDidMount=async()=> {
        console.log(this.props);
        this.setState({ companyId: this.props.auth.company.id, });
        await getTourResources(this.props.match.params.id, this.getTourResourcesSuccess, this.getError);
        await getSharedResources(this.props.auth.company.id, this.getSharedResourcesSuccess, this.getError);
        getTourSequencePano(this.props.match.params.id, this.getSuccess, this.getError);
    }
    getSuccess = (data: any) => {
        console.log(data.data);
        this.setState({ fileList: data.data.tour.sceneObjects, tourId: data.data.tour.id, tourName: data.data.tourName, loading: false, progressionType: data.data.courseProgressionType });
        console.log(this.state.fileList);

    }

    getTotalTourStats = () => {
        this.setState({ loading: true });
        getTourStats(this.state.tourId, this.getTotalTourStatsSuccess, this.getStatsError);
    }

    getTotalTourStatsSuccess = (data: any) => {
        console.log(data.data);
        this.setState({ stats: data.data, statModal:true, loading: false });
    }

    getStatsError = (data:any) => {
        console.log(data);
        this.setState({ loading: false });
        message.error("Sorry! Cannot fetch the records currently. Please try again later.");
    }

    validatingTour = () => {
        this.setState({ loading: true });
        validateTour(this.state.tourId, this.validateTourSuccess, this.validateTourError);
    }

    validateTourSuccess = (data: any) => {
        console.log(data.data);
        if (data.data.errors.length === 0 && data.data.warnings.length === 0) {
            message.success("No errors or warnings found in this tour.");
            this.setState({ validationErrors: {}, validationWarnings: {}, validateModal: false,loading: false });
        }
        else {
            this.setState({ validationErrors: data.data.errors, validationWarnings: data.data.warnings, validateModal: true, loading: false });
        }
    }

    validateTourError = (data: any) => {
        console.log(data);
        this.setState({ loading: false });
        message.error("Sorry! Cannot validate tour at this moment. Please try again later.");
    }

    getTourResourcesSuccess = (data: any) => {
        console.log(data.data);
        this.setState({ uploadList: data.data, loading: false });
    }

    getSharedResourcesSuccess = (data: any) => {
        console.log(data.data);
        this.setState({ sharedList: data.data, loading: false });
    }


    getError = (data: any) => {
        console.log(data);
    }
    onDragStart = (e: any, index: any) => {
        //console.log(e);
        //console.log(index);
        this.draggedItem = this.state.fileList[index];
        e.dataTransfer.effectAllowed = "move";
        e.dataTransfer.setData("text/html", e.target.parentNode);
        e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
    };

    onDragOver = (index: any) => {
        const draggedOverItem = this.state.fileList[index];
        //console.log(this.draggedItem);
        // if the item is dragged over itself, ignore
        if (this.draggedItem === draggedOverItem) {
            return;
        }

        // filter out the currently dragged item
        let items = this.state.fileList.filter(item => item !== this.draggedItem);

        // // add the dragged item after the dragged over item
        items.splice(index, 0, this.draggedItem);

        this.setState({ fileList: items });
    };

    onDragEnd = () => {
        this.draggedItem = null;
    };


    swapImageModal = (pano: any) => {
        var isInTimeline = false;
        let panoName = pano.substring(pano.lastIndexOf("/") + 1, pano.length);
        if (pano.length > 1) {
            console.log(pano);
            for (let i = 0; i < this.state.fileList.length; i++) {
                //console.log(this.state.fileList[i].fileName);

                if (panoName == this.state.fileList[i].fileName) {
                    var Listpano = this.state.fileList[i];
                    console.log(pano);
                    isInTimeline = true;
                }
            }
        }
        console.log(this.state.uploadList);
        let panoSwapThumb = pano.substring(0, pano.lastIndexOf("/panos")) + "/panos/thumbnails" + pano.substring(pano.lastIndexOf("/")) + "-Thumbnail" + pano.substring(pano.lastIndexOf("."));
        console.log(panoSwapThumb);
        if (isInTimeline === true) {
            this.setState((state) => { return { swapImageModal: !state.swapImageModal, panoToSwap: Listpano, swapPanoThumb: panoSwapThumb } })
            this.setState({
                isInTimeline: true
            });

        }
        else {
            this.setState((state) => { return { swapImageModal: !state.swapImageModal, panoToSwap: pano, isInTimeline: false, uploading: false, swapPanoThumb: panoSwapThumb  } })
        }
    }

    swapImageCancel = () => {
        this.setState((state) => { return { swapImageModal: false, isInTimeline: false, uploading: false, swapImage: {}, swapPanoThumb:"" } })
    }




    swapTimelineImageModal = (pano: any) => {

        //this.setState((state) => { return { swapTimelineImageModal: !state.swapTimelineImageModal } })
        this.setState({ swapTimelineImageModal: !this.state.swapTimelineImageModal, panoToSwap: pano });
        console.log(pano);
    }

    swapTimelinePano = () => {

        let panoSwap = {
            Id: this.state.panoToSwap.id || 0,
            UID: this.state.panoToSwap.uid,
            ScopicMode: "Monoscopic",
            criticalPano: this.state.panoToSwap.criticalPano || ((this.state.panoToSwap.uid === 0) ? true : false),
            mediaPath: AWS_URL + this.state.timelineSwapImage,
            fileName: this.state.timelineSwapImage.substring(this.state.timelineSwapImage.lastIndexOf("/") + 1, this.state.timelineSwapImage.length),
            niceName: this.state.timelineSwapImage.substring(this.state.timelineSwapImage.lastIndexOf("/") + 1, this.state.timelineSwapImage.lastIndexOf(".")),
            thumbnail: AWS_URL + this.state.timelineSwapImage.substring(0, this.state.timelineSwapImage.lastIndexOf("/")) + "/thumbnails/" + this.state.timelineSwapImage.substring(this.state.timelineSwapImage.lastIndexOf("/") + 1) + "-Thumbnail" + this.state.timelineSwapImage.substring(this.state.timelineSwapImage.lastIndexOf(".")),
            DeleteImage: false,
            type: "VirtualTourNetwork.ImageMediaNodeData"
        }

        panoSwap.DeleteImage = false;
        let panoData =
        {
            Id: this.state.tourId,
            Data: [panoSwap],
            SubmittedAt: getCurrentDate()
        };
        console.log(panoData);
        this.swapTimelineImageModal({});
        updatePanoImage(panoData, this.panoSwapSuccess, this.error);
    }


    onSwapChangeChecked = (status: string) => (e: any) => {
        if (status === 'local') {
            this.setState({
                local: true
            } as any);
        }
        else {
            this.setState({
                local: false
            } as any);
        }
        console.log(this.state);
    };


    swapTimelineImage = (e: any) => {

        //this.setState((state) => { return { swapTimelineImageModal: !state.swapTimelineImageModal } })
        //this.setState({ swapTimelineImageModal: !this.state.swapTimelineImageModal });
        console.log(e);
        this.setState({ timelineSwapImage: e });
    }


    selectOptionsLocal = () => {
        let list = this.state.uploadList;
        //console.log(list);
        let item = [];
        for (let i = 0; i < list.length; i++) {
            item.push(
                <Option key={i} value={list[i]} >
                    {
                        list[i].substring(list[i].lastIndexOf("/") + 1)
                    }
                </Option>

            );
        }
        return item;
    }

    selectOptionsShared = () => {
        let list = this.state.sharedList;
        //console.log(list);
        let item = [];
        for (let i = 0; i < list.length; i++) {
            item.push(
                <Option key={i} value={list[i].url.substring(list[i].url.lastIndexOf('.com/') + 5)} >
                    {
                        list[i].name
                    }
                </Option>

            );
        }


        return item;
    }

    // deletion of file list

    showModal = (image: any) => {
        console.log(image.fileName);
        this.setState({
            removeImage: image,
            showModal: true,
        });
    };


    handleCancel = () => this.setState({ showModal: false });


    handleOk = (e: any) => {

        console.log(this.state.removeImage);
        let deletePanoObj = {
            Id: this.state.removeImage.id,
            ScopicMode: this.state.removeImage.scopicMode,
            mediaPath: this.state.removeImage.mediaPath,
            FileName: this.state.removeImage.fileName || this.state.removeImage.response.substring(this.state.removeImage.response.lastIndexOf("/") + 1),
            UID: this.state.removeImage.uID,
            NiceName: this.state.removeImage.niceName,
            Thumbnail: this.state.removeImage.thumbnail,
            EditorPosition: this.state.removeImage.editorPosition,
            MappedData: this.state.removeImage.mappedData,
            type: this.state.removeImage.type,
            updatedAt: getCurrentDate(),
            TourDataId: this.state.tourId,
            deleteShared: false,
            deleteAWS: false
        }
        deletePano(deletePanoObj, this.deleteSuccess, this.error);

    };

    apiError = (data: any) => {
        console.log(data);
        alert("An Error occurred. Check the console for more techinical details");
    }
    deleteSuccess = (data: any) => {
        let newFileList = this.state.fileList.filter(function (this: PanoSequence, file: any) {
            return file.uid !== this.state.removeImage.uid
        }.bind(this));
        let firstEl = newFileList[0];
        if (typeof firstEl !== "undefined") {
            firstEl.criticalPano = true;
            newFileList.splice(0, 1, firstEl);
        }
        let errors = false;
        if (data.data.length > 0)
            errors = true;
        this.setState({
            showModal: false,
            fileList: newFileList,
            errors: data.data,
            errorModal: errors,
        });
        message.success("Pano deleted successfully.")
        console.log(this.state.fileList);
        console.log(this.state.uploadList);
        console.log(data.data);
    }






    //end of deletion


    // deletion for upload List


    showDeleteModal = (image: any) => {
        console.log(image.fileName);
        if (!image.fileName) {
            let index;
            let filelist = this.state.fileList;
            index = filelist.findIndex((pano) => pano.fileName === image.substring(image.lastIndexOf("/") + 1, image.length));
            console.log(index);
            console.log(filelist[index]);
            if (index >= 0) {
                image = filelist[index];
                console.log(image);
            }
        }
        this.setState({
            removeImage: image,
            showDeleteModal: true,
        });
    };


    handleDeleteCancel = () => this.setState({ showDeleteModal: false });


    handleDeleteOk = (e: any) => {

        // complete this implementation of the new method created in tour controller after commenting
        // the existing lines of code here
        if (this.state.removeImage.fileName) {
            var filePath = { url: this.state.removeImage.mediaPath.substring(this.state.removeImage.mediaPath.lastIndexOf('.com/') + 5) };
            console.log(filePath);
            deleteFile(filePath, this.deletionUpload, this.apiError);
            axios({
                method: 'delete',
                url: `/api/v1/vrsafety/Tour/PanoDeleteMultiple/${this.state.tourId}/${this.state.removeImage.fileName}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data; boundary=---------------------------81801171514357'
                }
            })
                .then((response) => {
                    //handle success
                    console.log(response);
                    this.setState({
                        showDeleteModal: false,
                        fileList: response.data.sceneObjects
                    });
                    Modal.success({
                        content: 'Associated panos were deleted successfully',
                    });
                    
                })
                .catch(function (response) {
                    //handle error
                    console.log(response);
                    message.error("There was a problem deleting some hotspots.");
                })
            
            
        }
        else {
            console.log("image not used in pano");
            console.log(this.state.removeImage);
            //var filePath = this.state.removeImage.toString();
            var filePath = { url: this.state.removeImage };
            console.log(filePath.url);
            deleteFile(filePath, this.deletionUpload, this.apiError);
        }



        /*console.log(this.state.removeImage);
        if (this.state.removeImage.fileName) {// if it is not an array, that is if it is from upload list and not in timeline
            confirm({
                title: "This will delete the pano in timeline and the hotspots associated with it?",
                content: "Deleting these files is PERMENANT and will affect the tours using the shared files",
                okType: "danger",
                okText: "Delete Anyways",
                onOk: () => {
                    let deletePanoObj = {
                        Id: this.state.removeImage.id,
                        ScopicMode: this.state.removeImage.scopicMode,
                        mediaPath: this.state.removeImage.mediaPath,
                        FileName: this.state.removeImage.fileName || this.state.removeImage.response.substring(this.state.removeImage.response.lastIndexOf("/") + 1),
                        UID: this.state.removeImage.uID,
                        NiceName: this.state.removeImage.niceName,
                        EditorPosition: this.state.removeImage.editorPosition,
                        MappedData: this.state.removeImage.mappedData,
                        type: this.state.removeImage.type,
                        updatedAt: getCurrentDate(),
                        TourDataId: this.state.tourId,
                        deleteShared: true,
                        deleteAWS: true
                    }
                    deletePano(deletePanoObj, this.deletionSuccess, this.error);
                },
                cancelText: "Don't Delete",
                onCancel: () => {
                    this.setState({
                        showDeleteModal: false,
                    });
                }
            })

        }
        else {
            console.log("image not used in pano");
            console.log(this.state.removeImage);
            //var filePath = this.state.removeImage.toString();
            var filePath = { url: this.state.removeImage };
            console.log(filePath.url);
            deleteFile(filePath, this.deletionUpload, this.apiError);
        }*/
    };

    deletionSuccess = (data: any) => {
        this.setState({
            showDeleteModal: false,
            fileList: this.state.fileList.filter(function (this: PanoSequence, file: any) {
                return file.uid !== this.state.removeImage.uid
            }.bind(this)),
            uploadList: this.state.uploadList.filter(function (this: PanoSequence, file: any) {
                return file.substring(file.lastIndexOf("/") + 1, file.length) !== this.state.removeImage.fileName
            }.bind(this))
        });

        console.log(this.state.fileList);
        console.log(this.state.uploadList);
    }



    deletionUpload = (data: any) => {

        console.log("here");
        if (this.state.removeImage.fileName) {
            this.setState({
                showDeleteModal: false,
                uploadList: this.state.uploadList.filter(function (this: PanoSequence, file: any) {
                    return file !== this.state.removeImage.mediaPath.substring(this.state.removeImage.mediaPath.lastIndexOf('.com/') + 5)
                }.bind(this))
            });
        }
        else {
        this.setState({
            showDeleteModal: false,
            uploadList: this.state.uploadList.filter(function (this: PanoSequence, file: any) {
                return file !== this.state.removeImage
            }.bind(this))
        });
        }

    }


    deletionResource = (data: any) => {

        console.log("here");
        this.setState({
            showDeleteModal: false,
            sharedList: this.state.sharedList.filter(function (this: PanoSequence, file: any) {
                return file.name !== this.state.removeImage.substring(this.state.removeImage.lastIndexOf('/') + 1)
            }.bind(this))
        });

    }


    // Deletion for shared list

    /*
    showSharedDeleteModal = (image: any) => {
        console.log(image);
        let index;
        let filelist = this.state.fileList;
        index = filelist.findIndex((pano) => pano.fileName === image.name);
        console.log(index);
        console.log(filelist[index]);
        if (index >= 0) {
            image = filelist[index];
            console.log(image);
        }
        this.setState({
        removeImage: image,
        showSharedDeleteModal: true,
        });
    };


    handleSharedDeleteCancel = () => this.setState({ showSharedDeleteModal: false });


    handleSharedDeleteOk = (e: any) => {

        console.log(this.state.removeImage);
        if (this.state.removeImage.fileName) {// if it has filename, that is if it is from timeline too
            confirm({
                title: "This will delete the pano in timeline and the hotspots associated with it?",
                content: "Deleting these files is PERMENANT and will affect the tours using the shared files",
                okType: "danger",
                okText: "Delete Anyways",
                onOk: () => {
                    let deletePanoObj = {
                        Id: this.state.removeImage.id,
                        ScopicMode: this.state.removeImage.scopicMode,
                        mediaPath: this.state.removeImage.mediaPath,
                        FileName: this.state.removeImage.fileName || this.state.removeImage.response.substring(this.state.removeImage.response.lastIndexOf("/") + 1),
                        UID: this.state.removeImage.uID,
                        NiceName: this.state.removeImage.niceName,
                        EditorPosition: this.state.removeImage.editorPosition,
                        MappedData: this.state.removeImage.mappedData,
                        type: this.state.removeImage.type,
                        updatedAt: getCurrentDate(),
                        TourDataId: this.state.tourId,
                        deleteShared: true,
                        deleteAWS: true
                    }
                    console.log(deletePanoObj);
                    deletePano(deletePanoObj, this.deletionSharedSuccess, this.error);
                },
                cancelText: "Don't Delete",
                onCancel: () => {
                    this.setState({
                        showDeleteModal: false,
                    });
                }
            })
            
        }
        else {
            console.log("image not used in pano");
            console.log(this.state.removeImage);
            //var filePath = this.state.removeImage.toString();
            var filePath = { url: this.state.removeImage.url.substring(this.state.removeImage.url.lastIndexOf('.com/')+5) };
            console.log(filePath);
            deleteFile(filePath, this.deletionShared, this.apiError);
        }
    };


    deletionSharedSuccess = (data: any) => {
        this.setState({
            showSharedDeleteModal: false,
            fileList: this.state.fileList.filter(function (this: PanoSequence, file: any) {
                return file.uid !== this.state.removeImage.uid
            }.bind(this)),
            sharedList: this.state.sharedList.filter(function (this: PanoSequence, file: any) {
                return file.name !== this.state.removeImage.fileName.substring(this.state.removeImage.fileName.lastIndexOf('/') + 1)
            }.bind(this))
        });

        console.log(this.state.fileList);
        console.log(this.state.sharedList);
    }



    deletionShared = (data: any) => {

        console.log("here");
        this.setState({
            showSharedDeleteModal: false,
            sharedList: this.state.sharedList.filter(function (this: PanoSequence, file: any) {
                return file.name !== this.state.removeImage.name
            }.bind(this))
        });

    }

    */

    // deletion for shared list ends


    deleteSwap = (data: any) => {

        console.log("here");

    }

    handlePreview = async (file: any) => {
        console.log(file);
    };

    handleChange = (fileList: any) => {
        console.log(fileList);
        console.log(fileList.event);
        fileList.file["tourId"] = this.state.tourId;
        console.log(fileList.file);
        let response = fileList.file;
        if (fileList.file.status === 'uploading') {
            this.setState({ uploading: true });
            return;
        }
        if (response.hasOwnProperty('xhr')) {
            console.log(response.xhr.responseText);
            let fileList = this.state.fileList
            console.log(this.state.fileList);
            let newFile = response;
            console.log(newFile);
            newFile.uid = fileList.length;
            // newFile["url"]=response.xhr.responseText;
            fileList.push(newFile);
            this.setState({ fileList: fileList, uploading: false });
        }
        // this.setState({fileList:fileList.fileList})
    };
    uploadImageSwap = (options: any) => {
        console.log(options);
        //console.log(this.state.panoToSwap.substring(this.state.panoToSwap.lastIndexOf("/")+1));
        var bodyFormData = new FormData();
        bodyFormData.append("file", options.file);
        bodyFormData.append("tourID", this.props.match.params.id);
        bodyFormData.append("companyID", this.state.companyId);
        bodyFormData.append("newVersion", this.state.newVersion.toString());
        bodyFormData.append("versionNum", this.state.versionNum.toString());
        bodyFormData.append("existsAlready", this.state.replace.toString());
        if (this.state.replace) {
            if (this.state.panoToSwap.fileName) {
                bodyFormData.append("existingName", this.state.panoToSwap.fileName);
            }
            else {
                bodyFormData.append("existingName", this.state.panoToSwap.substring(this.state.panoToSwap.lastIndexOf("/") + 1));
            }
        }
        else {
            bodyFormData.append("existingName", "");
        }
        axios({
            method: 'post',
            url: '/api/v1/vrsafety/File/uploadSwapPano',
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data; boundary=---------------------------81801171514357'
            }
        })
            .then((response) => {
                //handle success
                console.log(response);
                let uploadList = this.state.uploadList;
                let uploadFile = response.data.substring(response.data.lastIndexOf(".com/") + 5, response.data.length)
                console.log(uploadFile);
                uploadList.push(uploadFile);
                this.setState({ swapImage: uploadFile, uploading: false });
                this.setState({ uploadList: uploadList, newVersion: false });
            })
            .catch(function (response) {
                //handle error
                console.log(response);
                message.error("There was a problem uploading your Image.");
            })
    }
    goto = (index: number) => {
        let node = this.carousel.current;
        node!.goTo(index);
    }
    prev = () => {
        let node = this.carousel.current;
        node!.prev();
    }
    next = () => {
        let node = this.carousel.current;
        node!.next();
    }
    saveOrder = () => {
        this.setState({ saveLoading: false });
        let sequence = this.createSequence();
        console.log(sequence);
        let panosequence = {
            Id: this.state.tourId,
            Data: sequence.data,
            SubmittedAt: getCurrentDate()
        };
        console.log(panosequence);
        if (!sequence.errors) {
            createPanoSequence(panosequence, this.success, this.error);

        }
        else {
            Modal.error({
                title: "Problem with saving the course sequence",
                content: "The course sequence needs to have at least one critical pano!"
            });
            this.setState({ saveLoading: false });
        }
    }
    saveOrderContinue = () => {
        this.setState({ saveLoading: true });
        let sequence = this.createSequence();
        let panosequence = {
            Id: this.state.tourId,
            Data: sequence.data,
            SubmittedAt: getCurrentDate()
        };
        console.log(panosequence);
        if (!sequence.errors) {
            createPanoSequence(panosequence, this.successContinue, this.error);
        }
        else {
            Modal.error({
                title: "Problem with saving the course sequence",
                content: "The course sequence needs to have at least one critical pano!"
            });
            this.setState({ saveLoading: false });
        }
    }
    createSequence() {
        let sequence: any = [];
        let error = true;
        for (let i = 0; i < this.state.fileList.length; i++) {
            sequence.push({
                Id: this.state.fileList[i].id || 0,
                UID: this.state.fileList[i].uid||0,
                scopicMode: "Monoscopic",
                criticalPano: this.state.fileList[i].criticalPano || ((i === 0) ? true : false),
                mediaPath: this.state.fileList[i].response || this.state.fileList[i].mediaPath,
                FileName: this.state.fileList[i].fileName || this.state.fileList[i].name,
                NiceName: this.state.fileList[i].niceName || this.state.fileList[i].name.substring(0, this.state.fileList[i].name.indexOf('.')),
                thumbnail: this.state.fileList[i].thumbnail,
                type: "VirtualTourNetwork.ImageMediaNodeData"
            });
            if (this.state.fileList[i].criticalPano) {
                error = false;
            }
        }
        return {
            data: sequence,
            errors: error
        };
    }
    success = (data: any) => {
        console.log(`success!`);
        console.log(data);
        //let id = data.data;
        //console.error(id);
        this.setState({
            isBlocking: false ,
            fileList: data.data.sceneObjects
        });
        this.setState({ });
        message.success("Your progress is saved!!");
        //this.props.next();
    }

    successContinue = (data: any) => {
        console.log(`success!`);
        console.log(data);
        let id = data.data;
        console.error(id);
        this.setState({ isBlocking: false });
        this.props.next();
    }
    error(error: any) {
        console.log(`error!`);
        console.log(error);
    }
    showConfirm = (item: any) => {
    }
    beforeUpload = async (file: any) => {
        let m = await new Promise<void>((resolve: any, reject: any) => {
            if (file.type.substring(0, file.type.indexOf('/')) === 'image') {
                //let testUrl = "https://janustourbuilder.s3.ca-central-1.amazonaws.com/"+this.state.companyId+"/"+this.state.tourId+"/panos/"+file.name;
                axios.get(`/api/v1/vrsafety/file/check-resources/${file.name}/${this.props.match.params.id}`).then((result) => {
                    console.log(result);
                    if (!result.data) {
                        resolve();
                    }
                    else {
                        confirm({
                            title: "File is already uploaded",
                            content: "Would you like to upload the file anyways?",
                            okText: "Yes",
                            onOk: () => {
                                this.setState((state) => { return { newVersion: true } });
                                resolve();
                            },
                            cancelText: "No",
                            onCancel: () => {
                                reject(false);
                                this.setState({ uploading: false });
                            }
                        });
                    }
                }).catch((error) => {
                    message.error("There was a problem with uploading the file. Please try again");
                    console.log(error);
                })
            }
            else {
                message.error("Only image files can be uploaded");
                reject(false);
            }
        })
        return m;
    }


    beforeUploadShared = async (file: any) => {
        console.log(file);
        let m = await new Promise<void>((resolve: any, reject: any) => {
            if (file.type.substring(0, file.type.indexOf('/')) === 'image') {
                //let testUrl = "https://janustourbuilder.s3.ca-central-1.amazonaws.com/"+this.state.companyId+"/"+this.state.tourId+"/panos/"+file.name;
                axios.get(`/api/v1/vrsafety/file/check-shared-pano/${file.name}`).then((result) => {
                    console.log(result);
                    if (!result.data) {
                        resolve();
                    }
                    else {
                        confirm({
                            title: "File is already uploaded",
                            content: "Would you like to upload the file anyways?",
                            okText: "Yes",
                            onOk: () => {
                                this.setState((state) => { return { newVersion: true } });
                                resolve();
                            },
                            cancelText: "No",
                            onCancel: () => {
                                reject(false);
                            }
                        });
                    }
                }).catch((error) => {
                    message.error("There was a problem with uploading the file. Please try again");
                    console.log(error);
                })
            }
            else {
                message.error("Only image files can be uploaded");
                reject(false);
            }
        })
        return m;
    }



    tourToResource = async (file: string) => {
        console.log(file);
        let filename = file.substring(file.lastIndexOf('/') + 1);
        console.log(filename);
        let sharedList = this.state.sharedList;
        let index = sharedList.findIndex((pano: any) => pano.name === filename);
        if (index >= 0) {
            message.error("This file already exists in shared resources!");
        }
        else {
            let m = await new Promise<void>((resolve: any, reject: any) => {
                axios.post(`/api/v1/vrsafety/file/0/${filename}/${this.props.match.params.id}`).then((result) => {
                    console.log(result);

                    let filelist = this.state.fileList;
                    var index = filelist.findIndex((pano) => pano.fileName === result.data.name);
                    console.log(index);
                    //var imageName= filelist[index].fileName;
                    if (index != -1) {

                        let panoSwap = {
                            Id: filelist[index].id || 0,
                            UID: filelist[index].uid,
                            ScopicMode: "Monoscopic",
                            criticalPano: filelist[index].criticalPano || ((this.state.panoToSwap.uid === 0) ? true : false),
                            mediaPath: result.data.url,
                            fileName: filelist[index].fileName,
                            niceName: filelist[index].niceName,
                            DeleteImage: false,
                            type: "VirtualTourNetwork.ImageMediaNodeData"
                        }

                        let tourId = this.state.tourId;
                        let fileToSwap = filelist[index].fileName;
                        let swapFile = result.data.url.substring(result.data.url.lastIndexOf(".com/")+5);
                        let thumbnail = result.data.url.substring(0, result.data.url.lastIndexOf("/pano")) + "/panos/thumbnails/" + result.data.url.substring(result.data.url.lastIndexOf("/") + 1) + "-Thumbnail" + result.data.url.substring(result.data.url.lastIndexOf("."));

                        let niceName = filelist[index].niceName;
                        var bodyFormData = new FormData();
                        bodyFormData.append("tourId", tourId);
                        bodyFormData.append("swapFile", swapFile);
                        bodyFormData.append("fileToSwap", fileToSwap);
                        bodyFormData.append("niceName", niceName);
                        bodyFormData.append("thumbnail", thumbnail);
                        axios({
                            method: 'post',
                            url: `/api/v1/vrsafety/Tour/swapAllPanos`,
                            data: bodyFormData,
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'Content-Type': 'multipart/form-data; boundary=---------------------------81801171514357'
                            }
                        })
                            .then((response) => {
                                console.log(response);
                                this.setState({
                                    fileList: response.data.sceneObjects,
                                });
                                Modal.success({
                                    content: 'Associated panos were swapped successfully',
                                });
                            })
                            .catch(function (response) {
                                //handle error
                                console.log(response);
                                message.error("There was a problem swapping your panos");
                            })
                    }

                    this.setState({ removeImage: file })
                    //var filePath = this.state.removeImage.toString();
                    var filePath = { url: file };
                    console.log(filePath.url);
                    deleteFile(filePath, this.deletionUpload, this.apiError);



                    sharedList.push(result.data);
                    console.log(sharedList);
                    this.setState({ sharedList: sharedList, uploading: false, newVersion: false });
                    message.success("File successfully uploaded to shared resources.");

                }).catch((error) => {
                    message.error("There was a problem with uploading the file. Please try again");
                    console.log(error);
                })

            })
            return m;
        }
    }


    panoAddressSwapSuccess = (data: any) => {
        let index;
        console.log(data);
        let filelist = this.state.fileList;
        index = filelist.findIndex((pano) => pano.uid === data.data.uid);
        console.log(index);
        filelist[index] = data.data;
        this.setState((state) => { return { fileList: filelist } });
    }

    resourceToTour = async (file: string) => {
        console.log(file);
        let uploadList = this.state.uploadList;
        let index = uploadList.findIndex((pano: any) => pano.substring(pano.lastIndexOf('/') + 1) == file);
        console.log(index);
        if (index >= 0) {
            message.error("This file already exists in upload list!");
        }
        else {
            let m = await new Promise<void>((resolve: any, reject: any) => {
                axios.post(`/api/v1/vrsafety/file/tour-upload-from-resource/${file}/${this.props.match.params.id}`).then((result) => {
                    console.log(result);

                    let uploadFile = result.data;
                    console.log(uploadFile);
                    uploadList.push(uploadFile);
                    this.setState({ uploadList: uploadList, uploading: false, newVersion: false });


                    let filelist = this.state.fileList;
                    var index = filelist.findIndex((pano) => pano.fileName === result.data.substring(result.data.lastIndexOf('/') + 1));
                    console.log(index);
                    //var imageName= filelist[index].fileName;
                    console.log(filelist[index]);
                    if (index != -1) {

                        let tourId = this.state.tourId;
                        let fileToSwap = filelist[index].fileName;
                        let swapFile = result.data;
                        let niceName = filelist[index].niceName;
                        var thumbnail = AWS_URL + result.data.substring(0, result.data.lastIndexOf("/pano")) + "/panos/thumbnails/" + result.data.substring(result.data.lastIndexOf("/") + 1) + "-Thumbnail" + result.data.substring(result.data.lastIndexOf("."));
                        var bodyFormData = new FormData();
                        bodyFormData.append("tourId", tourId);
                        bodyFormData.append("swapFile", swapFile);
                        bodyFormData.append("fileToSwap", fileToSwap);
                        bodyFormData.append("niceName", niceName);
                        bodyFormData.append("thumbnail", thumbnail);
                        axios({
                            method: 'post',
                            url: `/api/v1/vrsafety/Tour/swapAllPanos`,
                            data: bodyFormData,
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'Content-Type': 'multipart/form-data; boundary=---------------------------81801171514357'
                            }
                        })
                            .then((response) => {
                                console.log(response);
                                this.setState({
                                    fileList: response.data.sceneObjects,
                                });
                                Modal.success({
                                    content: 'Associated panos were swapped successfully',
                                });
                            })
                            .catch(function (response) {
                                //handle error
                                console.log(response);
                                message.error("There was a problem swapping your panos");
                            })

                    }
                }).catch((error) => {
                    message.error("There was a problem with uploading the file. Please try again");
                    console.log(error);
                })

            })
            return m;
        }
    }

    beforeUploadSwap = async (file: any) => {
        this.setState((state) => { return { uploading: true } });
        let m = await new Promise<void>((resolve: any, reject: any) => {
            if (file.type.substring(0, file.type.indexOf('/')) === 'image') {
                //let testUrl = "https://janustourbuilder.s3.ca-central-1.amazonaws.com/"+this.state.companyId+"/"+this.state.tourId+"/panos/"+file.name;
                axios.get(`/api/v1/vrsafety/file/check-resources/${file.name}/${this.props.match.params.id}`).then((result) => {
                    console.log(result);
                    console.log("in before swap");
                    if (!result.data) {
                        resolve();
                    }
                    else {
                        confirm({
                            title: "File is already uploaded",
                            content: "Do you wanna continue?",
                            okText: "Yes",
                            onOk: () => {
                                confirm({
                                    title: "Replace file or create new version",
                                    content: "Do you want to replace the existing file or upload a new version of it?",
                                    okText: "Upload New Version",
                                    onOk: () => {
                                        this.setState((state) => { return { newVersion: true } });
                                        console.log(this.state.panoToSwap);

                                        resolve();
                                    },
                                    cancelText: "Replace",
                                    onCancel: () => {
                                        this.setState((state) => { return { replace: true } });
                                        this.setState({
                                            removeImage: this.state.panoToSwap,
                                        });
                                        if (this.state.panoToSwap.mediaPath) {
                                            var filePath = { url: this.state.panoToSwap.mediaPath.substring(this.state.panoToSwap.mediaPath.lastIndexOf(".com/") + 5) };
                                        }
                                        else {
                                            var filePath = { url: this.state.panoToSwap };
                                        }
                                        deleteFile(filePath, this.deletionUpload, this.apiError);
                                        resolve();
                                    }
                                });
                            },
                            cancelText: "No",
                            onCancel: () => {
                                reject(false);
                            }
                        });
                    }
                }).catch((error) => {
                    message.error("There was a problem with uploading the file. Please try again");
                    console.log(error);
                })
            }
            else {
                message.error("Only image files can be uploaded");
                reject(false);
            }
        });
        return m;
    }

    handleCheckChange = (input: number) => (e: any) => {
        let updateFileList = this.state.fileList;
        if (input === 0) {
            updateFileList[input].criticalPano = true;
        }
        else {
            updateFileList[input].criticalPano = e.target.checked;
        }

        this.setState({ fileList: updateFileList });
    }

    swapImage = () => {
        this.setState((state) => { return { uploading: true } });
        console.log(this.state.isInTimeline);
        if (this.state.replace === false) {
            if (this.state.isInTimeline) {
                let tourId = this.state.tourId;
                let fileToSwap = this.state.panoToSwap.fileName;
                let swapFile = this.state.swapImage;
                let niceName = this.state.swapImage.substring(this.state.swapImage.lastIndexOf("/") + 1, this.state.swapImage.lastIndexOf("."));
                let thumbnail = AWS_URL + this.state.swapImage.substring(0, this.state.swapImage.lastIndexOf("/pano")) + "/panos/thumbnails/" + this.state.swapImage.substring(this.state.swapImage.lastIndexOf("/") + 1) + "-Thumbnail" + this.state.swapImage.substring(this.state.swapImage.lastIndexOf("."));
                var bodyFormData = new FormData();
                bodyFormData.append("tourId", tourId);
                bodyFormData.append("swapFile", swapFile);
                bodyFormData.append("fileToSwap", fileToSwap);
                bodyFormData.append("niceName", niceName);
                bodyFormData.append("thumbnail", thumbnail);
                axios({
                    method: 'post',
                    url: `/api/v1/vrsafety/Tour/swapAllPanos`,
                    data: bodyFormData,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data; boundary=---------------------------81801171514357'
                    }
                })
                    .then((response) => {
                        console.log(response);
                        this.setState({
                            fileList: response.data.sceneObjects,
                        });
                        Modal.success({
                            content: 'Associated panos were swapped successfully',
                        });
                    })
                    .catch(function (response) {
                        //handle error
                        console.log(response);
                        message.error("There was a problem swapping your panos");
                    })
                confirm({
                    title: "Would you like to delete the file you are swapping or keep both files",
                    content: "Deleting this file will delete all the related data too.",
                    okType: "danger",
                    okText: "Delete",
                    onOk: () => {
                        var filePath = { url: this.state.panoToSwap.mediaPath.substring(this.state.panoToSwap.mediaPath.lastIndexOf(".com/")+5) };
                        deleteFile(filePath, this.deleteSwap, this.apiError);
                        let uploadlist = this.state.uploadList;
                        console.log(this.state.companyId + "/" + this.state.tourId + "/panos/" + this.state.panoToSwap);
                        let index = uploadlist.findIndex((pano) => pano === filePath.url);
                        console.log(index);
                        uploadlist.splice(index, 1);
                        this.setState({ uploadList: uploadlist, uploading: false, newVersion: false, swapImage: '' });
                        this.swapImageModal({});
                    },
                    cancelText: "Keep Both",
                    onCancel: () => {
                        this.setState({ uploading: false, swapImage: '' });
                        this.swapImageModal({});
                    }
                })
            }
            else {
                console.log('Sorry');
                console.log(this.state.panoToSwap);

                confirm({
                    title: "Would you like to delete the file you are swapping or keep both files",
                    content: "Deleting this file will delete all the related data too.",
                    okType: "danger",
                    okText: "Delete",
                    onOk: () => {

                        console.log(this.state.panoToSwap);
                        var filePath = { url: this.state.panoToSwap };
                        deleteFile(filePath, this.deleteSwap, this.apiError);
                        // removing the image from the uploadList
                        let uploadlist = this.state.uploadList;
                        console.log(this.state.companyId + "/" + this.state.tourId + "/panos/" + this.state.panoToSwap);
                        let index = uploadlist.findIndex((pano) => pano === (this.state.panoToSwap));
                        console.log(index);
                        uploadlist.splice(index, 1);
                        this.setState({ uploadList: uploadlist, uploading: false, newVersion: false, swapImage: '' });
                        this.swapImageModal({});
                    },
                    cancelText: "Keep Both",
                    onCancel: () => {
                        this.setState({ uploading: false, swapImage: '' });
                        this.swapImageModal({});
                    }
                })
            }
        }
        else {
            this.setState({ replace: false, uploading: false, swapImage: '' });
            this.swapImageModal({});
            window.location.href = window.location.href
        }
    }

    panoSwapSuccess = (data: any) => {
        let index;
        console.log(data);
        message.success("Your image has been swapped successfully");
        let filelist = this.state.fileList;
        index = filelist.findIndex((pano) => pano.uid === data.data.uid);
        console.log(index);
        //var imageName= filelist[index].fileName;
        filelist[index] = data.data;
        this.setState((state) => { return { fileList: filelist } });
        this.setState({ uploading: false, swapImage: '', timelineSwapImage: '' });
    }

    upload = (options: any) => {
        this.setState((state) => { return { uploading: true } });
        console.log(options);
        var bodyFormData = new FormData();
        bodyFormData.append("file", options.file);
        bodyFormData.append("tourID", this.props.match.params.id);
        bodyFormData.append("companyID", this.state.companyId);
        bodyFormData.append("newVersion", this.state.newVersion.toString());
        axios({
            method: 'post',
            url: '/api/v1/vrsafety/File/upload',
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data; boundary=---------------------------81801171514357'
            }
        })
            .then((response) => {
                //handle success
                console.log(response);
                let uploadList = this.state.uploadList
                let uploadFile = response.data.substring(response.data.lastIndexOf(".com/") + 5, response.data.length)
                console.log(uploadFile);
                uploadList.push(uploadFile);
                this.setState({ uploadList: uploadList, uploading: false, newVersion: false });
            })
            .catch(function (response) {
                //handle error
                console.log(response);
                message.error("There was a problem uploading your Image.");
            })
    }






    resourceUpload = (options: any) => {
        this.setState((state) => { return { uploading: true } });
        console.log(options);
        var bodyFormData = new FormData();
        bodyFormData.append("file", options.file);
        bodyFormData.append("tourID", this.props.match.params.id);
        bodyFormData.append("companyID", this.state.companyId);
        bodyFormData.append("newVersion", this.state.newVersion.toString());
        axios({
            method: 'post',
            url: '/api/v1/vrsafety/File/resource-upload',
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data; boundary=---------------------------81801171514357'
            }
        })
            .then((response) => {
                //handle success
                let sharedList = this.state.sharedList
                sharedList.push(response.data);
                console.log(sharedList);
                this.setState({ sharedList: sharedList, uploading: false, newVersion: false });
            })
            .catch(function (response) {
                //handle error
                console.log(response);
                message.error("There was a problem uploading your Image.");
            })
    }

    // shared resource swap starts


    swapSharedImageModal = (pano: any) => {
        console.log(pano);
        let panoSwapThumb = pano.url.substring(0, pano.url.lastIndexOf("/panos")) + "/panos/thumbnails" + pano.url.substring(pano.url.lastIndexOf("/")) + "-Thumbnail" + pano.url.substring(pano.url.lastIndexOf("."));
        this.setState((state) => { return { swapSharedImageModal: !state.swapSharedImageModal, panoToSwap: pano, swapPanoThumb: panoSwapThumb } })
        this.setState({
            replace: true
        });
        console.log(this.state.panoToSwap);

    }

    swapSharedImageModalCancel = () => {
        this.setState((state) => { return { swapSharedImageModal: false, swapImage: {}, swapPanoThumb: "" } })
    }

    beforeUploadSharedSwap = () => {

    }
    uploadSharedImageSwap = (options: any) => {
        var filePath = { url: this.state.panoToSwap.url.substring(this.state.panoToSwap.url.lastIndexOf(".com/") + 5) };

        deleteFile(filePath, this.deletionShared, this.apiError);
        console.log(this.state.panoToSwap.name);
        this.setState((state) => { return { uploading: true } });
        console.log(options);
        var bodyFormData = new FormData();
        bodyFormData.append("file", options.file);
        bodyFormData.append("tourID", this.props.match.params.id);
        bodyFormData.append("companyID", this.state.companyId);
        bodyFormData.append("newVersion", this.state.newVersion.toString());
        bodyFormData.append("existsAlready", this.state.replace.toString());
        if (this.state.replace) {
            bodyFormData.append("existingName", this.state.panoToSwap.name);
        }
        else {
            bodyFormData.append("existingName", "");// should not come here for shared library
        }
        axios({
            method: 'post',
            url: '/api/v1/vrsafety/File/shared-pano-swap-upload',
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data; boundary=---------------------------81801171514357'
            }
        })
            .then((response) => {
                //handle success
                let sharedList = this.state.sharedList
                sharedList.push(response.data);
                console.log(sharedList);
                this.setState({ sharedList: sharedList, uploading: false, newVersion: false });
                this.setState({ swapImage: response.data });
                console.log(this.state.swapImage);
            })
            .catch(function (response) {
                //handle error
                console.log(response);
                message.error("There was a problem uploading your Image.");
            })
    }

    deletionShared = (data: any) => {

        console.log("here");
        this.setState({
            showSharedDeleteModal: false,
            sharedList: this.state.sharedList.filter(function (this: PanoSequence, file: any) {
                return file.name !== this.state.panoToSwap.name
            }.bind(this))
        });

    }

    swapSharedImage = () => {
        this.setState((state) => { return { uploading: true } });
        console.log(this.state.isInTimeline);
        this.setState({ replace: false, uploading: false, swapImage: '', panoToSwap: '' });
        this.swapSharedImageModal({});

        window.location.href = window.location.href
    }


    // shared resource swap ends



    resourceUploadFromTour = (fileName: string) => {
        this.setState((state) => { return { uploading: true } });
        console.log(fileName);
        var bodyFormData = new FormData();
        bodyFormData.append("tourID", this.props.match.params.id);
        bodyFormData.append("companyID", this.state.companyId);
        bodyFormData.append("newVersion", this.state.newVersion.toString());
        axios({
            method: 'post',
            url: '/api/v1/vrsafety/File/resource-upload-from-tour/{fileName}/{this.state.companyId}',
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data; boundary=---------------------------81801171514357'
            }
        })
            .then((response) => {
                //handle success
                let sharedList = this.state.sharedList
                sharedList.push(response.data);
                console.log(sharedList);
                this.setState({ sharedList: sharedList, uploading: false, newVersion: false });
            })
            .catch(function (response) {
                //handle error
                console.log(response);
                message.error("There was a problem uploading your Image.");
            })
    }








    addToTimeline = async (item: any) => {

        try {
            let m = await new Promise<void>((resolve: any, reject: any) => {
                //let testUrl = "https://janustourbuilder.s3.ca-central-1.amazonaws.com/"+this.state.companyId+"/"+this.state.tourId+"/panos/"+file.name;
                var allResources = [];
                for (var i = 0; i < this.state.fileList.length; i++) {
                    allResources.push(this.state.fileList[i].fileName);
                    console.log(allResources);
                }
                if (allResources.length != 0) {
                    if (!allResources.includes(item.substring(item.lastIndexOf("/") + 1, item.length))) {
                        console.log(item);
                        let fileList = this.state.fileList
                        let newFile = {
                            uid: fileList.length,
                            mediaPath: AWS_URL + item,
                            fileName: item.substring(item.lastIndexOf("/") + 1, item.length),
                            niceName: item.substring(item.lastIndexOf("/") + 1, item.lastIndexOf(".")),
                            thumbnail: AWS_URL + item.substring(0, item.lastIndexOf("/")) + "/thumbnails/" + item.substring(item.lastIndexOf("/") + 1) + "-Thumbnail" + item.substring(item.lastIndexOf(".")),
                            criticalPano: (fileList.length === 0) ? true : false
                        };
                        console.log(newFile);
                        fileList.push(newFile);
                        this.setState({ fileList: fileList, uploading: false, newVersion: false });
                    }
                    else {
                        confirm({
                            title: "Image is already uploaded",
                            content: "Would you like to use that image again?",
                            onOk: () => {
                                let fileList = this.state.fileList
                                let newFile = {
                                    uid: fileList.length,
                                    mediaPath: AWS_URL + item,
                                    fileName: item.substring(item.lastIndexOf("/") + 1, item.length),
                                    niceName: item.substring(item.lastIndexOf("/") + 1, item.lastIndexOf(".")),
                                    thumbnail: AWS_URL + item.substring(0, item.lastIndexOf("/")) + "/thumbnails/" + item.substring(item.lastIndexOf("/") + 1) + "-Thumbnail" + item.substring(item.lastIndexOf(".")),
                                    criticalPano: (fileList.length === 0) ? true : false
                                };
                                console.log(newFile);
                                fileList.push(newFile);
                                this.setState({ fileList: fileList, uploading: false });
                                reject(false);
                            },
                            onCancel: () => {
                                this.setState((state) => { return { newVersion: true } });
                                resolve();
                            }
                        });
                    }



                }
                else {
                    let fileList = this.state.fileList
                    let newFile = {
                        uid: fileList.length,
                        mediaPath: AWS_URL + item,
                        fileName: item.substring(item.lastIndexOf("/") + 1, item.length),
                        niceName: item.substring(item.lastIndexOf("/") + 1, item.lastIndexOf(".")),
                        thumbnail: AWS_URL + item.substring(0, item.lastIndexOf("/")) + "/thumbnails/" + item.substring(item.lastIndexOf("/") + 1) + "-Thumbnail" + item.substring(item.lastIndexOf(".")),
                        criticalPano: (fileList.length === 0) ? true : false
                    };
                    console.log(newFile);
                    fileList.push(newFile);
                    this.setState({ fileList: fileList, uploading: false });
                }

            })
            return m;
        }
        catch (error) {
            console.log(error);
        }


    }

    highlight = (item: any, idx: any) => {
        console.log(item);
        console.log(idx);
        this.setState({ borderHighlight: idx });
    }

    collapseLeftSide = () => {
        if (this.state.collapseLeft === false) {
            this.setState({ collapseLeft: true });
        }
        else {
            this.setState({ collapseLeft: false });
        }
        console.log(this.state.collapseLeft);
    }

    collapseRightSide = () => {
        if (this.state.collapseRight === false) {
            this.setState({ collapseRight: true });
        }
        else {
            this.setState({ collapseRight: false });
        }
        console.log(this.state.collapseRight);
    }


    render() {
        const style = { padding: "6px 0" };
        const uploadSwapButton = (
            <div className="UploadButtonOrder">
                <Icon type={this.state.uploading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        const uploadButton = (
            <Button style={{ color: "#4ECDC4", borderColor: "#4ECDC4" }} className="tealText" ghost>
                {
                    <Icon type={this.state.uploading ? "loading" : 'upload'} />
                }
            Click to Upload
            </Button>
        );
        return (
            <div>

                <Prompt
                    when={this.state.isBlocking}
                    message={location =>
                        `Are you sure you want to leave this page without saving?`
                    }
                />
                <Spin spinning={this.state.loading}>
                    <Row type="flex" justify="space-between" style={{ marginTop: "20px", padding:"8px" }}>
                        <Col xs={24} sm={24} md={3} style={{ marginTop: "5px" }}>
                            <Button icon="left" style={{ width: "100%" }} type="primary" onClick={() => this.props.prev()}>
                                Previous
                            </Button>
                        </Col>

                        <Col xs={24} sm={24} md={6} style={{ marginTop: "5px" }}>
                            <Row type="flex" justify="space-between">
                                <Col xs={24} sm={24} md={10}><Button icon="save" style={{ width: "100%" }} type="primary" loading={this.state.saveLoading} onClick={() => this.saveOrder()}>
                                    Save Tour
                                </Button></Col>

                                <Col xs={24} sm={24} md={10}>
                                    <Button style={{ width: "100%" }} type="primary" loading={this.state.saveLoading} onClick={() => this.saveOrderContinue()}>
                                        Continue <Icon type="right" />
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="darkBlueBG" style={{ marginTop: "30px" }}><h1 style={{ padding: "10px", textAlign:"center", fontSize:"1.7em" }} className="whiteText bebas">{ this.state.tourName}</h1></Row>
                    <Row type="flex" justify="space-between" style={{ marginTop: "20px", padding: "8px" }}>
                        <Col xs={24} sm={24} md={6} className="clearfix">
                            <div style={{ minHeight: "600px", margin: "12px 0px", overflowX: "hidden", border: "1px solid #bbb", borderRadius: "2px" }}>
                                <Row style={{borderBottom:"1px solid #bbb",padding: "14px"}}>
                                    <Col xs={24} sm={24} md={12}><h3 className={this.props.auth.layout.h1Color}>Upload New Content</h3></Col>
                                    <Col xs={24} sm={24} md={12} style={{ textAlign: "right" }}>
                                        <Upload
                                            listType="picture"
                                            showUploadList={false}
                                            accept="image/*"
                                            beforeUpload={this.beforeUpload}
                                            customRequest={this.upload}
                                            onPreview={this.handlePreview}
                                            multiple={true}
                                        // disabled={this.state.fileList.length > 20}
                                        >
                                            {uploadButton}
                                        </Upload>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "20px",padding: "14px"}}>
                                    <div className="App">
                                        <ul style={{ height: "400px", overflowY: "auto", display: "flex", flexWrap: "nowrap", flexDirection: "column" }}>
                                            {this.state.uploadList.map((item, idx) => (
                                                <li key={idx} onDragOver={() => this.onDragOver(idx)} style={{ flex: "1 1 auto" }}>
                                                    <div
                                                        className="drag"
                                                        draggable
                                                        onDragStart={e => this.onDragStart(e, idx)}
                                                        onDragEnd={this.onDragEnd}
                                                    >
                                                        <div style={{ width: '100%', height: 'auto', background: '#FFF', border: "2px solid #bbb", borderRadius: "5px", padding: "15px" }}>
                                                            <Row type="flex" align="middle" justify="space-between">
                                                                <Col xs={10} sm={10} md={8}>
                                                                    <Row type="flex" align="middle" justify="space-between" className="uploadImageRow">
                                                                        <div className="showImgName">
                                                                            <p>{item.substring(item.lastIndexOf("/") + 1, item.length)}</p>
                                                                        </div>
                                                                        <img style={{ width: "100%", height: "100%" }} className="image" alt={`360 Pano #${idx}`} src={AWS_URL + item.substring(0, item.lastIndexOf("/panos")) + "/panos/thumbnails" + item.substring(item.lastIndexOf("/")) + "-Thumbnail" + item.substring(item.lastIndexOf("."))} onClick={() => this.goto(idx)} />
                                                                    </Row>
                                                                </Col>
                                                                <Col xs={24} sm={24} md={4}></Col>
                                                                <Col xs={12} sm={12} md={12}>
                                                                    <Icon style={{ margin: "0 5px", color: "#666" }} type="plus" title="Add to Timeline" onClick={() => this.addToTimeline(item)} />
                                                                    <Icon style={{ margin: "0 5px", color: "#e6ab22" }} type="swap" title="Move to Shared Resources" onClick={() => this.tourToResource(item)} />
                                                                    <Icon style={{ margin: "0 5px", color: "#4ECDC4" }} type="reload" title="Swap the Image" onClick={() => this.swapImageModal(item)} />
                                                                    <Icon style={{ margin: "0 5px", color: "#f66" }} type="delete" title="Delete" onClick={() => this.showDeleteModal(item)} />
                                                                </Col>
                                                                <div style={{ fontSize: '10px', marginTop: "8px" }}>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                    </div>

                                                </li>
                                            ))}
                                        </ul>

                                    </div>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={18}>
                            <main style={{padding:"14px", position: "relative"}}>
                                {/*<Icon type="left" className="carouselButton" onClick={() => this.prev()} />
                                <Icon type="right" style={{ right: 0 }} className="carouselButton" onClick={() => this.next()} />
                                <Carousel dotPosition={"top"}
                                    ref={this.carousel}
                                >
                                    {
                                        this.state.fileList.map((img, index) =>
                                            <img key={index} alt={`360 Pano #${index}`} src={img.response || img.mediaPath} />
                                        )
                                    }
                                </Carousel>*/}
                                <Row >
                                    <Row>
                                        <Button type="primary" onClick={() => this.collapseRightSide()}>
                                            Open Shared Library
                                        </Button>
                                    </Row>
                                    <Row>
                                    {
                                        this.state.fileList.map((pano, index) =>
                                            <Col key={index} onDragOver={() => this.onDragOver(index)} xs={24} sm={24} md={6} style={{ padding: "10px" }}
                                            >
                                                <Card
                                                    draggable
                                                    onDragStart={e => this.onDragStart(e, index)}
                                                    onDragEnd={this.onDragEnd}
                                                    className={(this.props.auth.layout==="RealX")?"blackBG drag": "drag"}
                                                    //style={{ minWidth: "250px", maxWidth: "350px" }}
                                                    size="small"
                                                    cover={<div>

                                                        <Row style={{ padding: "10px" }}>
                                                            <Row>
                                                                <img src={pano.thumbnail} style={{ width: "100%", height: "150px", margin: 0 }} className="courseImg" alt="Course"></img>
                                                            </Row>
                                                            <Row>

                                                                <div>
                                                                    <div style={{ marginBottom: "0", fontWeight: 600 }} className={(this.props.auth.layout==="RealX")?"whiteText": "darkBlueText"}>
                                                                        {pano.fileName}
                                                                    </div>
                                                                </div>
                                                                <Checkbox onChange={this.handleCheckChange(index)} checked={(index === 0) ? true : pano.criticalPano}>
                                                                    Critical Pano
                                                                </Checkbox>
                                                                {/*<Row><Button size="small" style={{ marginTop: "10px", fontSize: "12px" }} type="primary" href={`/vrsafety/viewer/${props.tour.id}`}>Start</Button></Row>*/}
                                                            </Row>

                                                        </Row>
                                                    </div>
                                                    }
                                                    actions={[
                                                        <span className="teamCardButtons" onClick={() => this.swapTimelineImageModal(pano)}><Icon type="reload" style={{ marginRight: "10px" }} />Replace</span>,
                                                        <span className="teamCardButtons" onClick={() => this.showModal(pano)}><Icon type="delete" style={{ marginRight: "10px" }} />Remove</span>,
                                                    ]}

                                                >
                                                </Card>
                                            </Col>
                                        )
                                    }
                                    <Drawer
                                        title=""
                                        placement="right"
                                        closable={true}
                                        onClose={this.collapseRightSide}
                                        visible={this.state.collapseRight}
                                        getContainer={false}
                                        width="430"
                                        className={this.props.auth.layout.styleClass}
                                    //key={placement}
                                    >
                                        <div 
                                        className={this.props.auth.layout.bgColor}
                                        >
                                            <Row style={{borderBottom:"1px solid #bbb",padding: "14px"}}>
                                                    <Col xs={24} sm={24} md={12}><h3 className={this.props.auth.layout.h1Color}>Shared Library</h3></Col>
                                                <Col xs={24} sm={24} md={12} style={{ textAlign: "right" }}>
                                                    <Upload
                                                        listType="picture"
                                                        showUploadList={false}
                                                        accept="image/*"
                                                        beforeUpload={this.beforeUploadShared}
                                                        customRequest={this.resourceUpload}
                                                        //onPreview={this.handlePreview}
                                                        multiple={true}
                                                    // disabled={this.state.fileList.length > 20}
                                                    >
                                                        {uploadButton}
                                                    </Upload>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: "20px", padding: "14px"  }}>
                                                <div className="App">
                                                    <ul style={{ height: "700px", overflowY: "auto", display: "flex", flexWrap: "nowrap", flexDirection: "column" }}>
                                                        {this.state.sharedList.map((item, idx) => (
                                                            <li key={idx} style={{ flex: "1 1 auto" }}>
                                                                <div style={{ width: '100%', height: 'auto', background: '#FFF', border: "2px solid #bbb", borderRadius: "5px", padding: "15px" }}>
                                                                    <Row type="flex" align="middle" justify="space-between">
                                                                        <Col xs={10} sm={10} md={10}>
                                                                            <Row type="flex" align="middle" justify="space-between" className="uploadImageRow">
                                                                                <div className="showImgName">
                                                                                    <p>{item.name}</p>
                                                                                </div>
                                                                                <img style={{ width: "100%", height: "100%" }} className="image" alt={`360 Pano #${idx}`} src={item.url.substring(0, item.url.lastIndexOf("/panos")) + "/panos/thumbnails" + item.url.substring(item.url.lastIndexOf("/")) + "-Thumbnail" + item.url.substring(item.url.lastIndexOf("."))} />
                                                                            </Row>
                                                                        </Col>
                                                                        <Col xs={12} sm={12} md={12}>
                                                                            <Icon style={{ margin: "0 5px", color: "#1c99c7" }} type="plus" title="Add to Timeline" onClick={() => this.addToTimeline(item.url.substring(item.url.lastIndexOf(".com/") + 5))} />
                                                                            {/*<Icon style={{margin: "0 5px", color: "#f66"}} type="delete" title="Delete" onClick={() => this.showSharedDeleteModal(item)}/>*/}
                                                                            <Icon style={{ margin: "0 5px", color: "#666" }} type="reload" title="Swap the Image" onClick={() => this.swapSharedImageModal(item)} />
                                                                            <Icon style={{ margin: "0 5px", color: "#e6ab22" }} type="swap" title="Move to Uploaded Content" onClick={() => this.resourceToTour(item.name)} />
                                                                        </Col>
                                                                    </Row>
                                                                </div>

                                                            </li>
                                                        ))}
                                                    </ul>

                                                </div>
                                            </Row>
                                        </div>
                                    </Drawer>
                                    </Row>
                                    
                                </Row>
                            </main>
                        </Col>
                        <Modal
                            title="Would you like to remove this pano?."
                            visible={this.state.showModal}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                        >
                            <p>Removing this pano will remove all hotspots associated with it. Would you like to contiue?</p>
                        </Modal>

                        <Modal
                            title="Would you like to remove this pano?."
                            visible={this.state.showDeleteModal}
                            onOk={this.handleDeleteOk}
                            onCancel={this.handleDeleteCancel}
                        >
                            <p>Removing this pano will delete it forever. Would you like to contiue?</p>
                        </Modal>

                        {/*<Modal
                              title="Would you like to remove this pano?."
                              visible={this.state.showSharedDeleteModal}
                              onOk={this.handleSharedDeleteOk}
                              onCancel={this.handleSharedDeleteCancel}
                          >
                          <p>Removing this pano will delete it forever. Would you like to contiue?</p>
                          </Modal>
                          */}
                        <Modal
                            title="Would you like to swap this pano image?"
                            visible={this.state.swapImageModal}
                            onOk={() => this.swapImage()}
                            onCancel={() => this.swapImageCancel()}>
                            <div>
                                <Row style={style}>
                                    <Col span={10}>
                                        <img src={AWS_URL + this.state.swapPanoThumb} alt="Pano to Swap" style={{ width: '100%', maxWidth:"200px", height:"100px"}} />
                                        <p>{this.state.panoToSwap.fileName}</p>
                                    </Col>
                                    <Col span={4}>
                                        <Icon type="arrow-right" style={{fontSize:"5em"}}/>
                                    </Col>
                                    <Col span={10} className="swap-upload">
                                        <Upload
                                            listType="picture-card"
                                            showUploadList={false}
                                            beforeUpload={this.beforeUploadSwap}
                                            disabled={this.state.uploading}
                                            customRequest={this.uploadImageSwap}
                                            accept="image/*"
                                            className="clear-none"
                                            //style={{ width: '100%', maxWidth: "200px", height: "100px" }}
                                        >
                                            {this.state.swapImage.length > 0 && !this.state.uploading ? <img src={AWS_URL + this.state.swapImage} alt="Pano to Swap" style={{ width: '100%', maxWidth: "200px", height: "100px" }}/> : uploadSwapButton}
                                        </Upload>
                                    </Col>
                                </Row>
                            </div>
                        </Modal>


                        <Modal
                            title="Would you like to swap this pano image?"
                            visible={this.state.swapSharedImageModal}
                            onOk={() => this.swapSharedImage()}
                            onCancel={() => this.swapSharedImageModalCancel()}>
                            <div>
                                <Row style={style}>
                                    <Col span={10}>
                                        <img src={this.state.swapPanoThumb} alt="Pano to Swap" style={{ width: '100%', maxWidth: "200px", height: "100px" }} />
                                        <p>{this.state.panoToSwap.name}</p>
                                    </Col>
                                    <Col span={4}>
                                        <Icon type="arrow-right" style={{ fontSize: "5em" }} />
                                    </Col>
                                    <Col span={10} className="swap-upload">
                                        <Upload
                                            listType="picture-card"
                                            showUploadList={false}
                                            disabled={this.state.uploading}
                                            customRequest={this.uploadSharedImageSwap}
                                            accept="image/*"
                                            className="clear-none"
                                        >
                                            {this.state.swapImage.name && !this.state.uploading ? <img src={this.state.swapImage.url} alt="Pano to Swap" style={{ width: '100%', maxWidth: "200px", height: "100px" }} /> : uploadSwapButton}
                                        </Upload>
                                    </Col>
                                </Row>
                            </div>
                        </Modal>


                        <Modal
                            title="Would you like to swap this pano image?"
                            visible={this.state.swapTimelineImageModal}
                            onOk={() => this.swapTimelinePano()}
                            onCancel={() => this.swapTimelineImageModal({})}>
                            <div>
                                <Row style={{ marginTop: "40px", marginLeft: "30px" }}>
                                    <Col xs={24} sm={24} md={12}>Local Upload List <Checkbox style={{ marginLeft: "40px" }} onChange={this.onSwapChangeChecked("local")} checked={this.state.local} /></Col>
                                    <Col xs={24} sm={24} md={12}>Shared Library <Checkbox style={{ marginLeft: "40px" }} onChange={this.onSwapChangeChecked("shared")} checked={!this.state.local} /></Col>
                                </Row>
                                <Row style={style}>
                                    <Col span={12}>
                                        <Select
                                            onChange={this.swapTimelineImage}
                                            value={this.state.timelineSwapImage}
                                            placeholder="Select a local image"
                                            style={{ width: "200px" }}
                                            disabled={!this.state.local}
                                        >
                                            {this.selectOptionsLocal()}
                                        </Select>
                                    </Col>
                                    <Col span={12}>
                                        <Select
                                            onChange={this.swapTimelineImage}
                                            value={this.state.timelineSwapImage}
                                            placeholder="Select a shared image"
                                            style={{ width: "200px" }}
                                            disabled={this.state.local}
                                        >
                                            {this.selectOptionsShared()}
                                        </Select>
                                    </Col>
                                </Row>
                            </div>
                        </Modal>

                        <Modal
                            title="Please resolve following errors"
                            visible={this.state.errorModal}
                            onOk={() => this.setState({ errorModal:false })}
                            onCancel={() => this.setState({ errorModal: false })}>
                            <div>
                                <Row style={{ marginTop: "40px", marginLeft: "30px" }}>
                                    <List
                                        header={<div>Errors list</div>}
                                        footer={<div>Fix these panos before publishing</div>}
                                        bordered
                                        dataSource={this.state.errors}
                                        renderItem={(item:any, ind:number) => (
                                            <List.Item>
                                                &#x2022; {item}
                                            </List.Item>
                                        )}
                                    />
                                </Row>
                            </div>
                        </Modal>

                        <Modal
                            title="Tour Stats"
                            visible={this.state.statModal}
                            onOk={() => this.setState({ statModal: false })}
                            onCancel={() => this.setState({ statModal: false })}>
                                <Row>
                                    <div style={{ border: "1px solid #ccc", padding: "14px"}}>
                                        <h2 className="bebas" style={{textAlign:"center"}}>Total Tour Stats</h2>
                                        <Row style={{ marginTop: "10px" }}>
                                            <Col span={12}><strong>Total Panos Size:</strong></Col>
                                            <Col span={12} style={{ textAlign: "right" }}>{this.state.stats.panosTotal}</Col>
                                        </Row>
                                        <Row style={{ marginTop: "10px" }}>
                                            <Col span={12}><strong>Largest Pano: </strong></Col>
                                            <Col span={12} style={{ textAlign: "right" }}>{this.state.stats.largestPano}</Col>
                                        </Row>
                                        <Row style={{ marginTop: "10px" }}>
                                            <Col span={12}><strong>Largest Pano Size: </strong></Col>
                                            <Col span={12} style={{ textAlign: "right" }}>{this.state.stats.maxPano}</Col>
                                        </Row>
                                        <Row style={{ marginTop: "10px" }}>
                                            <Col span={12}><strong>Total Resources Size:</strong></Col>
                                            <Col span={12} style={{ textAlign: "right" }}>{this.state.stats.resourceTotal}</Col>
                                        </Row>
                                        <Row style={{ marginTop: "10px" }}>
                                            <Col span={12}><strong>Largest Resource: </strong></Col>
                                            <Col span={12} style={{ textAlign: "right" }}>{this.state.stats.largestResource}</Col>
                                        </Row>
                                        <Row style={{ marginTop: "10px" }}>
                                            <Col span={12}><strong>Largest Resource Size: </strong></Col>
                                            <Col span={12} style={{ textAlign: "right" }}>{this.state.stats.maxResource}</Col>
                                        </Row>
                                    </div>
                                </Row>
                        </Modal>

                        <Modal
                            title="Validation Errors"
                            visible={this.state.validateModal}
                            onOk={() => this.setState({ validateModal: false })}
                            onCancel={() => this.setState({ validateModal: false })}>
                            <Row>
                                <div style={{ border: "1px solid #ccc", padding: "14px" }}>
                                    <h2 className="bebas" style={{ textAlign: "center" }}>Errors</h2>
                                    {
                                        (this.state.validationErrors.length>0)?
                                        <List
                                            header={<div>Errors list</div>}
                                            footer={<div>Fix these errors before publishing</div>}
                                            bordered
                                            dataSource={this.state.validationErrors}
                                            renderItem={(item: any, ind: number) => (
                                                <List.Item>
                                                    &#x2022; {item}
                                                </List.Item>
                                            )}
                                        />
                                        :
                                        <p>No validation errors in this tour</p>
                                    }
                                    <hr />
                                    <h2 className="bebas" style={{ textAlign: "center" }}>Warnings</h2>
                                    {
                                        (this.state.validationWarnings.length > 0) ?
                                        <List
                                            header={<div>Warnings list</div>}
                                            bordered
                                            dataSource={this.state.validationWarnings}
                                            renderItem={(item: any, ind: number) => (
                                                <List.Item>
                                                    &#x2022; {item}
                                                </List.Item>
                                            )}
                                        />
                                        :
                                        <p>No warnings in this tour</p>
                                    }
                                </div>
                            </Row>
                        </Modal>
                    </Row>
                    <div className="fixedButtonDiv">
                        <Button onClick={() => this.getTotalTourStats()} className="fixedButton">Get Stats</Button>
                    </div>
                    <div className="fixedValidateButtonDiv">
                        <Button onClick={() => this.validatingTour()} className="fixedButton">Validate Tour</Button>
                    </div>
                </Spin>

            </div>
        );
    }
}
export default PanoSequence;