import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Divider, Button, Tag, Modal, Input, Icon } from 'antd';
import { getAllTours } from '../../api/APIget';
import { deleteTour } from '../../api/APIpost';

const { confirm } = Modal;

interface State {
    loading: boolean,
    tours:any[],
    showModal:boolean,
    alertMessage:string,
    deletion:string,
    shareTourTitle:string
    shareLink:string,
    showLinkModal:boolean,
    updatePermission:boolean,
    deletePermission:boolean,
    deleteImages:boolean,
};
class CreateTour extends Component<any, State> {
  private textArea: React.RefObject<HTMLInputElement>; 
    state:State={
        loading:true,
        tours:[],
        showModal:false,
        showLinkModal:false,
        alertMessage:'',
        deletion:'',
        shareTourTitle:'',
        shareLink:'',
        updatePermission:false,
        deletePermission:false,
        deleteImages:false,
    }
    public constructor(props:any){
        super(props);
        this.textArea = React.createRef();
      }
      componentDidMount(){
        console.log(this.props.auth.permissions);
        this.setState({updatePermission: this.props.auth.permissions.includes("Update"),
        deletePermission:this.props.auth.permissions.includes("Delete")
        })
        getAllTours(this.success,this.error);
      }
      success = (data:any) =>
      {
        console.log(`success!`);
        console.log(data);
        this.setState({tours:data.data,loading:false});
      }
      error = (error:any)=>{
        this.setState({loading:false});
        console.log(`error!`);
        console.log(error);
        alert('An Error occurred. Check the console for more information');
      }
      delete = (id:string) =>
      {
        this.setState({deletion:id,alertMessage:"Do you want to delete this tour?",showModal:true});
       
        console.log(this.state.tours);
      }
      handleCancel = () => this.setState({ showModal: false, showLinkModal:false});
  
      handleOk = (e:any) => 
      {
        this.setState({showModal:false, loading:true});
        deleteTour(this.state.deletion, this.deleteSuccess, this.error)
        
      }
      deleteSuccess = (data:any) =>
      {
        console.log(data.data);
        Modal.success({
          content:"The tour was deleted successfully"
        });
        getAllTours(this.success,this.error);
      }
      onChange(pagination:any, filters:any, sorter:any, extra:any) 
      {
        console.log('params', pagination, filters, sorter, extra);
      }
      showLinkModal(tourId:string)
      {
        let tour = this.state.tours.find((tour)=>tour.id===tourId); 
        this.setState({showLinkModal:true,  shareTourTitle:'Link to: '+tour.tourName})
      }
      render() {
        const columns = [
            {
              title: 'Tour Name',
              dataIndex: 'tourName',
              key: 'tourName',
              // render: (text:any) => <p>{text}</p>,
              sorter: (a:any, b:any) => ((a.tourName > b.tourName) ? 1 : ((b.tourName > a.tourName) ? -1 : 0)),
            },
            {
              title: 'Tour Description',
              dataIndex: 'tourDescription',
              key: 'tourDescription',
            },
            {
              title: 'Tags',
              key: 'tags',
              dataIndex: 'tags',
              filters:
              [
                {
                  text:"Public",
                  value:"Public"
                },
                {
                  text:"Internal",
                  value:"Internal"
                },
                {
                  text:"Private",
                  value:"Private"
                }
              ],
              filterMultiple: false,
              onFilter: (value:any, record:any) => record.tags.filter((tag:any)=>tag.accessLevel === value).length > 0,
              render: (tags:any) => (
                <span>
                  {
                    (tags.length > 0) ? 
                      tags.map((tag:any) => {
                        let color = tag.accessLevel==="Public" ? 'green': 'blue';
                        return (
                          <Tag color={color} key={tag.id}>
                            {tag.name.toUpperCase()}
                          </Tag>
                        );
                      }) 
                    : 
                      null
                }
                </span>
              ),
            },
            {
              title: 'Action',
              key: 'action',
              render: (text:any, record:any) => (
                <span>
                  <a href={`/viewer/${text.id}`}>View Tour</a>
                  <Divider type="vertical" />
                  {
                    (this.state.updatePermission)? 
                    <React.Fragment>
                      <a href={`/tour/0/${text.id}`}> Edit Tour</a>
                      <Divider type="vertical" />
                    </React.Fragment>
                  : null
                  }
                  
                  <Button type="link" onClick={()=>this.showLinkModal(text.id)}>Share Tour</Button>
                  {
                    (this.state.deletePermission)? <React.Fragment>
                      <Divider type="vertical" />
                      <Button type="link" onClick={()=>this.delete(text.id)}>Delete Tour</Button>
                    </React.Fragment>
                    :
                    null
                  }
                  
                </span>
              ),
            },
          ];
        return (
          <div>
           <Table loading={this.state.loading} rowKey={record => record.id} onChange={this.onChange} columns={columns} dataSource={this.state.tours} />
           <Modal
              title={this.state.alertMessage}
              visible={this.state.showModal}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
            >
            </Modal>  
            <Modal
              title={this.state.shareTourTitle}
              visible={this.state.showLinkModal}
              footer={[
                <Button key="back" onClick={this.handleCancel}>
                  Close
                </Button>]}
            >
              <div style={{ marginBottom: 16 }}>
                <Input value={this.state.shareLink}
                  addonAfter={<Button onClick={() => {navigator.clipboard.writeText(this.state.shareLink)}}>Copy <Icon type="link" /></Button>}  
                />
              </div>
            </Modal>  
          </div>
        );
  }
}
function mapToStateToProps(state: any){
  return {
    auth: state.auth,
  };
}
export default connect(mapToStateToProps)(CreateTour);