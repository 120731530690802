import React, { Component } from 'react';
import 'aframe';
import { Entity, Scene } from 'aframe-react';
import { getTourSequence, getTourStats, validateTour } from '../../../api/APIget';
import { createdSharedTourData, updatePublished } from '../../../api/APIpost';
import { getCurrentDate, createMySqlDate, getDateDMY, } from '../../../utils/tools';
import { Modal, Spin, Checkbox, Row, Col, DatePicker, Button, Icon, message, Input, List, } from 'antd';
import moment from 'moment';
import { createSharedLink, refreshSharedLink } from '../../../api/JanusHostApiPost';

const CheckboxGroup = Checkbox.Group;

interface State {
    status: number;
    tourInfo: any;
    panoBackground: any;
    panoId: number,
    hotSpotArray: any;
    loading: boolean;
    panobackground: string;
    visible: boolean;
    publishBy: any,
    disableBy: any,
    publishNow: boolean,
    noDisable: boolean,
    shareLink: string,
    showLinkModal: boolean,
    sharedId: string,
    hasPassword: boolean,
    password: string,
    showRefreshModal: boolean,
    statModal: boolean,
    stats: any,
    validateModal: boolean,
    validationErrors: any,
    validationWarnings: any
};

class Publish extends Component<any, State> {
    state: State = {
        tourInfo: {},
        status: 1,
        panoBackground: {
        },
        panoId: 0,
        hotSpotArray: [],
        loading: true,
        panobackground: '',
        visible: false,
        publishBy: null,
        disableBy: null,
        publishNow: true,
        noDisable: true,
        shareLink: "",
        showLinkModal: false,
        sharedId: "",
        hasPassword: true,
        password: "!23QweAsd",
        showRefreshModal: false,
        statModal: false,
        stats: {},
        validateModal: false,
        validationErrors: {},
        validationWarnings: {}
    };

    public constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        // console.log(this.props.match.params.component);
        console.log(this.state);
        getTourSequence(this.props.match.params.id, this.getSuccess, this.getError);
        
    }

    getSuccess = (data: any) => {
        console.log(data);
        if (data.data.tour.sceneObjects.length > 0) {

            this.setState({
                status: data.data.status,
                publishBy: moment(data.data.publishedDate),
                disableBy: moment(data.data.publishUntil),
                tourInfo: data.data.tour,
                loading: false
            });
        }
        else {
            Modal.error({
                title: 'There is currently no data for this tour',
                content: 'There is currently no data for this tour. Press okay to edit this tour',
                onOk: () => {
                    window.location.replace(`/tour/1/${data.data.id}`)
                }
            });
        }
    }

    getTotalTourStats = () => {
        this.setState({ loading: true });
        getTourStats(this.state.tourInfo.id, this.getTotalTourStatsSuccess, this.getStatsError);
    }

    getTotalTourStatsSuccess = (data: any) => {
        console.log(data.data);
        this.setState({ stats: data.data, statModal: true, loading: false });
    }

    getStatsError = (data: any) => {
        console.log(data);
        this.setState({ loading: false });
        message.error("Sorry! Cannot fetch the records currently. Please try again later");
    }

    validatingTour = () => {
        this.setState({ loading: true });
        validateTour(this.state.tourInfo.id, this.validateTourSuccess, this.validateTourError);
    }

    validateTourSuccess = (data: any) => {
        console.log(data.data);
        if (data.data.errors.length === 0 && data.data.warnings.length === 0) {
            message.success("No errors or warnings found in this tour.");
            this.setState({ validateModal: true, loading: false });
        }
        else {
            this.setState({ validationErrors: data.data.errors, validationWarnings: data.data.warnings, validateModal: true, loading: false });
        }
    }

    validateTourError = (data: any) => {
        console.log(data);
        this.setState({ loading: false });
        message.error("Sorry! Cannot validate tour at this moment. Please try again later.");
    }

    getError = (data: any) => {
        console.log(data);
    }

    onChangeChecked = (status:string)=> (e: any) => {
        if (status === 'Now') {
            this.setState({
                publishNow: true,
                publishBy: moment(getCurrentDate())
            } as any);
        }
        else {
            this.setState({
                publishNow: false
            } as any);
        }
        console.log(this.state);
    };

    onDisableChangeChecked = (status: string) => (e: any) => {
        if (status === 'Now') {
            this.setState({
                noDisable: true
            } as any);
        }
        else {
            this.setState({
                noDisable: false
            } as any);
        }
        console.log(this.state);
    };

    handleDateChange(date: any, dateString: string, input: string) {
        // console.log(date, dateString);
        // console.log(input);
        this.setState({ [input]: date }as any);
        //console.log(this.state);
    }

    unpublishTour = () => {
        updatePublished(this.getunPublishedObj(), this.updateunPublishedSuccess, this.updatePublishedError);
    }

    publishTour = () => {
        //console.log(!this.state.publishNow && this.state.publishBy !== null && this.state.disableBy !== null);

        if (this.state.publishNow || (!this.state.publishNow && this.state.publishBy !== null)) {
            this.setState({ loading: true });
            validateTour(this.state.tourInfo.id, this.validatePublishSuccess, this.validateTourError);
        }
        else {
            message.error("Both the Publish By and Disable By options must be filled in");
        }
    }

    validatePublishSuccess = (data: any) => {
        console.log(data.data);
        if (data.data.errors.length === 0) {

            updatePublished(this.getPublishedObj(), this.updatePublishedSuccess, this.updatePublishedError);
            this.setState({loading: false });
        }
        else {
            message.error("Please resolve following errors before publishing.");
            this.setState({ validationErrors: data.data.errors, validationWarnings: data.data.warnings, validateModal: true, loading: false });
        }
    }

    updatePublishedSuccess = (data: any) => {
        if (data.data.status === 2) {
            message.info("The course has been published succesfully!");
            this.setState({ status: 2 });
        }
        else {
            message.info("This course will be published on " + getDateDMY(data.data.publishedDate));
        }

        console.log(data.data);
    }

    updatePublishedError = (data: any) => {
        console.log(data.data);
    }

    getPublishedObj() {
        let futureDate = new Date();
        futureDate.setFullYear(futureDate.getFullYear() + 5);
        return {
            Id: this.props.match.params.id,
            TourId: this.state.tourInfo.id,
            CompanyId: this.props.auth.company.id,
            Status: (this.state.publishNow || (this.state.publishBy !== null && createMySqlDate(this.state.publishBy.format('YYYY-MM-DD')) <= getCurrentDate())) ? "Published" : "NotPublished",
            LastUpdatedTime: getCurrentDate(),
            publishedDate: (this.state.publishNow) ? getCurrentDate() : createMySqlDate(this.state.publishBy.format('YYYY-MM-DD')),
            publishUntil: (this.state.noDisable) ? createMySqlDate(futureDate.toISOString()) : createMySqlDate(this.state.disableBy.format('YYYY-MM-DD'))
        }
    }

    getunPublishedObj() {
        let date = new Date();
        let lastMonth = date.getUTCMonth() - 1;
        date.setUTCMonth(lastMonth);
        let now = new Date();
        let yesterday = now.getUTCDate() - 1;
        now.setUTCDate(yesterday);
        return {
            Id: this.props.match.params.id,
            TourId: this.state.tourInfo.id,
            CompanyId: this.props.auth.company.id,
            Status: "NotPublished",
            LastUpdatedTime: getCurrentDate(),
            publishedDate: createMySqlDate(date.toISOString()),
            publishUntil: createMySqlDate(now.toISOString())
        }
    }

    updateunPublishedSuccess = (data: any) => {
        if (data.data.status === 0) {
            message.info("The course is no more published!");
        }
        this.setState({ status: 0 });
        console.log(data.data);
    }

    save = () => {
        message.success("Your tour has been saved");
    }
    //Shared link

    getSharedLink() {
        if (this.state.shareLink !== "")
        {
            this.setState((state) => { return { showLinkModal: true } })
        }
        else {
            this.setState((state) => { return { showLinkModal: true } })
            this.createlink()
        }
    }
    clipboard = () => {
        navigator.clipboard.writeText(this.state.shareLink);
        message.success("Copied to clipboard");
    }
    createlink() {
        if (this.state.sharedId !== "") {
            let obj = {
                fullUrl: "/shared?shareContentId=" + this.state.sharedId,
                loginProvider: "VrSafety",
                tokenName: "AuthTokenShareContent"
            }
            createSharedLink(obj, this.createLinkSuccess, this.postError);
        }
        else {
            let sharedTour =
            {
                CompanyId: this.props.auth.company.id,
                Available: true,
                TourId: this.props.match.params.id,
                hasPassword: this.state.hasPassword,
                password: this.state.password,
                submittedAt: getCurrentDate()
            }
            //console.log(sharedTour);
            createdSharedTourData(sharedTour, this.createSharedSuccess, this.postError);
        }
    }
    refreshSharedLink() {
        this.setState({ showRefreshModal: true, showLinkModal: false })
        if (this.state.sharedId !== "") {
            let obj = {
                fullUrl: "/shared?shareContentId=" + this.state.sharedId,
                loginProvider: "VrSafety",
                tokenName: "AuthTokenShareContent",
            }
            console.log(this.state.sharedId);
            refreshSharedLink(obj, this.createLinkSuccess, this.postError);
        }
        else {
            let sharedTour =
            {
                CompanyId: this.props.auth.company.id,
                Available: true,
                TourId: this.props.match.params.id,
                hasPassword: this.state.hasPassword,
                password: this.state.password,
                submittedAt: getCurrentDate()
            }
            //console.log(sharedTour);

            createdSharedTourData(sharedTour, this.createSharedSuccess, this.postError);
        }
    }
    createLinkSuccess = (data: any) => {
        console.log(data);
        let link = "https://" + data.data;
        this.setState((state) => { return { shareLink: link } });
    }

    createSharedSuccess = (data: any) => {
        //console.log(data);
        let obj = {
            fullUrl: "/shared?shareContentId=" + data.data.id,
            loginProvider: "VrSafety",
            tokenName: "AuthTokenShareContent",
        }
        createSharedLink(obj, this.createLinkSuccess, this.postError);
    }
    postError = (data: any) => {
        console.log(data);
        message.error(data.response.data);
    }
    handleCancel = () => this.setState({ showLinkModal: false });
    handleCancelRefresh = () => this.setState({ showRefreshModal: false });
    render() {
        return (
        <Spin spinning={this.state.loading}>
        <Row type="flex" justify="space-between" style={{ marginTop: "20px" }} className={this.props.auth.layout.primaryColor}>
          <Col xs={24} sm={24} md={8}></Col>
          <Col xs={24} sm={24} md={8}>
              {
                (this.state.status === 2)?
                <React.Fragment>
                  <Row style={{marginTop:"20px"}}>
                    This Tour is published.
                    <Row style={{marginTop:"20px"}}>
                    Published On: {createMySqlDate(this.state.publishBy.format('YYYY-MM-DD'))}
                    </Row>
                    <Row style={{marginTop:"20px"}}>
                    Disable By: {createMySqlDate(this.state.disableBy.format('YYYY-MM-DD'))}
                    </Row>
                    <Row style={{marginTop:"20px"}}>
                    <Button onClick={this.unpublishTour}>
                      Unpublish Tour
                      <Icon type="stop" />
                    </Button>
                    </Row>
                  </Row>
                  
                </React.Fragment>
                :
                <React.Fragment>
                  <Row style={{marginTop:"40px", textAlign: "center"}}>
                    <h1 className="darkBlueText">Publish My Tour</h1>
                    <p>How would you like to publish your tour</p>
                  </Row>
                  <Row style={{marginTop:"80px"}}>
                    <Col xs={24} sm={24} md={8} style={{textAlign: "left"}}>
                        <Checkbox style={{marginLeft:"8px"}} onChange={this.onChangeChecked("Now")} checked={this.state.publishNow}/>
                        Publish Now 
                    </Col>
                    <Col xs={24} sm={24} md={8} style={{textAlign: "center"}}>OR</Col>
                    <Col xs={24} sm={24} md={8} style={{textAlign: "right"}}>
                        <Row style={{textAlign: "left", marginBottom: "5px"}}>
                        <Checkbox  style={{marginLeft:"8px"}} onChange={this.onChangeChecked("Later")} checked={!this.state.publishNow} />
                        Publish Later
                        </Row>
                        <DatePicker  disabled={this.state.publishNow} onChange={(date,datestring)=>this.handleDateChange(date,datestring,"publishBy")} value={this.state.publishBy} />
                    </Col>
                  </Row>

                  <Row style={{marginTop:"60px"}}>
                    <h4 style={{textAlign: "center", marginBottom: "20px"}} className= "darkBlueText">Disable Settings</h4>
                    <Col xs={24} sm={24} md={8} style={{textAlign: "left"}}>
                    <Checkbox style={{marginLeft:"8px"}} onChange={this.onDisableChangeChecked("Now")} checked={this.state.noDisable}/>
                    Never 
                    <Row style= {{fontSize: "0.8em"}}>(Will be Auto Un-published in 5 years)</Row>
                    </Col>
                    <Col xs={24} sm={24} md={8} style={{textAlign: "center"}}>OR</Col>
                    <Col xs={24} sm={24} md={8} style={{textAlign: "right" }}>
                        <Row style={{textAlign: "left", marginBottom: "5px"}}>
                        <Checkbox  style={{marginLeft:"8px"}} onChange={this.onDisableChangeChecked("Later")} checked={!this.state.noDisable} /> 
                        Disable By
                        </Row>
                        <DatePicker  disabled={this.state.noDisable} onChange={(date, datestring) => this.handleDateChange(date, datestring, "disableBy")} value={this.state.disableBy} />
                    </Col>
                  </Row>


                  <Row style={{marginTop:"40px"}}>
                  <Col xs={24} sm={24} md={8} style={{marginTop:"40px", textAlign: "left"}}>
                    <Button onClick={()=>this.props.prev()}>
                        <Icon type="left" />
                        Previous
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={8} style={{marginTop:"40px", textAlign: "center"}}>
                    <Button onClick={this.save} style={{ color: "#4ECDC4", borderColor: "#4ECDC4" }} className="tealText" ghost>
                      Save as Draft
                      <Icon type="save" />
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={8} style={{marginTop:"40px", textAlign: "right"}}>
                    <Button onClick={this.publishTour}>
                      Publish Tour
                      <Icon type="check" />
                    </Button>
                  </Col>
                  
                  </Row>
              </React.Fragment>
                    }
                    <React.Fragment>
                        <Row style={{ marginTop: "20px" }}>
                            <Button icon="link" onClick={() => this.getSharedLink()}>Shared Link</Button>
                        </Row>
                </React.Fragment>
            
          </Col>
                <Col xs={24} sm={24} md={8}></Col>
                <Modal
                    title={"The shareable link for this tour is: "}
                    visible={this.state.showLinkModal}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            Close
                </Button>]}
                >
                    <div style={{ marginBottom: 16 }}>
                        <Input value={this.state.shareLink}
                            addonAfter={<Button onClick={() => this.clipboard()}>Copy <Icon type="link" /></Button>}
                        />
                        <Button onClick={() => this.refreshSharedLink()}>Regenerate Link<Icon type="reload" /></Button>
                    </div>
                </Modal>
                <Modal
                    title={"The New shareable link for this tour is: "}
                    visible={this.state.showRefreshModal}
                    onCancel={this.handleCancelRefresh}
                    footer={[
                        <Button key="back" onClick={this.handleCancelRefresh}>
                            Close
                        </Button>]}
                >
                    <div style={{ marginBottom: 16 }}>
                        <Input value={this.state.shareLink}
                            addonAfter={<Button
                                onClick={() => this.clipboard()
                                }>Copy
                                
                                <Icon type="link" /></Button>}
                        />
                    </div>
                </Modal> 
                <Modal
                    title="Tour Stats"
                    visible={this.state.statModal}
                    onOk={() => this.setState({ statModal: false })}
                    onCancel={() => this.setState({ statModal: false })}>
                    <Row>
                        <div style={{ border: "1px solid #ccc", padding: "14px" }}>
                            <h2 className="bebas" style={{ textAlign: "center" }}>Total Tour Stats</h2>
                            <Row style={{ marginTop: "10px" }}>
                                <Col span={12}><strong>Total Panos Size:</strong></Col>
                                <Col span={12} style={{ textAlign: "right" }}>{this.state.stats.panosTotal}</Col>
                            </Row>
                            <Row style={{ marginTop: "10px" }}>
                                <Col span={12}><strong>Largest Pano: </strong></Col>
                                <Col span={12} style={{ textAlign: "right" }}>{this.state.stats.largestPano}</Col>
                            </Row>
                            <Row style={{ marginTop: "10px" }}>
                                <Col span={12}><strong>Largest Pano Size: </strong></Col>
                                <Col span={12} style={{ textAlign: "right" }}>{this.state.stats.maxPano}</Col>
                            </Row>
                            <Row style={{ marginTop: "10px" }}>
                                <Col span={12}><strong>Total Resources Size:</strong></Col>
                                <Col span={12} style={{ textAlign: "right" }}>{this.state.stats.resourceTotal}</Col>
                            </Row>
                            <Row style={{ marginTop: "10px" }}>
                                <Col span={12}><strong>Largest Resource: </strong></Col>
                                <Col span={12} style={{ textAlign: "right" }}>{this.state.stats.largestResource}</Col>
                            </Row>
                            <Row style={{ marginTop: "10px" }}>
                                <Col span={12}><strong>Largest Resource Size: </strong></Col>
                                <Col span={12} style={{ textAlign: "right" }}>{this.state.stats.maxResource}</Col>
                            </Row>
                        </div>
                    </Row>
                </Modal>

                <Modal
                        title="Validation Errors"
                        visible={this.state.validateModal}
                        onOk={() => this.setState({ validateModal: false })}
                        onCancel={() => this.setState({ validateModal: false })}>
                        <Row>
                            <div style={{ border: "1px solid #ccc", padding: "14px" }}>
                                <h2 className="bebas" style={{ textAlign: "center" }}>Errors</h2>
                                {
                                    (this.state.validationErrors.length > 0) ?
                                        <List
                                            header={<div>Errors list</div>}
                                            footer={<div>Fix these errors before publishing</div>}
                                            bordered
                                            dataSource={this.state.validationErrors}
                                            renderItem={(item: any, ind: number) => (
                                                <List.Item>
                                                    &#x2022; {item}
                                                </List.Item>
                                            )}
                                        />
                                        :
                                        <p>No validation errors in this tour</p>
                                }
                                <hr />
                                <h2 className="bebas" style={{ textAlign: "center" }}>Warnings</h2>
                                {
                                    (this.state.validationWarnings.length > 0) ?
                                        <List
                                            header={<div>Warnings list</div>}
                                            bordered
                                            dataSource={this.state.validationWarnings}
                                            renderItem={(item: any, ind: number) => (
                                                <List.Item>
                                                    &#x2022; {item}
                                                </List.Item>
                                            )}
                                        />
                                        :
                                        <p>No warnings in this tour</p>
                                }
                            </div>
                        </Row>
                    </Modal>

            </Row>
            <div className="fixedButtonDiv">
                    <Button onClick={() => this.getTotalTourStats()} className="fixedButton">Get Stats</Button>
            </div>
            <div className="fixedValidateButtonDiv">
                <Button onClick={() => this.validatingTour()} className="fixedButton">Validate Tour</Button>
            </div>
        </Spin >
    );

    }
}
export default Publish;