import React, { Component } from 'react';
import { connect } from 'react-redux';
import { message, Row, Col, Radio, Input, Spin, Table, Button, Icon } from 'antd';
import { getAllTourProgress, getCriticalHotspots, getTourUsers } from '../../api/APIget';
import TeamProfiles from '../TeamProfiles/teamProfiles';
import { CompanySummary } from './CompanySummary';
import { getConnectedUsers } from '../../api/JanusHostApiGet';
import { GradeGraph } from './gradeGraph';
import { getGrade, getCurrentDate, getGradePoints, getGradeFromPoints, totalTime } from '../../utils/tools';
import axios from "axios";

let searchInput: any = {};
interface filterDropDown {
    setSelectedKeys: any,
    selectedKeys: any,
    confirm: any,
    clearFilters: any,
    
}
interface State {
    loading: boolean,
    courses: any[],
    teamMates: any[],
    usersProgress: any[],
    user: any[],
    searchedColumn: string,
    searchText: string,
    courseName: any[],
    courseGrade: any[],
    criticalHotspots: any[],
    course: string,
    allCourses: any[],
    totalCourse: number
};
class ManageGrades extends Component<any, State> {
    state: State = {
        loading: true,
        courses: [],
        teamMates: [],
        usersProgress: [],
        user: [],
        searchedColumn: '',
        searchText: '',
        courseName: [],
        courseGrade: [],
        criticalHotspots: [],
        course: '',
        allCourses: [],
        totalCourse: 0
    }
    public constructor(props: any) {
        super(props);
    }
    componentDidMount() {
        //console.log(this.props);
        getAllTourProgress(this.getSuccess, this.getError);
        getConnectedUsers(this.props.auth.company.id, this.getUsersSuccess, this.getUsersError);
        
    }
    getSuccess = (data: any) => {
        //console.log(data);
        let courseNames: any[] = []; // for tour info to extract tour names to plot on graph later
        for (let i = 0; i < data.data.length; i++) {
            courseNames.push(data.data[i]);
            this.setState({ course: data.data[i].id });
            // getting tour progresses for each tour
            getTourUsers(data.data[i].tourId, this.getUserSuccess, this.getError);
        }
        this.setState({ courses: data.data, allCourses: courseNames, totalCourse: data.data.length });
    }


    getUserSuccess = (data: any) => {
        let course;
        let points = 0;
        let length = 0;

        if (data.data[0].id) { //condition to check if the course has progress 

            // filtering to get the course that we are checking the progress of here
            let courseName = this.state.allCourses.filter((tour: any) => { return tour.tourId === data.data[0].tourId; })
            getCriticalHotspots(this.state.course, this.getHotspotSuccess, this.getError);
            for (let i = 0; i < data.data.length; i++) {
                let grade = getGrade(data.data[i], this.state.criticalHotspots);
                if (grade !== '-') {
                    points = points + getGradePoints(grade);
                    length++;
                }
            }
            course = courseName[0].tourName; //getting course name
            //console.log(course);
        }
        else { // the course doesn't have any progress, there would be tour name returned in 'data.data'
            course = data.data;
            //console.log(course)
        }
        if (length != 0) {
            points = Math.round(points / length);
        }
        let courseGrade = this.state.courseGrade;
        let courseNames = this.state.courseName;

        courseGrade.push(points);
        courseNames.push(course);
        this.setState({ courseGrade: courseGrade, courseName: courseNames }); // Updating courseGrade and courseName to plot on graph
        //console.log(points);
    }


    getUsersSuccess = (data: any) => {
        //console.log(data);
        let teamMates = data.data.connections.filter((user: any) => user.user.firstName !== "shared");
        //console.log(teamMates);
        this.setState({ teamMates: teamMates });
        this.UsersProgress();
    }
    getError = (data: any) => {
        console.log(data);
        message.error("There was a problem getting your courses");
    }
    getUsersError = (data: any) => {
        console.log(data);
    }

    UsersProgress = () => {
        //console.log(this.state.teamMates);
        //console.log("teamMates");
        for (var i = 0; i < this.state.teamMates.length; i++) {
            this.getUserProgressDetails(this.state.teamMates[i].user.id)
        }
        this.setState({loading: false});
    }

    getUserProgressDetails = async (userId: any) => {
        let m = await new Promise<void>((resolve: any, reject: any) => {
            axios.get(`/api/v1/vrsafety/tourProgress/user/${userId}`).then((data) => {
                let usersProgress = this.state.usersProgress;
                //console.log(data);
                let teamUser = this.state.teamMates.filter((user: any) => { return user.user.id == userId; });
                if (data.data.length > 0)// To prevent getting undefined results for users that never started a course
                {
                    let progress = data.data.filter((data: any) => { return !data.tourProgress.completed });
                    let completed = data.data.filter((data: any) => { return data.tourProgress.completed});
                    let time = totalTime(data.data);
                    let points = 0;
                    let failed = 0;
                    // getting grades
                    for (let i = 0; i < completed.length; i++) {

                        //console.log(completed[i]);
                        getCriticalHotspots(completed[i].tourInfo.id, this.getHotspotSuccess, this.getError);
                        //console.log(this.state.criticalHotspots);
                        let grade = getGrade(completed[i].tourProgress, this.state.criticalHotspots)
                        let point = getGradePoints(grade);
                        if (grade === 'F') {
                            failed++;
                        }
                        points = points + point;
                    }

                    let averagePoints = Math.round(points / completed.length);

                    let averageGrade = getGradeFromPoints(averagePoints);
                    let user = {
                        "id": teamUser[0].user.id,
                        "name": teamUser[0].user.firstName + " " + teamUser[0].user.lastName,
                        "name_link": <a href={`/teammate-grades/${teamUser[0].user.id}`}>{teamUser[0].user.firstName + " " + teamUser[0].user.lastName}</a>,
                        "completed": completed.length,
                        "started": progress.length,
                        "failed": failed,
                        "grade": averageGrade,
                        "totalTime": time
                    }
                    //console.log(user);
                    usersProgress.push(user);
                    this.setState({ usersProgress: usersProgress })
                }
                else {
                    let user = {
                        "id": teamUser[0].user.id,
                        "name": teamUser[0].user.firstName + " " + teamUser[0].user.lastName,
                        "name_link": <a href={`/teammate-grades/${teamUser[0].user.id}`}>{teamUser[0].user.firstName + " " + teamUser[0].user.lastName}</a>,
                        "completed": 0,
                        "started": 0,
                        "failed": 0,
                        "grade": '-',
                        "totalTime": '00:00:00'
                    }
                    //console.log(user);
                    usersProgress.push(user);
                    this.setState({ usersProgress: usersProgress })
                }
            }).catch((error) => {
                message.error("There was a problem with uploading the file. Please try again");
                console.log(error);
            })
        })
        return m;
    }


    getStats = () => {
        let progress = 0;
        let complete = 0;
        let failed = 0;
        let points = 0;
        let length = 0;
        let time = this.totalTime(this.state.usersProgress);
        for (var i = 0; i < this.state.usersProgress.length; i++) {
            progress = Math.round(progress + this.state.usersProgress[i].started);
            complete = Math.round(complete + this.state.usersProgress[i].completed);
            failed = Math.round(failed + this.state.usersProgress[i].failed);
            if (complete + this.state.usersProgress[i].completed) {
                points = points + getGradePoints(this.state.usersProgress[i].grade);
                length++;
            }
        }
        let average = Math.round(points / length);
        let avgGrade = getGradeFromPoints(average);
        let stats = {
            "progress": progress,
            "complete": complete,
            "failed": failed,
            "time": time,
            "grade": avgGrade
        }
        return stats;
    }

    getHotspotSuccess = async(data: any) => {
        //console.log(data);
        await this.setState({ criticalHotspots: data.data });
    }
    totalTime = (courses: any) => {
        var seconds = 0;
        var minutes = 0;
        var hours = 0;
        for (let i = 0; i < courses.length; i++) {
            var times = [0, 0, 0]
            var max = times.length

            var a = (courses[i].totalTime || '').split(':')

            // normalize time values
            for (var j = 0; j < max; j++) {
                a[j] = isNaN(parseInt(a[j])) ? 0 : parseInt(a[j]);
            }


            hours = hours + a[0];
            minutes = minutes + a[1];
            seconds = seconds + a[2];

            if (seconds >= 60) {
                var m = (seconds / 60) << 0
                minutes += m
                seconds -= 60 * m
            }

            if (minutes >= 60) {
                var h = (minutes / 60) << 0
                hours += h
                minutes -= 60 * h
            }



        }
        if (minutes == 0 && hours == 0 && seconds == 0) {
            return "00:00:00"
        }
        else {
            return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2)
        }
    }

    getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: (filterDropDown: filterDropDown) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={filterDropDown.selectedKeys[0]}
                    onChange={e => filterDropDown.setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(filterDropDown.selectedKeys, filterDropDown.confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Row>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(filterDropDown.selectedKeys, filterDropDown.confirm, dataIndex)}
                        icon="search"
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
          </Button>
                    <Button onClick={() => this.handleReset(filterDropDown.clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
          </Button>
                </Row>
            </div>
        ),
        filterIcon: (filtered: any) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: string, record: { [x: string]: { toString: () => string; }; }) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: (dataIndex: string | undefined) =>
            this.state.searchedColumn === dataIndex ? (
                dataIndex
            ) : dataIndex,
    } as any);

    handleSearch = (selectedKeys: any[], confirm: () => void, dataIndex: any) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = (clearFilters: () => void) => {
        clearFilters();
        this.setState({ searchText: '' });
    };


    render() {
        const columns = [
            {
                title: "Name",
                dataIndex: 'name_link',
                key: 'name_link',
                id: 'id',
                sorter: (a: any, b: any) => (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0),
                sortDirections: ['descend', 'ascend'],
                ...this.getColumnSearchProps('name'),
            },
            {
                title: "In Progress",
                dataIndex: 'started',
                key: 'started',
                sorter: (a: any, b: any) => (a.started < b.started) ? 1 : ((b.started < a.started) ? -1 : 0),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: "Completed",
                dataIndex: 'completed',
                key: 'completed',
                sorter: (a: any, b: any) => (a.completed < b.completed) ? 1 : ((b.completed < a.completed) ? -1 : 0),
                sortDirections: ['descend', 'ascend'],

            },
            {
                title: "Failed",
                dataIndex: 'failed',
                key: 'failed',
            },
            {
                title: "Grade",
                dataIndex: 'grade',
                key: 'grade',
                sorter: (a: any, b: any) => a.grade.localeCompare(b.grade),
                sortDirections: ['descend', 'ascend'],
            },
        ];
        return (
            <Row id="main" type="flex" justify="space-between" style={{ minHeight: "80vh" }}>
                <Col xs={24} sm={24} md={16} className="whiteBG" style={{ padding: "14px"}}>
                    <Row>
                       <h1 className="blackText bebas" style={{ fontSize: "1.4em" }}>GRADE MANAGEMENT</h1>
                    </Row>
                    <Row style={{ marginTop: "20px" }} type="flex" justify="space-between">
                    <Col  xs={24} sm={24} md={16} style={{backgroundColor: "#fff", border: "1px solid #ccc", padding: "5px"}}>
                        
                        <Spin spinning={this.state.loading}>
                            <Table columns={columns} pagination={false} scroll={{ y: 400 }} rowKey={(record: any) => record.id} dataSource={this.state.usersProgress} />
                        </Spin>
                    </Col>
                    <Col xs={24} sm={24} md={7}>
                    
                        <CompanySummary
                            teamMates={this.state.teamMates.length}
                            courses={this.state.courses.length}
                            stats= {this.getStats()}
                        />
                    </Col>
                    </Row>

                    <Row className="chartBox">
                        <GradeGraph
                            xAxis={this.state.courseName}
                            yAxis={this.state.courseGrade}
                            totalCourses={this.state.totalCourse}
                        />
                    </Row>
                 </Col>   
                
                <Col xs={24} sm={24} md={8} >

                    <TeamProfiles
                        bgColor={"whiteBG"}
                        titleColor={"orangeText"}
                        textColor={"blackText"}
                        available={this.state.teamMates}
                        cardHeight={"930px"}
                    />
                </Col>
            </Row>
        );
    }
}
function mapToStateToProps(state: any) {
    return {
        auth: state.auth,
        management: state.management,        
    };
}
export default connect(mapToStateToProps)(ManageGrades);