import React, { Component } from 'react';
import 'aframe';
import { Entity, Scene } from 'aframe-react';
import TextHotspot from '../HotSpotComponents/TextHotspot';
import ImageHotspot from '../HotSpotComponents/ImageHotspot';
import AudioHotspot from '../HotSpotComponents/AudioHotspot';
import VideoHotspot from '../HotSpotComponents/VideoHotspot';
import Video360Hotspot from '../HotSpotComponents/Video360Hotspot';
import LinkHotspot from '../HotSpotComponents/LinkHotspot';
import PaginationHotspot from '../HotSpotComponents/PaginationHotspot';
import CompleteTourHotspot from '../HotSpotComponents/CompleteTourHotspot';
import { getTourSequence, getTourPermission, getTourPermissionById, getUserTourProgress } from '../../api/APIget';
import { createTourProgress, updateTourProgress, completeTourProgress, restartTourProgress, updateTourProgressInfo } from '../../api/APIpost';
import { getCurrentDate, } from '../../utils/tools';
import { Modal, Spin, message, Button, Row, Col, Switch, Progress, Icon } from 'antd';
import { getheight, getWidth } from '../../utils/tools';
import arrow_to_left_1 from '../../assets/Icons/arrow_to_left.png';
import arrow_to_right_1 from '../../assets/Icons/arrow_to_right.png';
import gifLogo from '../../assets/Logos/VRS_GIF_logo_orange.gif';
import Forward from '../../assets/Icons/Forward.png';
import Gaze_Dark from '../../assets/Icons/gaze_light.png';
import Gaze_Light from '../../assets/Icons/gaze_dark.png';
import exit_icon_white from '../../assets/Icons/exit_icon_white.png';
import exit_icon_black from '../../assets/Icons/exit_icon_black.png';
import Branch_icon_white from '../../assets/Icons/Branch_icon_white.png';
import Branch_icon_black from '../../assets/Icons/Branch_icon_black.png';
import Text_icon_white from '../../assets/Icons/Text_Icon_White.png';
import Text_icon_black from '../../assets/Icons/Text_Icon_Black.png';
import Pagination_icon_white from '../../assets/Icons/Pagination_Icon_White.png';
import Pagination_icon_black from '../../assets/Icons/Pagination_Icon_Black.png';
import Image_icon_black from '../../assets/Icons/Image_Icon_Black.png';
import Image_icon_white from '../../assets/Icons/Image_Icon_White.png';
import Audio_icon_white from '../../assets/Icons/Audio_Icon_White.png';
import Audio_icon_black from '../../assets/Icons/Audio_Icon_Black.png';
import Video360_icon_black from '../../assets/Icons/360video_Icon_Black.png';
import Video360_icon_white from '../../assets/Icons/360video_Icon_White.png';
import Video_icon_black from '../../assets/Icons/Video_Icon_Black.png';
import Video_icon_white from '../../assets/Icons/Video_Icon_White.png';
import Link_icon_white from '../../assets/Icons/Link_Icon_White.png';
import Link_icon_black from '../../assets/Icons/Link_Icon_Black.png';
import { connect } from 'react-redux';
import { VisitedInfo } from '../../models/HotspotInterfaces';
import BranchingHotspot from '../HotSpotComponents/BranchingHotspot';
import CaptionHotspot from '../HotSpotComponents/CaptionHotspot';


const { confirm } = Modal;

interface State {
    tourInfo: any;
    panoBackground: any;
    panoId: number,
    hotSpotArray: any;
    loading: boolean;
    visible: boolean;
    panobackground: string;
    userPermission: boolean;
    progressObject: any;
    checkedProgress: boolean;
    start: boolean,
    timer: DOMHighResTimeStamp;
    visitedhotspots: VisitedInfo[];
    visitedCriticalPanosIds: string[];
    visitedPanoIds: string[];
    gaze: boolean;
    started: boolean;
    assetsLoaded: boolean;
    interactionsDisabled: boolean;
    progressPercent: number;
    position: any,
    rotation: any,
    selectedState: any
};

class WebViewer extends Component<any, State> {
    state: State = {
        tourInfo: {},
        panoBackground: '#pano0',
        panoId: 0,
        hotSpotArray: [],
        loading: true,
        visible: false,
        panobackground: '',
        userPermission: false,
        progressObject: {},
        checkedProgress: false,
        start: false,
        timer: 0,
        visitedhotspots: [],
        visitedCriticalPanosIds: [],
        visitedPanoIds: [],
        gaze: true,
        started: false,
        assetsLoaded: false,
        interactionsDisabled: true,
        progressPercent: 0,
        position: {},
        rotation: {},
        selectedState: "default"
    };

    public constructor(props: any) {
        super(props);
    }
    componentDidMount() {
        //console.log(this.props);

        getTourPermissionById(this.props.match.params.id, this.getSuccess, this.getError);
    }

    getSuccess = (data: any) => {
        //console.log(data.data);
        if (data.data.doesThisUserHaveAccess) {
            getTourSequence(data.data.id, this.getTourSuccess, this.getError);
        }
        else {
            Modal.error({
                title: 'Permission Denied',
                content: 'You do not have permission to view this tour. Make sure you are logged into your account',
                onOk: () => {
                    window.location.href = `/login`;
                }
            });
        }
    }
    getTourSuccess = async (data: any) => {
        //console.log(data);
        if (data.data.tour.sceneObjects.length > 0) {
            this.setState({ userPermission: true });
            //console.log(data.data.tour.sceneObjects[0].contentData.filter((hotspot: any) => hotspot.autoPlay === true));
            let cameraPosition = data.data.tour.sceneObjects[0].contentData.filter((hotspot: any) => { return (hotspot.type === "VirtualTourNetwork.CameraPositionData" && (hotspot.state === "default" || hotspot.state === null)) })
            //console.log(cameraPosition);
            if (cameraPosition.length > 0) {
                let position = {
                    x: cameraPosition[0].position.x,
                    y: cameraPosition[0].position.y,
                    z: cameraPosition[0].position.z
                }
                let rotation = {
                    x: cameraPosition[0].aframePosition.rotationx,
                    y: cameraPosition[0].aframePosition.rotationy,
                    z: cameraPosition[0].aframePosition.rotationz
                }
                //console.log(position);
                //console.log(rotation);
                this.setState({
                    position: position,
                    rotation: rotation,
                });
            }
            let hasAutoplay = this.checkAutoPlay(data.data.tour.sceneObjects[0].contentData.filter((data: any) =>
                data.state === "default" || data.state === null));

            this.setState(() => ({
                tourInfo: data.data.tour,
                panoBackground: data.data.tour.sceneObjects[0],
                interactionsDisabled: hasAutoplay,
                hotSpotArray: data.data.tour.sceneObjects[0].contentData.filter((data: any) => data.state === "default" || data.state === null),
                visitedPanoIds: [data.data.tour.sceneObjects[0].id], loading: false
            }));
            if (data.data.tour.states === null) {
                this.setState({
                    selectedState: null
                })
            }
            else {
                this.setState({
                    selectedState: "default"
                })
            }
            let assetloader: any = document.querySelector('a-assets');
            let scene: any = document.querySelector('a-scene');
            assetloader?.addEventListener("loaded", this.showStartButton);
            scene?.addEventListener("enter-vr", this.selectCamera(true));
            getUserTourProgress({ userId: this.props.auth.user.nameid, tourId: data.data.tour.id }, this.getProgressSuccess, this.getProgressError);
        }
        else {
            Modal.error({
                title: 'Error',
                content: 'There was an error getting this Tour',
                onOk: () => {
                    window.location.replace(`/home`);
                }
            });
        }
    }
    getProgressSuccess = async (data: any) => {
        //console.log(data.data)
        if (Object.keys(data.data).length === 0) {
            this.createTourProgress();
        }
        else {
            this.setState((state) => ({
                progressObject: data.data,
                visitedhotspots: data.data.visitedhotspots,
                visitedCriticalPanosIds: data.data.visitedCriticalPanosIds,
                visitedPanoIds: data.data.visitedPanoIds,
                start: true
            }));
            if (!this.state.checkedProgress) {
                this.checkProgress();
            }
            //console.log(data);
        }

    }

    checkAutoPlay(hotspotArray: any) {
        let hasAutoplay = false;
        let filteredArray = hotspotArray.filter((hotspot: any) => { return hotspot.autoPlay === true && (hotspot.type === "VirtualTourNetwork.VideoData" || hotspot.type === "VirtualTourNetwork.SphereVideoData") });
        if (filteredArray.length > 0)
            hasAutoplay = true;
        return hasAutoplay;
    }
    enableInteractions = () => {
        this.setState((state) => { return { interactionsDisabled: false } });
    }

    getProgressError = (data: any) => {
        console.log(data);
    }

    createTourProgress() {
        createTourProgress(this.state.tourInfo.id, this.postSuccess, this.postError);
    }

    getError = (data: any) => {
        console.log(data);
    }

    postSuccess = (data: any) => {
        this.setState((state) => { return { progressObject: data.data, start: true } });
    }

    postCompleteSuccess = (data: any) => {
        this.setState((state) => { return { progressObject: data.data, start: false, timer: 0 } });
    }

    postError = (data: any) => {
        console.log(data);
        message.error(data.data);
    }

    next = async (hotspot: any) => {
        //console.log("next pano " + hotspot);
        let index = this.state.tourInfo.sceneObjects.findIndex((data: any) => data.uid === hotspot.targetUID);
        this.updateProgress(hotspot);
        let hasAutoplay = this.checkAutoPlay(this.state.tourInfo.sceneObjects[index].contentData.filter((data: any) =>
            data.state === this.state.selectedState));
        let hotspots = this.state.tourInfo.sceneObjects[index].contentData.filter((data: any) =>
            data.state === this.state.selectedState);
        if (this.state.selectedState === "default") {
            hasAutoplay = this.checkAutoPlay(this.state.tourInfo.sceneObjects[index].contentData.filter((data: any) =>
                data.state === this.state.selectedState || data.state === null));
            hotspots = this.state.tourInfo.sceneObjects[index].contentData.filter((data: any) =>
                data.state === this.state.selectedState || data.state === null);
        }
        await this.setState((state) => {
            return {
                panoId: index,
                panobackground: `#pano${index}`,
                interactionsDisabled: hasAutoplay,
                hotSpotArray: hotspots
            }
        });

    }


    nextAnswer = async (pano: any, newState: string) => {
        console.log(pano);
        console.log(newState);
        let index = this.state.tourInfo.sceneObjects.findIndex((data: any) => data.uid === pano);
        console.log(this.state.tourInfo.sceneObjects[index].contentData.filter((data: any) =>
            data.state === newState
        ));
        let hasAutoplay = this.checkAutoPlay(this.state.tourInfo.sceneObjects[index].contentData.filter((data: any) =>
            data.state === newState));
        let hotspots = this.state.tourInfo.sceneObjects[index].contentData.filter((data: any) =>
            data.state === newState);
        if (newState === "default") {
            hotspots = this.state.tourInfo.sceneObjects[index].contentData.filter((data: any) =>
                data.state === newState || data.state === null);
            hasAutoplay = this.checkAutoPlay(this.state.tourInfo.sceneObjects[index].contentData.filter((data: any) =>
                data.state === newState || data.state === null));
        }
        await this.setState((state) => {
            return {
                panoId: index,
                panobackground: `#pano${index}`,
                interactionsDisabled: hasAutoplay,
                selectedState: newState,
                hotSpotArray: hotspots
            }
        });
    }

    viewedHotspot = (panoId: number, hotspotId: number) => {
        //console.log(panoId);
        if (this.state.visitedhotspots.length > 0) {
            let panoIndex = this.state.visitedhotspots.findIndex((pano) => pano.pano === panoId+1);
            //console.log(this.state.visitedhotspots);
            //console.log("panoIndex: " + panoIndex);
            if (panoIndex !== -1) {
                let oldVisitedHotspots: VisitedInfo[] = this.state.visitedhotspots;
                let visitedHotspotArray: VisitedInfo = oldVisitedHotspots[panoIndex];
                //console.log(visitedHotspotArray);
                let hotspotExists = visitedHotspotArray.hotspots.findIndex((hotspot) => hotspot === hotspotId);
                if (hotspotExists === -1) {
                    visitedHotspotArray.hotspots = [hotspotId, ...visitedHotspotArray.hotspots];
                    oldVisitedHotspots[panoIndex] = visitedHotspotArray;
                }
                this.setState((state) => ({ visitedhotspots: oldVisitedHotspots }));
            }
            else
            {
                let visitedHotspot: VisitedInfo = {
                    pano: panoId+1, hotspots: [hotspotId]
                }
                this.setState((state) => ({ visitedhotspots: [visitedHotspot, ...this.state.visitedhotspots] }));
            }
        }
        else
        {
            let visitedHotspot: VisitedInfo = {
                pano: panoId+1, hotspots: [hotspotId]
            }
            this.setState((state) => ({ visitedhotspots: [visitedHotspot] }));
        }
        //console.log(this.state.visitedhotspots);
    }

    updateProgress(hotspot: any) {
        //https://stackoverflow.com/questions/41632942/how-to-measure-time-elapsed-on-javascript
        let endTime = performance.now();
        let time = endTime - this.state.timer;
        let pano = this.state.tourInfo.sceneObjects[hotspot.parentUID];
        //console.log(pano);
        //console.log(this.state.visitedCriticalPanosIds);
        let VisistedPanos: any[] = this.state.visitedPanoIds;
        if (!this.state.visitedPanoIds.includes(pano.id)) {
            VisistedPanos = [pano.id, ...this.state.visitedPanoIds];
        }
        let criticalPanos = this.state.visitedCriticalPanosIds;
        if (pano.criticalPano && !this.state.visitedCriticalPanosIds.includes(pano.id)) {
            criticalPanos = [pano.id, ...criticalPanos];
        }
        this.setState((state) => ({
            timer: performance.now(),
            visitedPanoIds: VisistedPanos,
            visitedCriticalPanosIds: criticalPanos,
        }));
        time /= 1000;
        time = Math.round(time); //get seconds
        //console.log(time);
        let progressObject = {
            Id: this.state.progressObject.id,
            TourId: this.state.tourInfo.id,
            currentPano: hotspot.parentUID,
            visitedhotspots: this.state.visitedhotspots,
            visitedPanoIds: this.state.visitedPanoIds,
            visitedCriticalPanosIds: this.state.visitedCriticalPanosIds,
            timeSpent: time,
            submittedAt: getCurrentDate()
        };
        //console.log(progressObject);

        updateTourProgress(progressObject, this.postSuccess, this.postError);
    }
    postFinalSuccess = (data: any) => {
        //console.log(data);
        window.location.href = `/overview`;
    }

    leavePage = () => {
        let endTime = performance.now();
        let time = endTime - this.state.timer;
        this.setState((state) => ({ timer: performance.now() }));
        time /= 1000;
        time = Math.round(time); //get seconds
        //console.log(time);
        let progressObject = {
            Id: this.state.progressObject.id,
            TourId: this.state.tourInfo.id,
            currentPano: this.state.panoId,
            timeSpent: time,
            visitedhotspots: this.state.visitedhotspots,
            visitedPanoIds: this.state.visitedPanoIds,
            visitedCriticalPanosIds: this.state.visitedCriticalPanosIds,
            submittedAt: getCurrentDate()
        };
        //console.log(progressObject);

        updateTourProgress(progressObject, this.postFinalSuccess, this.postError);

    }

    restartTourProgress() {
        restartTourProgress(this.state.tourInfo.id, this.postSuccess, this.postError);
    }

    completeTour = async (panoId: number) => {
        console.error(panoId);
        this.setState({ visible: true });
        let endTime = performance.now();
        let time = endTime - this.state.timer;
        time /= 1000;
        time = Math.round(time); //get seconds
        //console.log(time);
        let pano = this.state.tourInfo.sceneObjects[panoId];
        //console.log(pano);
        let VisistedPanos: any[] = [];
        //console.log(!this.state.visitedPanoIds.includes(pano.id));
        if (!this.state.visitedPanoIds.includes(pano.id)) {
            VisistedPanos = [pano.id, ...this.state.visitedPanoIds];
        }

        let criticalPanos = this.state.visitedCriticalPanosIds;
        if (pano.criticalPano && !this.state.visitedCriticalPanosIds.includes(pano.id)) {
            criticalPanos = [pano.id, ...criticalPanos];
        }

        this.setState((state) => ({
            timer: performance.now(),
            visitedPanoIds: VisistedPanos,
            visitedCriticalPanosIds: criticalPanos,
        }));
        console.warn(this.state);
        let progressObject = {
            Id: this.state.progressObject.id,
            TourId: this.state.tourInfo.id,
            currentPano: this.state.tourInfo.sceneObjects.length,
            visitedhotspots: this.state.visitedhotspots,
            visitedPanoIds: this.state.visitedPanoIds,
            visitedCriticalPanosIds: this.state.visitedCriticalPanosIds,
            timeSpent: time,
            submittedAt: getCurrentDate()
        };
        //console.log(progressObject);
        completeTourProgress(progressObject, this.postCompleteSuccess, this.postError);
    }
    restartProgress = async () => {
        this.restartTourProgress();
        let hasAutoplay = this.checkAutoPlay(this.state.tourInfo.sceneObjects[0].contentData.filter((data: any) => data.state === "default" || data.state === null));
        await this.setState((state) => ({
            panoId: 0, panobackground: `#pano${0}`,
            interactionsDisabled: hasAutoplay,
            hotSpotArray: this.state.tourInfo.sceneObjects[0].contentData.filter((data: any) => data.state === "default" || data.state === null)
        }));
    }
    loaded = (e: any) => {
        this.setState({ panobackground: `#pano0` });
        if (typeof this.state.progressObject.currentPano !== 'undefined' && !this.state.checkedProgress) {
            this.checkProgress();
        }
        //console.log("loaded");

    }
    checkProgress() {
        if (this.state.progressObject.currentPano === this.state.tourInfo.sceneObjects.length) {
            confirm({
                title: 'Completed',
                content: 'You have already completed this course would you like to restart?',
                onOk: () => {
                    Modal.destroyAll();
                    this.restartProgress();

                },
                onCancel: () => {
                    //console.log(this.state.progressObject.currentPano);
                    Modal.destroyAll();
                    //console.log(this.state.tourInfo.sceneObjects[0].contentData);
                    let hasAutoplay = this.checkAutoPlay(this.state.tourInfo.sceneObjects[0].contentData);
                    this.setState({ panoId: 0, panobackground: `#pano${0}`, interactionsDisabled: hasAutoplay, hotSpotArray: this.state.tourInfo.sceneObjects[0].contentData, checkedProgress: true });
                }
            });
        }
        else if (this.state.progressObject.currentPano !== 0) {
            confirm({
                title: 'Resume',
                content: 'would you like to resume where you last left off?',
                onOk: () => {
                    Modal.destroyAll();
                    let hasAutoplay = this.checkAutoPlay(this.state.tourInfo.sceneObjects[this.state.progressObject.currentPano].contentData);
                    this.setState((state) => {
                        return {
                            panoId: this.state.progressObject.currentPano,
                            interactionsDisabled: hasAutoplay,
                            panobackground: `#pano${this.state.progressObject.currentPano}`,
                            hotSpotArray: this.state.tourInfo.sceneObjects[this.state.progressObject.currentPano].contentData,
                            checkedProgress: true,
                            start: true,
                            timer: performance.now()
                        }
                    });
                },
                onCancel: () => {
                    Modal.destroyAll();
                    this.setState((state) => {
                        return {
                            checkedProgress: true,
                            start: true,
                            timer: performance.now()
                        }
                    });
                }
            });
        }
    }
    showStartButton = (e: any) => {
        this.setState({ assetsLoaded: true });
    }
    updateGaze = (checked: boolean) => {
        //console.log("checked" + checked);
        this.setState({ gaze: checked });
    }
    startTour(start: boolean, gaze: boolean) {
        //console.log(start);
        this.setState((state) => { return { started: start, gaze: gaze } });
        this.selectCamera(gaze);

    }
    selectCamera = (checked: boolean) => {
        let MouseCamera: any = document.getElementById("MouseCamera");
        let GazeCamera: any = document.getElementById("GazeCamera");
        //console.log("checked:" + checked);
        if (checked) {
            MouseCamera?.setAttribute('active', 'false');
            MouseCamera?.setAttribute('raycaster', { enabled: false });
            MouseCamera?.setAttribute('visible', 'false');

            GazeCamera?.setAttribute('active', 'true');
            GazeCamera?.setAttribute('raycaster', { enabled: true });
            GazeCamera?.setAttribute('visible', 'true');
        }
        else {
            MouseCamera?.setAttribute('active', 'true');
            MouseCamera?.setAttribute('raycaster', { enabled: true });
            MouseCamera?.setAttribute('visible', 'false');

            GazeCamera?.setAttribute('active', 'false');
            GazeCamera?.setAttribute('raycaster', { enabled: false });
            GazeCamera?.setAttribute('visible', 'false');

        }

    }
    assetLoaded(e: any) {
        //console.log(e);
        //console.log("asset Loaded");
    }

    progress = () => {
        let progress = this.state.progressPercent + 10;
        this.setState({ progressPercent: progress })
        //console.log(progress);
        if (progress >= 100) {
            clearInterval()
        }
    }
    percentage = () => {
        let progress = this.state.progressPercent;
        let intervalID = setInterval(progressPer, 2000)

        function progressPer() {
            progress = progress + 20;
            //console.log(progress);
            var tag = document.getElementById('progressText');
            if (tag != null) {
                if (progress === 20 || progress === 80) {
                    tag.innerHTML = "Getting your images...";
                }
                if (progress === 40) {
                    tag.innerHTML = "Getting videos...";
                }
                if (progress === 100) {
                    tag.innerHTML = "Setting the environment...";
                }
                if (progress === 60) {
                    tag.innerHTML = "Preparing the camera...";
                }
            }

            if (progress >= 100) {
                clearInterval(intervalID);
            }
        }
    }
    errorLoadingResource = (id: string) => (e: any) => {
        var el = document.getElementById(id);
        var src = el?.getAttribute("src");
        if ((src?.match(/\?t\=/g) || []).length !== 5) {
            var timestamp = new Date().getTime();
            var queryString = "?t=" + timestamp;
            src = src + queryString;
            el?.setAttribute("src", src);
        }
    }
    render() {
        return (
            <div>
                {/* <Spin spinning={this.state.loading}> */}
                {
                    (!this.state.userPermission) ? <Spin spinning={this.state.loading}></Spin> :
                        <div>
                            <Row type="flex" justify="end">
                                <Col>
                                    <Button
                                        onClick={this.leavePage}
                                        style={{ marginBottom: "10px" }}
                                    >
                                        Continue Later
                                    </Button>
                                </Col>
                            </Row>
                            <div style={{ height: getheight(42), width: getWidth(), position: "absolute" }}>
                                <div className={(this.state.started) ? "hidden" : "backGroundLoader blackBG"}>
                                    {(this.state.assetsLoaded) ?
                                        <div className="centeredStartButton" >
                                            <Button style={{ margin: "10px" }} onClick={() => this.startTour(true, true)}>
                                                Start Tour With Gaze
                                            </Button>
                                            <Button style={{ margin: "10px" }} onClick={() => this.startTour(true, false)}>
                                                Start Tour With Mouse
                                            </Button>
                                        </div>
                                        :

                                        <div className="centeredStartButton">
                                            {this.percentage()}
                                            <div style={{ textAlign: "center" }}><img id={gifLogo} alt='' src={gifLogo} height="200" width="200" /></div>
                                            <div id="progressText" style={{ textAlign: "center" }} className="tealText">Preparing the tour...</div>
                                            <Progress style={{ margin: "30px 0", width: "400px" }} strokeColor={"#FA8C16"} showInfo={false} percent={80} status="active" />
                                            <div style={{ textAlign: "center" }} className="orangeText">Loading...</div>


                                        </div>
                                    }

                                </div>
                                <a-scene embedded >
                                    <a-assets id="asset" timeout="60000" >
                                    <img id={'arrow_to_left'} alt='' src={arrow_to_left_1} />
                                        <img id={'arrow_to_right'} alt='' src={arrow_to_right_1} />
                                        <img id={'forward'} alt='' src={Forward} />
                                        <img id={'Branch_Icon_White'} alt='' src={Branch_icon_white} />
                                        <img id={'Branch_Icon_Black'} alt='' src={Branch_icon_black} />
                                        <img id={'exit_white'} alt='' src={exit_icon_white} />
                                        <img id={'exit_black'} alt='' src={exit_icon_black} />
                                        <img id={'text_icon_white'} alt='' src={Text_icon_white} />
                                        <img id={'text_icon_black'} alt='' src={Text_icon_black} />
                                        <img id={'pagination_icon_white'} alt='' src={Pagination_icon_white} />
                                        <img id={'pagination_icon_black'} alt='' src={Pagination_icon_black} />
                                        <img id={'link_icon_white'} alt='' src={Link_icon_white} />
                                        <img id={'link_icon_black'} alt='' src={Link_icon_black} />
                                        <img id={'video360_icon_white'} alt='' src={Video360_icon_white} />
                                        <img id={'video360_icon_black'} alt='' src={Video360_icon_black} />
                                        <img id={'video_icon_white'} alt='' src={Video_icon_white} />
                                        <img id={'video_icon_black'} alt='' src={Video_icon_black} />
                                        <img id={'image_icon_white'} alt='' src={Image_icon_white} />
                                        <img id={'image_icon_black'} alt='' src={Image_icon_black} />
                                        <img id={'audio_icon_white'} alt='' src={Audio_icon_white} />
                                        <img id={'audio_icon_black'} alt='' src={Audio_icon_black} />
                                        {
                                            this.state.tourInfo.hasOwnProperty("sceneObjects") ?
                                                <React.Fragment>
                                                    {
                                                        this.state.tourInfo.sceneObjects.map((asset: any, index: number) =>
                                                        {
                                                            if (index === 0) {
                                                                return (
                                                                    <img key={index} id={`pano` + index} onError={this.errorLoadingResource(`pano` + index)} alt='' crossOrigin="anonymous" src={asset.mediaPath + "?dummy=viewer" + index} onLoad={this.loaded} />
                                                                )
                                                            }
                                                            else {
                                                                return <img key={index} id={`pano` + index} onError={this.errorLoadingResource(`pano` + index)} alt='' crossOrigin="anonymous" src={asset.mediaPath + "?dummy=viewer" + index} />
                                                            }

                                                        })
                                                    }
                                                    {
                                                        this.state.tourInfo.sceneObjects.map((sceneObjects: any, ind: number) => {
                                                            if (sceneObjects.contentData.length > 0) {
                                                                return sceneObjects.contentData.map((hotspot: any, index: number) => {
                                                                    switch (hotspot.type) {
                                                                        case "VirtualTourNetwork.VideoData":
                                                                            return <video key={"assets" + hotspot.id} onError={this.errorLoadingResource(ind + `video` + hotspot.id)} id={ind + `video` + hotspot.id} crossOrigin="anonymous" src={hotspot.videoPath!} autoPlay={false} preload="auto" loop={false}></video>;
                                                                        case "VirtualTourNetwork.AudioData":
                                                                            return <audio key={"assets" + hotspot.id} onError={this.errorLoadingResource(ind + `audioSrc` + hotspot.id)} id={ind + `audioSrc` + hotspot.id} crossOrigin="anonymous" src={hotspot.audioFile!} preload="auto" ></audio>;
                                                                        case "VirtualTourNetwork.ImageMessageData":
                                                                            return <img key={"assets" + hotspot.id} onError={this.errorLoadingResource(ind + `image` + hotspot.id)} id={ind + `image` + hotspot.id} alt='' crossOrigin="anonymous" src={hotspot.imagePath!} />;
                                                                        case "VirtualTourNetwork.SphereVideoData":
                                                                            return <video key={"assets" + hotspot.id} onError={this.errorLoadingResource(ind + `video` + hotspot.id)} id={ind + `video` + hotspot.id} crossOrigin="anonymous" src={hotspot.videoPath!} autoPlay={false} preload="auto" loop={false}></video>;
                                                                        case "VirtualTourNetwork.PageMessageData":
                                                                            return <React.Fragment key={"assets" + hotspot.id}>
                                                                                {
                                                                                    hotspot.messagePages.map((page: any, pos: number) =>
                                                                                        (page.type === "VirtualTourNetwork.ImagePageData" || page.type === "VirtualTourNetwork.BothPageData") ?
                                                                                            <img key={"assets" + hotspot.id + "_" + pos} onError={this.errorLoadingResource(ind + `image` + hotspot.id + pos)} id={ind + `image` + hotspot.id + pos} alt='' crossOrigin="anonymous" src={page.pageImagePath!} />
                                                                                            :
                                                                                            null
                                                                                    )
                                                                                }

                                                                            </React.Fragment>;
                                                                        case "VirtualTourNetwork.BranchingData":
                                                                            return <React.Fragment key={"assets" + hotspot.id}>
                                                                                {
                                                                                    hotspot.answers.map((page: any, pos: number) =>
                                                                                        (page.type === "VirtualTourNetwork.ImagePageData" || page.type === "VirtualTourNetwork.BothPageData") ?
                                                                                            <img key={"assets" + hotspot.id + "_" + pos} onError={this.errorLoadingResource(ind + `image` + hotspot.id + `ans` + pos)} id={ind + `image` + hotspot.id + `ans` + pos} alt='' crossOrigin="anonymous" src={page.imageAnswer!} />
                                                                                            :
                                                                                            null
                                                                                    )

                                                                                }
                                                                                {
                                                                                    (hotspot.questionImage !== null && hotspot.questionImage !== "") ?
                                                                                        <img key={"assets" + hotspot.id + "ques"} onError={this.errorLoadingResource(ind + `image` + hotspot.id + `ques`)} id={ind + `image` + hotspot.id + `ques`} alt='' crossOrigin="anonymous" src={hotspot.questionImage!} />
                                                                                        :
                                                                                        null
                                                                                }

                                                                            </React.Fragment>;
                                                                        default:
                                                                            return null;
                                                                    }
                                                                })

                                                            }
                                                            else {
                                                                return null;
                                                            }
                                                        })
                                                    }
                                                </React.Fragment>
                                                :
                                                null
                                        }
                                    </a-assets>
                                    {
                                        this.state.hotSpotArray.map((asset: any, index: number) => {
                                            switch (asset.type) {
                                                case "VirtualTourNetwork.VideoData":
                                                    return <VideoHotspot key={"tour" + index} interactionsDisabled={this.state.interactionsDisabled} enableInteractions={this.enableInteractions} panoid={this.state.panoId} data={asset} viewedHotspot={this.viewedHotspot} started={this.state.started} id={asset.id} />;
                                                case "VirtualTourNetwork.ImageMessageData":
                                                    return <ImageHotspot key={"tour" + index} interactionsDisabled={this.state.interactionsDisabled} panoid={this.state.panoId} id={asset.id} started={this.state.started} viewedHotspot={this.viewedHotspot} data={asset} />;
                                                case "VirtualTourNetwork.SphereVideoData":
                                                    return <Video360Hotspot key={"tour" + index} interactionsDisabled={this.state.interactionsDisabled} enableInteractions={this.enableInteractions} panoid={this.state.panoId} data={asset} viewedHotspot={this.viewedHotspot} started={this.state.started} id={asset.id} />
                                                case "VirtualTourNetwork.MessageData":
                                                    return <TextHotspot key={"tour" + index} interactionsDisabled={this.state.interactionsDisabled} panoid={this.state.panoId} id={asset.id} viewedHotspot={this.viewedHotspot} started={this.state.started} data={asset} />;
                                                case "VirtualTourNetwork.AudioData":
                                                    return <AudioHotspot key={"tour" + index} interactionsDisabled={this.state.interactionsDisabled} panoid={this.state.panoId} id={asset.id} viewedHotspot={this.viewedHotspot} started={this.state.started} data={asset} />;
                                                case "VirtualTourNetwork.HotspotLinkData":
                                                    return <LinkHotspot key={"tour" + index} interactionsDisabled={this.state.interactionsDisabled} id={asset.id} panoid={this.state.panoId} next={() => this.next(asset)} started={this.state.started} viewedHotspot={this.viewedHotspot} data={asset} />;
                                                case "VirtualTourNetwork.HotspotCompleteData":
                                                    return <CompleteTourHotspot key={"tour" + index} interactionsDisabled={this.state.interactionsDisabled} id={asset.id} panoid={this.state.panoId} completeTour={() => this.completeTour(this.state.panoId)} started={this.state.started} viewedHotspot={this.viewedHotspot} data={asset} />;
                                                case "VirtualTourNetwork.PageMessageData":
                                                    return <PaginationHotspot key={"tour" + index} interactionsDisabled={this.state.interactionsDisabled} panoid={this.state.panoId} data={asset} started={this.state.started} viewedHotspot={this.viewedHotspot} id={asset.id} />;
                                                case "VirtualTourNetwork.BranchingData":
                                                    return <BranchingHotspot key={"tour" + index} interactionsDisabled={this.state.interactionsDisabled} panoid={this.state.panoId} data={asset} started={this.state.started} viewedHotspot={this.viewedHotspot} id={asset.id} next={this.nextAnswer} />;
                                                case "VirtualTourNetwork.CaptionData":
                                                    return <CaptionHotspot key={"tour" + index} interactionsDisabled={this.state.interactionsDisabled} panoid={this.state.panoId} id={asset.id} viewedHotspot={this.viewedHotspot} started={this.state.started} data={asset} />;
                                                default:
                                                    return null;
                                            }
                                        }
                                        )
                                    }
                                    {/*<a-assets>
                                        <img id="dark-gaze" src={Gaze_Dark} />
                                    </a-assets>
                                    <a-assets>
                                        <img id="light-gaze" src={Gaze_Light} />
                                    </a-assets>*/}
                                    <Entity id="sky" primitive="a-sky" phi-length="360" phi-start="0" radius="200" src={this.state.panobackground} scale={{ x: -1, y: 1, z: 1 }} />
                                    {
                                        (this.state.panobackground === "#pano0" && this.state.rotation.x) ?
                                            <Entity id="gazeRig" rotation={this.state.rotation.x + " " + this.state.rotation.y + " " + this.state.rotation.z}>
                                                <Entity id="GazeCamera" primitive="a-camera" wasd-controls="false">
                                                    <Entity primitive="a-cursor"
                                                        visible={true}
                                                        //radiusInner= {"0.2"}
                                                        //radiusOuter= {"0.3"}
                                                        id="GazeCursor"
                                                        fuse={true}
                                                        fuseTimeout={"1000"}
                                                        scale={"1.3 1.3 1.3"}
                                                        material={"color:#000; shader: flat; vertexColors: vertex"}
                                                        raycaster={"far: 20; objects: .intersectable"}
                                                        animation__mouseenter={"property: material.color; type: color; to: #fff; startEvents: mouseenter; dur: 1500"}
                                                        animation__mouseleave={"property: material.color; type: color; to: #000; startEvents: mouseleave; dur: 500"}

                                                        //animation__fusing={{property: 'material.src', startEvents: 'fusing', easing: 'easeInOutBack', dur: 1500, from:'#dark-gaze' , to: '#light-gaze'}}
                                                        animation__click={{ property: 'scale', startEvents: 'click', from: '0.1 0.1 0.1', to: '1 1 1', dur: 150 }} />
                                                </Entity>
                                            </Entity>
                                            :
                                            <Entity id="gazeRig">
                                                <Entity id="GazeCamera" primitive="a-camera" wasd-controls="false">
                                                    <Entity primitive="a-cursor"
                                                        visible={true}
                                                        //radiusInner= {"0.2"}
                                                        //radiusOuter= {"0.3"}
                                                        id="GazeCursor"
                                                        fuse={true}
                                                        fuseTimeout={"1000"}
                                                        scale={"1.3 1.3 1.3"}
                                                        material={"color:#000; shader: flat; vertexColors: vertex"}
                                                        raycaster={"far: 20; objects: .intersectable"}
                                                        animation__mouseenter={"property: material.color; type: color; to: #fff; startEvents: mouseenter; dur: 1500"}
                                                        animation__mouseleave={"property: material.color; type: color; to: #000; startEvents: mouseleave; dur: 500"}

                                                        //animation__fusing={{property: 'material.src', startEvents: 'fusing', easing: 'easeInOutBack', dur: 1500, from:'#dark-gaze' , to: '#light-gaze'}}
                                                        animation__click={{ property: 'scale', startEvents: 'click', from: '0.1 0.1 0.1', to: '1 1 1', dur: 150 }} />
                                                </Entity>
                                            </Entity>
                                    }


                                    {
                                        (this.state.panobackground === "#pano0" && this.state.rotation.x) ?
                                            <Entity id="mouseRig" rotation={this.state.rotation.x + " " + this.state.rotation.y + " " + this.state.rotation.z}>
                                                <Entity id="MouseCamera" primitive="a-camera" cursor={"rayOrigin: mouse"} raycaster={"far: 20; objects: .intersectable"} wasd-controls="false" />
                                            </Entity>
                                            :
                                            <Entity id="mouseRig">
                                                <Entity id="MouseCamera" primitive="a-camera" cursor={"rayOrigin: mouse"} raycaster={"far: 20; objects: .intersectable"} wasd-controls="false" />
                                            </Entity>
                                    }
                                </a-scene>
                            </div>
                        </div>
                }

                {/* </Spin> */}
                <Modal
                    title="Tour has been Completed"
                    visible={this.state.visible}
                    okText="Return to Home"
                    onOk={() => { window.location.replace('/home') }}
                    cancelText="Restart the Course"
                    onCancel={() => {
                        this.setState({ visible: false });
                        //console.log("Restart the Course")
                        this.restartProgress();
                    }}
                >
                    <p>What would you like to do now?</p>
                </Modal>

            </div>
        );
    }
}
function mapToStateToProps(state: any) {
    return {
        auth: state.auth,
        management: state.management,
    };
}
export default connect(mapToStateToProps)(WebViewer);