import axios from 'axios';

export function createTour(postObject:object,callback:Function, errorcallback:Function)
{
    axios.post('/api/v1/vrsafety/Tour/createTour',postObject)
    .then(res => {
      //do something
      if(callback != null){
         callback(res);
      }
    })
    .catch(err => {
      // catch error
      if(errorcallback != null){
         errorcallback(err);
      }
    })
}

export function updateTour(postObject:object,callback:Function, errorcallback:Function)
{
    axios.patch('/api/v1/vrsafety/Tour/patchTour',postObject)
    .then(res => {
      //do something
      if(callback != null){
         callback(res);
      }
    })
    .catch(err => {
      // catch error
      if(errorcallback != null){
         errorcallback(err);
      }
    })
}

export function deleteTour(id:string,callback:Function, errorcallback:Function)
{
    axios.delete(`/api/v1/vrsafety/Tour/deleteTour/${id}`)
    .then(res => {
      //do something
      if(callback != null){
         callback(res);
      }
    })
    .catch(err => {
      // catch error
      if(errorcallback != null){
         errorcallback(err);
      }
    })
}

export function createTag(postObject:object,callback:Function, errorcallback:Function)
{
    axios.post('/api/v1/vrsafety/tag/create',postObject)
    .then(res => {
      //do something
      if(callback != null){
         callback(res);
      }
    })
    .catch(err => {
      // catch error
      if(errorcallback != null){
         errorcallback(err);
      }
    })
}

export function updateTag(postObject:object,callback:Function, errorcallback:Function)
{
    axios.patch('/api/v1/vrsafety/tag/update', postObject)
    .then(res => {
      //do something
      if(callback != null){
         callback(res);
      }
    })
    .catch(err => {
      // catch error
      if(errorcallback != null){
         errorcallback(err);
      }
    })
}

export function deleteTag(id:string,callback:Function, errorcallback:Function)
{
    axios.delete(`/api/v1/vrsafety/tag/delete/${id}`)
    .then(res => {
      //do something
      if(callback != null){
         callback(res);
      }
    })
    .catch(err => {
      // catch error
      if(errorcallback != null){
         errorcallback(err);
      }
    })
}

export function createPanoSequence(postObject:object,callback:Function, errorcallback:Function)
{
  axios.post('/api/v1/vrsafety/Tour/panoSequence',postObject)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function updatePanoImage(postObject:object,callback:Function, errorcallback:Function)
{
  axios.patch('/api/v1/vrsafety/Tour/swapImage',postObject)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function updatePublished(postObject:object,callback:Function,errorcallback:Function)
{
  axios.patch("/api/v1/vrsafety/Tour/status",postObject)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function updatePublishedArray(postObject:object,callback:Function,errorcallback:Function)
{
  axios.patch("/api/v1/vrsafety/Tour/all/status",postObject)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function deletePano(postObject:object,callback:Function, errorcallback:Function)
{
  axios.delete('/api/v1/vrsafety/Tour/deletePano',{data:postObject})
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function createCoordinates(postObject:object,callback:Function, errorcallback:Function)
{
  axios.post('/api/v1/vrsafety/hotspot/coordinates',postObject)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function updateCoordinates(postObject:object,callback:Function, errorcallback:Function)
{
  axios.patch('/api/v1/vrsafety/hotspot/coordinates',postObject)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function moveHotspotLocation(postObject:object,callback:Function, errorcallback:Function)
{
  axios.patch('/api/v1/vrsafety/hotspot/update-coordinates',postObject)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function createHotspots(postObject:object,callback:Function, errorcallback:Function)
{
  axios.post('/api/v1/vrsafety/hotspot/hotspot',postObject)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}



export function createNewState(postObject: object, callback: Function, errorcallback: Function) {
    axios.post('/api/v1/vrsafety/hotspot/addNewState', postObject)
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}

export function addDefaultState(tourId: string, callback: Function, errorcallback: Function) {
    axios.post('/api/v1/vrsafety/hotspot/addDefaultState', null, {
        params:
        {
            tourId: tourId
        }
    })
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}


export function deleteHotspot(postObject:object,callback:Function,errorcallback:Function)
{
  
  axios.delete('/api/v1/vrsafety/hotspot/hotspot', { data: postObject}
  )
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function deleteFile(filename:any,callback:Function,errorcallback:Function)
{
    axios.delete(`/api/v1/vrsafety/File/deleteFile/`, { data: filename })
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function createAccessTag(postObject:object,callback:Function,errorcallback:Function)
{
  axios.post('/api/v1/vrsafety/UserAccess/createUserAccessTag', postObject)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function removeAccessTag(postObject:object,callback:Function,errorcallback:Function)
{
  axios.delete('/api/v1/vrsafety/UserAccess/removeUserAccessTag', {data:postObject})
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function updateAccessLevel(postObject:object,callback:Function,errorcallback:Function)
{
  axios.patch('/api/v1/vrsafety/UserAccess/UpdateUserAccess', postObject)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function createAccessLevel(postObject:object,callback:Function,errorcallback:Function)
{
  axios.patch('/api/v1/vrsafety/UserAccess/createUserAccess', postObject)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

/*********Tour Progress Get**********/

export function createTourProgress(postObject: string, callback: Function, errorcallback: Function)
{
    axios.post('/api/v1/vrsafety/tourProgress/createTourProgress', null, {
        params:
        {
            tourId: postObject
        }
    })
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function updateTourProgress(postObject:object,callback:Function,errorcallback:Function)
{
  axios.patch('/api/v1/vrsafety/tourProgress/updateProgress', postObject)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function updateTourProgressInfo(postObject:object,callback:Function,errorcallback:Function)
{
  axios.patch('/api/v1/vrsafety/tourProgress/updateInformationProgress', postObject)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function restartTourProgress(postObject:string,callback:Function,errorcallback:Function)
{
    axios.post('/api/v1/vrsafety/tourProgress/restartProgress', null, {
        params:
        {
            tourId: postObject
        }
    })
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function completeTourProgress(postObject:object,callback:Function,errorcallback:Function)
{
  axios.patch('/api/v1/vrsafety/tourProgress/completeProgress', postObject)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}
/*****************Shared  Tour ******************/
export function createdSharedTourData(postObject:object,callback:Function,errorcallback:Function)
{
  axios.post('/api/v1/vrsafety/sharedtour/create', postObject)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function updateSharedTourData(postObject:object,callback:Function,errorcallback:Function)
{
  axios.patch('/api/v1/vrsafety/sharedtour/update', postObject)
  .then(res => {
    //do something
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function updateSharedTourVisits(Id:string, callback: Function, errorcallback: Function) {
    axios.patch(`/api/v1/vrsafety/sharedtour/updateVisits/${Id}`)
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}

export function updateSharedTourCompleted(Id: string, callback: Function, errorcallback: Function) {
    axios.patch(`/api/v1/vrsafety/sharedtour/updateCompleted/${Id}`)
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}



export function copyATour(tourId: string, tourName: string, callback: Function, errorcallback: Function) {
    axios.post(`/api/v1/vrsafety/file/copyATour/${tourId}/${tourName}`)
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}

export function createComponent(payload:any, callback: Function, errorcallback: Function) {
    axios.post(`/api/v1/vrsafety/component/createComponent`, payload)
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}

export function createComponentAccess(payload: any, callback: Function, errorcallback: Function) {
    axios.post(`/api/v1/vrsafety/component/createComponentAccess`, payload)
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}