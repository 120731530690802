import React, { Component } from 'react';
import { connect } from 'react-redux';
import { message, Row, Col, Modal, Spin, Table, Avatar, Button, Select, Input } from 'antd';
import { getAllTours } from '../../api/APIget';
import { copyATour } from '../../api/APIpost';
const { Option } = Select;

interface State {
    tours: any[],
    companies: any[],
    showModal: boolean,
    selectedTour: string,
    tourName: string
};

class CopyATour extends Component<any, State> {
    state: State = {
        tours: [],
        companies: [],
        showModal: false,
        selectedTour: "",
        tourName: ""
    };

    public constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        getAllTours(this.getToursSuccess, this.getError);
    }

    getToursSuccess = (data: any) => {
        console.log(data.data);
        this.setState({ tours: data.data});
    }

    getCompaniesSuccess = (data: any) => {
        console.log(data.data);
        this.setState({ companies: data.data })
    }


    getError = (data: any) => {
        console.log(data);
    }

    handleToggle = () => {
        let modal = this.state.showModal;
        this.setState({
            showModal: !modal
        });
    }

    selectOptionsTour() {

        let items: any[] = [];
        for (let i = 0; i < this.state.tours.length; i++) {
            items.push(
                <Option key={ i || 0}
                    value={this.state.tours[i].id}>
                    {
                        this.state.tours[i].tourName
                    }
                </Option>);
        }
        return items;
    }

    handleSelectChange = (input: any) => (e: any)=>{
        switch (input) {
            case "tour":
                this.setState({
                    selectedTour: e
                });
                break;
            case "tourName":
                this.setState({
                    tourName: e.target.value
                })
                break;
            default:
                break;
        }
    }

    handleChange = () => {
        console.log(this.state.selectedTour);
        console.log(this.state.tourName);
        copyATour(this.state.selectedTour, this.state.tourName, this.success, this.error);
        this.handleToggle();
    }

    success = (data: any) => {
        console.log(data);
        message.success("A tour was copied");
        this.props.updateCoursesList(data.data);
    }

    error = (data: any) => {
        console.log(data);
    }

    render() {

        return (
            <div>
                <Button onClick={() => this.handleToggle()} type="primary" >Copy A Tour</Button>
                <Modal
                    visible={this.state.showModal}
                    title="Manage Courses"
                    onOk={this.handleChange}
                    onCancel={this.handleToggle}
                >
                    <div>
                    <label>Select a Tour </label><br />
                    <Select
                        onChange={this.handleSelectChange("tour")}
                        value={this.state.selectedTour}
                        placeholder="Select a tour to copy"
                        style={{width: "250px"}}
                    >
                        {this.selectOptionsTour()}
                    </Select> 
                    </div>
                    <div>
                    <label>Tour Name </label>
                    <Input
                        value={this.state.tourName}
                        placeholder="Tour Name"
                        allowClear
                        onChange={this.handleSelectChange('tourName')}
                        style={{ marginBottom: "30px" }}
                    />
                    </div>
                </Modal>
            </div>

        );

    }
}
export default CopyATour;