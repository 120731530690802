import * as React from 'react';
import { Row, Col } from 'antd';
import { getGradeStyle } from '../../utils/tools';

export interface IAppProps {
    userStats: any,
}

export function TeammateStats(props: IAppProps) {
    return (
        <div style={{ border: "1px solid #4ECDC4", backgroundColor: "#fff", padding: "30px"}}>
            <Row type="flex">
                <Col className="progressStatsStyle">
                    <span className="smallText">Total In Progress</span>
                    <div style={{ textAlign: "center", alignContent: "baseline" }} > <h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{props.userStats.started}</h3></div>
                </Col>
                <Col className="progressStatsStyle">
                    <span className="smallText">Total Time Spent</span>
                    <div style={{ textAlign: "center", alignContent: "baseline" }} > <h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{props.userStats.totalTime}</h3></div>
                </Col>
                <Col className="progressStatsStyle">
                    <span className="smallText">Total Courses Completed</span>
                    <div style={{ textAlign: "center", alignContent: "baseline" }} > <h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{props.userStats.completed}</h3></div>
                </Col>
                <Col className="progressStatsStyle">
                    <span className="smallText">Total Courses Failed</span>
                    <div style={{ textAlign: "center", alignContent: "baseline" }} > <h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{props.userStats.failed}</h3></div>
                </Col>
                <Col>
                    <span className="smallText">Average Grade</span>
                    <Row type="flex" align="middle" justify="end">
                        <h3 style={{ fontWeight: "bold", textAlign:"center" }} className={getGradeStyle("B")}>{props.userStats.grade}</h3>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
