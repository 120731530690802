import React, { Component} from 'react';
import 'aframe';
import {Entity} from 'aframe-react';
import { changePaginationPos } from '../../utils/aframetools';
interface Props {
    data: any
    id:number
    panoid:number
    viewedHotspot:(panoId:number,hotspotId:number)=>void,
    interactionsDisabled:boolean;
    started:boolean
}
interface State
{
    lineHeight:number;
    textSize:string
}

class TextHotspot extends Component<Props, State> 
{
    state: State = {
        lineHeight:0.5,
        textSize:"12"
    }
    componentDidMount()
    {
        
        if (this.props.data?.message !== null)
            this.textLoaded()
    }
    componentDidUpdate(previousProps: any) {
        if (this.props.data !== previousProps.data && this.props.data?.message !==null) {

                this.textLoaded();
        }
    }
    componentWillUnmount()
    {
        let showButton = document.getElementById(this.props.panoid + "showButton" + this.props.id);
        showButton?.removeEventListener('click',this.show);
        showButton?.removeEventListener('mouseleave',this.hide);
    }
    setLineHeight = () =>
    {
        switch (this.props.data.textSize)
        {
            case "10":
                return  0.12;
            case "12":
                 return 0.25;
            case "14":
                return 0.33;
            default:
                return 0.25;
                
        }
    }
    textLoaded=()=> {
        let lineHeight = this.setLineHeight();
        let textSize = this.props.data.textSize;
        if (textSize === null || textSize === "") {
            textSize = "12";
        }
        else{
            this.setState({textSize:textSize})
        }
        console.log(textSize);
        let splitMessage=this.props.data.message.split(/\r\n|\r|\n/);
        let lines=this.props.data.message.split(/\r\n|\r|\n/).length;
        let textBGHotspot: any = document.getElementById(this.props.panoid + "textBackground" + this.props.id);
        //console.log(this.state.lineHeight);
        //console.log(this.props.data.message.length);
        let height = lineHeight * (this.props.data.message.length / parseInt(textSize, 10));
        if(lines>1){
            height=lines*lineHeight;
            console.log(height);
            for(let i=0;i<lines;i++){ // after many hours of calculations and inspection, this works, leave it like this if it doesn't give any kind of bugs
                if(splitMessage[i].length<40)
                    {
                        height+= lineHeight+lineHeight
                    }
                else
                    {
                        height+= (lineHeight*(splitMessage[i].length / parseInt(textSize, 10)));
                    }
            }
        }
        let totalWidth = this.props.data.message.length * (parseInt(textSize, 10) / 40)
        //console.log(totalWidth);
        
        //console.log(height);
        textBGHotspot.setAttribute("geometry", `height: ${height+0.9}`);
        textBGHotspot.setAttribute("geometry", `width: ${parseInt(textSize, 10)+1}`);
        let textBorder: any = document.getElementById(this.props.panoid + "textBorder" + this.props.id);
        textBorder.setAttribute("geometry", `height: ${height+1.1}`);
        textBorder.setAttribute("geometry", `width: ${parseInt(textSize, 10) + 1.2}`);
        let textHotspot = document.getElementById(this.props.panoid + "textHotspot" + this.props.id);
        textHotspot?.setAttribute('visible', 'false');
        let closingBox:any = document.getElementById(this.props.panoid + "closingBox" + this.props.id);
        closingBox.setAttribute("geometry", `height: ${height + 2.5}`);
        closingBox.setAttribute("geometry", `width: ${parseInt(textSize, 10) + 2.5}`);
        closingBox?.setAttribute('visible', 'false');
    }
    show =() =>
    {
        //console.log(!this.props.interactionsDisabled && this.props.started)
        //console.log(this.props.started);
        //console.log(this.props.interactionsDisabled);
        console.log("clicked");
        if(!this.props.interactionsDisabled && this.props.started)
        {
            console.log("clicked");
            let showButton = document.getElementById(this.props.panoid + "showButton" + this.props.id);
            showButton?.setAttribute('visible','false');
            let textHotspot = document.getElementById(this.props.panoid + "textHotspot" + this.props.id);
            textHotspot?.setAttribute('visible','true');
            this.props.viewedHotspot(this.props.data.parentUID, this.props.data.id);
            let closingBox: any = document.getElementById(this.props.panoid + "closingBox" + this.props.id);
            closingBox?.addEventListener("mouseleave", this.hide);
            closingBox?.setAttribute('visible', 'true');
            closingBox?.classList.add("intersectable");
        }
    }
    hide = () =>
    {
        let showButton = document.getElementById(this.props.panoid + "showButton" + this.props.id);
        showButton?.setAttribute('visible','true');
        let textHotspot = document.getElementById(this.props.panoid + "textHotspot" + this.props.id);
        textHotspot?.setAttribute('visible','false');
        console.log(textHotspot?.getAttribute('visible'));
        let closingBox = document.getElementById(this.props.panoid + "closingBox" + this.props.id);
        closingBox?.setAttribute('visible', 'false');
        closingBox?.classList.remove("intersectable");
    }

    render(){
        return(
            <React.Fragment>


            <Entity
                id={this.props.panoid + "showButton" + this.props.id}
                position={`${this.props.data.position.x} ${this.props.data.position.y} ${this.props.data.position.z}`} 
                rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
                className="intersectable" 
                raycaster="objects: [data-raycastable]"
                animation__hover={{property: 'scale', startEvents: 'mouseenter', from: '1 1 1', to: '3 3 1', dur: 2000}}
                animation__fade={{property: 'material.opacity', startEvents: 'mouseenter', from: '1', to: '0.0', dur: 1500}}
                animation__rescale={{property: 'scale', startEvents: 'mouseleave', from: '2 2 2', to: '1 1 1', dur: 500}}
                animation__refade={{property: 'material.opacity', startEvents: 'mouseleave', from: '0.25', to: '1', dur: 500}}
            >
            <Entity
                geometry={{width:2,height:2}}
                raycaster="objects: [data-raycastable]"
                primitive="a-image"
                src={(this.props.data.iconColor==="black")?"#text_icon_black":"#text_icon_white"}
                className="intersectable"
                id={this.props.panoid + "hideButton" + this.props.id}
                events={{click:this.show}}
                />
            </Entity>

            {/* <Entity
              id={this.props.panoid + "showButton" + this.props.id}
              position={`${this.props.data.position.x} ${this.props.data.position.y} ${this.props.data.position.z}`} 
              rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
              // cursor={{fuse: true}}
              className="intersectable" 
              raycaster="objects: [data-raycastable]"
            >
            
            <Entity primitive='a-image' 
              src={(this.props.data.IconColor==="black")?"":"#text_icon_white"}
              raycaster="objects: [data-raycastable]"
            />
            <Entity primitive='a-circle' 
              color="green"
              position="0 0 -0.1"
              className="intersectable" 
              id={this.props.panoid + "hideButton" + this.props.id}
              raycaster="objects: [data-raycastable]"
              events={{click:this.show,mouseleave:this.hide}}
              animation__hover={{property: 'scale', startEvents: 'mouseenter', from: '1 1 1', to: '3 3 1', dur: 2000}}
              animation__fade={{property: 'material.opacity', startEvents: 'mouseenter', from: '1', to: '0.0', dur: 1500}}
              animation__rescale={{property: 'scale', startEvents: 'mouseleave', from: '2 2 2', to: '1 1 1', dur: 500}}
              animation__refade={{property: 'material.opacity', startEvents: 'mouseleave', from: '0.25', to: '1', dur: 500}}
            />
                </Entity> */}
                <Entity
                    //visible={false}
                    id={this.props.panoid + "textHotspot" + this.props.id} 
                    position={changePaginationPos(this.props.data.position)}
                    rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
                >   
                <a-text 
                    
                    id={this.props.panoid + "textArea" + this.props.id} 
                    text={`value: ${this.props.data.message}; zOffset:1; width: ${parseInt(this.state.textSize,10)};`}
                    font='french-msdf.json' negate="false"
                    scale="1 1 0.1"
                    //geometry="primitive:plane;height:0;width:0;"
                    //material="color:black"
                    position={`${parseInt(this.state.textSize,10)/2*-1} 0 0`}
                    align={(this.props.data.textAlign!==null||this.props.data.textAlign!=='')?this.props.data.textAlign:"left"}
                    anchor="left"
                    //rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
                    ></a-text>

                    {/* <a-entity
                    geometry="primitive: plane; height: auto; width: auto"
                    material="color: black"
                    text={`value: ${this.props.data.message}; zOffset:1; width: ${parseInt(this.state.textSize,10)};`}></a-entity> */}
                <Entity geometry={{ primitive: 'plane' }} material={{ color: "black", side: "double" }}
                    id={this.props.panoid + "textBackground" + this.props.id}
                    position={ "0 0 -0.1"}
                    //rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
                
                    />
                <Entity geometry={{ primitive: 'plane' }} material={{ color: "#eee", side: "double" }}
                    id={this.props.panoid + "textBorder" + this.props.id}
                    position={"0 0 -0.15"}
                //rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}

                />
                </Entity>
                <Entity geometry={{ primitive: 'plane'}} material={{ color: "blue", opacity: "0.0", side: "double" }}
                    id={this.props.panoid + "closingBox" + this.props.id}
                    position={changePaginationPos(this.props.data.position)}
                    rotation={`${this.props.data.aframePosition.rotationx} ${this.props.data.aframePosition.rotationy} ${this.props.data.aframePosition.rotationz}`}
                    visible={false}
                />

            </React.Fragment>
        )
    }
}
export default TextHotspot;