import { CHANGE_MENU } from './types';

export function setMenuLayout(menu: string, company: string,
  role: string){
  return {
    type: CHANGE_MENU,
    menu,
    company,
    role
  };
}

export function userMenu(){
  return async (dispatch: any) => {
    localStorage
      .setItem('menu', JSON.stringify({
        menu: 'DefaultUser',
        company: '',
        role: 'User'
      }, null, 2));
    dispatch(setMenuLayout('DefaultUser', '', 'User'));
  }
}

export function emptyMenu(){
  return async (dispatch: any) => {
    localStorage.removeItem('menu');
    dispatch(setMenuLayout('', '', ''));
  }
}

export function changeMenu(menuName: string, companyId:string, companyName: string,
  menuRole: string) {
  return async (dispatch: any) => {
    let menu = JSON.stringify({
        menu: menuName,
        companyId: companyId,
        company: companyName,
        role: menuRole
      }, null, 2);
    localStorage.setItem('menu', menu);
    dispatch(setMenuLayout(menuName, companyName, menuRole));
  };
}