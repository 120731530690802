import React, { Component } from 'react';
import { Select, Input, Button, Checkbox, Spin, Row, Col, Avatar } from 'antd';
import { getCompanyTag, getTourInformation, getTourIcons } from '../../../api/APIget';
import { createTour, updateTour } from '../../../api/APIpost';
import { isNullOrUndefined } from 'util';
import { getCurrentDate } from '../../../utils/tools';
import { connect } from 'react-redux';
import tour_icon from '../../../assets/Icons/VRS_asset_360globe_icon.png'
import { AWS_URL } from '../../../app/FrontEndVariables';

const { TextArea } = Input;

interface State {
    loading: boolean;
    tourName: string;
    tourDescription: string;
    tourInformation: any;
    tags: any[];
    selectedTags: any[];
    filteredOptions: any[];
    selectedTagNames: string[];
    progressType: boolean;
    saving: boolean;
    selectedIcon: string;
    iconOptions: any[];
    showGrid: boolean;
}
class CreateTour extends Component<any, State> {
    state: State = {
        loading: true,
        tourName: '',
        tourDescription: '',
        tourInformation: {},
        tags: [],
        selectedTags: [],
        filteredOptions: [],
        selectedTagNames: [],
        saving: false,
        progressType: false,
        selectedIcon: "",
        iconOptions: [],
        showGrid: false
    };
    public constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        // let companyId = '1';
        getCompanyTag(
            this.props.auth.company.id,
            this.getsuccess,
            this.getError
        );
        //getCompanyTag(companyId,this.getsuccess,this.getError);
        // console.log(isNullOrUndefined(this.props.match.params.id));
        if (!isNullOrUndefined(this.props.match.params.id)) {
            getTourInformation(
                this.props.match.params.id,
                this.getInfoSuccess,
                this.getError
            );
        }
        getTourIcons(this.props.match.params.id, this.getIconsSuccess, this.getError);
    }
    componentWillUnmount() {}

    getsuccess = (data: any) => {
        // console.log(data);
        this.setState({
            tags: data.data,
            filteredOptions: data.data,
            loading: false,
        })
        
    };

    getInfoSuccess = (data: any) => {
        console.log(data);
        let tags: any = [];
        let selectedTags: any[] = [];
        let filteredOptions: any[] = [];
        if (data.data.tags.length > 0) {
            selectedTags = data.data.tags;
            console.log(this.state.tags);
            filteredOptions = this.state.tags.filter(
                (elem: any) => !selectedTags.find(({ id }) => elem.id === id)
            );
            console.log(filteredOptions);
            data.data.tags.forEach((tag: any) => {
                tags.push(`${tag.name} (${tag.accessLevel})`);
            });
        } else {
            filteredOptions = this.state.filteredOptions;
        }
      
        this.setState({
            tourInformation: data.data,
            tourDescription: data.data.tourDescription,
            tourName: data.data.tourName,
            selectedTagNames: tags,
            selectedTags: selectedTags,
            filteredOptions: filteredOptions,
            saving: false,
            progressType: data.data.courseProgressionType
        })
        if (data.data.tourIcon !== null && data.data.tourIcon !== "") {
            this.setState({
                selectedIcon: data.data.tourIcon
            })
        }
        
    };

    getIconsSuccess = (data: any) => {
        console.log(data);
        this.setState({
            iconOptions: data.data
        });
    }

    getError(data: any) {
        console.log(data);
    }

    handleChange = (input: any) => (e: any) => {
        this.setState({ [input]: e.target.value } as any);
    };

    onChange = (e: any) => {
        this.setState({ progressType: e.target.checked } as any);
        // console.log(`checked = ${e.target.checked}`);
        // console.log(this.state);
    };

    tagChange = (e: any) => {
        // console.log(e);
        let currentTagNames = this.state.selectedTagNames;
        let currentTags = this.state.selectedTags;
        let notselected: any[] = [];
        if (e.length > this.state.selectedTagNames.length) {
            this.state.tags.forEach((tag, index) => {
                if (tag.id === e[e.length - 1]) {
                    currentTagNames.push(`${tag.name} (${tag.accessLevel})`);
                    currentTags.push(tag);
                }
            });
        } else {
            // console.log("removed a tag");
            currentTagNames = e;
            currentTags = this.state.selectedTags.filter((selectedTag: any) => {
                return e.some((e: any) => {
                    return (
                        e.substring(0, e.indexOf(' ')) === selectedTag.name &&
                        e.substring(e.indexOf('(') + 1, e.indexOf(')')) ===
                            selectedTag.accessLevel
                    );
                });
            });
        }
        notselected = this.state.tags.filter(
            (elem: any) => !currentTags.find(({ id }) => elem.id === id)
        );
        this.setState({
            filteredOptions: notselected,
            selectedTags: currentTags,
            selectedTagNames: currentTagNames,
        });
        // console.log(notselected);
        // console.log(currentTags);
    };

    onSubmit = async (e: any) => {
        e.preventDefault();
        console.log('submit');
        console.log(this.state);
        if (isNullOrUndefined(this.props.match.params.id)) {
            let tour = this.createTourObject();
            console.log(tour);
            createTour(tour, this.success, this.error);
        } else {
            let tour = this.updateTourObject();
            console.log(tour);
            updateTour(tour, this.success, this.error);
        }
    };

    iconChange = (item:string) => {
        this.setState({
            selectedIcon: item,
            showGrid: !this.state.showGrid
        });
        console.log(item);
    }

    showGrid = () => {
        console.log(this.state.showGrid);
        this.setState({
            showGrid: !this.state.showGrid
        });
    }

    createTourObject() {
        return {
            "TourName": this.state.tourName,
            "TourDescription": this.state.tourDescription,
            "Tags": this.state.selectedTags,
            "CourseProgressionType": this.state.progressType,
            "TourIcon": this.state.selectedIcon
        };
    }

    updateTourObject() {
        return {
            Id: `${this.state.tourInformation.id}`,
            TourId: `${this.state.tourInformation.tourId}`,
            CompanyId: `${this.state.tourInformation.companyId}`,
            TourName: `${this.state.tourName}`,
            TourDescription: `${this.state.tourDescription}`,
            Tags: this.state.selectedTags,
            CourseProgressionType: this.state.progressType,
            LastUpdateTime: getCurrentDate(),
            TourIcon: this.state.selectedIcon
        };
    }

    success = (data: any) => {
        console.log(`success!`);
        console.log(data);
        let id = data.data.id;
        console.error(id);
        this.props.history.push(`/tour/1/${id}`);
        //window.location.replace(`/tour/1/${id}`);
    };

    error(error: any) {
        console.log(`error!`);
        console.log(error);
    }

    render() {
        return (
            <Row style={{ paddingTop: '25px' }}>
                <Col xs={24} sm={24} md={6}>
                </Col>
                <Col xs={24} sm={24} md={12}>
                <Spin spinning={this.state.loading}>
                    <form onSubmit={this.onSubmit}>
                        <Input
                            value={this.state.tourName}
                            placeholder="Tour Name"
                            allowClear
                            onChange={this.handleChange('tourName')}
                            style={{marginBottom: "30px"}}
                        />
                        <TextArea
                            value={this.state.tourDescription}
                            placeholder="Tour Description"
                            rows={10}
                            allowClear
                            onChange={this.handleChange('tourDescription')}
                            style={{ marginBottom: "20px" }}
                        />
                        <Select
                            mode="tags"
                            placeholder="Tags"
                            value={this.state.selectedTagNames}
                            onChange={this.tagChange}
                            style={{ width: '100%', marginBottom: "20px" }}
                        >
                            {this.state.filteredOptions.map((item: any) => (
                                <Select.Option key={item.id} value={item.id}>
                                    {`${item.name} (${item.accessLevel})`}
                                </Select.Option>
                            ))}
                        </Select>
                        <div style={{marginBottom: "10px", cursor: "pointer"}} onClick={this.showGrid} title="Choose a thumbnail">
                        {
                        (this.state.selectedIcon==="")
                        ?
                        <Avatar size={60} shape="square" src={tour_icon}/>
                        :
                        <Avatar size={60} shape="square" src={AWS_URL+ this.state.selectedIcon}/>
                        }
                        </div>
                        {
                        (this.state.showGrid)?
                        <Row id="iconGrids" className="imgGrid">
                            <h3>Select a thumbnail</h3>
                            {this.state.iconOptions.map((item: any) => (
                                <Col style={{ marginBottom: "5px" }} span={8} key={item} onClick={() => this.iconChange(item)}>
                                    <Avatar size={60} shape="square" src={AWS_URL + item} />
                                </Col>
                            ))}
                            <Col span={8} onClick={() => this.iconChange("")}>
                                <Avatar size={60} shape="square" src={tour_icon} />
                            </Col>
                        </Row>
                        :
                        null
                        }
                        <Checkbox 
                            onChange={this.onChange}
                            checked={this.state.progressType}
                            className={this.props.auth.layout.primaryColor}>
                            Is this tour progress based?
                        </Checkbox>
                        <Row style={{ marginTop: "20px" }}>
                        <Col xs={24} sm={24} md={12} >
                        <Button
                            type="primary"
                            href={`/manage/`}
                            icon= "home"
                        >
                        Home
                        </Button>
                        </Col>
                        <Col xs={24} sm={24} md={12} >
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={this.state.saving}
                            icon="edit"
                            style={{float: "right"}}
                        >
                            {isNullOrUndefined(this.props.match.params.id)
                                ? 'Create'
                                : 'Update'}
                        </Button>
                        </Col>
                        </Row>
                    </form>
                </Spin>
                </Col>
                <Col xs={24} sm={24} md={6}>
                </Col>
            </Row>
        );
    }
}
function mapToStateToProps(state: any) {
    return {
        auth: state.auth,
        management: state.management,
        
    };
}
export default connect(mapToStateToProps)(CreateTour);
