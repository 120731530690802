import { Management } from '../../models/Management';
import { changeManaged } from '../../actions/management-actions/management-actions';
import { setCompanyHeader } from '../setAuthToken';

export function SetManagementFromLocalStorage(store: any) {
    try
    {
        let localStorageToken = localStorage.management ? localStorage.management : '';
        let management: Management = JSON.parse(localStorageToken);
        if (management) {
            setCompanyHeader();
            store.dispatch(
                changeManaged(management.menuSettings)
            );
        }
    }
    catch(e)
    {
        console.log(e);
        localStorage.clear();
    }
}
