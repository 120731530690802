import React, { Component } from 'react';
import { Button,Avatar,Row,Col, Icon, Modal, Card } from 'antd';
import ManageAccess from '../ManageAccess/ManageAccess';

interface State {
    loading: boolean,
    key: string,
    accessModal: boolean,
    teamMate: any,
};


class AllTeamMates extends Component<any, State> {
    state: State = {
    teamMate: {},
    loading:false,
    key: '1',
    accessModal: false,
    }
    public constructor(props:any){
        super(props);
    }
    componentDidMount(){
        this.setState({
            teamMate: this.props.teamMate.user.id
        }); 
    }
    accessModalToggle = () => {
        this.setState({
            accessModal: !this.state.accessModal
        });
        console.log(this.props.teamMate.user.id);
    }
        
      render() {
          return (
            <div>
            <div className="teamMateCard">
                      <Row style={{marginBottom: "20px"}}>
                         <Card
                              
                              size="small"
                              cover={
                                  <Row type="flex" justify="space-between" style={{ boxSizing: "border-box", paddingLeft: "30px"}}>
                                  <Col span={5} style={{ marginTop: "20px"}}>
                                      <Avatar shape="circle" size={45} src={this.props.teamMate.user.avatarImage} />
                                  </Col>
                                  <Col span={17} id="CourseInfo" style={{ marginTop: "20px" }}>
                                      <h3 style={{ marginBottom: "0", fontWeight: 600 }}>{`${this.props.teamMate.user.firstName} ${this.props.teamMate.user.lastName}`}</h3>
                                      <h4 style={{ marginBottom: "0" }} className="greyText">{`${this.props.teamMate.user.userInformation.jobPosition}`}</h4>
                                  </Col>
                                  </Row>
                              }
                              actions={[
                                  <span className="teamCardButtons" onClick={this.accessModalToggle}><Icon type="tool" style={{ marginRight: "10px" }}/>Access</span>,
                                  <span className="teamCardButtons" onClick={() => { window.location.href = `/teammate-grades/${this.props.teamMate.user.id}` }}><Icon type="star" style={{ marginRight: "10px" }}/>Progress</span>,
                                  
                              ]}
                          >
                          </Card>


                </Row>

            </div>
            <Modal
                title="Manage Access"
                visible={this.state.accessModal}
                onOk={() => this.accessModalToggle()}
                onCancel={() => this.accessModalToggle()}>
                <ManageAccess userId={this.props.teamMate.user.id}/>
            </Modal>
            </div>
        );
    }
}
export default AllTeamMates;