import React, { Component } from 'react';
import { Layout, Icon, Menu, message, Typography,Avatar, Drawer } from 'antd';
import { HeadProps } from '../modules';
import UserProfile from '../../components/UserProfile/UserProfile';


const { Header } = Layout;
const { Text } = Typography;

interface State {
    manageCourseModal:boolean,
    manageTagsModal:boolean,
    manageCourses:any[],
    manageCoursesUpdate:any[],
    tagList:any[],
    loading:boolean,
    searchText: string,
    searchedColumn: string,
    tagName: string,
    selectedAccess:string,
    accessLevel:any[],
    updateTag: any,
    showProfile: boolean
};

const UserInfoLayout = (props: any) => {
    return (
        <div id="Profile"
            style={{
                display: 'flex',
                alignItems: 'center',
                float: 'right',
            }}
        >
            {props.auth.user.avatar !== '' ? (
                <Avatar
                    style={{ marginTop: 'auto' }}
                    size="large"
                    src={props.auth.user.avatar}
                ></Avatar>
            ) : (
                <Avatar
                    style={{ marginTop: 'auto' }}
                    size="large"
                    icon="user"
                ></Avatar>
            )}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: 1,
                    padding: '0 10px',
                }}
            >
                <Text strong={true}  className={props.fontColor}>
                    {props.auth.user.display_name}
                </Text>
                <Text className={props.fontColor}>
                    {console.log(props)}
                    { `${props.auth.company.name}`}
                </Text>
            </div>
        </div>
    );
};

class Head extends Component<HeadProps, {}> { 
    state:State=
    {
        manageCourseModal:false,
        manageCourses:[],
        manageCoursesUpdate:[],
        manageTagsModal:false,
        tagList:[],
        loading:true,
        searchText: '',
        searchedColumn: '',
        tagName: "",
        selectedAccess:"",
        accessLevel:[{id:0,name:"Public"},{id:1,name:"Internal"},{id:2,name:"Private"}],
        updateTag:{},
        showProfile:false
    };
    constructor(props: any) {
        super(props);
       
        this.handleMenuClick = this.handleMenuClick.bind(this);
    }


    handleMenuClick(e: any) {
        switch (e.key) {
            case 'profile':
                window.location.href ="/profile";
                break;
            case 'gradeDetails':
                window.location.assign("/grades");
                break;
            case 'progress':
                window.location.assign("/progress");
                break;
            case 'AdminHome':
                window.location.assign("/home");
                break;
            case 'ManagerHome':
                window.location.assign("/manage");
                break;
            case 'VRBuilder':
                window.location.assign("/tour/0/");
                break;
            default:
                message.error('This should not happen.');
                break;
        }
    }

    logout=async()=>{
        //console.log("clicked");
        await this.props.logout();
        window.location.href = '/login';
    }
    handleChange = (input: any) => (e: any) => 
    {
        this.setState({ [input]: e.target.value } as any);
    }

    onSelectChange = (e:any)=> {
        this.setState({ selectedAccess: e } as any);
    }

    direct=(location:string)=>
    {
        window.location.href=location;
    }

    onToggle = () => {
        this.setState({ showProfile: !this.state.showProfile });
    }
    

    render() {
       
        const permissions=["Create","Update","Delete"]
        const menu = (
            <Menu onClick={this.handleMenuClick} style={{ border: "1px solid #4ECDC4", marginTop: "20px" }} >
                <Menu.Item key="profile" className="darkBlueText"><Icon type="user" className="darkBlueText"/>My Profile Settings</Menu.Item>
                <Menu.Item key="gradeDetails" className="darkBlueText"><Icon type="profile" className="darkBlueText"/>My Grade Details</Menu.Item>
                <Menu.Item key="progress" className="darkBlueText"><Icon type="star" className="orangeText" />My Current Progress</Menu.Item>
            </Menu>
        );
        const managerMenu = (
            <Menu onClick={this.handleMenuClick} style={{ border: "1px solid #FFC069", marginTop: "10px" }}>
                <Menu.Item key="ManagerHome" className="blackText"><Icon type="home" className="blackText" />Manager Home</Menu.Item>
                <Menu.Item key="AdminHome" className="blackText"><Icon type="crown" className="blackText" />Admin Home</Menu.Item>
            </Menu>
        );
        return (
            <React.Fragment>
                {
                    (this.props.auth.isAuthenticated && (this.props.auth.user.family_name !=="content" &&this.props.auth.user.given_name !=="shared")) ?
                    <React.Fragment>
                    {
                    
                    this.props.auth.permissions.some((item:any) =>permissions.includes(item))
                    ?
                    <Header
                        className={`blackBG ${this.props.auth.layout.managerColor}`}
                        style={{
                            paddingLeft: 15,
                        }}
                    >

                        <div  style={{
                                alignItems: 'center',
                                float: 'right',
                                paddingTop: '12px',
                                paddingBottom: '12px',
                                margin: "0 30px",
                                cursor: "pointer"
                                }}
                                onClick={() => this.onToggle()}
                                        >
                           <UserInfoLayout {...this.props} fontColor={this.props.auth.layout.managerColor}></UserInfoLayout>
                        </div>
                    </Header>
                    :
                    <Header 
                        className="blackBG tealText" 
                        style={{
                            paddingLeft: 15,
                            height: 'auto',
                        }}
                    >

                        <div  style={{
                                alignItems: 'center',
                                float: 'right',
                                paddingTop: '12px',
                                paddingBottom: '12px',
                                margin: "0 30px",
                                cursor: "pointer"
                            }}
                            onClick={() => this.onToggle()}>
                            <UserInfoLayout {...this.props} fontColor={this.props.auth.layout.userColor}></UserInfoLayout>
                        </div>
                       
                        
                    </Header>
                    }
                    </React.Fragment>
                    :
                    null
                    // <Header className="blackBG orangeText" 
                    //     style={{
                    //         paddingLeft: 15,
                    //     }}
                    // >

                    //     <button  className="StrippedButton" title="Return Home" onClick={()=>this.logout()}>
                    //         <img alt="VR Safety"
                    //         style={{ marginRight: 15, fontSize: '20px',
                    //         height: 40, margin: "12px", padding:10,
                    //         }}
                    //         src={logo}
                    //     />
                    //     </button>
                    // </Header>
                }
                <Drawer
                    title="User Profile"
                    placement="right"
                    closable={true}
                    onClose={this.onToggle}
                    visible={this.state.showProfile}
                    getContainer={false}
                    width="600"
                    className={this.props.auth.layout.styleClass}
                //key={placement}
                >

                    <UserProfile />

                </Drawer>
            </React.Fragment>
        );
    }
}

export default Head;
