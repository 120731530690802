import * as React from 'react';
import { Row, Col } from 'antd';

export interface IAppProps {
    completed: number,
    inprogress: number,
    visits:number
}

export function SharedStats(props: IAppProps) {
    return (
        <div>
            <Row type="flex" style={{ border: "1px solid #ccc", padding: "30px 20px", backgroundColor: "#fff" }}>
                <Col className="progressStatsStyle">
                    <h3 className="darkBlueText">Shared Tour Stats</h3>
                </Col>
                <Col className="progressStatsStyle">
                    <span className="smallText">Total Visits</span>
                    <div style={{ textAlign: "center", alignContent: "baseline" }} > <h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{props.visits||0}</h3></div>
                </Col>
                <Col className="progressStatsStyle">
                    <span className="smallText">Total Times Unfinished</span>
                    <div style={{ textAlign: "center", alignContent: "baseline", fontWeight: "bold" }} > <h3 style={{ fontWeight: "bold" }} className="tealText">{props.inprogress||0}</h3></div>
                </Col>
                <Col>
                    <span className="smallText">Total Times Finished</span>
                    <div style={{ textAlign: "center", alignContent: "baseline" }} > <h3 style={{ fontWeight: "bold" }} className="OrangeText">{props.completed||0}</h3></div>
                </Col>
            </Row>
        </div>
    );
}
