import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { store } from './store/configureStore';
import jwtDecode from 'jwt-decode';
import { setAuthToken } from './utils/setAuthToken';
import { setCurrentUser, setPermissions } from './actions/authActions';
import { setMenuLayout } from './actions/menuActions';
import { SetManagementFromLocalStorage } from './utils/OnRefresh/managementOnRefresh';

const history = require('history').createBrowserHistory();
const beginingOfTime = new Date().getTime() / 1000;

if (localStorage.token) {
    let jwtoken: any = jwtDecode(localStorage.token);
    if (beginingOfTime > jwtoken.exp) {
        localStorage.removeItem('token');
        localStorage.clear();
        setAuthToken(false);
        store.dispatch(setCurrentUser({}));
    } else {
        setAuthToken(localStorage.token);
        store.dispatch(setCurrentUser(jwtDecode(localStorage.token)));
    }
}
if (localStorage.menu) {
    let menu = JSON.parse(localStorage.menu);
    store.dispatch(setMenuLayout(menu.menu, menu.company, menu.role));
}

SetManagementFromLocalStorage(store);

let companyPermissionsCheck: any = localStorage.getItem(
    'user-company-permissions'
);

console.warn(
    companyPermissionsCheck !== null && companyPermissionsCheck !== undefined
);
if (companyPermissionsCheck !== null && companyPermissionsCheck !== undefined) {
    try {
        let userCompanyPermissionToken = JSON.parse(companyPermissionsCheck!);
        store.dispatch(setPermissions(userCompanyPermissionToken));
    } catch (error) {
        console.error(error);
    }
}

const rootElement = document.getElementById('root');

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <App />
        </Router>
    </Provider>,
    rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
