import * as React from 'react';
import { Row, Col } from 'antd';
import { getGradeStyle } from '../../../utils/tools';

export interface IAppProps {
    completed: number,
    inprogress:number,
    time: string,
    grade: string,
    failed: number
}

export function CourseStats(props: IAppProps) {
    return (
        <div>
            <Row type="flex" style={{border: "1px solid #ccc", padding: "30px 20px", backgroundColor: "#fff"}}>
                <Col className="progressStatsStyle">
                    <span className="smallText">Teammates Invited</span>
                    <div style={{ textAlign: "center", alignContent: "baseline" }} > <h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{30}</h3></div>
                </Col>
                <Col className="progressStatsStyle">
                    <span className="smallText">Total In Progress</span>
                    <div style={{ textAlign: "center", alignContent: "baseline", fontWeight: "bold" }} > <h3 style={{ fontWeight: "bold" }} className="tealText">{props.inprogress}</h3></div>
                </Col>
                <Col className="progressStatsStyle">
                    <span className="smallText">Average Duration</span>
                    <div style={{ textAlign: "center", alignContent: "baseline" }} > <h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{props.time}</h3></div>
                </Col>
                <Col className="progressStatsStyle">
                    <span className="smallText">Completed</span>
                    <div style={{ textAlign: "end", alignContent: "baseline" }} > <h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{props.completed}</h3></div>
                </Col>
                <Col className="progressStatsStyle">
                    <span className="smallText">Failed</span>
                    <div style={{ textAlign: "end", alignContent: "baseline" }} > <h3 style={{ fontWeight: "bold" }} className="mediumBlueText">{props.failed}</h3></div>
                </Col>
                <Col style={{ padding: "0 2%", margin: "auto"}}>
                    <span className="smallText">Average Grade</span>
                    <Row type="flex" align="middle" justify="end">
                        <h3 style={{ fontWeight: "bold" }} className={getGradeStyle("B+")}>{ props.grade }</h3>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
