import React from 'react';
import {} from "antd";
import Chart from "react-apexcharts";
import { getGradeFromPoints } from '../../utils/tools';

export interface IAppProps {
    yAxis: any[],
    xAxis: any[],
    h1Color: string
}

export function GradeChart (props: IAppProps) {
  const  options = {
    chart: {
      type: 'line'
    },
    xaxis: {
        categories: props.xAxis,
        labels: {
            hideOverlappingLabels: true,
            trim: true,
            rotate: -45,
            rotateAlways: true,
        }
    },
    yaxis: {
            min: 0,
            max:5,
            floating: false,
            tickAmount: 5, // number of intervals to show
            labels: {
            formatter: function (value: number) {
                    console.log(value);
                    return getGradeFromPoints(value);
                }
            }
    }
  }
  const  series = [{
      name: 'Grades',
      data: props.yAxis,
    }];
  return (
    <div style={{border: "1px solid #ccc", margin: "14px", marginTop:"30px" , padding: "14px"}}>
      <h1 className={props.h1Color}>GRADE STATISTICS</h1>
      <Chart
       options={options}
       series={series}
       width="100%"
       height="300px"
       />
    </div>
  );
}
