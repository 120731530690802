import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Radio, Row, Col, message, Cascader } from 'antd';
import { getUserProgress } from '../../api/APIget';
import { CourseInProgress } from './CourseInProgress';
import { CourseCompleted } from './CourseCompleted';
import { getCurrentDate } from '../../utils/tools';
import { restartTourProgress } from '../../api/APIpost';

const options = [
    { label: 'All', value: 'All' },
    { label: 'Completed', value: 'Completed' },
    { label: 'In Progress', value: 'InProgress' },
];
const sortOptions = [
    { label: 'Newest To Oldest', value: 'NewToOld' },
    { label: 'Oldest To Newest', value: 'OldToNew' },
];
interface State {
    value:string,
    inProgressCourses:any[],
    coursesCurrentlyInProgress:any[],
    coursesCompleted: any[],
    allCompleteCourses:any[],
    allProgressCourses:any[],
    showInProgress: boolean,
    showCompleted: boolean,
}
class UserProgress extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    this.state = {
        value:'All',
        inProgressCourses:[],
        coursesCurrentlyInProgress:[],
        coursesCompleted: [],
        showInProgress: true,
        showCompleted: true,
        allCompleteCourses: [],
        allProgressCourses: [],
    }
  }
  componentDidMount(){
    console.log(this.props.auth.user.nameid);
      getUserProgress(this.props.auth.user.nameid, this.getSuccess, this.getError);
  }
  getSuccess=(data:any)=>
  {
    console.log(data.data);
    let currentProgress = data.data.filter((data:any)=> { return (!data.tourProgress.completed)});
    let completedProgress = data.data.filter((data:any)=> { return (data.tourProgress.completed)});
      for (let i = 0; i < data.data.length; i++) {
          console.log(data.data[i].grade);
      }
      this.setState((state) => ({
          inProgressCourses: data.data,
          coursesCurrentlyInProgress: currentProgress,
          coursesCompleted: completedProgress,
          allProgressCourses: currentProgress,
          allCompleteCourses: completedProgress
      }));
    console.log(currentProgress);
    this.setState({ //don't remove this. It is loading the grades properly
        showCompleted: true,
        showInProgress: true
    });
  }
  getError=(data:any)=>
  {
    console.log(data);
    message.error("There was a problem getting your courses");
  }


    onChange = (e: any) => {
        console.log(e);

        this.setState({
            value: e[0],
        });

        if (e[0] === 'InProgress') {
            this.setState({
                showCompleted: false,
                showInProgress: true
            });
        }
        else if (e[0] === 'Completed') {
            this.setState({
                showCompleted: true,
                showInProgress: false
            });
        }
        else if (e[0] === 'All') {
            this.setState({
                showCompleted: true,
                showInProgress: true
            });
        }
        { this.ProgressComponents() }
        { this.CompleteComponents() }
    }

    ProgressComponents = () => {
        let components;
        if (this.state.showInProgress) {
            components = <div id="inProgress">
                <CourseInProgress
                    inProgress={this.state.coursesCurrentlyInProgress}
                    restart={this.restart}
                    company={this.props.auth.company.name}
                    onSearch={this.onSearchProgress}
                    layout={this.props.auth.layout}
                />
            </div>
        }
        else {
            components = '';
        }
        return components;
    }

    CompleteComponents = () => {
        let components;
        if (this.state.showCompleted) {
            components = <div id="completed">
                <CourseCompleted
                    completed={this.state.coursesCompleted}
                    restart={this.restart}
                    company={this.props.auth.company.name}
                    onSearch={this.onSearch}
                    layout={this.props.auth.layout}
                /> 
            </div>
        }
        else {
            components = '';
        }
        return components;
    }

    onSort = (value: any) => {
      let completeCourses = this.state.allCompleteCourses;
      let progressCourses = this.state.allProgressCourses; 
      let progtours;
      let comptours;
      console.log(value[0]);
      switch (value[0]) {
          case "NewToOld":
              progtours = progressCourses.sort((a: any, b: any) => (a.tourProgress.startedAt < b.tourProgress.startedAt) ? 1 : ((b.tourProgress.startedAt < a.tourProgress.startedAt) ? -1 : 0));
              comptours = completeCourses.sort((a: any, b: any) => (a.tourProgress.completedAt < b.tourProgress.completedAt) ? 1 : ((b.tourProgress.completedAt < a.tourProgress.completedAt) ? -1 : 0));
              this.setState({
                  coursesCurrentlyInProgress: progtours,
                  coursesCompleted: comptours

              });
              break;
          case "OldToNew":
              progtours = progressCourses.sort((a: any, b: any) => (a.tourProgress.startedAt > b.tourProgress.startedAt) ? 1 : ((b.tourProgress.startedAt > a.tourProgress.startedAt) ? -1 : 0));
              comptours = completeCourses.sort((a: any, b: any) => (a.tourProgress.completedAt > b.tourProgress.completedAt) ? 1 : ((b.tourProgress.completedAt > a.tourProgress.completedAt) ? -1 : 0));
              this.setState({
                  coursesCurrentlyInProgress: progtours,
                  coursesCompleted: comptours

              });
              break;
          default:
              console.log("this should not happen");
              break;
      }

  };


  restart=(tourProgress:any)=>{
    // let updateTourProgressObject={
    //   Id:tourProgress.id,
    //   UserId:tourProgress.userId,
    //   TourId:tourProgress.tourId,
    //   CompanyId:tourProgress.companyId,
    //   currentPano:0,
    //   totalPano:tourProgress.totalPano,
    //   submittedAt:getCurrentDate()
    // }
    restartTourProgress(tourProgress.tourId,this.restartSuccess,this.postError);
  }
  restartSuccess=(data:any)=>
  {
    window.location.assign(`/viewer/${data.data.tourId}`);
  }
  postError=(data:any)=>
  {
    console.log(data);
    message.error("There was a problem restarting the tour");
  }

    onSearch = (value: any) => {
        console.log(value);
        let newList = this.state.allCompleteCourses.filter(item => {
            const lc = item.tourInfo.tourName.toLowerCase();
            const filter = value.toLowerCase();
            return lc.includes(filter);
        });
        console.log(newList);
        this.setState({
            coursesCompleted: newList
        });
    }

    onSearchProgress = (value: any) => {
        console.log(value);
        let newList = this.state.allProgressCourses.filter(item => {
            const lc = item.tourInfo.tourName.toLowerCase();
            const filter = value.toLowerCase();
            return lc.includes(filter);
        });
        console.log(newList);
        this.setState({
            coursesCurrentlyInProgress: newList
        });
    }

  public render() {
    return (
        <React.Fragment>
        <main>
                <Row style={{ marginBottom: "5px", padding: "20px" }} className={this.props.auth.layout.bgColor}>
                <Col xs={24} sm={24} md={12}></Col>
                <Col xs={24} sm={24} md={5}>
                    <span className={this.props.auth.layout.filterColor} style={{ display: "inline-block", marginRight: "10px" }}>Filter By :</span>
                    <Cascader
                        style={{ marginRight: "10px", marginLeft: "5px", textAlign: "left", width: "150px" }}
                        options={options}
                        value={[this.state.value]}
                        onChange={this.onChange} />
                </Col>
                <Col xs={24} sm={24} md={7} style={{ textAlign: "right" }}><span className={this.props.auth.layout.filterColor} style={{ display: "inline-block", marginRight: "10px" }}>Sort Courses By: </span><Cascader style={{ marginRight: "10px", textAlign: "left" }} options={sortOptions} onChange={this.onSort} /></Col>
            </Row>
          
        {this.ProgressComponents()}
        {this.CompleteComponents()}    
           
        </main>
        </React.Fragment>
    );
  }
}
function mapToStateToProps(state: any) {
    return {
        auth: state.auth,
        management: state.management,
        
    };
  }
export default connect(mapToStateToProps)(UserProgress);