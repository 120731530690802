import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Button, Spin, List, Avatar, Collapse, message } from 'antd';
import {getComponentAccessFull} from '../../api/APIget';
import { createComponentAccess } from '../../api/APIpost';
import { getCurrentDate } from '../../utils/tools';
interface State {
    components: any[];
    layouts: [];
    loading: boolean,
};
const { Panel } = Collapse;
var layouts:any = [];
class ManageComponentAccess extends Component<any, State> {
    state: State = {
        components: [],
        layouts: [],
        loading: true,
    }
    public constructor(props: any) {
        super(props);
    }
    componentDidMount() {
        getComponentAccessFull(this.successComponentAccess, this.error);
    }

    successComponentAccess = (data: any) => {
        console.log(data.data);
        this.setState({ components:data.data, loading: false });
    }

    toggleAccess = (component: any) => {
        this.setState({loading:true})
        let obj = {
            Id: component.id,
                CompanyId: this.props.auth.company.id,
                TimeUpdated: getCurrentDate,
                Component: component.component,
                TimeCreated: component.timeCreated,
                Enabled: !component.enabled
            }
        createComponentAccess(obj, this.revokeSuccess, this.error);
    }

    revokeSuccess = (data:any) => {
        console.log(data);
        getComponentAccessFull(this.successComponentAccess, this.error);
        message.success("Access changed");
    }

    error = (data: any) => {
        console.log(data);
        this.setState({loading:false})
    }

    render() {
        return (
            <div id="main">
                <h1 className="bebas">Add/Edit page permissions</h1>
                <Row style={{ marginBottom: "" }}>
                    <Spin spinning={this.state.loading}>
                        <List
                            dataSource={this.state.components}
                            renderItem={(component: any) => (
                                <List.Item
                                    actions={[
                                        (component.enabled) ?
                                            <Button
                                                ghost
                                                type="danger"
                                                onClick={() => this.toggleAccess(component)
                                                }
                                            >
                                                Revoke Access
                                            </Button>
                                            :

                                            <Button
                                                ghost
                                                type="primary"
                                                onClick={() => this.toggleAccess(component)
                                                }
                                            >
                                                Grant Access
                                            </Button>
                                    ]}
                                >
                                    <List.Item.Meta
                                        title={
                                            `${component.component}`
                                        }

                                    />
                                    </List.Item>
                                )}
                                />
                    </Spin>
                </Row>
            </div>
        );
    }
}
function mapToStateToProps(state: any) {
    return {
        auth: state.auth,
        management: state.management,

    };
}
export default connect(mapToStateToProps)(ManageComponentAccess);