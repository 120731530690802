import axios from 'axios';

const JANUS_ADDRESS = "https://janus.bsdev.ca";
export function getConnectedUsers(payload:any, callback:Function, errorcallback:Function){
    axios.get(JANUS_ADDRESS+'/api/v1/user-company/user-connections',{ params: { company: payload } })
    .then(res => {
      //do something
      if(callback != null){
         callback(res);
      }
    })
    .catch(err => {
      // catch error
      if(errorcallback != null){
         errorcallback(err);
      }
    })
  }
export function getCompanyInformation(companyId:string,callback:Function,errorcallback:Function)
{
  //http://localhost:6729/api/v1/entity/company/66b0ed75-3a9d-420f-86b3-680db88d4831
    axios.get(JANUS_ADDRESS +`/api/v1/entity/company/${companyId}`)
  .then(res => {
    //do something
    if(callback != null){
        callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
        errorcallback(err);
    }
  })
}

export function getCompanies(callback: Function, errorcallback: Function) {
    //http://localhost:6729/api/v1/entity/company/66b0ed75-3a9d-420f-86b3-680db88d4831
    axios.get(JANUS_ADDRESS +`/api/v1/entity/companies`)
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}

export function deserializeGLT(glt:string,callback: Function, errorcallback: Function) {
    axios.get(JANUS_ADDRESS +`/api/v1/external/glt/${glt}`)
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}



export function getProfile(callback: Function, errorcallback: Function) {
    axios.get(JANUS_ADDRESS + '/api/v1/account/profile')
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}

export function getLayoutClasses(layout:string, callback: Function, errorcallback: Function) {
    axios.get(JANUS_ADDRESS + '/api/v1/auth/layoutClasses/'+layout)
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}