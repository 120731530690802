import React, { Component } from 'react';
import { connect } from 'react-redux';
import { message, Col, Row, Cascader } from 'antd';
import AvailableCourses from './AvailableCoures';
import InProgressCourses from './InProgressCourses';
import { getUserProgress, getCompanyToursViewer } from '../../api/APIget';
import { restartTourProgress } from '../../api/APIpost';
import { getCurrentDate } from '../../utils/tools';

interface State {
    loading: boolean,
    inProgressCourses:any[],
    coursesCurrentlyInProgress:any[],
    coursesCompleted:any[],
    newCourses: any[],
    value: string,
    tours: any[],
    newTours: any[],
    completedTours: any[],
    showInProgress: boolean,
    showCompleted: boolean,
    showAvailable: boolean,
    tour: any,
    allNewCourses: any[],
    allProgressCourses: any[]
};

const options = [
    { label: 'Newest To Oldest', value: 'NewToOld' },
    { label: 'Oldest To Newest', value: 'OldToNew' },
];

const filters = [
    { label: 'All', value: 'All' },
    { label: 'New Courses', value: 'New' },
    { label: 'In Progress', value: 'InProgress' }
];

class LandingPage extends Component<any, State> {
    state:State={
    loading:false,
    inProgressCourses:[],
    coursesCurrentlyInProgress:[],
    coursesCompleted:[],
    newCourses: [],
    value: 'All',
    tours: [],
    newTours: [],
    completedTours:[],
    showInProgress: true,
    showAvailable: true,
    showCompleted: true,
    allNewCourses: [],
    allProgressCourses: [],
    tour: null
    }
    public constructor(props:any){
        super(props);
      }
      componentDidMount(){
        //console.log(this.props.auth.user.nameid);
        getUserProgress(this.props.auth.user.nameid,this.getSuccess,this.getError);
       
      }
      getSuccess=(data:any)=>
      {
        //console.log(data.data);
        let currentProgress = data.data.filter((data:any)=> { return !data.tourProgress.completed});
        let currentCompleted = data.data.filter((data:any)=> { return data.tourProgress.completed});
        this.setState((state)=>({inProgressCourses:data.data,coursesCurrentlyInProgress:currentProgress,allProgressCourses: currentProgress}));
        getCompanyToursViewer(this.props.auth.company.id,this.getToursSuccess,this.getError);
      }
      getToursSuccess=(data:any)=>
      {
        //console.log(data.data);
        let toursNotInProgress = data.data.filter((elem: any) => !this.state.inProgressCourses.find(({ tourInfo }) => elem.id === tourInfo.id));
        
          //console.log(toursNotInProgress);
          this.setState((state) => ({ newCourses: toursNotInProgress, allNewCourses: toursNotInProgress }));
      }
      getError=(data:any)=>
      {
        //console.log(data);
        message.error("There was a problem getting your courses");
      }
    restart = (tour: any) => {
        
        this.setState({ tour: tour });
          restartTourProgress(tour.tourInfo.tourId, this.restartSuccess, this.postError);
      }
      restartSuccess=(data:any)=>
      {
          window.location.assign(`/viewer/${this.state.tour.tourInfo.id}`);
      }
      postError=(data:any)=>
      {
        console.log(data);
        message.error("There was a problem restarting the tour");
    }
    onFilter = (e: any) => {
        //console.log(e.target.value);
        
        this.setState({
            value: e[0],
        });

        if (e[0] === 'InProgress') {
            this.setState({
                showAvailable: false,
                showInProgress: true
            });
        }
        else if (e[0] === 'New') {
            this.setState({
                showAvailable: true,
                showInProgress: false
            });
        }
        else if (e[0] === 'All') {
            this.setState({
                showAvailable: true,
                showInProgress: true
            });
        }
        { this.ProgressComponents() }
        { this.AvailableComponents() }
    }
    onSearch = (value: any) => {
        console.log(value);
        let newList = this.state.allNewCourses.filter(item => {
            const lc = item.tourName.toLowerCase();
            const filter = value.toLowerCase();
            return lc.includes(filter);
        });
        console.log(newList);
        this.setState({
            newCourses: newList
        });
    }

    onSearchClear = () => {
        this.setState({
            newCourses: this.state.allNewCourses
        });
    }

    onSearchProgress = (value: any) => {
        console.log(value);
        let newList = this.state.allProgressCourses.filter(item => {
            const lc = item.tourInfo.tourName.toLowerCase();
            const filter = value.toLowerCase();
            return lc.includes(filter);
        });
        console.log(newList);
        this.setState({
            coursesCurrentlyInProgress: newList
        });
    }

    onSearchProgressClear = () => {
        this.setState({
            coursesCurrentlyInProgress: this.state.allProgressCourses
        });
    }

        ProgressComponents = () => {
        let components;
        if (this.state.showInProgress) {
            components = <div id="inProgress">
                <InProgressCourses
                    inProgress={this.state.coursesCurrentlyInProgress}
                    restart={this.restart}
                    company={this.props.auth.company.name}
                    onSearch={this.onSearchProgress}
                    layout={this.props.auth.layout}
                    clearSearch={this.onSearchProgressClear}
                />
            </div>
        }
        else {
            components = '';
        }
        return components;
    }

    AvailableComponents = () => {
        let components;

        if (this.state.showAvailable) {
            components = <div id="newCourses">
                <AvailableCourses
                    available={this.state.newCourses}
                    company={this.props.auth.company.name}
                    onSearch={this.onSearch}
                    layout={this.props.auth.layout}
                    clearSearch={this.onSearchClear}
                />
            </div>
        }
        else {
            components = '';
        }
        return components;
    }



    onChangeAvailable = (value: any) => {
        let sortedCourses;
        if (value == 'NewToOld') {
            
            sortedCourses = this.state.allNewCourses;
            let newtours = sortedCourses.sort((a: any, b: any) => (a.publishedDate < b.publishedDate) ? 1 : ((b.publishedDate < a.publishedDate) ? -1 : 0));
            //console.log(sortedCourses);
            sortedCourses = this.state.allProgressCourses;
            let progtours = sortedCourses.sort((a: any, b: any) => (a.tourProgress.startedAt < b.tourProgress.startedAt) ? 1 : ((b.tourProgress.startedAt < a.tourProgress.startedAt) ? -1 : 0));
            this.setState({
                newTours: newtours,
                tours: progtours
            });
        }
        else {
            sortedCourses = this.state.newCourses;
            let newtours = sortedCourses.sort((a: any, b: any) => (a.publishedDate > b.publishedDate) ? 1 : ((b.publishedDate > a.publishedDate) ? -1 : 0));
            //console.log(sortedCourses);
            sortedCourses = this.state.coursesCurrentlyInProgress;
            let progtours = sortedCourses.sort((a: any, b: any) => (a.tourProgress.startedAt > b.tourProgress.startedAt) ? 1 : ((b.tourProgress.startedAt > a.tourProgress.startedAt) ? -1 : 0));
            sortedCourses = this.state.coursesCompleted;
            let comptours = sortedCourses.sort((a: any, b: any) => (a.tourProgress.completedAt > b.tourProgress.completedAt) ? 1 : ((b.tourProgress.completedAt > a.tourProgress.completedAt) ? -1 : 0));
            this.setState({
                newTours: newtours,
                tours: progtours,
                completedTours: comptours

            });
        }

    };

    render() {
        return (
  
          <Row id="main"  style={{marginBottom:""}}>
                <Row style={{ padding: "20px", marginBottom: "5px" }} className={this.props.auth.layout.bgColor}>
                <Col xs={24} sm={24} md={12}></Col>
                <Col xs={24} sm={24} md={5}>
                    <span className={this.props.auth.layout.filterColor} style={{display: "inline-block",marginRight: "10px"}}>Filter By :</span>
                    <Cascader 
                    style={{ marginRight: "10px", marginLeft: "5px", textAlign: "left", width: "150px" }}
                    options={filters}
                    value={[this.state.value]}
                    onChange={this.onFilter} />
                </Col>
                <Col xs={24} sm={24} md={7} style={{textAlign: "right"}}><span className={this.props.auth.layout.sortColor}style={{display: "inline-block",marginRight: "10px"}}>Sort Courses By :</span><Cascader style={{ marginRight: "10px", textAlign: "left"  }} options={options} onChange={this.onChangeAvailable} /></Col>
                </Row>
          {this.ProgressComponents()} 
          {this.AvailableComponents()}
          </Row>
        );  
      }
}
function mapToStateToProps(state: any) {
  return {
      auth: state.auth,
      management: state.management,
      
  };
}
export default connect(mapToStateToProps)(LandingPage);