import { CHANGE_MENU } from '../../actions/types';
import { Management, ActionManagement } from '../../models/Management';

const initialState: Management = {
    menuSettings: {
        menu: '',
        role: '',
    }
};

export default (state = initialState, action: ActionManagement) => {
    switch (action.type) {
        case CHANGE_MENU:
            return {
                menuSettings: action.menuSettings,
            };
        default:
            return state;
    }
};
