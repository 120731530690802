import React, { Component } from 'react';
import { logout } from '../actions/authActions';
import {
    defaultManaged,
    emptyManaged,
    changeManaged,
} from '../actions/management-actions/management-actions';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import Head from './Head/Head';
import SideMenu from './Sider/SideMenu';
import { LayoutProps } from './modules';
import LoginBackground from '../assets/Logos/login-background.jpg';

const { Content, Footer,  } = Layout;//Sider

class LayOut extends Component<LayoutProps, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            collapsed: false,
        };
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    render() {
        const { isAuthenticated } = this.props.auth;
        const { display_name } = this.props.auth.user;
        
        return (
            <div style={{ height: '100%' }}>{console.log(display_name)}
            {
            (isAuthenticated && !window.location.href.includes("/companySelection") && !window.location.href.includes("/management/login") && !window.location.href.includes("/user/login") && !window.location.href.includes("/landing"))?(
            <Layout style={{ minHeight: '100vh' }} >
                {
                        (display_name !== "shared content") ?
                        <SideMenu
                            {...this.props}
                        />:null
                }
                <Layout className={(this.props.auth.layout)?this.props.auth.layout.containerColor:""}> 
                    {
                        (display_name !== "shared content") ?
                        <Head
                            {...this.props}
                         />:null
                    }
                    
                    <Content>
                        {
                            (display_name==="shared content")?
                             <div
                                id="container"
                                style={{
                                    paddingTop: '10vh',
                                    backgroundImage: `url(${LoginBackground})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    height: '100vh',
                                }}
                                className={`container ${(this.props.auth.layout)?this.props.auth.layout.containerColor:""}`}
                            >
                                {this.props.children}
                            </div> 
                            :
                            <div
                                id="container"
                                className={`container ${(this.props.auth.layout)?this.props.auth.layout.containerColor:""}`}
                            >
                                {this.props.children}
                            </div>
                        }
                        <div style={{ clear: 'both' }}></div>
                    </Content>
                    <Footer id="footer" className={(this.props.auth.layout)?this.props.auth.layout.footer:""} style={{ textAlign: 'center' }}>
                        <span><strong>Powered with  &hearts; by BSD.</strong></span>
                    </Footer>
                </Layout>
            </Layout >
            ) : (
            <div
                style={{
                    paddingTop: '10vh',
                    backgroundImage: `url(${LoginBackground})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    height: '100vh',
                }}
            >
                {this.props.children}
            </div>
            )}
            </div>
        );
    }
}
function mapToStateToProps(state: any) {
    return {
        auth: state.auth,
        management: state.management,
        
    };
}

export default connect(
    mapToStateToProps,
    { logout, defaultManaged, emptyManaged, changeManaged },
    null,
    { pure: false }
)(LayOut);
