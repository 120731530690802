import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login, logout } from '../../actions/authActions';
import { changeManagement } from '../../actions/management-actions/management-actions';
import { isValid } from '../../utils/tools';
// import PropTypes from 'prop-types';
import { Form, Icon, Input, Button, message, Modal, Row, Col, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import {
    defaultUserCompPermission,
    userCompanyPermission,
} from '../../actions/permissionActions';
import { Permission } from '../../Interfaces/Permission';
import { loginUser, loginUserPermsAll, userPermission } from '../../api/JanusHostApiPost';
import { setCompanyHeaderLogin} from '../../utils/setAuthToken';
import axios from 'axios';

interface Props {
    login: (data: string) => {};
    logout: Function;
    changeManagement: Function;
    defaultUserCompPermission: Function;
    userCompanyPermission: Function;
    location: any;
}

interface State {
    email: string;
    password: string;
    errors: any;
    loading: boolean;
    showModal: boolean;
    companyData: any;
    layout: any;
}

let USER_ROLE = '';
let PAGE_ROLE = 'Management';

let REQUEST_ERROR = {
    isError: false,
    message: '',
};

// Info if a normal user trys to login here, kick them out
// if manager login send them to vr safety with the manager permissions
// if a user owner or admin send them to janus host
class LoginForm extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            errors: [],
            loading: true,
            showModal: false,
            companyData: {},
            layout: {},
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        await this.setState({ loading: false });
    }

    async onSubmit(e: any) {
        this.setState({ loading: true });
        e.preventDefault();
        let checker = isValid({
            email: this.state.email,
            password: this.state.password,
        });
        if (checker.isValid) {
            await loginUser(
                {
                    email: this.state.email,
                    password: this.state.password,
                },
                this.UserLoginSuccess,
                this.errorUserPermissions
            );
        }
        this.setState({ errors: checker.errors, loading: false });
    }

    SuccessLoginAfterProccess = async (data: any) => {
        console.log("The Data that comes from user login perms is!!!!!!! " + JSON.stringify(data));
        if (USER_ROLE === 'Company Owner') {
            await this.props.changeManagement(
                {
                    menu: `${USER_ROLE} Login`,
                    role: USER_ROLE,
                }
            );
            const hide = message.success('Loading...');
            await setTimeout(hide, 2000);
        } else if (USER_ROLE === 'Company Admin' || USER_ROLE === 'Company Manager' || USER_ROLE === 'User') {
            if (data.relation.permissions == "00000" || !data.relation.connected) {
                await this.props.logout();
                const hide = message.error('Contact your company Owner to grant you permissions');
                await setTimeout(hide, 5000);
            }
            else {
                if (!REQUEST_ERROR.isError) {
                    await this.props.changeManagement(
                        {
                            menu: USER_ROLE === 'User' ? 'VRSafety Login' : `${USER_ROLE} Login`,
                            role: USER_ROLE,
                        }
                    );
                    const hide = message.success('Loading...');
                    await setTimeout(hide, 500);

                    //Old Redirect Logic
                    //let URLpath = '/overview';
                    //switch (USER_ROLE) {
                    //    case 'Company Admin':
                    //        //URLpath = '/home';
                    //        const hide = message.success('Loading...');
                    //        await setTimeout(hide, 2000);
                    //        break;
                    //    case 'Company Manager':
                    //        URLpath = '/manage';
                    //        window.location.href = URLpath;
                    //        break;
                    //    case 'User':
                    //    default:
                    //        URLpath = '/home';
                    //        window.location.href = URLpath;
                    //        break;
                    //}
                    //if (PAGE_ROLE === 'Management') {
                    //    URLpath = '/manage';
                    //} else {
                    //    URLpath = '/home';
                    //}
                } else if (USER_ROLE !== PAGE_ROLE) {
                    await this.props.logout();
                    const hide = message.error('You are not allowed to login here.');
                    await setTimeout(hide, 5000);
                } else {
                    await this.props.logout();
                    const hide = message.error(REQUEST_ERROR.message);
                    // Dismiss manually and asynchronously
                    await setTimeout(hide, 5000);
                }
            }
        }
        window.location.href = '/home';
    };

    UserLoginSuccess = async (data: any) => {
        await this.props.login(data);
        await loginUserPermsAll(this.successUserLoginPerms);
    };

    tokenSuccess = (data: any) => {
        console.log(data);
        localStorage.setItem('companyToken', JSON.stringify(data.data.company));
    }

    successUserLoginPerms = async (data: any) => {
        console.log(data);
        

        let userCompService = {
            params: {
                User: data.userId,
                Company: data.company[0].id,
                Service: data.serviceId,
                Role: PAGE_ROLE,
            },
        };
        
        if (data.company.length > 1) {
            this.setState({ showModal: true, companyData: data });
        }
        else {
            this.setState({ layout: data.company[0].layoutClasses });
            localStorage.setItem('layout', JSON.stringify(data.company[0].layoutClasses, null, 2));
            setCompanyHeaderLogin(data.company[0]);
        await userPermission(
            userCompService,
            async (data: any) =>
                await this.successUserPermissions(data, data.company),
            this.errorUserPermissions
        );
        }
    };

    selectCompany = async (selected: any) => {

        console.log("company selected");
        console.log(this.state.companyData);
        this.setState({ layout: selected.layoutClasses });
        localStorage.setItem('layout', JSON.stringify(selected.layoutClasses, null, 2));
        let data = this.state.companyData;
        setCompanyHeaderLogin(selected);
        let userCompService = {
            params: {
                User: data.userId,
                Company: selected.id,
                Service: data.serviceId,
                Role: PAGE_ROLE,
            },
        };

        await userPermission(
            userCompService,
            async (data: any) => {
                console.log(data);
                await this.successUserPermissions(data, data.company)
            },
                this.errorUserPermissions
            );
    }

    errorUserPermissions = async (data: any) => {
        REQUEST_ERROR = {
            isError: true,
            message: data,
        };
        //message.error(data.response.data);
    };

    UserCompanyService(data: any) {
        return;
    }

    successUserPermissions = async (data: any, company: any) => {
        USER_ROLE = data.relation.role;
        console.log(data);
        let perms: Permission = {
            company: {
                id: company.id,
                logo: company.logoImage,
                banner: company.bannerImage,
                name: company.legalEntityName,
            },
            relation: {
                connected: data.relation.connected,
                permissions: data.relation.permissions,
                role: USER_ROLE,
            },
            service: data.service,
            components: {}
        };
        perms.service.layout = this.state.layout;
        await axios.get(`/api/v1/vrsafety/component/getComponentAccess`)
            .then((res: any) => {
                let components:any= {
                    names: [],
                    paths:[]
                };
                components.names= res.data;
                components.paths = ["/overview", "/manage", "/coursedetails/:id", "/manageAccess", "/home", "/details/:id", "/"];
                if (res.data.includes("Tour Builder")) {
                    components.paths.push("/tour/:component/:id?","/viewer/:id");
                }
                if (res.data.includes("Grades")) {
                    components.paths.push("/manage-grades", "/teammate-grades/:id","/grades");
                }
                if (res.data.includes("Tags"))
                    components.paths.push("/viewTags", "/updateTag/:id", "/createTag");

                if (res.data.includes("Company Profile")) {
                    components.paths.push("/company");
                }
                if (res.data.includes("Progress"))
                    components.paths.push("/progress");
                if (res.data.includes("Manage Access"))
                    components.paths.push("/manageAccess");

                perms.components = components;
            })
            .catch((err: any) => {
                console.log(err);
            })

        console.log(perms);
        //await this.props.changeManagement(
        //    {
        //        menu: USER_ROLE === 'User' ? 'VRSafety Login' : `${USER_ROLE} Login`,
        //        role: USER_ROLE,
        //    },
        //    company
        //);
        console.log(perms);
        await this.props.userCompanyPermission(perms);
        await this.SuccessLoginAfterProccess(data);
        
    };

    handleChange = (input: any) => (e: any) => {
        this.setState({ [input]: e.target.value } as any);
    };

    

    render() {
        return (
            <div>{
                (this.state.showModal) ?
                    <Row style={{ overflowX: "hidden", overflowY: "auto", flexDirection: "column", height: "310px", display: "flex", flexWrap: "nowrap" }}>
                        <Row type="flex" justify="space-between" align="middle">
                        {
                            this.state.companyData.company.map((company: any, index: number) =>
                                <Col key={index} xs={24} sm={24} md={11} style={{ padding: "10px" }} >
                                    <Avatar src={company.logoImage} size={64} />
                                    <p className="whiteText" style={{ height: "30px"}}>{company.legalEntityName}</p>
                                    <Button size="small" className={`bsd-btn-orange`} onClick={ ()=>this.selectCompany(company)}>Manage</Button>
                                </Col>
                            )
                        }
                        </Row>
                    </Row>
                :
            
            <div
                style={{
                    textAlign: 'center',
                    minHeight: 280,
                    maxWidth: 300,
                    margin: '0 auto',
                }}
            >
                <h1 className="orangeText bebas">Management Login</h1>
                <Form onSubmit={this.onSubmit} className="login-form">
                    <Form.Item
                        validateStatus={this.state.errors.email ? 'error' : ''}
                        help={this.state.errors.email || ''}
                    >
                        <Input
                            prefix={
                                <Icon
                                    type="mail"
                                    style={{ color: 'rgba(0,0,0,.25)' }}
                                />
                            }
                            type="email"
                            value={this.state.email}
                            placeholder="Email"
                            className="bsd-input-teal"
                            onChange={this.handleChange('email')}
                        />
                    </Form.Item>
                    <Form.Item
                        validateStatus={
                            this.state.errors.password ? 'error' : ''
                        }
                        help={this.state.errors.password || ''}
                    >
                        <Input
                            prefix={
                                <Icon
                                    type="lock"
                                    style={{ color: 'rgba(0,0,0,.25)' }}
                                />
                            }
                            type="password"
                            value={this.state.password}
                            placeholder="Password"
                            onChange={this.handleChange('password')}
                        />
                    </Form.Item>
                    <Form.Item>
                        {this.state.loading ? (
                            <Button type="primary" loading>
                                Loading
                            </Button>
                        ) : (
                            <Button
                                htmlType="submit"
                                style={{
                                    padding: '0px 30px',
                                }}
                                className={`bsd-btn-orange`}
                            >
                                Sign in
                            </Button>
                            )}
                        <br />
                        <Link
                            className={`bsd-text-orange`}
                            to={`/forgot-password`}
                        >
                            Forgot password
                        </Link>
                    </Form.Item>
                </Form>
                    </div>
            }
            </div>
        );
    }
}

export default connect(null, {
    login,
    logout,
    changeManagement,
    defaultUserCompPermission,
    userCompanyPermission,
})(LoginForm);
