import React, { Component } from 'react';
import { Steps, Icon } from 'antd';
import { isNullOrUndefined } from 'util';
import PanoSequence from '../CreatePanoSequence/PanoSequence';
import CreateTour from '../CreateTour/CreateTour';
import Preview from '../PreviewTour/PreviewTour';
import Publish from '../PublishTour/PublishTour';
import Coordinates from '../AssignCoordinates/Coordinates';
import { connect } from 'react-redux';

const { Step } = Steps;

const progress= [
    {
        title:"Create Tour",
        description:"",
        // url:"/create/",
        icon:"form",
    },
    {
        title:"Upload 360 Images",
        description:"",
        // url:"/sequence/",
        icon:"upload",
    },
    {
        title:"Create Hotspots",
        description:"",
        // url:"/coordinates/",
        icon:"fire",
    },
    {
        title:"Preview",
        description:"",
        // url:"/preview/",
        icon:"play-circle",
    },
    {
        title: "Publish",
        description: "",
        // url:"/publish/",
        icon: "cloud-upload",
    },
];
interface State {
   current:number
};

class ProgressBar extends Component<any, State> {
    state:State={
        current:-1,
    }
    public constructor(props:any){
        super(props);
      }
      componentDidMount(){
        // console.log(this.props.match.params.component);
        this.setState({current:parseInt(this.props.match.params.component)});
      }
      componentDidUpdate(prevProps:any) {
        // Typical usage (don't forget to compare props):
        if (this.props.match.params.component !==prevProps.match.params.component||this.props.match.params.id !== prevProps.match.params.id) 
            {
                this.setState({current:parseInt(this.props.match.params.component)})
            }
        }
      onChange = (current:any) => {
        console.log('onChange:', current);
        this.props.history.push(`/tour/${current}/${this.props.match.params.id}`);
        //this.setState({ current: current });
      };
      next=()=>{
        this.props.history.push(`/tour/${this.state.current+1}/${this.props.match.params.id}`);
        //   this.setState({current:this.state.current+1});
          
      }
      prev=()=>{
        this.props.history.push(`/tour/${this.state.current-1}/${this.props.match.params.id}`);
        //   this.setState({current:this.state.current+1});
          
      }
      getStatus(index:number)
      {
        let status:'wait' | 'process' | 'finish' | 'error'="wait";
        if(this.state.current>index)
        {
           status="finish";
        }
        else if(this.state.current===index)
        {
            status="process";
        }
        else
        {
            status="wait";
        }
        return status;
      }
      getProgressComponent(current:number)
      {
        switch(current){
            case 0:
                return <CreateTour next={this.next} {...this.props} />;
            case 1:
                return <PanoSequence next={this.next} prev={this.prev} {...this.props} />;
            case 2:
                return <Coordinates next={this.next} prev={this.prev} {...this.props} />;
            case 3:
                return <Preview next={this.next} prev={this.prev} {...this.props} />;
            case 4:
                return <Publish next={this.next} prev={this.prev} {...this.props} />;
            default:
                return <div>Something went wrong</div>;
        }
      }
    render() {
        return (
            <div style={{ width: "100%", padding: "14px 0" }} className={this.props.auth.layout.bgColor}>
            <Steps
             type="navigation"
             current={this.state.current}
             onChange={this.onChange}
             className={this.props.auth.layout.styleClass}
             >
                {
                    progress.map((step,index:number)=>
                        <Step style={{ width: "20%" }} key={index} disabled={this.state.current===0 && isNullOrUndefined(this.props.match.params.id) && index !==0} status={this.getStatus(index)} title={step.title} icon={<Icon type={step.icon} />} />
                    )
                }
            </Steps>
            <div>
                {
                    this.getProgressComponent(this.state.current)
                }
            </div>
          </div>
        );
}
}
function mapToStateToProps(state: any){
    return {
      auth: state.auth,
      management: state.management,
    };
  }
export default connect(mapToStateToProps)(ProgressBar);