import React, { Component } from 'react';
import { Layout, Dropdown, Icon, Menu, message, Typography, Avatar, Row, Modal, Button, Table, Switch, Spin, Input, Col, Select, Drawer } from 'antd';
import { HeadProps } from '../modules';
import { getCurrentDate, createMySqlDate } from '../../utils/tools';
import tour_icon from '../../assets/Icons/VRS_asset_360globe_icon.png';
import { updatePublishedArray, deleteTour, createTag, updateTag, deleteTag } from '../../api/APIpost';
import { getAllTours, getCompanyTag } from '../../api/APIget';
import CompanyProfile from '../../components/CompanyProfile/CompanyProfile';
import { AWS_URL } from '../../app/FrontEndVariables';
const { confirm } = Modal;

const { Header, Sider } = Layout;
const { Text } = Typography;

interface State {
    manageCourseModal: boolean,
    manageTagsModal: boolean,
    manageCourses: any[],
    manageCoursesUpdate: any[],
    tagList: any[],
    loading: boolean,
    searchText: string,
    searchedColumn: string,
    tagName: string,
    selectedAccess: string,
    accessLevel: any[],
    updateTag: any,
    collapsed: boolean,
    showCompany: boolean
};

class SideMenu extends Component<HeadProps, {}> {
    state: State =
        {
            manageCourseModal: false,
            manageCourses: [],
            manageCoursesUpdate: [],
            manageTagsModal: false,
            tagList: [],
            loading: true,
            searchText: '',
            searchedColumn: '',
            tagName: "",
            selectedAccess: "",
            accessLevel: [{ id: 0, name: "Public" }, { id: 1, name: "Internal" }, { id: 2, name: "Private" }],
            updateTag: {},
            collapsed: true,
            showCompany: false
        };
    constructor(props: any) {
        super(props);

        this.handleMenuClick = this.handleMenuClick.bind(this);
    }

    onCollapse = (collapsed:any) => {
        console.log(collapsed);
        this.setState({ collapsed });
    };

    onClose = () => {
        this.setState({ showCompany: false });
    }

    handleMenuClick(e: any) {
        switch (e.key) {
            case 'profile':
                window.location.href = "/profile";
                break;
            case 'gradeDetails':
                window.location.assign("/grades");
                break;
            case 'progress':
                window.location.assign("/progress");
                break;
            case 'home':
                window.location.assign("/home");
                break;
            case 'AdminHome':
                window.location.assign("/home");
                break;
            case 'ManagerHome':
                window.location.assign("/manage");
                break;
            case 'VRBuilder':
                window.location.assign("/tour/0/");
                break;
            case 'ManageCourses':
                this.manageCoursesOpen();
                break;
            case 'ManageTags':
                this.manageTagsOpen();
                break;
            case 'ManageGrades':
                window.location.assign("/manage-grades");
                break;
            case 'CompanyProfile':
                //window.location.assign("/company");
                this.setState({showCompany: true, manageTagsModal: false});
                break;
            case 'Logout':
                this.logout();
                break;
            case 'ManageComponents':
                window.location.assign("/manageComponentAccess");
                break;
            default:
                message.error('This should not happen.');
                break;
        }
    }

    logout = async () => {
        //console.log("clicked");
        await this.props.logout();
        window.location.assign("/login");
        
    }
    handleChange = (input: any) => (e: any) => {
        this.setState({ [input]: e.target.value } as any);
    }

    onSelectChange = (e: any) => {
        this.setState({ selectedAccess: e } as any);
    }

    direct = (location: string) => {
        window.location.href = location;
    }



    getToursSuccess = (data: any) => {
        console.log(data.data);
        this.setState({ manageCourses: data.data, manageCoursesUpdate: data.data, loading: false });
    }
    getToursError = (data: any) => {
        console.log(data);
    }
    manageCoursesOpen() {
        this.setState({ manageCourseModal: true, loading: true });
        getAllTours(this.getToursSuccess, this.getToursError);

    }

    manageCoursesClose = () => {
        this.setState({ manageCourseModal: false });
    }

    handleOk = () => {
        console.log(this.state.manageCoursesUpdate);
        this.setState({ manageCourseModal: false });
        let updatepublished = this.state.manageCoursesUpdate;
        updatePublishedArray(updatepublished, this.updateSuccess, this.updateError);
    }

    updateSuccess = (data: any) => {
        message.success(data.data);
    }

    updateError = (data: any) => {
        message.error("An unexpected error has occured");
        console.log(data);
    }

    delete = (tourId: string) => {

        console.log(tourId)
        confirm({
            title: 'Deleting this course will remove all progress and grades for this tour permanantly.',
            content: 'Are you sure?',
            okText: 'Delete',
            okButtonProps: { icon: "delete" },
            okType: 'danger',
            cancelText: 'No, Cancel',
            onOk: () => {
                    this.setState({ loading: true });
                    deleteTour(tourId, this.deleteSuccess, this.error);
            },
            onCancel() { },
        });
    }
    deleteSuccess = (data: any) => {
        console.log(data.data);
        Modal.success({
            content: "The tour was deleted successfully"
        });
        this.setState({ manageCourseModal: true });
        getAllTours(this.getToursSuccess, this.getToursError);
    }
    error = (error: any) => {
        this.setState({ loading: false });
        console.log(`error!`);
        console.log(error);
        alert('An Error occurred. Check the console for more information');
    }

    handleSearch = (data: any) => {
        data.confirm();
        this.setState({
            searchText: data.selectedKeys[0]
        });
    };

    handleReset = (data: any) => {
        data.clearFilters();
        this.setState({ searchText: '' });
    };

    changePublish = (tour: any) => (event: any) => {
        let update = this.state.manageCoursesUpdate;
        console.log(event);
        let index = update.findIndex((course) => course.id === tour.id)
        if (index > -1) {
            let date = new Date();
            update[index].status = (event) ? 2 : 1;
            if (event) {
                let fiveYears = date.getFullYear() + 5;
                date.setUTCFullYear(fiveYears);
                update[index].publishedDate = getCurrentDate();
                update[index].publishUntil = createMySqlDate(date.toISOString());
            }
            else {
                let lastMonth = date.getUTCMonth() - 1;
                date.setUTCMonth(lastMonth);
                update[index].publishedDate = createMySqlDate(date.toISOString());
                let now = new Date();
                let yesterday = now.getUTCDate() - 1;
                now.setUTCDate(yesterday);
                update[index].publishUntil = createMySqlDate(now.toISOString());
            }

        }
        console.log(update);
        this.setState({ manageCoursesUpdate: update });
    }
    manageTagsOpen = () => {
        console.log(this.props);
        this.setState({ manageTagsModal: true, showCompany: false, loading: true });
        getCompanyTag(this.props.auth.company.id, this.getTagsSuccess, this.error);
    }

    getTagsSuccess = (data: any) => {
        this.setState({ tagList: data.data, loading: false });
    }
    manageTagsClose = () => {
        this.setState({ manageTagsModal: false });
    }
    submitTag = () => {
        let tag = this.createTagObject();
        console.log(tag);
        createTag(tag, this.createTagSuccess, this.error);
    }

    createTagSuccess = (data: any) => {
        console.log(data.data);
        message.success("Tag was created Successfully");
        this.setState({ tagList: [data.data, ...this.state.tagList] });
    }

    onAccessChange = (tag: any) => (e: any) => {
        if (tag.accessLevel !== e) {
            this.setState({ loading: true });
            tag.accessLevel = e;
            let updatedTag = this.createUpdatedTagObject(tag);
            tag.accessLevelId = updatedTag.AccessLevelId;
            console.log(tag);
            this.setState({ updateTag: tag });
            updateTag(updatedTag, this.updateTagSuccess, this.error);
        }
    }
    createTagObject() {
        let accessLevel = this.state.accessLevel.find((access) => access.name === this.state.selectedAccess);
        return {
            Name: this.state.tagName,
            AccessLevel: this.state.selectedAccess,
            AccessLevelId: accessLevel.id,
        };
    }
    createUpdatedTagObject(tag: any) {
        let accessLevel = this.state.accessLevel.find((access) => access.name === tag.accessLevel);
        return {
            Id: tag.id,
            Name: tag.name,
            AccessLevel: tag.accessLevel,
            AccessLevelId: accessLevel.id,
            UpdatedAt: getCurrentDate()
        };
    }
    deleteSeletectTag = (id: string) => {
        confirm({
            title: 'Do you want to PERMENANTLY delete this tag?',
            content: 'This will affect all tours that currently have this tag',
            onOk: () => {
                this.setState({ loading: true });
                deleteTag(id, this.deleteTagSuccess, this.error);

            },
            onCancel: () => {
            },
        });
    }
    updateTagSuccess = (data: any) => {
        console.log(this.state.updateTag);
        let removedTagList = this.state.tagList.filter((tag => {
            return tag.id !== this.state.updateTag.id
        }));
        console.log(removedTagList);
        this.setState({ tagList: [this.state.updateTag, ...removedTagList], loading: false });
    }
    deleteTagSuccess = (data: any) => {
        this.setState({
            tagList: this.state.tagList.filter((tag => {
                return tag.id !== data.data
            })), loading: false
        });
    }


    render() {
        const permissions = ["Create", "Update", "Delete"]
        const columns = [
            {
                title: "avatar",
                key: 'tourIcon',
                dataIndex: 'tourIcon',
                colSpan: 0,
                render: (avatar: any) =>
                    (

                        <div>
                            {
                                (avatar === "" || avatar === null) ?
                                    <Avatar shape="square" src={tour_icon} />
                                    :
                                    <Avatar shape="square" src={AWS_URL + avatar} />
                            }
                        </div>
                    )
            },
            {
                title: "Course Name",
                dataIndex: 'tourName',
                key: 'tourName',
                colSpan: 2,
                sorter: (a: any, b: any) => ((a.tourName > b.tourName) ? 1 : ((b.tourName > a.tourName) ? -1 : 0)),
                filterDropdown: (data: any) => (
                    //({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        <Input
                            placeholder={`Search Course Name`}
                            value={data.selectedKeys[0]}
                            onChange={e => data.setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => this.handleSearch(data)}
                            style={{ width: 188, marginBottom: 8, display: 'block' }}
                        />
                        <Button
                            type="primary"
                            onClick={() => this.handleSearch(data)}
                            icon="search"
                            size="small"
                            style={{ width: 90, marginRight: 8 }}
                        >
                            Search
                      </Button>
                        <Button onClick={() => this.handleReset(data)} size="small" style={{ width: 90 }}>
                            Reset
                      </Button>
                    </div>
                ),
                filterIcon: (filtered: any) => (
                    <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
                ),
                onFilter: (value: any, record: any) => {
                    return record['tourName']
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())

                },
                onFilterDropdownVisibleChange: (visible: any) => { }
            },
            {
                title: "Publish",
                key: 'publish',
                render: (course: any) =>
                    (
                        <span>
                            Publish <Switch checkedChildren="Enabled" unCheckedChildren="Disabled" onChange={this.changePublish(course)} checked={course.status === 2} />
                        </span>
                    )
            },
            {
                title: "Delete",
                key: 'delete',
                render: (tour: any, record: any) =>
                    (
                        <span>
                            <Button icon="delete" type="danger" ghost onClick={() => this.delete(tour.id)} />
                        </span>
                    ),
            }
        ];
        const tagColumns = [
            {
                title: "Tag Name",
                dataIndex: 'name',
                key: 'name',
                sorter: (a: any, b: any) => ((a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0)),
                filterDropdown: (data: any) => (
                    <div style={{ padding: 8 }}>
                        <Input
                            placeholder={`Search Tag Name`}
                            value={data.selectedKeys[0]}
                            onChange={e => data.setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => this.handleSearch(data)}
                            style={{ width: 188, marginBottom: 8, display: 'block' }}
                        />
                        <Button
                            type="primary"
                            onClick={() => this.handleSearch(data)}
                            icon="search"
                            size="small"
                            style={{ width: 90, marginRight: 8 }}
                        >
                            Search
                      </Button>
                        <Button onClick={() => this.handleReset(data)} size="small" style={{ width: 90 }}>
                            Reset
                      </Button>
                    </div>
                ),
                filterIcon: (filtered: any) => (
                    <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
                ),
                onFilter: (value: any, record: any) => {
                    return record['name']
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())
                },
                onFilterDropdownVisibleChange: (visible: any) => { },
            },
            {
                title: "Type",
                key: 'access',
                filters: [
                    {
                        text: 'Public',
                        value: 'Public',
                    },
                    {
                        text: 'Internal',
                        value: 'Internal',
                    },
                    {
                        text: 'Private',
                        value: 'Private',
                    },
                ],
                onFilter: (value: any, tag: any) => tag.accessLevel.indexOf(value) === 0,
                render: (tag: any) =>
                    (
                        <Select
                            placeholder="Select Access Level"
                            onChange={this.onAccessChange(tag)}
                            value={tag.accessLevel}
                        >
                            {this.state.accessLevel.map((item: any, id: number) => (
                                <Select.Option key={id} value={item.name}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    )
            },
            {
                title: "Delete",
                key: 'deleteTags',
                render: (tag: any, record: any) =>
                    (
                        <span>
                            <Button icon="delete" type="danger" ghost onClick={() => this.deleteSeletectTag(tag.id)} />
                        </span>
                    ),
            }
        ];
        const { collapsed } = this.state;
        return (
            <div className="blackBG">{
                (this.props.auth.isAuthenticated && (this.props.auth.user.family_name !== "content" && this.props.auth.user.given_name !== "shared")) ?
                    <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse} collapsedWidth={70}>

                        <div className="logo" onClick={() => { (this.props.management.menuSettings.role === "Company Manager") ? this.direct("/manage") : this.direct("/home") }}>
                            <button className="StrippedButton" title="Return Home" onClick={() => { (this.props.management.menuSettings.role === "Company Manager") ? this.direct("/manage") : this.direct("/home") }}>
                                <div><img alt={this.props.auth.layout.name}
                                    style={{
                                        fontSize: '20px',
                                        height: 30, display: "inline-block",
                                    }}
                                    className="logo_img"
                                    src={this.props.auth.layout.logo}
                                /><span>{this.props.auth.layout.name}</span></div>  
                            </button>
                        </div>
                        {
                            (this.props.auth.isAuthenticated && (this.props.auth.user.family_name !== "content" && this.props.auth.user.given_name !== "shared")) ?
                                <div className={this.props.auth.layout.styleClass}>
                                    {

                                        this.props.auth.permissions.some((item: any) => permissions.includes(item))
                                            ?
                                            (this.props.auth.user.type === "company manager") ?
                                                <Menu onClick={this.handleMenuClick} theme="dark" >
                                                    <Menu.Item key="ManagerHome" className={((window.location.href.includes("/manage") && !window.location.href.includes("/manage-grades")) ? "ant-menu-item-selected" : "")} ><Icon type="home" className="sideMenuIcons" style={{ fontSize: '25px' }} /><span>Manager Home</span></Menu.Item>
                                                    {
                                                        (this.props.auth.components.names.includes("Tour Builder")) ?
                                                            <Menu.Item key="VRBuilder" className={((window.location.href.includes("/tour/")) ? "ant-menu-item-selected" : "")}><Icon type="edit" className="sideMenuIcons" style={{ fontSize: '25px' }} />Create Tour</Menu.Item>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        (this.props.auth.components.names.includes("Grades")) ?
                                                            <Menu.Item key="ManageGrades" className={((window.location.href.includes("/manage-grades")) ? "ant-menu-item-selected" : "")}><Icon type="file" className="sideMenuIcons" style={{ fontSize: '25px' }} />Manage Grades</Menu.Item>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        (this.props.auth.components.names.includes("Tags")) ?
                                                            <Menu.Item key="ManageTags" ><Icon type="tag" className="sideMenuIcons" style={{ fontSize: '25px' }} />Manage Tags</Menu.Item>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        (this.props.auth.components.names.includes("Company Profile")) ?
                                                            <Menu.Item key="CompanyProfile" className={((window.location.href.includes("/company")) ? "ant-menu-item-selected" : "")}><Icon type="bank" className="sideMenuIcons" style={{ fontSize: '25px' }} />Company Profile</Menu.Item>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        (this.props.auth.components.names.includes("Manage Courses")) ?
                                                            <Menu.Item key="ManageCourses" ><Icon type="tool" className="sideMenuIcons" style={{ fontSize: '25px' }} />Manage Courses</Menu.Item>
                                                            :
                                                            null
                                                    }<Menu.Item key="Logout" ><Icon type="logout" className="sideMenuIcons" style={{ fontSize: '25px' }} />Logout</Menu.Item>
                                                </Menu>
                                                :
                                                <Menu onClick={this.handleMenuClick} theme="dark" >
                                                    <Menu.Item key="ManagerHome" className={((window.location.href.includes("/manage") && !window.location.href.includes("/manage-grades")) ? "ant-menu-item-selected" : "")} ><Icon type="home" className="sideMenuIcons" style={{ fontSize: '25px' }} />Manager Home</Menu.Item>
                                                    <Menu.Item key="AdminHome" ><Icon type="crown" className="sideMenuIcons" style={{ fontSize: '25px' }} />Admin Home</Menu.Item>
                                                    {
                                                        (this.props.auth.components.names.includes("Tour Builder")) ?
                                                            <Menu.Item key="VRBuilder" className={((window.location.href.includes("/tour/")) ? "ant-menu-item-selected" : "")}><Icon type="edit" className="sideMenuIcons" style={{ fontSize: '25px' }} />Create Tour</Menu.Item>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        (this.props.auth.components.names.includes("Grades")) ?
                                                            <Menu.Item key="ManageGrades" className={((window.location.href.includes("/manage-grades")) ? "ant-menu-item-selected" : "")}><Icon type="file" className="sideMenuIcons" style={{ fontSize: '25px' }} />Manage Grades</Menu.Item>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        (this.props.auth.components.names.includes("Tags")) ?
                                                            <Menu.Item key="ManageTags" ><Icon type="tag" className="sideMenuIcons" style={{ fontSize: '25px' }} />Manage Tags</Menu.Item>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        (this.props.auth.components.names.includes("Company Profile")) ?
                                                            <Menu.Item key="CompanyProfile" className={((window.location.href.includes("/company")) ? "ant-menu-item-selected" : "")}><Icon type="bank" className="sideMenuIcons" style={{ fontSize: '25px' }} />Company Profile</Menu.Item>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        (this.props.auth.components.names.includes("Manage Courses")) ?
                                                            <Menu.Item key="ManageCourses" ><Icon type="tool" className="sideMenuIcons" style={{ fontSize: '25px' }} />Manage Courses</Menu.Item>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        (this.props.auth.user.email ==="janus@bsdev.ca") ?
                                                            <Menu.Item key="ManageComponents" className={((window.location.href.includes("/manageComponent")) ? "ant-menu-item-selected" : "")}><Icon type="folder" className="sideMenuIcons" style={{ fontSize: '25px' }} />Manage Components</Menu.Item>
                                                            :
                                                            null
                                                    }
                                                    <Menu.Item key="Logout" ><Icon type="logout" className="sideMenuIcons" style={{ fontSize: '25px' }} />Logout</Menu.Item>
                                                </Menu>
                                            :
                                            <Menu onClick={this.handleMenuClick} theme="dark" className="userNav">
                                                <Menu.Item key="home" className={((window.location.href.includes("/home")) ? "ant-menu-item-selected" : "")}><Icon type="home" className="tealText sideMenuIcons" style={{ fontSize: '25px' }} />Home</Menu.Item>
                                                {
                                                    (this.props.auth.components.names.includes("Company Profile")) ?
                                                        <Menu.Item key="CompanyProfile" className={((window.location.href.includes("/company")) ? "ant-menu-item-selected" : "")}><Icon type="bank" className="tealText sideMenuIcons" style={{ fontSize: '25px' }} />Company Profile</Menu.Item>
                                                        :
                                                        null
                                                }
                                                {
                                                    (this.props.auth.components.names.includes("Grades")) ?
                                                        <Menu.Item key="gradeDetails" className={((window.location.href.includes("/grades")) ? "ant-menu-item-selected" : "")}><Icon type="profile" className="tealText sideMenuIcons" style={{ fontSize: '25px' }} />My Grades</Menu.Item>
                                                        :
                                                        null
                                                }
                                                {
                                                    (this.props.auth.components.names.includes("Progress")) ?
                                                        <Menu.Item key="progress" className={((window.location.href.includes("/progress")) ? "ant-menu-item-selected" : "")}><Icon type="star" className="tealText sideMenuIcons" style={{ fontSize: '25px' }} />My Progress</Menu.Item>
                                                        :
                                                        null
                                                }
                                                {
                                                    (this.props.auth.components.names.includes("User Profile")) ?
                                                        <Menu.Item key="profile"><Icon type="user" className="tealText sideMenuIcons" style={{ fontSize: '25px' }} />My Profile</Menu.Item>
                                                        :
                                                        null
                                                }<Menu.Item key="Logout"><Icon type="logout" className="tealText sideMenuIcons" style={{ fontSize: '25px' }} />Logout</Menu.Item>
                                            </Menu>
                                    }
                                </div>
                                :
                                null
                        }

                        <Modal
                            visible={this.state.manageCourseModal}
                            title="Manage Courses"
                            onOk={this.handleOk}
                            onCancel={this.manageCoursesClose}
                            footer={[
                                <Button key="submit" type="primary" onClick={this.handleOk}>
                                    Save Changes
                        </Button>,
                            ]}
                        >
                            <Spin spinning={this.state.loading}>
                                <Table columns={columns} rowKey={record => record.id} dataSource={this.state.manageCoursesUpdate} />
                            </Spin>

                        </Modal>
                        {/*<Modal
                visible={this.state.manageTagsModal}
                title="Create New Tag"
                onCancel={this.manageTagsClose}
                footer={[]}
            >
                <Spin spinning={this.state.loading}>
                    <Row>
                        <Col>
                            <Input placeholder="Tag Name"
                                value={this.state.tagName}
                                allowClear
                                onChange={this.handleChange("tagName")} />
                        </Col>
                        <Col>
                            <Select
                                placeholder="Select Access Level"
                                onChange={this.onSelectChange}
                                style={{ width: '100%' }}
                                value={this.state.selectedAccess}
                            >
                                {this.state.accessLevel.map((item: any, id: number) => (
                                    <Select.Option key={id} value={item.name}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={12}></Col>
                        <Col span={12}>
                            <Button onClick={() => this.submitTag()}>
                                <Icon type="tag" />Create Tag
                                </Button>
                        </Col>
                    </Row>
                    <Row type="flex" justify="center" align="middle">
                        <h3 className="darkBlueText">View, Edit or Delete Tags</h3>
                    </Row>
                    <Table columns={tagColumns} rowKey={record => record.id} dataSource={this.state.tagList} />
                </Spin>

                </Modal>*/}
                        <Drawer
                            title="Company Profile"
                            placement="left"
                            closable={true}
                            onClose={this.onClose}
                            visible={this.state.showCompany}
                            getContainer={false}
                            width="800"
                            className={this.props.auth.layout.styleClass}
                        //key={placement}
                        >

                            <CompanyProfile />

                        </Drawer>

                        <Drawer
                            title="Create New Tag"
                            placement="left"
                            closable={true}
                            onClose={this.manageTagsClose}
                            visible={this.state.manageTagsModal}
                            getContainer={false}
                            width="430"
                        //key={placement}
                        >

                            <Spin spinning={this.state.loading}>
                                <Row>
                                    <Row>
                                        <Input placeholder="Tag Name"
                                            value={this.state.tagName}
                                            style={{ width: "80%" }}
                                            allowClear
                                            onChange={this.handleChange("tagName")} />
                                    </Row>
                                    <Row>
                                        <Select
                                            placeholder="Select Access Level"
                                            onChange={this.onSelectChange}
                                            style={{ width: "80%", marginTop: "10px" }}
                                            value={this.state.selectedAccess}
                                        >
                                            {this.state.accessLevel.map((item: any, id: number) => (
                                                <Select.Option key={id} value={item.name}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Row>
                                    <Row>
                                        <Button style={{ marginTop: "10px", marginBottom: "10px" }} onClick={() => this.submitTag()}>
                                            <Icon type="tag" />Create Tag
                                </Button>
                                    </Row>
                                </Row>
                                <Row type="flex" justify="center" align="middle">
                                    <h3 className="darkBlueText">View, Edit or Delete Tags</h3>
                                </Row>
                                <Table columns={tagColumns} rowKey={record => record.id} dataSource={this.state.tagList} />
                            </Spin>

                        </Drawer>
                    </Sider>
                    : null
            }   
            </div>
        );
    }
}

export default SideMenu;
