import {setAuthToken} from '../utils/setAuthToken'
import jwtDecode from 'jwt-decode';
import { SET_CURRENT_USER, SET_PERMISSIONS } from './types';

export function setCurrentUser(user: any){
  return {
    type: SET_CURRENT_USER,
    user,
  };
}
export function setPermissions(permissions:any){
  return {
    type: SET_PERMISSIONS,
    permissions,
  };
}

export function logout(){
  return async (dispatch: any) => {
    localStorage.clear();
    setAuthToken(false);
    dispatch(setCurrentUser({}));
  }
}

export function login(token: string) {
    return async (dispatch: any) => {
        if (token != null) {
            localStorage.setItem('token', token);
            setAuthToken(token);
            dispatch(setCurrentUser(jwtDecode(token)));
        }
  };
}